import React, { useEffect, useState } from "react";

// 3rd party libraries
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import clsx from "clsx";
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Drawer,
  Divider,
  IconButton
} from "@mui/material";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";

// components
import Splash from "@/components/Splash";
import ReportingCalendar from "@/components/reports/ReportingCalendar";
import JobSlateHeader from "@/components/JobSlateHeader";
import ReportingJobSubmissions from "@/components/reports/ReportingJobSubmissions";
import ReportingJobActivity from "@/components/reports/ReportingJobActivity";
import SubmissionsPanel from "@/components/reports/SubmissionsPanel";

// helpers
import { getStatusStringArray } from "@/helpers/statuses";
import { constants as styleConstants } from "@/styles/config";

// constants
const DRAWER_WIDTH = 400;

// styles
const useStyles = makeStyles((theme) => ({
  container: {
    padding: styleConstants.desktopDrawerClosedWidth / 2,
    maxWidth: "100vw",
    minHeight: "100vh"
  },
  formControl: {
    margin: 0,
    borderRadius: 4
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    zIndex: 4000
  },
  drawerContainer: {
    overflow: "auto"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("width", {
      easing: "sharp",
      duration: "0.3s"
    }),
    marginRight: -DRAWER_WIDTH,
    width: "100%"
  },
  contentShift: {
    transition: theme.transitions.create("width", {
      easing: "easeOut",
      duration: "0.3s"
    }),
    marginRight: 0,
    width: "100%"
  }
}));

// render
export default function InternalReportingJobView() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { id } = useParams();
  const { loading } = useSelector((state) => state.reporting);
  const { submissionsLoading, submissions, currentJob } = useSelector((state) => state.jobs);
  const [submissionView, setSubmissionView] = useState("team");
  const [reportingInitialized, setReportingInitialized] = useState(false);
  const [reportingDrawerOpen, setReportingDrawerOpen] = useState(false);
  const [currentDayData, setCurrentDayData] = useState(null);
  const [currentSubmissionStatus, setCurrentSubmissionStatus] = useState(null);
  const allStatuses = getStatusStringArray();
  const [selectedStatuses] = useState(allStatuses);

  // console.log({ loading, submissionsLoading });

  useEffect(() => {
    dispatch.jobs.startupJob({ id, dashboard: true, status: selectedStatuses });
  }, [dispatch, id, selectedStatuses]);

  useEffect(() => {
    if (currentJob && !reportingInitialized && !loading) {
      // console.log(
      //   "Start date:",
      //   moment(currentJob.dateAdded).startOf("day").format("YYYYMMDD")
      // );
      dispatch.reporting
        .startupJobReporting({
          startDate: `${moment(currentJob.dateAdded).startOf("day").format("YYYYMMDD")}000000`,
          endDate: moment().endOf("day").format("YYYYMMDDkkmmss"),
          queryStr: ` AND NOT commentingPerson:2021 AND jobOrderID:${id} AND isDeleted:false`,
          fieldsStr:
            "id,action,minutesSpent,commentingPerson,dateAdded,jobOrder,personReference,jobOrders"
        })
        .then(() => setReportingInitialized(true));
    }
  }, [currentJob, reportingInitialized, dispatch, id, selectedStatuses, loading]);

  // const currentSubmissionsByStatus = useMemo(() => {
  //   if (currentSubmissionStatus) {
  //     return currentDayData.submissions.filter(
  //       (submission) => submission.status === currentSubmissionStatus
  //     );
  //   } else if (currentDayData) {
  //     return currentDayData.submissions;
  //   }
  // }, [currentDayData, currentSubmissionStatus]);

  const handleDrawerOpen = () => {
    setReportingDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setReportingDrawerOpen(false);
  };

  const handleDataPointClick = (data) => {
    setCurrentSubmissionStatus(null);
    setCurrentDayData(data);
    handleDrawerOpen();
  };

  // const handlePieSliceClick = (data) => {
  //   const status = data ? data.id : null;
  //   setCurrentSubmissionStatus(status);
  // };

  return (
    <Container className={classes.container} maxWidth={false}>
      <Box
        className={clsx(classes.content, {
          [classes.contentShift]: reportingDrawerOpen
        })}
      >
        {currentJob && <JobSlateHeader style={{ left: styleConstants.desktopDrawerClosedWidth }} />}
        <Container style={{ marginTop: 200, maxWidth: 1600 }}>
          {(loading || submissionsLoading) && (
            <Grid item xs={12}>
              <Splash />
            </Grid>
          )}
          {!loading && !submissionsLoading && (
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Button
                  color="primary"
                  onClick={() => {
                    if (history?.length > 1) {
                      history.goBack();
                    } else {
                      window.close();
                    }
                  }}
                  startIcon={<ArrowBackIosIcon style={{ width: 12, marginTop: "-2px" }} />}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box boxShadow={0} p={0}>
                  <ReportingCalendar />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box>
                  {submissions && (
                    <Typography
                      variant="subtitle1"
                      style={{
                        marginBottom: theme.spacing(1)
                      }}
                    >
                      Submissions over time
                    </Typography>
                  )}
                  <Box boxShadow={2}>
                    <Box p={2} display="flex" alignItems="center" justifyContent="flex-end">
                      <ToggleButtonGroup
                        style={{ marginTop: theme.spacing(1) }}
                        size="small"
                        value={submissionView}
                        exclusive
                        onChange={(event, newValue) => setSubmissionView(newValue)}
                      >
                        <ToggleButton
                          value="team"
                          style={{
                            paddingTop: theme.spacing(0.5),
                            paddingBottom: theme.spacing(0.5)
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: 12,
                              textTransform: "capitalize"
                            }}
                          >
                            Team
                          </Typography>
                        </ToggleButton>
                        <ToggleButton
                          value="recruiter"
                          style={{
                            paddingTop: theme.spacing(0.5),
                            paddingBottom: theme.spacing(0.5)
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: 12,
                              textTransform: "capitalize"
                            }}
                          >
                            Recruiter
                          </Typography>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    <ReportingJobSubmissions
                      submissionView={submissionView}
                      handleDataPointClick={handleDataPointClick}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box>
                  <Typography
                    variant="subtitle1"
                    style={{
                      marginBottom: theme.spacing(1)
                    }}
                  >
                    Activity
                  </Typography>
                  <Box boxShadow={1} p={2} mb={4}>
                    <ReportingJobActivity />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={reportingDrawerOpen}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Box p={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {currentDayData && (
              <Box pl={2}>
                <Typography variant="subtitle1">{`${currentDayData.id} Submissions by Status`}</Typography>
                <Typography variant="h4">{`${moment(currentDayData.date).format(
                  "ddd MMM Do, YYYY"
                )}`}</Typography>
              </Box>
            )}
            <IconButton
              color="inherit"
              onClick={handleDrawerClose}
              style={{ marginRight: theme.spacing(1) }}
              size="large"
            >
              <CloseIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        {currentDayData && (
          <Box p={2} className={classes.drawerContainer}>
            <SubmissionsPanel
              submissions={currentDayData.submissions}
              setCurrentSubmissionStatus={setCurrentSubmissionStatus}
              currentSubmissionStatus={currentSubmissionStatus}
              currentDate={currentDayData.date}
            />
          </Box>
        )}
      </Drawer>
    </Container>
  );
}
