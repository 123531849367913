/* eslint-disable react/prop-types */
import React, { useState, useRef, useMemo } from "react";
import clsx from "clsx";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import {
  Select,
  Box,
  ListItemIcon,
  Checkbox,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Popover
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as CheckboxIcon } from "../../assets/icons/checkbox.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/checked-blue.svg";
import { ReactComponent as IndeterminateIcon } from "../../assets/icons/indeterminate-blue.svg";

import { colors } from "../../styles/config";

const useGroupTabButtonStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    cursor: "pointer",
    width: 190,
    height: 34,
    borderRadius: 4,
    marginRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    backgroundColor: "rgba(238, 238, 238, 0.5)",
    border: "1px solid rgba(238, 238, 238, 0.4)",
    "&:hover": {
      backgroundColor: "rgba(238, 238, 238, 0.7)",
      border: "1px solid rgba(238, 238, 238, 0.7)"
    }
  },
  activeContainer: {
    border: `1px solid ${colors.varinerBlue}`,
    backgroundColor: colors.varinerLightBlue,
    pointerEvents: "none",
    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 12px 0px",
    "&:hover": {
      background: colors.varinerLightBlue,
      border: `1px solid ${colors.varinerBlue}`
    }
  },
  selectIcon: {
    display: "none"
  }
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 500,
      width: 250
    }
  },
  variant: "menu"
  // getContentAnchorEl: null,
};

export default function GroupTabButton({
  label,
  active = false,
  statusOptions,
  handleSetSelectedStatuses,
  onClick,
  length,
  groupedSubmissions,
  selectedRowIds
}) {
  const classes = useGroupTabButtonStyles();
  const theme = useTheme();
  const [groupSelected, setGroupSelected] = useState(statusOptions);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const containerRef = useRef();

  const selectedLength = useMemo(() => {
    if (!selectedRowIds || !groupedSubmissions) return 0;

    const selectedIds = Object.keys(selectedRowIds).map((id) => parseInt(id));

    const selectedSubmissions = groupedSubmissions
      ? groupedSubmissions.filter((submission) => selectedIds.indexOf(submission.id) > -1)
      : null;

    return selectedSubmissions.length;
  }, [groupedSubmissions, selectedRowIds]);

  const handleSelectClose = () => {
    handleSetSelectedStatuses(groupSelected);
    setAnchorEl(null);
  };

  const handleSelectOpen = () => {
    setAnchorEl(containerRef.current);
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setGroupSelected(groupSelected.length === statusOptions.length ? [] : statusOptions);
      return;
    }
    setGroupSelected(value);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      className={clsx(classes.container, "no-outline", {
        [classes.activeContainer]: active
      })}
      ref={containerRef}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        className="clickable-area"
        onClick={(event) => {
          // only change to selected statuses if the main button is clicked, not if select icon button is clicked
          if (event.target.tagName !== "svg") {
            onClick(groupSelected);
          }
        }}
        style={{ minWidth: 160, cursor: "pointer" }}
      >
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            style={{
              fontSize: 12,
              marginRight: theme.spacing(1),
              marginBottom: 2
            }}
          >
            {`${label}`}
          </Typography>
          {selectedLength && selectedLength > 0 ? (
            <Typography
              style={{
                fontSize: 9,
                color: colors.varinerBlue,
                position: "absolute",
                bottom: 0
              }}
            >{`${selectedLength} selected`}</Typography>
          ) : null}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            borderRadius: "100%",
            width: 26,
            height: 26,
            color: active ? colors.white : "inherit",
            backgroundColor: active ? colors.varinerBlue : colors.white,
            visibility: length ? "visible" : "hidden"
          }}
        >
          <Typography style={{ fontSize: 14 }}>{length}</Typography>
        </Box>
      </Box>
      <IconButton
        size="small"
        onClick={handleSelectOpen}
        style={{
          position: "absolute",
          right: theme.spacing(1),
          top: theme.spacing(0.3),
          zIndex: 200,
          pointerEvents: "initial"
        }}
      >
        <KeyboardArrowDownIcon size="small" />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        // onClose={handleSelectClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Select
          open={Boolean(anchorEl)}
          onClose={handleSelectClose}
          classes={{ icon: classes.selectIcon }}
          variant="outlined"
          labelId="status-label"
          id={`status-select-${label}`}
          multiple
          value={groupSelected}
          renderValue={() => {
            return null;
          }}
          onChange={handleStatusChange}
          input={
            <OutlinedInput
              classes={{ inputMarginDense: classes.inputMarginDense }}
              margin="dense"
            />
          }
          label=""
          MenuProps={MenuProps}
          IconComponent={KeyboardArrowDownIcon}
        >
          <MenuItem value="all" style={{ padding: 0 }}>
            <ListItemIcon style={{ minWidth: 0 }}>
              <Checkbox
                size="small"
                checked={statusOptions.length > 0 && groupSelected.length >= statusOptions.length}
                indeterminate={
                  groupSelected.length > 0 && groupSelected.length < statusOptions.length
                }
                icon={<CheckboxIcon />}
                checkedIcon={<CheckedIcon />}
                indeterminateIcon={<IndeterminateIcon />}
              />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body2">Select All</Typography>} />
          </MenuItem>
          {statusOptions.map((option) => (
            <MenuItem value={option} key={option.replace(" ", "")} style={{ padding: 0 }}>
              <Checkbox
                size="small"
                checked={groupSelected.indexOf(option) > -1}
                icon={<CheckboxIcon />}
                checkedIcon={<CheckedIcon />}
                indeterminateIcon={<IndeterminateIcon />}
              />
              <ListItemText
                primary={
                  <Typography variant="body2">
                    {option === "New Lead" ? "Web Response" : option}
                  </Typography>
                }
              />
            </MenuItem>
          ))}
        </Select>
      </Popover>
    </Box>
  );
}
