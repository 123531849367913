import { sortSubmissionsByStatus } from "@/helpers/statuses";
import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import SubmissionsStatusBar from "../reports/SubmissionsStatusBar";

// eslint-disable-next-line react/prop-types
export default function SubmissionsTableCell({ jobId, value, handleSubmissionsBarClick }) {
  const { activeJobs } = useSelector((state) => state.jobs);
  const job = activeJobs?.find((job) => job.id === jobId);

  return (
    <Box
      style={{ marginTop: -4, maxWidth: 300, minWidth: 240 }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <SubmissionsStatusBar
        height={30}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        showLegend={false}
        filteredSubmissions={sortSubmissionsByStatus(value)}
        onBarClick={(barData) => {
          handleSubmissionsBarClick({
            data: barData,
            job,
            submissions: value
          });
        }}
      />
    </Box>
  );
}
