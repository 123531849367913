/* eslint-disable react/prop-types */
import React from "react";

import { ResponsiveBar } from "@nivo/bar";
import { convertNumToTime } from "../../helpers/callTime";
import { Box, Typography, useTheme } from "@mui/material";

export default function BarCallTimeByActionAndOwner({
  data,
  keys,
  setBarChartKeys,
  handleActionClick,
  currentBarChartKey
}) {
  const theme = useTheme();

  const legendStyle = {
    dataFrom: "keys",
    anchor: "top-left",
    direction: "row",
    justify: false,
    translateX: -20,
    translateY: -50,
    itemsSpacing: 0,
    itemWidth: 180,
    itemHeight: 20,
    itemDirection: "left-to-right",
    itemOpacity: 0.75,
    symbolSize: 16,
    onClick: (owner) => {
      setBarChartKeys(owner.id);
    },
    effects: [
      {
        on: "hover",
        style: {
          itemOpacity: 1
        }
      }
    ]
  };

  return (
    <ResponsiveBar
      theme={{
        grid: {
          line: {
            stroke: "rgba(238, 238, 238, 0.8)"
          }
        }
      }}
      tooltip={({ id, value, indexValue }) => {
        return (
          <Box>
            <Typography
              style={{
                fontSize: 10,
                fontWeight: "bold",
                paddingBottom: theme.spacing(0.5)
              }}
            >
              {id}
            </Typography>

            <Box display="flex" alignItems="center">
              <Typography
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  marginRight: theme.spacing(1)
                }}
              >
                {convertNumToTime(value)}
              </Typography>
              <Typography style={{ fontSize: 10 }}>{indexValue}</Typography>
            </Box>
          </Box>
        );
      }}
      data={data}
      keys={keys}
      indexBy="action"
      margin={{ top: 60, right: 40, bottom: 60, left: 60 }}
      padding={0.3}
      layout="vertical"
      groupMode={data.length === 1 ? "grouped" : "stacked"}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={({ id, data }) => data[`${id}Color`]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        legend: "CALL ACTION",
        legendPosition: "middle",
        legendOffset: 50,
        onClick: handleActionClick
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        legend: "CALL TIME",
        legendPosition: "middle",
        legendOffset: -40
      }}
      labelSkipWidth={0.1}
      labelSkipHeight={0.1}
      labelFormat={(value) => convertNumToTime(value)}
      labelTextColor="white"
      legends={currentBarChartKey ? [] : [legendStyle]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      layers={["grid", "axes", "bars", "markers", "legends"]}
    />
  );
}
