/* eslint-disable indent */
/* eslint-disable react/prop-types */
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Box, Divider, Drawer, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { ResponsiveBar } from "@nivo/bar";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";

import { ZERO_VALUE } from "@/helpers/constants";
import { convertNumToTime, getCallTime } from "../../helpers/callTime";
import { colors } from "../../styles/config";
import StatCard from "./Cards/StatCard";
import ReportingFiltersMenu from "./ReportingFiltersMenu";
import SubmissionsPanel from "./SubmissionsPanel";
import CallTimeTooltip from "./Tooltips/CallTimeTooltip";
import CandidatesTooltip from "./Tooltips/CandidatesTooltip";
import PlacementsTooltip from "./Tooltips/PlacementsTooltip";
import PrescreensTooltip from "./Tooltips/PrescreensTooltip";
import SubmissionStatusTooltip from "./Tooltips/SubmissionStatusTooltip";

const drawerWidth = 400;

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 0,
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    height: 34,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "unset",
    width: 150
  },
  statsContainer: {
    transition: "0.8s width ease, 0.8s height ease"
  },
  statsContainerPaper: {
    height: 750
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 4000
  },
  drawerContainer: {
    overflow: "auto"
  }
}));

const statKeys = ["Call Time", "Candidates", "Prescreens", "Submissions", "Placements"];

export default function ByRecruiterStats({ loading }) {
  const classes = useStyles();
  const theme = useTheme();
  const { newCandidates, newSubmissions, newPlacements, newNotes } = useSelector(
    (state) => state.reporting
  );

  const [filteredBarData, setFilteredBarData] = useState(null);
  const [filteredHeaderData, setFilteredHeaderData] = useState(null);
  const [hasOwnerFilter, setHasOwnerFilter] = useState(false);
  const [barChartKeys, setBarChartKeys] = useState(statKeys);
  const [currentOwner, setCurrentOwner] = useState(null);
  const [currentBarChartKey, setCurrentBarChartKey] = useState(null);
  const [filters, setFilters] = useState(null);
  const [reportingDrawerOpen, setReportingDrawerOpen] = useState(false);
  const [currentSubmissionStatus, setCurrentSubmissionStatus] = useState(null);

  const { dateRange, rangeSelection } = useSelector((state) => state.app);

  // TODO: lock current owner for non partner users
  useEffect(() => {
    if (!currentOwner && !currentBarChartKey) {
      setFilters([]);
    } else {
      let newFilters = [];

      if (currentBarChartKey) {
        newFilters = [
          ...newFilters,
          {
            icon: null,
            kind: "group",
            label: currentBarChartKey,
            onClick: () => {
              console.log("chip clicked");
            },
            onDelete: () => {
              // console.log({
              //   filtersWithoutGroupFilter: _.filter(filters, (f) => {
              //     return f.kind !== "group";
              //   }),
              // });
              setBarChartKeys(statKeys);
              setCurrentBarChartKey(null);
              handleDrawerClose();

              setFilters(
                _.filter(filters, (f) => {
                  return f.kind !== "group";
                })
              );
            }
          }
        ];
      }

      if (currentOwner) {
        newFilters = [
          ...newFilters,
          {
            icon: PersonOutlineIcon,
            kind: "owner",
            label: currentOwner.name,
            onClick: () => {
              console.log("chip clicked");
            },
            onDelete: () => {
              // console.log({
              //   filtersWithoutOwnerFilter: _.filter(filters, (f) => {
              //     return f.kind !== "owner";
              //   }),
              // });
              setCurrentOwner(null);
              setFilteredBarData(null);
              handleDrawerClose();
              setFilters(
                _.filter(filters, (f) => {
                  return f.kind !== "owner";
                })
              );
            }
          }
        ];
      }

      setFilters(newFilters);
    }
  }, [currentOwner, currentBarChartKey]);

  const handleDrawerOpen = () => {
    setReportingDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setCurrentOwner(null);
    setReportingDrawerOpen(false);
  };

  const handleSetSingleBarKey = (title) => {
    setBarChartKeys([title]);
    setCurrentBarChartKey(title);
  };

  const newCandidatesByOwner =
    newCandidates?.reduce((acc, curr) => {
      const ownerId = curr.owner.id;

      if (acc[ownerId]) {
        acc[ownerId] = {
          ...acc[ownerId],
          newCandidates: [...acc[ownerId].newCandidates, curr]
        };
      } else {
        acc[ownerId] = {
          owner: `${curr.owner.firstName} ${curr.owner.lastName}`,
          newCandidates: [curr]
        };
      }

      return acc;
    }, {}) || null;

  const newSubmissionsByOwner = useMemo(() => {
    return (
      newSubmissions?.reduce((acc, curr) => {
        const ownerId = curr.sendingUser.id;

        if (acc[ownerId]) {
          acc[ownerId] = {
            ...acc[ownerId],
            submissions: [...acc[ownerId].submissions, curr]
          };
        } else {
          acc[ownerId] = {
            owner: `${curr.sendingUser.firstName} ${curr.sendingUser.lastName}`,
            submissions: [curr]
          };
        }

        return acc;
      }, {}) || []
    );
  }, [newSubmissions]);

  const newPlacementsByOwner = useMemo(() => {
    return (
      newPlacements?.reduce((acc, curr) => {
        const ownerId = curr.jobSubmission.sendingUser.id;

        if (acc[ownerId]) {
          acc[ownerId] = {
            ...acc[ownerId],
            newPlacements: [...acc[ownerId].newPlacements, curr]
          };
        } else {
          acc[ownerId] = {
            owner: `${curr.jobSubmission.sendingUser.firstName} ${curr.jobSubmission.sendingUser.lastName}`,
            newPlacements: [curr]
          };
        }

        return acc;
      }, {}) || []
    );
  }, [newPlacements]);

  const newPrescreensByOwner = useMemo(() => {
    return (
      newNotes?.reduce((acc, curr) => {
        const ownerId = curr.commentingPerson.id;
        const prescreen = curr.action === "Prescreen";

        if (prescreen) {
          if (acc[ownerId]) {
            acc[ownerId] = {
              ...acc[ownerId],
              newPrescreens: [...acc[ownerId].newPrescreens, curr]
            };
          } else {
            acc[ownerId] = {
              owner: `${curr.commentingPerson.firstName} ${curr.commentingPerson.lastName}`,
              newPrescreens: [curr]
            };
          }
        }

        return acc;
      }, {}) || []
    );
  }, [newNotes]);

  const newNotesByOwner = useMemo(() => {
    return (
      newNotes?.reduce((acc, curr) => {
        const ownerId = curr.commentingPerson.id;

        if (acc[ownerId]) {
          acc[ownerId] = {
            ...acc[ownerId],
            notes: [...acc[ownerId].notes, curr]
          };
        } else {
          acc[ownerId] = {
            owner: `${curr.commentingPerson.firstName} ${curr.commentingPerson.lastName}`,
            notes: [curr]
          };
        }

        return acc;
      }, {}) || []
    );
  }, [newNotes]);

  // console.log(
  //   "new notes with mins spent",
  //   newNotes &&
  //     newNotes.filter(
  //       (note) =>
  //         note.minutesSpent && (note.action === "I/B" || note.action === "O/B")
  //     )
  // );

  const getOwners = useCallback(() => {
    const all = [
      newSubmissionsByOwner,
      newCandidatesByOwner,
      newPlacementsByOwner,
      newPrescreensByOwner || [],
      newNotesByOwner || []
    ].map((dataArray) => {
      const owners =
        Object.keys(dataArray) &&
        Object.keys(dataArray).map((key) => {
          const dataObj = dataArray[key];
          return {
            id: key,
            name: dataObj.owner,
            from: Object.keys(dataObj)[1]
          };
        });

      return owners;
    });

    const ownersArray = _.uniqBy(all.flat(), "id");
    return ownersArray;
  }, [
    newSubmissionsByOwner,
    newCandidatesByOwner,
    newPlacementsByOwner,
    newPrescreensByOwner,
    newNotesByOwner
  ]);

  const barData = useMemo(() => {
    const owners =
      newSubmissionsByOwner &&
      newCandidatesByOwner &&
      newPlacementsByOwner &&
      // newPrescreensByOwner &&
      // newNotesByOwner &&
      getOwners();

    const data =
      newSubmissionsByOwner &&
      newCandidatesByOwner &&
      newPlacementsByOwner &&
      // newPrescreensByOwner &&
      // newNotesByOwner &&
      owners.map((owner) => {
        const ownerId = owner.id;

        return {
          ownerId,
          owner: owner.name,
          Candidates:
            (newCandidatesByOwner[ownerId] &&
              newCandidatesByOwner[ownerId].newCandidates?.length) ||
            ZERO_VALUE,
          CandidatesColor: "rgba(62, 159, 172, 0.85)",
          Submissions:
            (newSubmissionsByOwner[ownerId] && newSubmissionsByOwner[ownerId].submissions.length) ||
            ZERO_VALUE,
          SubmissionsColor: "rgba(27, 59, 64, 0.85)",
          Placements:
            (newPlacementsByOwner[ownerId] && newPlacementsByOwner[ownerId].newPlacements.length) ||
            ZERO_VALUE,
          PlacementsColor: "rgba(39, 39, 39, 0.85)",
          ...(newPrescreensByOwner
            ? {
                Prescreens:
                  (newPrescreensByOwner[ownerId] &&
                    newPrescreensByOwner[ownerId].newPrescreens.length) ||
                  ZERO_VALUE,
                PrescreensColor: "rgba(63, 94, 98, 0.85)"
              }
            : {}),
          ...(newNotesByOwner
            ? {
                "Call Time":
                  (newNotesByOwner[ownerId] && getCallTime(newNotesByOwner[ownerId].notes)) ||
                  ZERO_VALUE,
                "Call TimeColor": "rgba(251, 169, 38, 0.85)"
              }
            : {})
        };
      });

    return data;
  }, [
    newCandidatesByOwner,
    newSubmissionsByOwner,
    newPlacementsByOwner,
    newPrescreensByOwner,
    newNotesByOwner,
    getOwners
  ]);

  // console.log({ barData });

  const statsConfig = [
    ...(newNotes
      ? [
          {
            sort: 1,
            title: "Call Time",
            color: colors.varinerMedium,
            // color: colors.varinerGold,
            total: convertNumToTime(getCallTime(newNotes, "hrs")),
            filterTotal: ZERO_VALUE,
            hasOwnerFilter: hasOwnerFilter
          },
          {
            sort: 3,
            title: "Prescreens",
            color: colors.varinerMedium,
            total: newNotes?.filter((note) => note.action === "Prescreen").length,
            filterTotal: ZERO_VALUE,
            hasOwnerFilter: hasOwnerFilter
          }
        ]
      : []),
    {
      sort: 2,
      title: "Candidates",
      color: colors.varinerMedium,
      // color: colors.varinerLight,
      total: newCandidates?.length,
      filterTotal: ZERO_VALUE,
      hasOwnerFilter: hasOwnerFilter
    },
    {
      sort: 4,
      title: "Submissions",
      color: colors.varinerMedium,
      // color: colors.variner,
      total: newSubmissions?.length,
      filterTotal: ZERO_VALUE,
      hasOwnerFilter: hasOwnerFilter
    },
    {
      sort: 5,
      title: "Placements",
      color: colors.varinerMedium,
      // color: colors.darkerGray,
      total: newPlacements?.length,
      filterTotal: ZERO_VALUE,
      hasOwnerFilter: hasOwnerFilter
    }
  ].map((stat) => ({
    ...stat,
    filterTotal:
      filteredHeaderData?.find((header) => header.title === stat.title)?.filterTotal || ZERO_VALUE
  }));

  const handleOwnerClick = (event) => {
    const owner = event.target.textContent;

    setCurrentOwner({
      name: owner,
      id: barData.find((obj) => obj.owner === owner).ownerId
    });

    const filteredData = barData.filter((d) => d.owner === owner);

    setFilteredBarData(filteredData);

    const formatTime = (value) => {
      const minutes = Math.floor(value);
      const seconds = Math.ceil((value % 1) * 100);

      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    };

    const updatedStatsConfig = statsConfig.map((config) => {
      const filterTotal =
        filteredData.length > ZERO_VALUE ? filteredData[0][config.title] : ZERO_VALUE;

      if (config.title === "Call Time") {
        return { ...config, filterTotal: formatTime(filterTotal) };
      }

      return { ...config, filterTotal };
    });

    setFilteredHeaderData(updatedStatsConfig);
  };

  const handleBarClick = (data) => {
    if (data.id === "Submissions") {
      const ownerObj = barData.find((obj) => obj.owner === data.indexValue);
      const ownerId = ownerObj && ownerObj.ownerId;

      if (ownerId) {
        setCurrentOwner({
          name: data.indexValue,
          id: ownerId,
          newSubmissions:
            newSubmissionsByOwner &&
            newSubmissionsByOwner[ownerId] &&
            newSubmissionsByOwner[ownerId].submissions
        });

        handleDrawerOpen();
      }
    }

    setHasOwnerFilter(false);
    handleSetSingleBarKey(data.id);
  };

  // console.log({ filters });
  // console.log({ currentOwner });
  // console.log({ currentBarChartKey });
  // console.log({ barData });

  if (loading) return <Box sx={{ height: 600 }}></Box>;

  return (
    <Box>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={reportingDrawerOpen}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Box p={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box pl={2}>
              <Typography variant="subtitle1">{"Submissions by Status"}</Typography>
              {currentOwner && <Typography variant="h4">{`${currentOwner.name}`}</Typography>}
            </Box>
            <IconButton
              color="inherit"
              onClick={handleDrawerClose}
              style={{ marginRight: theme.spacing(1) }}
              size="large"
            >
              <CloseIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box p={2} className={classes.drawerContainer}>
          {currentOwner && (
            <SubmissionsPanel
              submissions={currentOwner.submissions}
              setCurrentSubmissionStatus={setCurrentSubmissionStatus}
              currentSubmissionStatus={currentSubmissionStatus}
            />
          )}
        </Box>
      </Drawer>
      <Box>
        <Box>
          <Box mb={2} pb={2}>
            <Box display="flex" alignItems="flex-start" justifyContent="space-between">
              <Box>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                  <Typography variant="subtitle1">{`${_.startCase(rangeSelection)}`}</Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle1" style={{ marginRight: theme.spacing(1) }}>
                      {moment(dateRange.startDate).format("MMM DD, YYYY")}
                    </Typography>
                    <Typography variant="subtitle1">to</Typography>
                    <Typography variant="subtitle1" style={{ marginLeft: theme.spacing(1) }}>
                      {moment(dateRange.endDate).format("MMM DD, YYYY")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              {_.sortBy(statsConfig, "sort").map((stat, index) => (
                <StatCard
                  key={stat.title}
                  stat={stat}
                  shadow={false}
                  align="left"
                  style={{
                    marginRight: index !== 4 ? theme.spacing(5) : 0,
                    borderRight: index !== 4 ? `1px solid ${colors.gray10}` : "none"
                  }}
                  onClick={() => handleSetSingleBarKey(stat.title)}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box py={2}>{/* <Divider /> */}</Box>
          {filters?.length > ZERO_VALUE && (
            <ReportingFiltersMenu filters={filters} setHasOwnerFilter={setHasOwnerFilter} />
          )}

          <Box style={{ height: 540, width: "100%" }}>
            {barData && (
              <ResponsiveBar
                theme={{
                  grid: {
                    line: {
                      stroke: "rgba(238, 238, 238, 0.8)"
                    }
                  }
                }}
                innerPadding={1}
                tooltip={(data) => {
                  const { id, data: innerData } = data;

                  if (id === "Submissions") {
                    return (
                      <SubmissionStatusTooltip
                        newSubmissionsByOwner={newSubmissionsByOwner}
                        innerData={innerData}
                      />
                    );
                  }
                  if (id === "Candidates") {
                    return (
                      <CandidatesTooltip
                        newCandidatesByOwner={newCandidatesByOwner}
                        innerData={innerData}
                      />
                    );
                  }
                  if (id === "Prescreens") {
                    return (
                      <PrescreensTooltip
                        newPrescreensByOwner={newPrescreensByOwner}
                        innerData={innerData}
                      />
                    );
                  }
                  if (id === "Placements") {
                    return (
                      <PlacementsTooltip
                        newPlacementsByOwner={newPlacementsByOwner}
                        innerData={innerData}
                      />
                    );
                  }
                  if (id === "Call Time") {
                    return (
                      <CallTimeTooltip newNotesByOwner={newNotesByOwner} innerData={innerData} />
                    );
                  }
                }}
                data={filteredBarData || barData}
                keys={barChartKeys}
                groupMode="grouped"
                indexBy="owner"
                margin={{ top: 32, right: 16, bottom: 40, left: 16 }}
                padding={0.3}
                layout="vertical"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={({ id, data }) => data[`${id}Color`]}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 0,
                  tickPadding: 15,
                  tickRotation: 0,
                  onClick: handleOwnerClick
                }}
                axisLeft={null}
                labelSkipWidth={0}
                labelSkipHeight={0}
                labelTextColor={colors.white}
                labelFormat={(value) => (value % 1 === 0 ? value : value.toFixed(2))}
                onClick={handleBarClick}
                animate={false}
                motionStiffness={90}
                motionDamping={15}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "top-right",
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: -24,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 16,
                    onClick: (d) => {
                      handleBarClick(d);
                    },
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1
                        }
                      }
                    ]
                  }
                ]}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
