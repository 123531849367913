/* eslint-disable react/prop-types */
import React from "react";
import { TextField, FormControl, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colors } from "../../../styles/config";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "flex",
    margin: 0,
    borderRadius: 3,
    padding: theme.spacing(0.4),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    minWidth: 300,
    color: colors.black
  },
  row: { display: "flex", justifyContent: "center", alignItems: "center" }
}));

// Defines a default UI for filtering
export function DefaultColumnFilter({ column }) {
  const classes = useStyles();
  const { Header, filterValue, setFilter } = column;

  const selectedFilterValue = filterValue;

  return (
    <FormControl className={classes.formControl} variant="standard">
      <Box className={classes.row}>
        <TextField
          variant="standard"
          fullWidth={true}
          id={`default-column-filter-${Header.replace(/[^A-Z0-9]/gi, "")}`}
          placeholder={"Search"}
          value={selectedFilterValue || ""}
          onChange={(event) => {
            // set undefined to remove the filter entirely
            setFilter(event.target?.value || undefined);
          }}
          inputProps={{
            style: { fontSize: 16 }
          }}
        />
        <SearchIcon />
      </Box>
    </FormControl>
  );
}
