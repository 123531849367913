/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PercentPill from "./PercentPill";
import { colors } from "../styles/config";
const useStyles = makeStyles(() => ({
  row: {
    display: "flex",

    alignItems: "center",
    gap: ".5vw"
  }
}));

export default function ReportingCardRow({
  dateSort = "Quarter over quarter",
  changePercent,
  type
}) {
  const classes = useStyles();
  return (
    <Box className={classes.row}>
      <PercentPill changePercent={changePercent} type={type} />
      <Typography style={{ fontSize: 14, color: colors.gray43 }} variant="body2">
        {dateSort}
      </Typography>
    </Box>
  );
}
