/* eslint-disable react/prop-types */
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../styles/config";

export default function StatCard({
  stat,
  backgroundColor = "transparent",
  textColor = "rgba(0, 0, 0, 0.6)",
  totalColor = colors.darkerGray,
  shadow = true,
  align = "center",
  onClick,
  style = {}
}) {
  const theme = useTheme();
  return (
    <Box
      onClick={() => onClick && onClick()}
      p={2}
      boxShadow={shadow ? 1 : 0}
      display="flex"
      flexDirection="column"
      alignItems={align === "left" ? "flex-start" : "center"}
      justifyContent="space-between"
      style={{
        backgroundColor,
        width: "100%",
        borderRadius: 3,
        ...style
      }}
    >
      <Typography
        variant="h5"
        style={{
          textTransform: "uppercase",
          color: textColor
        }}
      >
        {stat.title}
      </Typography>
      <Typography
        variant="h2"
        style={{
          fontWeight: "normal",
          color: totalColor,
          marginTop: theme.spacing(0.5)
        }}
      >
        {stat.hasOwnerFilter ? stat.filterTotal : stat.total}
      </Typography>
    </Box>
  );
}
