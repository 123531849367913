import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import {
  APIBadRequestError,
  APIClientError,
  APIError,
  APIRequestAbortedError,
  APIRequestTimeoutError,
  APIServerError,
  APIUnauthorizedError,
  APIRequestConflictError,
  APIForbiddenError
} from "@/errors";

// eslint-disable-next-line no-undef
const baseURL = process?.env.REACT_APP_API_URL;

// console.log("SENDING API REQUESTS TO: ", baseURL);

const api = axios.create({
  baseURL,
  withCredentials: false,
  timeout: 5 * 60 * 1000, // 5 mins
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  },
  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => camelcaseKeys(data, { deep: true })
  ]
});

// these are the methods we want to intercept
const allowlist = ["get", "post", "put", "delete"];

/* eslint-disable no-param-reassign */
const copyAxiosErrorProps = (axiosError, apiError) => {
  apiError.config = axiosError.config;
  apiError.code = axiosError.code;
  apiError.request = axiosError.request;
  apiError.response = axiosError.response;
  apiError.isAxiosError = axiosError.isAxiosError;
  apiError.toJSON = axiosError.toJSON;
};
/* eslint-enable no-param-reassign */

api.interceptors.response.use(
  // returns response unaltered
  (response) => response,
  // our custom error handling
  (axiosError) => {
    let apiError;

    if (axiosError.response) {
      // ↓ when client received an error response (5xx, 4xx) ↓
      if (axiosError.code === "ECONNABORTED") {
        apiError = new APIRequestAbortedError(axiosError.message);
      } else if (axiosError.code === "ETIMEDOUT") {
        apiError = new APIRequestTimeoutError(axiosError.message);
      } else if (axiosError.response.status === 401) {
        apiError = new APIUnauthorizedError(axiosError.message);
      } else if (axiosError.response.status === 403) {
        apiError = new APIForbiddenError(axiosError.message);
      } else if (axiosError.response.status === 400) {
        apiError = new APIBadRequestError(axiosError.message);
      } else if (axiosError.response.status === 409) {
        apiError = new APIRequestConflictError(axiosError.message);
      } else if (axiosError.response.status.toString().startsWith("4")) {
        apiError = new APIClientError(axiosError.message);
      } else if (axiosError.response.status.toString().startsWith("5")) {
        apiError = new APIServerError(axiosError.message);
      } else {
        apiError = new APIError(axiosError.message);
      }
    } else if (axiosError.request) {
      // ↓ when client never received a response, or request never left ↓
      apiError = new APIRequestAbortedError(axiosError.message);
    } else {
      // ↓ for anything else ↓
      apiError = new APIError(axiosError.message);
    }

    copyAxiosErrorProps(axiosError, apiError);

    throw apiError;
  }
);

const handler = {
  get(target, method, receiver) {
    if (!allowlist.includes(method)) {
      return Reflect.get(target, method, receiver);
    }

    return async function (...args) {
      const [url, data] = args;

      // console.log("Requesting from API", url);

      const response = await api[method](`/v2${url}`, data);

      if (response.headers.token) {
        api.defaults.headers.common["Authorization"] = response.headers.token;
      }

      return response;
    };
  }
};

const enhancedAPI = new Proxy(
  {
    setToken(token) {
      api.defaults.headers.common["Authorization"] = token;
    }
  },
  handler
);

export default enhancedAPI;
