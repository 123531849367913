import api from "../api";
import commonReducers from "./utils/common-reducers";
import { APIError } from "@/errors";

const INITIAL_STATE = {
  loading: false,
  error: false,
  newCandidates: null,
  newSubmissions: null,
  newPlacements: null,
  newNotes: null,
  comparisonCandidates: null,
  comparisonSubmissions: null,
  comparisonPlacements: null,
  comparisonNotes: null,
  submissionsHistory: null,
  currentJob: null
};

export const reporting = {
  state: INITIAL_STATE,

  reducers: {
    ...commonReducers,

    update(state, updates) {
      return {
        ...state,
        ...updates
      };
    },

    setCurrentJob(state, payload) {
      return {
        ...state,
        currentJob: payload
      };
    },

    setNewCandidates(state, payload) {
      return {
        ...state,
        newCandidates: payload
      };
    },

    setNewSubmissions(state, payload) {
      return {
        ...state,
        newSubmissions: payload
      };
    },

    setNewPlacements(state, payload) {
      return {
        ...state,
        newPlacements: payload
      };
    },

    setNewNotes(state, payload) {
      return {
        ...state,
        newNotes: payload
      };
    },

    setComparisonCandidates(state, payload) {
      return {
        ...state,
        comparisonCandidates: payload
      };
    },

    setComparisonSubmissions(state, payload) {
      return {
        ...state,
        comparisonSubmissions: payload
      };
    },

    setComparisonPlacements(state, payload) {
      return {
        ...state,
        comparisonPlacements: payload
      };
    },

    setComparisonNotes(state, payload) {
      return {
        ...state,
        comparisonNotes: payload
      };
    },

    setSubmissions(state, payload) {
      return {
        ...state,
        submissions: payload,
        loading: false
      };
    },

    setError(state, payload) {
      return {
        loading: false,
        error: payload,
        newCandidates: null,
        newSubmissions: null,
        newPlacements: null,
        newNotes: null
      };
    },

    setLoading(state, payload) {
      return {
        ...state,
        loading: payload
      };
    },

    purge() {
      return {
        ...INITIAL_STATE
      };
    }
  },
  effects: (dispatch) => ({
    async startupJobReporting(payload) {
      try {
        dispatch.app.setLoading(true);
        dispatch.reporting.setLoading(true);

        const { data: noteData } = await api.post("/notes", payload);

        dispatch.reporting.setNewNotes(noteData.data);

        return true;
      } catch (error) {
        console.log("Error starting up job reporting:", error);

        dispatch.reporting.setError({
          error
        });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.placements.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        dispatch.app.setLoading(false);
        dispatch.reporting.setLoading(false);
      }
    },
    async bootstrapReporting(payload) {
      try {
        dispatch.app.setLoading(true);
        dispatch.reporting.setLoading(true);

        const { data: submissionData } = await api.post("/submissionsBySendingUser", payload);

        const { data: candidateData } = payload.candidates
          ? await api.post("/candidates", payload)
          : {};

        const { data: placementData } = payload.placements
          ? await api.post("/placementsBySendingUser", payload)
          : {};

        const { data: noteData } = payload.notes ? await api.post("/notes", payload) : {};

        const candidateReqPass = payload.candidates
          ? candidateData && candidateData.data && candidateData.success
          : true;

        const submissionsReqPass = submissionData && submissionData.data && submissionData.success;

        const placementsReqPass = payload.placements
          ? placementData && placementData.data && placementData.success
          : true;

        const notesReqPass = payload.notes ? noteData && noteData.data && noteData.success : true;

        if (candidateReqPass && submissionsReqPass && placementsReqPass && notesReqPass) {
          dispatch.reporting.setNewSubmissions(submissionData.data);

          if (payload.candidates) dispatch.reporting.setNewCandidates(candidateData.data);

          if (payload.placements) dispatch.reporting.setNewPlacements(placementData.data);

          if (payload.notes) dispatch.reporting.setNewNotes(noteData.data);
        } else {
          dispatch.app.setLoading(false);
          dispatch.reporting.setError({
            error: "Something went wrong capturing new somethings."
          });
        }

        dispatch.reporting.update({
          bootstrapped: true
        });

        return true;
      } catch (error) {
        // console.log("Error bootstrapping reporting data:", error);

        dispatch.reporting.setError({
          error
        });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.placements.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        // set loading to false once completed
        dispatch.app.setLoading(false);
        dispatch.reporting.setLoading(false);
      }
    },
    async fetchComparisonReportingData(payload) {
      try {
        dispatch.app.setLoading(true);
        dispatch.reporting.setLoading(true);

        const { data: submissionData } = await api.post("/submissionsBySendingUser", payload);

        dispatch.reporting.setComparisonSubmissions(submissionData.data);

        const { data: candidateData } = await api.post("/candidates", payload);

        dispatch.reporting.setComparisonCandidates(candidateData.data);

        const { data: placementData } = await api.post("/placementsBySendingUser", payload);

        dispatch.reporting.setComparisonPlacements(placementData.data);

        const { data: noteData } = await api.post("/notes", payload);

        dispatch.reporting.setComparisonNotes(noteData.data);

        return true;
      } catch (error) {
        console.log("Error bootstrapping reporting COMPARISON data:", error);

        dispatch.reporting.setError({
          error
        });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.placements.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        // set loading to false once completed
        dispatch.app.setLoading(false);
        dispatch.reporting.setLoading(false);
      }
    }
  })
};
