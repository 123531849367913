import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import moment from "moment";

import Splash from "../Splash";
import "../../styles/stylesheets/reporting-calendar.css";
import { colors } from "../../styles/config";

// const calsPerRow = 7;
// const calsMargin = 8;
// const calWidth = 1600 / calsPerRow - calsMargin * (calsPerRow - 1);

// FROM FULLCALENDAR: https://github.com/fullcalendar/fullcalendar/blob/495d925436e533db2fd591e09a0c887adca77053/packages/common/src/common/StandardEvent.tsx#L79
// function renderInnerContent(innerProps) {
//   return (
//     <div className="fc-event-main-frame">
//       {innerProps.timeText && (
//         <div className="fc-event-time">{innerProps.timeText}</div>
//       )}
//       <div className="fc-event-title-container">
//         <div className="fc-event-title fc-sticky">
//           {innerProps.event.title || <Typography>&nbsp;</Typography>}
//         </div>
//       </div>
//     </div>
//   );
// }

export default function ReportingCalendar() {
  // const calRef = useRef();
  const { currentJob } = useSelector((state) => state.jobs);
  const [sortedEvents, setSortedEvents] = useState(null);
  const [, setGroupedByMonth] = useState(null);

  // take all dates and group by month... each month its own cal
  useEffect(() => {
    if (currentJob) {
      let events = currentJob && [
        // {
        //   title: "Job Added",
        //   start: moment(currentJob.dateAdded).format("YYYY-MM-DD"),
        // },
        {
          title: "Engagement",
          start: moment(currentJob.engagementDate).format("YYYY-MM-DD"),
          color: colors.gray43
        },
        {
          title: "Launch",
          start: moment(currentJob.launchDate).format("YYYY-MM-DD"),
          color: colors.variner
        },
        {
          title: "Slate Delivery",
          start: moment(currentJob.slateDeliveryDate).format("YYYY-MM-DD"),
          color: colors.darkerGray
        },
        {
          title: "Today",
          start: moment().format("YYYY-MM-DD"),
          color: colors.varinerGold
        }
      ];

      const launchPlacementDiff = moment(currentJob.startDate).diff(
        currentJob.launchDate,
        "months"
      );

      // if the placement date is beyond 5 months from the launch date, don't include
      if (launchPlacementDiff < 5) {
        events.push({
          title: "Placement Start Date",
          start: moment(currentJob.startDate).format("YYYY-MM-DD"),
          color: colors.varinerLight
        });
      }

      let sortedEvents =
        events &&
        events
          .filter((event) => event.start && event.start !== "Invalid date")
          .sort((a, b) => {
            return moment(a.start).valueOf() - moment(b.start).valueOf();
          });

      setSortedEvents(sortedEvents);

      const startYear = moment(sortedEvents[0].start).format("YYYY");
      const startMonth = moment(sortedEvents[0].start).format("M");

      const totalMonths =
        sortedEvents &&
        moment(sortedEvents[sortedEvents.length - 1].start).diff(sortedEvents[0].start, "months") +
          2;
      const monthsArray =
        sortedEvents &&
        Array.from({ length: totalMonths }, (_, i) => ({
          date: moment(`${startYear}-${startMonth}-01`).add(i, "months").toISOString()
        }));

      const calendarArray = monthsArray.map((month) => {
        const monthDate = moment(month.date).format("M");
        const yearDate = moment(month.date).format("YYYY");
        const events = sortedEvents.filter((event) => {
          const eventMonth = moment(event.start).format("M");
          const eventYear = moment(event.start).format("YYYY");
          return monthDate === eventMonth && yearDate === eventYear;
        });
        month.events = events;
        return month;
      });

      setGroupedByMonth(calendarArray);
    }
  }, [currentJob]);

  if (!currentJob) return <Splash />;

  // console.log("groupedByMonth", groupedByMonth);

  return (
    <Box>
      <Box
        mb={2}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {sortedEvents &&
          sortedEvents.map((event) => {
            return (
              <Box display="flex" alignItems="center" mr={2} pb={1} key={event.title}>
                <Box
                  mr={1}
                  style={{
                    backgroundColor: event.color,
                    width: 16,
                    height: 16,
                    borderRadius: 3,
                    opacity: 0.65
                  }}
                ></Box>
                <Typography style={{ fontSize: 12, width: 100 }}>
                  {moment(event.start).format("MMM DD, YYYY")}
                </Typography>

                <Typography style={{ fontSize: 12 }}>{event.title}</Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  );

  // return (
  //   <Box>
  //     <Box
  //       mb={2}
  //       display="flex"
  //       alignItems="flex-start"
  //       justifyContent="flex-start"
  //     >
  //       {sortedEvents &&
  //         sortedEvents.map((event) => {
  //           return (
  //             <Box display="flex" alignItems="center" mr={2} key={event.title}>
  //               <Box
  //                 mr={1}
  //                 style={{
  //                   backgroundColor: event.color,
  //                   width: 16,
  //                   height: 16,
  //                   borderRadius: 3,
  //                   opacity: 0.65,
  //                 }}
  //               ></Box>
  //               <Typography style={{ fontSize: 12 }}>{event.title}</Typography>
  //             </Box>
  //           );
  //         })}
  //     </Box>
  //     <Box
  //       display="flex"
  //       alignItems="flex-start"
  //       justifyContent="flex-start"
  //       // flexWrap="wrap"
  //     >
  //       {groupedByMonth &&
  //         groupedByMonth.length &&
  //         groupedByMonth.map((month, index) => {
  //           const data = month.events;
  //           if (!data) return null;

  //           return (
  //             <Box
  //               key={month.date}
  //               mr={index === groupedByMonth - 1 ? 0 : 1}
  //               style={{
  //                 // width: `calc(${100 / groupedByMonth.length}% - 16px)`,
  //                 width: calWidth,
  //                 // minWidth: "10%",
  //                 height: calHeight,
  //               }}
  //             >
  //               <FullCalendar
  //                 ref={calRef}
  //                 showNonCurrentDates={false}
  //                 headerToolbar={{
  //                   // layout header
  //                   start: "title",
  //                   center: "",
  //                   end: "",
  //                   // end: "month,agendaWeek,listWeek",
  //                 }}
  //                 height={calHeight}
  //                 contentHeight={calHeight}
  //                 // aspectRatio={0.6}
  //                 plugins={[dayGridPlugin]}
  //                 initialView="dayGridMonth"
  //                 weekends={true}
  //                 eventColor={colors.varinerLight}
  //                 eventContent={(arg) => {
  //                   return (
  //                     <Tooltip
  //                       title={
  //                         <Typography color="inherit">
  //                           {arg.event.title}
  //                         </Typography>
  //                       }
  //                       arrow
  //                     >
  //                       {renderInnerContent(arg)}
  //                     </Tooltip>
  //                   );
  //                 }}
  //                 initialDate={month.date}
  //                 events={data}
  //               />
  //             </Box>
  //           );
  //         })}
  //     </Box>
  //   </Box>
  // );
}
