import React, { createContext } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider, useDispatch } from "react-redux";
import { getPersistor } from "@rematch/persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
export const authContext = createContext();

// eslint-disable-next-line react/prop-types
const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const useProvideAuth = () => {
  const dispatch = useDispatch();

  const signin = async (values, callback) => {
    // eslint-disable-next-line no-useless-catch
    try {
      await dispatch.user.attemptLogin(values);

      callback();
    } catch (error) {
      // throw error to wherever this is being called to handle
      throw error;
    }
  };

  const signout = (callback) => {
    dispatch.user.attemptLogout();

    callback();
  };

  return {
    signin,
    signout
  };
};

// needed in bootstrap phase to manually persist data and stuff
export const persistor = getPersistor();

Sentry.init({
  dsn: "https://43c151b374254f5183028f1f2d8ee1a1@o528326.ingest.sentry.io/5695727",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5
});

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <PersistGate persistor={persistor}>
          <ProvideAuth>
            <BrowserRouter basename="/">
              <App />
            </BrowserRouter>
          </ProvideAuth>
        </PersistGate>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
