import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, CircularProgress, IconButton, Tooltip, useTheme } from "@mui/material";
import moment from "moment";
import { Cached as CachedIcon } from "@mui/icons-material";

import { rangeSelectionMap } from "@/helpers/dateRanges";
import makeExpandedSearchesTableData from "@/helpers/makeExpandedSearchesTableData";
import SubmissionsBarDrawer from "../SubmissionsBarDrawer";
import ActiveSearchesTableExpanded from "../tables/ActiveSearchesTableExpanded";

// eslint-disable-next-line react/prop-types
export default function ActiveSearchesExpandedSection({ handleDateRangeSelectionChange }) {
  // ↓ styling ↓
  const theme = useTheme();

  // ↓ redux ↓
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { dateRange, rangeSelection, customDateRange } = useSelector((state) => state.app);

  const { allSearches, searchesSubmissionsByDate, searchesCallsByDate, loading } = useSelector(
    (state) => state.searches
  );

  // const { activeJobs } = useSelector((state) => state.jobs);

  // ↓ other hooks ↓

  // ↓ state ↓
  const [submissionsBarDrawer, setSubmissionsBarDrawer] = useState({
    open: false,
    type: null,
    data: null
  });

  const fetchActiveSearchesData = useCallback(async () => {
    await dispatch.searches.fetchAllSearches({
      startDate: `${moment(dateRange.startDate).startOf("day").format("YYYYMMDD")}000000`,
      endDate: moment(dateRange.endDate).endOf("day").format("YYYYMMDDkkmmss")
    });
  }, [dateRange, dispatch.searches]);

  // ↓ effects ↓

  // fetch on initial app load
  // AND
  // fetch on range selection change
  useEffect(() => {
    if (dateRange && rangeSelection) fetchActiveSearchesData();
  }, [dateRange, rangeSelection, fetchActiveSearchesData]);

  const currentOwner = useMemo(() => {
    return {
      id: user.id,
      name: `${user.firstName} ${user.lastName}`
    };
  }, [user]);

  const expandedSearchesTableData = useMemo(() => {
    if (user && allSearches && searchesSubmissionsByDate && searchesCallsByDate) {
      return makeExpandedSearchesTableData({
        searches: allSearches,
        searchesSubmissions: searchesSubmissionsByDate,
        searchesCalls: searchesCallsByDate,
        currentOwner
      });
    }
  }, [user, allSearches, searchesSubmissionsByDate, searchesCallsByDate, currentOwner]);

  // ↓ callbacks ↓
  // const handleDrawerOpen = () => {
  //   setReportingDrawerOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setReportingDrawerOpen(false);
  //   // setCurrentJobPanelData(null);
  //   // setCurrentSubmissionStatus(null);
  // };

  // const handleSearchSelected = (values) => {
  //   const jobId = _.get(values, ["job", "id"]);
  //   if (jobId) {
  //     dispatch.reporting.setCurrentJob(
  //       activeJobs.find((activeJob) => activeJob.id === jobId)
  //     );
  //   } else {
  //     dispatch.reporting.setCurrentJob(null);
  //   }
  // };

  // ↓ renders ↓

  return (
    <>
      <Box>
        {expandedSearchesTableData && (
          <Box>
            <Tooltip title="Reload">
              <span>
                <IconButton
                  disabled={loading}
                  color="inherit"
                  size="small"
                  onClick={() => fetchActiveSearchesData()}
                  className="icon-button__backgrounded"
                  style={{
                    padding: theme.spacing(0.5),
                    borderRadius: 5,
                    height: 36,
                    width: 36,
                    position: "absolute",
                    top: theme.spacing(4),
                    right: theme.spacing(4)
                  }}
                >
                  {loading && <CircularProgress size={16} />}
                  {!loading && <CachedIcon fontSize="small" />}
                </IconButton>
              </span>
            </Tooltip>
            <ActiveSearchesTableExpanded
              style={{ marginTop: theme.spacing(2) }}
              loading={loading}
              handleDateRangeSelectionChange={handleDateRangeSelectionChange}
              rangeSelectionMap={rangeSelectionMap}
              rangeSelection={rangeSelection}
              setRangeSelection={(payload) => dispatch.app.setRangeSelection(payload)}
              customDateRange={customDateRange}
              setCustomDateRange={(payload) => dispatch.app.setCustomDateRange(payload)}
              currentOwner={currentOwner}
              skipPageReset={false}
              handleSubmissionsBarClick={({ data, submissions, job }) => {
                setSubmissionsBarDrawer({
                  open: true,
                  type: job?.title,
                  data: { submissions, initialStatusGroup: data.id }
                });
              }}
              data={expandedSearchesTableData}
            />
          </Box>
        )}
      </Box>
      <SubmissionsBarDrawer
        componentTitle="Active Searches"
        open={submissionsBarDrawer}
        toggleDrawer={setSubmissionsBarDrawer}
      />
    </>
  );
}
