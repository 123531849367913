const colors = {
  // grayscale, light -> dark
  white: "#FFFFFF",
  gray1: "#EEEEEE",
  gray2: "#ACACAC",
  gray3: "#F7F7F7",
  gray6: "#F0F0F0",
  gray10: "#E5E5E5",
  gray13: "#DDDDDD",
  gray43: "#909090",
  darkGray: "#2D2D2D",
  darkerGray: "#272727",
  black: "#000000",

  // branding colors
  red: "#FF5442",
  salmon: "#FE9786",
  variner: "#1B444A",
  varinerLight: "#3E9FAC",
  varinerMedium: "#3F5E62",
  varinerDark: "#1B3B40",
  varinerGold: "#FBA926",
  varinerBlue: "#2699FB",
  varinerLightBlue: "#E5F1FB",
  linkedin: "#1A7BB5",

  authDisabled: "#71A2F6"
};

export default colors;
