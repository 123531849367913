import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  List,
  Card,
  CardContent,
  Container
} from "@mui/material";
import logoNoBg from "../../assets/logo-no-bg.png";
import { colors } from "../../styles/config";
import Splash from "../../components/Splash";

const useStyles = makeStyles((theme) => ({
  logo: {
    marginBottom: 0
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: colors.white
  }
}));

export default function JobList() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { publicJobsList, loading } = useSelector((state) => state.jobs);
  const [jobsInitialized, setJobsInitialized] = useState(false);

  useEffect(() => {
    if (!jobsInitialized) {
      dispatch.jobs.startupJobsList().then(() => setJobsInitialized(true));
    }
  }, [jobsInitialized, dispatch]);

  if (loading) {
    return <Splash />;
  }

  return (
    <Box style={{ height: "100vh", width: "100vw" }}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
            style={{
              padding: 16,
              paddingLeft: 72
            }}
          >
            <img className={classes.logo} src={logoNoBg} style={{ maxHeight: 20 }} alt="logo" />
          </Box>
        </Toolbar>
      </AppBar>
      <Container>
        <Box mt={15}>
          <List>
            {publicJobsList &&
              !!(publicJobsList.length > 0) &&
              publicJobsList.map((job, index) => (
                <Card variant="outlined" key={index}>
                  <CardContent>
                    <Typography>{job.publicName}</Typography>
                  </CardContent>
                </Card>
              ))}
          </List>
        </Box>
      </Container>
    </Box>
  );
}
