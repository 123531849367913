import { APIError } from "errors";
import api from "../api";

const INITIAL_STATE = {
  calls: undefined,
  call: undefined,
  error: undefined,
  loading: false
};

// ----------> MODEL <----------

export const calls = {
  state: INITIAL_STATE,
  reducers: {
    setCalls(state, calls) {
      return { ...state, calls };
    },

    setLoading(state, loading) {
      return { ...state, loading };
    },

    setError(state, error) {
      return { ...state, error };
    },

    purge() {
      return {
        ...INITIAL_STATE
      };
    }
  },
  effects: (dispatch) => ({
    fetchCalls: async ({ dateRangePayload, userId }) => {
      try {
        dispatch.calls.setLoading(true);

        const { data: calls } = await api.post(`/calls?owner=${userId}`, dateRangePayload);

        // console.log(calls.data);
        // console.log({ THISONE: calls.data?.find((call) => call.id === 82346) });

        dispatch.calls.setCalls(calls.data);
      } catch (error) {
        dispatch.calls.setCalls([]);

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.calls.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        } else {
          dispatch.calls.setError(error);
        }

        throw error;
      } finally {
        dispatch.calls.setLoading(false);
      }
    }
  })
};
