/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Button,
  Box,
  TextField,
  FormControl,
  Typography,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colors } from "../../styles/config";
import "../../styles/stylesheets/base.css";
import ErrorMessage from "./ErrorMessage";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginBottom: 0,
    marginTop: 0,
    minWidth: "100%"
  },
  selectLabel: {
    backgroundColor: colors.white,
    paddingLeft: 5,
    paddingRight: 5
  }
}));

export default function OptOutForm({ onSubmitOptOut, onCancel, values = {} }) {
  const { error } = useSelector((state) => state.jobs);
  const notFoundError =
    error &&
    error.error &&
    error.error.toLowerCase().includes("job") &&
    error.error.includes("not found");

  const CHARACTER_LIMIT = 350;

  const initialValues = {
    optOutReason: "",
    optOutComments: ""
  };

  const data = {
    ...initialValues,
    ...values
  };

  const validationSchema = yup.object().shape({
    optOutReason: yup.string().required("Reason for opting out is required."),
    optOutComments: yup.string()
  });

  const classes = useStyles();

  if (notFoundError) {
    return <Typography>{notFoundError}</Typography>;
  }

  return (
    <Formik
      initialValues={data}
      onSubmit={async (values, { resetForm }) => {
        await onSubmitOptOut(values);
        resetForm();
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        setFieldTouched,
        setFieldValue,
        touched,
        dirty,
        handleSubmit,
        resetForm
      }) => (
        <Box
          style={{ width: "100%" }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mx="auto"
        >
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ marginTop: 24, marginBottom: 24 }}
          >
            <InputLabel className={classes.selectLabel} shrink>
              Reason for Opting Out
            </InputLabel>
            <Select
              labelId="optOutReason-label"
              id="optOutReason"
              value={values.optOutReason}
              onChange={(event) => setFieldValue("optOutReason", event.target.value)}
              label="Reason for Opting Out"
              InputLabelProps={{
                shrink: true
              }}
              onBlur={() => setFieldTouched("optOutReason", true)}
              variant="standard"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Candidate Rejected-Timing"}>Wrong Timing</MenuItem>
              <MenuItem value={"Candidate Rejected-Location"}>Wrong Location</MenuItem>
              <MenuItem value={"Candidate Rejected-Comp"}>Wrong Compensation Level</MenuItem>
              <MenuItem value={"Candidate Rejected-Role"}>Wrong Functional Role</MenuItem>
              {/* <MenuItem value={"Candidate Rejected-Industry/Size"}>
                Wrong Industry or Company Size
              </MenuItem> */}
              <MenuItem value={"Candidate Rejected-Other Offer"}>Accepting Another Offer</MenuItem>
            </Select>
            {errors.optOutReason && touched.optOutReason ? (
              <ErrorMessage message={"Please select a reason for opting out."} />
            ) : null}
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              multiline
              rows={4}
              inputProps={{
                maxLength: CHARACTER_LIMIT
              }}
              helperText={`${values.optOutComments.length}/${CHARACTER_LIMIT}`}
              className={values.optOutComments.length / CHARACTER_LIMIT === 1 ? "maxed-chars" : ""}
              variant="outlined"
              value={values.optOutComments}
              onChange={(event) => setFieldValue("optOutComments", event.target.value)}
              onBlur={() => {
                setFieldTouched("optOutComments");
              }}
              label="Comments"
              placeholder="A quick sentence or two about why you are opting out of this process."
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
          <Box my={2}></Box>

          <Box width={"100%"} display="flex" alignItems="flex-end" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={!dirty}
              style={{ width: 200 }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
          <Box width={"100%"} my={1} display="flex" alignItems="flex-end" justifyContent="flex-end">
            <Button
              variant="default"
              color="secondary"
              size="medium"
              style={{ width: 200 }}
              onClick={() => {
                resetForm();
                onCancel();
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Formik>
  );
}
