/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { FormControlLabel, FormGroup, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import { colors } from "../../../styles/config";
import { searchTypeMap } from "@/helpers/search";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginLeft: theme.spacing(1.5)
  },
  checkBoxContainer: {
    display: "flex"
  },
  innerContainer: { display: "flex", justifyContent: "" },
  label: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 6,
    paddingRight: 30,
    paddingLeft: 10,
    alignItems: "center",
    fontSize: 14,
    backgroundColor: colors.gray1,
    borderRadius: "4px",
    border: `2px solid ${colors.gray1}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colors.gray1,
      border: "2px solid #2699fb"
    }
  },

  labelContainer: {
    display: "flex",
    alignItems: "center",
    gap: 30,
    paddingLeft: 10
  },

  number: {
    lineHeight: "26px",
    textAlign: "center",
    fontSize: 14,
    pointerEvents: "none",
    backgroundColor: "white",
    height: "25px",
    width: "25px",
    borderRadius: "100%"
  },
  checkBox: {
    display: "none",
    "&.Mui-checked": {
      "&, & + .MuiFormControlLabel-label": {
        backgroundColor: "#e9f5ff",
        border: "2px solid #2699fb"
      },
      "&, & + .MuiFormControlLabel-label > .MuiBox-root > .MuiTypography-root:nth-child(2)": {
        color: "white",
        backgroundColor: "#2699fb"
      }
    }
  }
}));

export function PriorityColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const classes = useStyles();

  // Options should always be what we want to show in the top buttons,
  // even if 0 data exists for any option
  const options = Object.values(searchTypeMap);

  useEffect(() => {
    const newFilterArray = filterValue ? filterValue : [];
    setFilter(newFilterArray);
  }, []);

  const handleChange = (event, value) => {
    if (value === "All") {
      setFilter([]);
    } else {
      setFilter([value]);
    }
  };

  return (
    <Box>
      <FormControl required component="fieldset" className={classes.formControl} variant="standard">
        {/* <FormLabel component="legend">Select</FormLabel> */}
        <FormGroup>
          <Box className={classes.checkBoxContainer}>
            <Box className={classes.innerContainer}>
              <FormControlLabel
                classes={{
                  label: classes.label
                }}
                size="small"
                control={
                  <Checkbox
                    className={classes.checkBox}
                    style={{ fontSize: 12 }}
                    checked={!filterValue || !_.size(filterValue)}
                    onChange={(event) => handleChange(event, "All")}
                    name="All"
                  />
                }
                label={
                  <Box className={classes.labelContainer}>
                    <Typography>All</Typography>
                    <Typography className={classes.number}>{preFilteredRows.length}</Typography>
                  </Box>
                }
              />
            </Box>
            {options &&
              options.map((option, index) => {
                return (
                  <Box className={classes.innerContainer} key={option}>
                    <FormControlLabel
                      key={`${option}-${index}`}
                      classes={{
                        label: classes.label
                      }}
                      size="small"
                      control={
                        <Checkbox
                          className={classes.checkBox}
                          checked={filterValue ? filterValue.indexOf(option) > -1 : false}
                          onChange={(event) => handleChange(event, option)}
                          name={option}
                        />
                      }
                      label={
                        <Box className={classes.labelContainer}>
                          <Typography>{option}</Typography>
                          <Typography className={classes.number}>
                            {preFilteredRows.filter((row) => row.values.priority === option).length}
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                );
              })}
          </Box>
        </FormGroup>
      </FormControl>
    </Box>
  );
}
