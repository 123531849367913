/* eslint-disable react/prop-types */
import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import {
  Button,
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import WysiwygEditor from "./Editor";
import { colors } from "../../styles/config";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: "100%"
  },
  label: {
    fontSize: "10px !important"
  },
  formSection: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  formSectionLabel: {
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: 10
  }
}));

const likertMarks = [
  {
    value: 1,
    label: "Strongly Disagree"
  },
  {
    value: 2,
    label: "Disagree"
  },
  {
    value: 3,
    label: "Neutral"
  },
  {
    value: 4,
    label: "Agree"
  },
  {
    value: 5,
    label: "Strongly Agree"
  }
];

const gradeOptions = [
  {
    value: 5,
    label: "A"
  },
  {
    value: 4,
    label: "B"
  },
  {
    value: 3,
    label: "C"
  },
  {
    value: 2,
    label: "D"
  },
  {
    value: 1,
    label: "F"
  }
];

const gradeTransform = (letter) => {
  const map = {
    A: 5,
    B: 4,
    C: 3,
    D: 2,
    F: 1
  };

  return map[letter];
};

/**
 * ScoreSubmissionForm is used by the recruiter user to update their ranking of the candidate for that particular job,
 * so it is data that Variner is inputing vs. collecting information from candidates via the opt-in form or post-close survey form.
 *
 *  - Only the “submission owner” should be able to update the rankings and text fields ranking a candidate
 *  - The “job owner” only puts a letter grade in the job owner grade box
 *  - Submission owners should not have access to the “job owner grade” box
 *
 * @todo values is not in use.
 * @param {*} param0
 * @returns
 */
export default function ScoreSubmissionForm({ onSubmit, values = {}, varinerScoreComments }) {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useSelector((state) => state.user);

  const { currentSubmission, activeJobs, updatingSubmission } = useSelector((state) => state.jobs);

  const [editorFocused, setEditorFocused] = useState(null);
  const [resetting, setResetting] = useState(null);

  const currentJob = useMemo(() => {
    return (
      activeJobs.find((activeJob) => activeJob.id === currentSubmission.jobOrder?.id) ||
      currentSubmission.jobOrder
    );
  }, [activeJobs, currentSubmission]);

  const initialValues = {
    submissionOwnerGrade:
      (currentSubmission.submissionOwnerGrade &&
        gradeTransform(currentSubmission.submissionOwnerGrade)) ||
      null,
    jobOwnerGrade:
      (currentSubmission.jobOwnerGrade && gradeTransform(currentSubmission.jobOwnerGrade)) || null,
    varinerScoreDesire: currentSubmission.varinerScore.desire || null,
    varinerScoreDesireComments: varinerScoreComments
      ? `<strong>DESIRE:</strong>${varinerScoreComments.desire}`
      : "",
    varinerScoreAbility: currentSubmission.varinerScore.ability || null,
    varinerScoreAbilityComments: varinerScoreComments
      ? `<strong>ABILITY:</strong>${varinerScoreComments.ability}`
      : "",
    varinerScoreFit: currentSubmission.varinerScore.fit || null,
    varinerScoreFitComments: varinerScoreComments
      ? `<strong>FIT:</strong>${varinerScoreComments.fit}`
      : ""
  };

  const data = {
    ...initialValues,
    ...values
  };

  return (
    <React.Fragment>
      <Formik initialValues={data} onSubmit={onSubmit} enableReinitialize>
        {({ values, setFieldTouched, dirty, handleSubmit, setFieldValue, resetForm }) => {
          return (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              style={{ width: "100%", marginBottom: theme.spacing(10) }}
            >
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{
                  borderBottom: "1px solid #eee",
                  marginBottom: theme.spacing(3)
                }}
              >
                <Grid item sm={6}>
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="flex-start"
                    className={classes.formSection}
                  >
                    <Grid item xs={12} sm={12}>
                      <Typography
                        variant="subtitle1"
                        className={classes.formSectionLabel}
                        style={{
                          color:
                            currentSubmission.sendingUser?.id !== user.id
                              ? "rgb(144, 144, 144)"
                              : "inherit"
                        }}
                      >
                        Submission Owner Grade
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl
                        className={classes.formControl}
                        style={{ minWidth: 150 }}
                        variant="standard"
                      >
                        <Select
                          variant="outlined"
                          labelId="submissionOwnerGrade-label"
                          id="submissionOwnerGrade"
                          value={values.submissionOwnerGrade}
                          onChange={(event) =>
                            setFieldValue("submissionOwnerGrade", parseInt(event.target.value))
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          onBlur={() => setFieldTouched("submissionOwnerGrade", true)}
                          disabled={currentSubmission.sendingUser?.id !== user.id}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {gradeOptions.map((grade) => {
                            return (
                              <MenuItem value={grade.value} key={grade.value}>
                                {grade.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6}>
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={classes.formSection}
                  >
                    <Grid item xs={12} sm={12}>
                      <Typography
                        variant="body2"
                        className={classes.formSectionLabel}
                        style={{
                          color: currentJob?.owner.id !== user.id ? "rgb(144, 144, 144)" : "inherit"
                        }}
                      >
                        Job Owner Grade
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl
                        className={classes.formControl}
                        style={{ minWidth: 150 }}
                        variant="standard"
                      >
                        <Select
                          variant="outlined"
                          labelId="jobOwnerGrade-label"
                          id="jobOwnerGrade"
                          value={values.jobOwnerGrade}
                          onChange={(event) =>
                            setFieldValue("jobOwnerGrade", parseInt(event.target.value))
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          onBlur={() => setFieldTouched("jobOwnerGrade", true)}
                          disabled={currentJob?.owner.id !== user.id}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {gradeOptions.map((grade, index) => {
                            return (
                              <MenuItem key={index} value={grade.value}>
                                {grade.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {["desire", "ability", "fit"].map((scoreLabel) => {
                return (
                  <React.Fragment key={scoreLabel}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      style={{
                        paddingTop: theme.spacing(2)
                      }}
                    >
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="body2"
                          className={classes.formSectionLabel}
                          style={{
                            marginBottom: theme.spacing(1),
                            color:
                              currentSubmission.sendingUser?.id === user.id
                                ? "inherit"
                                : "rgb(144, 144, 144)"
                          }}
                        >
                          {scoreLabel === "fit"
                            ? "The candidate fits with the team / company"
                            : `The candidate has the ${scoreLabel} to do the job`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormControl className={classes.formControl} variant="standard">
                          <RadioGroup
                            row
                            style={{ justifyContent: "flex-end" }}
                            onChange={(event) => {
                              setFieldValue(
                                `varinerScore${
                                  scoreLabel.charAt(0).toUpperCase() + scoreLabel.slice(1)
                                }`,
                                parseInt(event.target.value)
                              );
                              setFieldTouched(
                                `varinerScore${
                                  scoreLabel.charAt(0).toUpperCase() + scoreLabel.slice(1)
                                }`,
                                true
                              );
                            }}
                            name={`varinerScore${
                              scoreLabel.charAt(0).toUpperCase() + scoreLabel.slice(1)
                            }`}
                            value={
                              values[
                                `varinerScore${
                                  scoreLabel.charAt(0).toUpperCase() + scoreLabel.slice(1)
                                }`
                              ]
                            }
                          >
                            {likertMarks.map((likert, index) => {
                              return (
                                <FormControlLabel
                                  key={index}
                                  value={likert.value}
                                  style={{ fontSize: 10, color: colors.gray43 }}
                                  classes={{
                                    label: classes.label
                                  }}
                                  control={
                                    <Radio
                                      color="primary"
                                      size="small"
                                      checked={
                                        values[
                                          `varinerScore${
                                            scoreLabel.charAt(0).toUpperCase() + scoreLabel.slice(1)
                                          }`
                                        ] === likert.value
                                      }
                                    />
                                  }
                                  label={likert.label}
                                  labelPlacement="top"
                                  disabled={currentSubmission.sendingUser?.id !== user.id}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {currentSubmission.sendingUser?.id !== user.id && (
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                        className={classes.formSection}
                      >
                        <Grid item sm={12}>
                          <Box
                            className="external-html-container"
                            style={{ color: "rgb(144, 144, 144)" }}
                            dangerouslySetInnerHTML={{
                              __html: varinerScoreComments[scoreLabel]
                            }}
                          ></Box>
                        </Grid>
                      </Grid>
                    )}
                    {currentSubmission.sendingUser?.id === user.id && (
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                        className={classes.formSection}
                      >
                        <Grid item sm={12}>
                          <Typography
                            variant="body2"
                            className={classes.formSectionLabel}
                            style={{
                              marginBottom: theme.spacing(1),
                              color:
                                currentSubmission.sendingUser?.id === user.id
                                  ? "inherit"
                                  : "rgb(144, 144, 144)"
                            }}
                          >
                            Comments
                          </Typography>

                          <FormControl
                            className={classes.formControl}
                            style={{
                              marginTop: 0,
                              border:
                                editorFocused === scoreLabel
                                  ? `2px solid ${colors.variner}`
                                  : "1px solid rgba(0, 0, 0, 0.23)",
                              borderRadius: 4
                            }}
                            variant="standard"
                          >
                            <WysiwygEditor
                              reset={resetting}
                              onBlur={() => setEditorFocused(null)}
                              onFocus={() => setEditorFocused(scoreLabel)}
                              varinerCommentsHtml={varinerScoreComments[scoreLabel]}
                              onChange={(string) => {
                                const updatedString = `<strong>${scoreLabel.toUpperCase()}:&nbsp;</strong>${string}`;

                                setFieldValue(
                                  `varinerScore${
                                    scoreLabel.charAt(0).toUpperCase() + scoreLabel.slice(1)
                                  }Comments`,
                                  updatedString
                                );

                                setFieldTouched(
                                  `varinerScore${
                                    scoreLabel.charAt(0).toUpperCase() + scoreLabel.slice(1)
                                  }Comments`,
                                  true
                                );
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                  </React.Fragment>
                );
              })}

              <Box
                display="flex"
                boxShadow={4}
                justifyContent="flex-end"
                style={{
                  width: "calc(55vw - 32px)",
                  backgroundColor: "white",
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  padding: theme.spacing(2),
                  zIndex: 200
                }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    resetForm();
                    setResetting(true);
                    setTimeout(() => {
                      setResetting(false);
                    }, 300);
                  }}
                  style={{
                    width: "calc(50% - 8px)",
                    marginRight: theme.spacing(1)
                  }}
                  disabled={!dirty}
                >
                  Cancel Unsaved Changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={
                    (!values.jobOwnerGrade &&
                      !values.submissionOwnerGrade &&
                      !values.varinerScoreAbility &&
                      !values.varinerScoreDesire &&
                      !values.varinerScoreFit) ||
                    (currentSubmission.sendingUser?.id !== user.id &&
                      currentJob?.owner.id !== user.id)
                  }
                  onClick={handleSubmit}
                  style={{
                    width: "calc(50% - 8px)",
                    marginLeft: theme.spacing(1)
                  }}
                >
                  Save Changes & Update in Bullhorn
                </Button>
              </Box>
            </Box>
          );
        }}
      </Formik>
      <Backdrop
        open={updatingSubmission}
        style={{
          color: colors.white,
          zIndex: 2000,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "rgba(0, 0, 0, 0.8)"
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Typography variant="h1" style={{ color: colors.white }}>
            Updating Submission
          </Typography>
        </Box>

        <CircularProgress color="inherit" style={{ marginTop: theme.spacing(2) }} />
      </Backdrop>
    </React.Fragment>
  );
}
