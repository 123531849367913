/* eslint-disable indent */
import React, { useEffect, useState, useMemo, useCallback } from "react";

// 3rd party libraries
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

// components
import ActiveSubmissionsBars from "@/components/ActiveSubmissionsBars";
import DashboardModuleCard from "@/components/DashboardModuleCard";

// helpers
import { statuses } from "@/helpers/statuses";
import SubmissionsTableDrawer from "@/components/SubmissionsTableDrawer";
import { toast } from "react-toastify";

// style things

export default function ActiveSubmissionsSection() {
  const [toggleDrawer, setToggleDrawer] = useState({
    open: false,
    type: null,
    data: null
  });

  const dispatch = useDispatch();

  const { activeSubmissions, activeLoading: activeSubmissionsLoading } = useSelector(
    (state) => state.submissions
  );

  const fetchData = useCallback(async () => {
    try {
      await dispatch.submissions.fetchActiveSubmissionsBySender();
    } catch (error) {
      toast.error("There was an error getting active submissions by sender.");
    }
  }, [dispatch.submissions]);

  // fetch data on every load
  useEffect(() => {
    fetchData();
  }, []);

  const groupedSubmissions = useMemo(() => {
    return [
      {
        status: "Select Recruit",
        submissions: !activeSubmissions
          ? []
          : activeSubmissions.filter(
              (submission) => submission.status === statuses.SELECT_RECRUIT.bhLabel
            )
      },

      {
        status: "Variner Pursuing",
        submissions: !activeSubmissions
          ? []
          : activeSubmissions.filter(
              (submission) => submission.status === statuses.VARINER_PURSUING.bhLabel
            )
      },

      {
        status: "Pending Interest",
        submissions: !activeSubmissions
          ? []
          : activeSubmissions.filter(
              (submission) => submission.status === statuses.PENDING_INTEREST.bhLabel
            )
      },

      {
        status: "Confirmed Interest",
        submissions: !activeSubmissions
          ? []
          : activeSubmissions.filter(
              (submission) => submission.status === statuses.CONFIRMED_INTEREST.bhLabel
            )
      },

      {
        status: "Client Submission",
        submissions: !activeSubmissions
          ? []
          : activeSubmissions.filter(
              (submission) => submission.status === statuses.CLIENT_SUBMISSION.bhLabel
            )
      },

      {
        status: "Interviewing",
        submissions: !activeSubmissions
          ? []
          : activeSubmissions.filter((submission) =>
              _.includes(
                [
                  statuses.INITIAL_INTERVIEW.bhLabel,
                  statuses.SECOND_INTERVIEW.bhLabel,
                  statuses.FINAL_INTERVIEW.bhLabel
                ],
                submission.status
              )
            )
      },

      {
        status: "Holding Pattern",
        submissions: !activeSubmissions
          ? []
          : activeSubmissions.filter(
              (submission) => submission.status === statuses.HOLDING_PATTERN.bhLabel
            )
      },

      {
        status: "No Response",
        submissions: !activeSubmissions
          ? []
          : activeSubmissions.filter((submission) =>
              _.includes(
                [
                  statuses.NEW_LEAD.bhLabel,
                  statuses.WEB_RESPONSE_ACCEPTED.bhLabel,
                  statuses.INTERNALLY_SUBMITTED.bhLabel
                ],
                submission.status
              )
            )
      }
    ];
  }, [activeSubmissions]);

  const highestTotal = useMemo(() => {
    const grouped = _.groupBy(activeSubmissions, "status");

    return _.max(Object.values(grouped).map((a) => a.length));
  }, [activeSubmissions]);

  return (
    <>
      {groupedSubmissions && (
        <DashboardModuleCard
          title="Active Submissions"
          refresh={true}
          onRefresh={() => dispatch.submissions.fetchActiveSubmissionsBySender()}
          refreshing={activeSubmissionsLoading}
          bottomContent={
            <ActiveSubmissionsBars
              setToggleDrawer={setToggleDrawer}
              groupedSubmissions={groupedSubmissions}
              highestTotal={highestTotal}
              loading={activeSubmissionsLoading}
            />
          }
        />
      )}
      <SubmissionsTableDrawer
        componentTitle="Active Submissions"
        // data={groupedSubmissions}
        open={toggleDrawer}
        toggleDrawer={setToggleDrawer}
      />
    </>
  );
}
