/* eslint-disable react/prop-types */
import React, { useState, useRef, useMemo } from "react";
import clsx from "clsx";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import {
  Select,
  Box,
  ListItemIcon,
  Checkbox,
  MenuItem,
  ListItemText,
  OutlinedInput
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as CheckboxIcon } from "@/assets/icons/checkbox.svg";
import { ReactComponent as CheckedIcon } from "@/assets/icons/checked-blue.svg";
import { ReactComponent as IndeterminateIcon } from "@/assets/icons/indeterminate-blue.svg";

import { colors } from "@/styles/config";
import _ from "lodash";

const useFilterDropdownStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    cursor: "pointer",
    width: 220,
    height: 50,
    borderRadius: 4,
    marginRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    backgroundColor: "rgba(238, 238, 238, 0.5)",
    border: "1px solid rgba(238, 238, 238, 0.4)",
    "&:hover": {
      backgroundColor: "rgba(238, 238, 238, 0.7)",
      border: "1px solid rgba(238, 238, 238, 0.7)"
    }
  },
  selectIcon: {
    display: "none"
  }
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 500,
      minWidth: 250
      // marginLeft: -64,
    }
  },
  variant: "menu"
  // getContentAnchorEl: null,
};

export default function FilterDropdown({
  label,
  options,
  initialSelected,
  handleSetSelectedStatuses,
  dataLength,
  // filteredDataLength,
  subheader = true,
  fontSize = 14,
  fontWeight = 500,
  style = {},
  maxSelected
}) {
  const classes = useFilterDropdownStyles();
  const theme = useTheme();
  const [selected, setSelected] = useState(initialSelected || options);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const containerRef = useRef();

  const multipleAllowed = useMemo(() => {
    return !maxSelected || maxSelected > 1;
  }, [maxSelected]);

  const handleSelectClose = () => {
    // if not multiple, close will be automatic
    if (multipleAllowed) {
      handleSetSelectedStatuses(selected);
    }

    setAnchorEl(null);
  };

  const handleSelectOpen = () => {
    setAnchorEl(containerRef.current);
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === "All") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }

    if (!multipleAllowed) {
      handleSetSelectedStatuses(value);
    }

    setSelected(value);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      className={clsx(classes.container, "no-outline")}
      ref={containerRef}
      style={style || {}}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        className="clickable-area"
        onClick={handleSelectOpen}
        style={{ minWidth: 160, cursor: "pointer" }}
      >
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex" }}>
            <Typography
              mr={1}
              style={{
                fontSize,
                fontWeight
              }}
            >
              {label && <span style={{ marginRight: theme.spacing(0.5) }}>{`${label}: `}</span>}
              {!label && multipleAllowed && (
                <span style={{ marginRight: theme.spacing(0.5) }}>{`${
                  _.size(selected) === 1 ? selected[0] : _.size(selected) + " Selected"
                }`}</span>
              )}
              {!label && !multipleAllowed && (
                <span style={{ marginRight: theme.spacing(0.5) }}>{`${selected}`}</span>
              )}
              {_.size(options) === _.size(selected) && <span style={{ fontWeight: 500 }}>All</span>}
            </Typography>
          </Box>
          {subheader && _.size(options) !== _.size(selected) ? (
            <Typography
              style={{
                fontSize: 9,
                color: colors.varinerBlue,
                position: "absolute",
                bottom: -2
              }}
            >{`Filters selected: ${_.size(selected)}`}</Typography>
          ) : null}
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            borderRadius: "100%",
            width: 26,
            height: 26,
            color: "inherit",
            backgroundColor: colors.white,
            visibility: dataLength ? "visible" : "hidden"
          }}
        >
          <Typography style={{ fontSize: 14 }}>{dataLength}</Typography>
        </Box>
      </Box>
      <IconButton
        size="small"
        onClick={handleSelectOpen}
        style={{
          position: "absolute",
          right: theme.spacing(1),
          top: theme.spacing(0.3),
          zIndex: 200,
          pointerEvents: "initial"
        }}
      >
        <KeyboardArrowDownIcon size="small" />
      </IconButton>
      {/* <Popover
        open={Boolean(anchorEl)}
        onClose={handleSelectClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      > */}
      <Select
        open={Boolean(anchorEl)}
        onClose={handleSelectClose}
        classes={{ icon: classes.selectIcon }}
        variant="outlined"
        labelId="status-label"
        id={`status-select-${label}`}
        multiple={multipleAllowed}
        value={selected}
        renderValue={() => {
          return null;
        }}
        onChange={handleStatusChange}
        input={
          <OutlinedInput classes={{ inputMarginDense: classes.inputMarginDense }} margin="dense" />
        }
        label=""
        MenuProps={MenuProps}
        IconComponent={KeyboardArrowDownIcon}
        // style={{ marginTop: -60 }}
      >
        {multipleAllowed && (
          <MenuItem value="all" style={{ padding: 0 }}>
            <ListItemIcon style={{ minWidth: 0 }}>
              <Checkbox
                size="small"
                checked={options.length > 0 && selected.length >= options.length}
                indeterminate={selected.length > 0 && selected.length < options.length}
                icon={<CheckboxIcon />}
                checkedIcon={<CheckedIcon />}
                indeterminateIcon={<IndeterminateIcon />}
              />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body2">Select All</Typography>} />
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem value={option} key={option.replace(" ", "")} style={{ padding: 0 }}>
            <Checkbox
              size="small"
              checked={selected.indexOf(option) > -1}
              icon={<CheckboxIcon />}
              checkedIcon={<CheckedIcon />}
              indeterminateIcon={<IndeterminateIcon />}
            />
            <ListItemText primary={<Typography variant="body2">{option}</Typography>} />
          </MenuItem>
        ))}
      </Select>
      {/* </Popover> */}
    </Box>
  );
}
