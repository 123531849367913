/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTheme } from "@mui/material/styles";

const convertHtml = (html) => {
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
};

export default function WysiwygEditor({
  varinerCommentsHtml,
  onChange,
  onFocus,
  onBlur,
  reset,
  height = 80
}) {
  const theme = useTheme();

  const initialContentState =
    varinerCommentsHtml && varinerCommentsHtml !== "null"
      ? convertHtml(varinerCommentsHtml)
      : EditorState.createEmpty();

  const [editorState, setEditorState] = useState(initialContentState);
  const handleChange = (newEditorState) => {
    setEditorState(newEditorState);
    const htmlString = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onChange(htmlString);
  };

  useEffect(() => {
    if (reset) {
      setEditorState(initialContentState);
    }
  }, [reset, initialContentState]);

  return (
    <div>
      <Editor
        onBlur={onBlur}
        onFocus={onFocus}
        editorStyle={{
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          fontSize: 14,
          height
        }}
        toolbar={{ options: ["list", "link"] }}
        editorState={editorState}
        wrapperClassName="wysiwyg-editor-wrapper"
        editorClassName="wysiwyg-editor"
        onEditorStateChange={handleChange}
      />
    </div>
  );
}
