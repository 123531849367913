/* eslint-disable react/prop-types */
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Box } from "@mui/material";
import logo from "@/assets/logo-no-bg.png";

const useStyles = makeStyles((theme) => ({
  thankYouContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrom: 1,
    height: "100vh",
    alignItems: "center",
    justifyContent: "center"
  },
  indicator: {
    textAlign: "center"
  },
  allTextContainer: {
    width: "75%",
    margin: "auto"
  },
  textBox: {
    marginTop: theme.spacing(2)
  },
  logoContainer: {
    marginTop: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default function ThankYouSplash({ header, text, Component }) {
  const classes = useStyles();

  return (
    <div className={classes.thankYouContainer}>
      <div className={classes.indicator}>
        {!header && !text && <Typography variant="h1">Thank you! We will be in touch.</Typography>}
        {(header || text || Component) && (
          <Box className={classes.allTextContainer}>
            <Typography variant="h1" style={{ marginBottom: 8 }}>
              {header}
            </Typography>
            {text && (
              <Typography className={classes.textBox} variant="h3">
                {text}
              </Typography>
            )}
            {Component && <Component />}
          </Box>
        )}
        <Box className={classes.logoContainer}>
          <a href="https://www.variner.com">
            <img src={logo} style={{ maxHeight: 15 }} alt="logo" />
          </a>
        </Box>
      </div>
    </div>
  );
}
