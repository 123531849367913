export default class APIError extends Error {
  constructor(message) {
    super(message);

    this.name = "APIError";
  }
}

// // eslint-disable-next-line @typescript-eslint/no-empty-interface
// export default interface APIError extends AxiosError {}
