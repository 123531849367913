import { init } from "@rematch/core";
// import createRematchPersist from "@rematch/persist";
import persistPlugin from "@rematch/persist";
import models from "./models";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  // add any state key we want to apply the rematch persistor
  blacklist: ["app"], // never persist the app reducer
  whitelist: [
    "user",
    "submissions",
    // "jobs",
    // "reporting",
    "calls",
    "candidates",
    "placements",
    "searches"
  ]
  // throttle: 3000,
};

const store = init({
  models,
  plugins: [persistPlugin(persistConfig)],
  redux: {
    devtoolOptions: { trace: true }
  }
});

export default store;
