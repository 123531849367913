export const filterObjectByKey = (obj, f) => {
  return Object.entries(obj)
    .filter(([key]) => f(key))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};

export const filterObjectByValue = (obj, f) => {
  return Object.entries(obj) // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => f(value))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};

export const removeById = (state, payload) => {
  const { id: idToRemove } = payload;
  // hermagherd apparently Object.entries stringifies integer keys
  return filterObjectByKey(state, (id) => id !== idToRemove.toString());
};

export const addById = (state, payload) => {
  const { id } = payload;
  return {
    ...state,
    [id]: payload
  };
};

export const removeAllIds = (state, payload) => {
  const { id: idToRemove } = payload;
  return state.filter((id) => id !== idToRemove);
};

export const updateById = (state, payload) => {
  const { id } = payload;
  return {
    ...state,
    [id]: {
      ...state[id],
      ...payload
    }
  };
};

export const toggleById = (state, payload) => {
  const { id } = payload;
  return {
    ...state,
    [id]: {
      ...state[id],
      done: !state[id].done
    }
  };
};

export const toggleAllIds = (state) => {
  return state;
};
