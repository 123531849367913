import React from "react";

// 3rd party libraries
import { Box, Typography, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// components

// helpers

// style things
import { constants as styleConstants } from "@/styles/config";
import QuotasSection from "@/components/sections/QuotasSection";
import ActiveSubmissionsSection from "@/components/sections/ActiveSubmissionsSection";
import ActiveSearchesCompactSection from "@/components/sections/ActiveSearchesCompactSection";

const useStyles = makeStyles(() => ({
  container: {
    padding: styleConstants.desktopDrawerClosedWidth / 2,
    maxWidth: "100vw",
    minHeight: "100vh"
  },
  content: {
    marginTop: 20,
    marginLeft: styleConstants.desktopDrawerClosedWidth
  },

  pageTitle: {
    marginLeft: styleConstants.desktopDrawerClosedWidth
  },
  titleText: { fontSize: 24, fontWeight: "bold" }
}));

export default function InternalHomeView() {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth={false}>
      <Box className={classes.pageTitle}>
        <Typography className={classes.titleText}>Recruiter Home</Typography>
      </Box>
      <Box className={classes.content}>
        <QuotasSection />

        <Box my={2}></Box>

        <Box sx={{ display: "flex" }}>
          <ActiveSearchesCompactSection />
          <Box mx={2}></Box>
          <ActiveSubmissionsSection />
        </Box>

        <Box my={2}></Box>

        {/* <DashboardModuleCard
          title="Calendar"
          bottomContent={<Box>Working Feature TBD</Box>}
        /> */}
      </Box>
    </Container>
  );
}
