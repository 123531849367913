import api from "../api";
import { APIError } from "@/errors";

const INITIAL_STATE = {
  submissions: undefined,
  activeSubmissions: undefined,
  submission: undefined,
  error: undefined,
  loading: false,
  activeLoading: false,
  lastAPIError: undefined,
  lastAPIErrorEffect: undefined // "fetch" | "fetchActive"
};

// ----------> MODEL <----------

export const submissions = {
  state: INITIAL_STATE,

  reducers: {
    setSubmissions(state, submissions) {
      return { ...state, submissions };
    },

    setActiveSubmissions(state, activeSubmissions) {
      return { ...state, activeSubmissions };
    },

    setLoading(state, loading) {
      return { ...state, loading };
    },

    setActiveLoading(state, activeLoading) {
      return { ...state, activeLoading };
    },

    setError(state, error) {
      return { ...state, error };
    },

    setLastAPIError(state, payload) {
      return {
        ...state,
        ...payload
      };
    },

    purge() {
      return {
        ...INITIAL_STATE
      };
    }
  },
  effects: (dispatch) => ({
    fetchSubmissionsBySender: async ({ dateRangePayload, userId }) => {
      try {
        dispatch.submissions.setLoading(true);

        const { data: submissions } = await api.post(
          `/submissionsBySendingUser?sender=${userId}`,
          dateRangePayload
        );

        dispatch.submissions.setSubmissions(submissions.data);
      } catch (error) {
        dispatch.submissions.setSubmissions([]);

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          dispatch.submissions.setLastAPIError({
            lastAPIError: error,
            lastAPIErrorEffect: "fetch"
          });
        } else {
          dispatch.submissions.setError(error);
        }

        throw error;
      } finally {
        dispatch.submissions.setLoading(false);
      }
    },
    fetchActiveSubmissionsBySender: async (_, rootState) => {
      try {
        const user = rootState.user.user;
        const userId = user?.id;

        dispatch.submissions.setActiveLoading(true);

        const { data: activeSubmissions } = await api.post(
          `/activeSubmissionsBySendingUser?sender=${userId}`
        );

        dispatch.submissions.setActiveSubmissions(activeSubmissions.data);
      } catch (error) {
        dispatch.submissions.setActiveSubmissions([]);

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          dispatch.submissions.setLastAPIError({
            lastAPIError: error,
            lastAPIErrorEffect: "fetchActive"
          });
        } else {
          dispatch.submissions.setError(error);
        }

        throw error;
      } finally {
        dispatch.submissions.setActiveLoading(false);
      }
    }
  })
};
