import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import BootstrapWrapper from "./BootstrapWrapper";

// eslint-disable-next-line react/prop-types
export default function PrivateRoute({ children, ...rest }) {
  const { user, authorized } = useSelector((state) => state.user);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user && authorized ? (
          <BootstrapWrapper>{children}</BootstrapWrapper>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
