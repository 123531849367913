/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../styles/config";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  statCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    border: `2px solid ${colors.gray1}`,
    borderRadius: 6,
    overflow: "hidden",
    padding: theme.spacing(3),
    minHeight: 124
  },
  title: {
    fontSize: 16,
    color: colors.gray43
  },
  counter: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: theme.spacing(2)
  },
  topStat: {
    fontSize: 36,
    color: colors.darkGray,
    fontWeight: 500,
    marginBottom: -8
  },
  divider: {
    marginLeft: theme.spacing(1),
    fontSize: 24,
    fontWeight: 500,
    color: colors.gray43
  },
  bottomStat: {
    marginLeft: theme.spacing(1),
    fontSize: 24,
    fontWeight: 500,
    color: colors.gray43
  },
  loadingLine: {
    height: 24,
    width: "100%",
    backgroundColor: colors.gray1,
    marginTop: theme.spacing(1),
    borderRadius: 3
  }
}));

export default function StatCardV2({
  title,
  topStat,
  bottomStat,
  bottomComponent,
  statDivider = false,
  loading = false
}) {
  const classes = useStyles();

  return (
    <Box className={classes.statCard}>
      <Typography className={classes.title} variant="body1">
        {title}
      </Typography>

      {loading && <Box className={classes.loadingLine} style={{ height: 36 }} mt={1}></Box>}
      {!loading && (
        <Box className={classes.counter}>
          <Typography className={classes.topStat} variant="body1">
            {topStat}
          </Typography>
          {statDivider && (
            <Typography className={classes.divider} variant="body1">
              /
            </Typography>
          )}
          <Typography className={classes.bottomStat} variant="body1">
            {bottomStat}
          </Typography>
        </Box>
      )}
      {!loading && <Box m={1}></Box>}
      {loading && <Box className={classes.loadingLine}></Box>}
      {!loading && <Box className={classes.bottomContent}>{bottomComponent}</Box>}
    </Box>
  );
}
