import React, { useEffect, useMemo, useState } from "react";

// 3rd party libraries
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  CircularProgress,
  Container,
  Button,
  Paper,
  Typography,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// styles
import { colors } from "../../styles/config";

const useStyles = makeStyles((theme) => ({
  cardInnerContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "calc(100% - 32px)"
  },
  cardTitle: {
    color: colors.black
  },
  divider: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100%"
  },
  container: {
    maxWidth: 1600,
    marginTop: 20
  },
  backIcon: {
    width: 16
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  fullWidth: {
    width: "100%"
  }
}));

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export default function InternalSellingPointsView() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { id } = useParams();
  const { activeJobs, attachedFile } = useSelector((state) => state.jobs);
  const [fileId, setFileId] = useState();
  const [fileName, setFileName] = useState();

  const job = useMemo(() => {
    return activeJobs && activeJobs.length && activeJobs.find((j) => j.id === parseInt(id));
  }, [activeJobs, id]);

  useEffect(() => {
    if (job) {
      job.fileAttachments &&
        job.fileAttachments.data &&
        job.fileAttachments.data.forEach((e) => {
          if (e.type === "Additional Info. Deck") {
            setFileId(e.id);
            setFileName(e.name);
          }
        });
    }
  }, [job]);

  useEffect(() => {
    if (fileId) {
      dispatch.jobs.fetchAttachedFile({ id: id, fileId: fileId });
    }
  }, [fileId, job]);

  useEffect(() => {
    if (job) {
      dispatch.jobs.setAttachedFile(null);
      dispatch.jobs.setCurrentJob(job);
      dispatch.app.setCurrentPageTitle("Selling Points");
    }
    return function cleanup() {
      setFileId(null);
      dispatch.app.setCurrentPageTitle(null);
    };
  }, [job, dispatch]);

  return (
    <Box mb={4}>
      <Container className={classes.container}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Button
            color="primary"
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIosIcon className={classes.backIcon} />}
          >
            Back
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={12} md={8}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="subtitle1" className={classes.cardTitle}>
                  Quick Selling Points
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  className={classes.cardInnerContainer}
                >
                  {job && (
                    <Box
                      className="external-html-container MuiTypography-body2"
                      dangerouslySetInnerHTML={{
                        __html: job.customTextBlock1
                      }}
                    ></Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="subtitle1" className={classes.cardTitle}>
                  Selling Points
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  className={classes.cardInnerContainer}
                >
                  {job && (
                    <Box
                      className="external-html-container MuiTypography-body2"
                      dangerouslySetInnerHTML={{
                        __html: job.correlatedCustomTextBlock1
                      }}
                    ></Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item sm={12} md={4}>
            <Paper
              elevation={2}
              style={{
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2)
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                className={classes.cardInnerContainer}
              >
                <Box className={classes.row}>
                  <Typography variant="subtitle1" className={classes.cardTitle}>
                    Reason for Hire
                  </Typography>
                  {job && (
                    <Box>
                      <Typography>{job.correlatedCustomText2}</Typography>
                    </Box>
                  )}
                </Box>
                {/* ------------ */}
                <Divider dark orientation="horizontal" className={classes.divider} />
                <Box className={classes.row}>
                  <Typography variant="subtitle1" className={classes.cardTitle}>
                    Remote Allowed
                  </Typography>
                  {job && (
                    <Box>
                      <Typography>{job.customText5 || "Unknown"}</Typography>
                    </Box>
                  )}
                </Box>
                {/* ------------ */}
                <Divider className={classes.divider} />
                <Box className={classes.row}>
                  <Typography variant="subtitle1" className={classes.cardTitle}>
                    Location & Travel
                  </Typography>
                </Box>
                {job && (
                  <Box className={classes.fullWidth}>
                    <Box className={classes.row}>
                      <Typography variant="subtitle2">Preferred Location</Typography>
                      <Typography>{`${_.get(job, ["address", "city"])}, ${_.get(job, [
                        "address",
                        "state"
                      ])}`}</Typography>
                    </Box>
                    {job.customText6 && (
                      <Box mt={1} className={classes.row}>
                        <Typography variant="subtitle2">Secondary Location</Typography>
                        <Typography>{job.customText6}</Typography>
                      </Box>
                    )}
                    {job.travelRequirements && (
                      <Box mt={1} className={classes.row}>
                        <Typography variant="subtitle2">Travel Requirements</Typography>
                        <Typography>{job.travelRequirements}</Typography>
                      </Box>
                    )}
                  </Box>
                )}
                {/* ------------ */}
                <Divider className={classes.divider} />
                <Box className={classes.row}>
                  <Typography variant="subtitle1" className={classes.cardTitle}>
                    Target Start Date
                  </Typography>
                  {job && (
                    <Box>
                      <Typography>{moment(job.startDate).format("MMM DD, YYYY")}</Typography>
                    </Box>
                  )}
                </Box>
                {/* ------------ */}
                <Divider className={classes.divider} />
                <Box className={classes.row}>
                  <Typography variant="subtitle1" className={classes.cardTitle}>
                    Compensation Info
                  </Typography>
                </Box>
                {job && (
                  <Box className={classes.fullWidth}>
                    {!!job.correlatedCustomFloat1 && (
                      <Box className={classes.row}>
                        <Typography variant="subtitle2">Base Salary Low</Typography>
                        <Typography>{usdFormatter.format(job.correlatedCustomFloat1)}</Typography>
                      </Box>
                    )}
                    {!!job.correlatedCustomFloat2 && (
                      <Box mt={1} className={classes.row}>
                        <Typography variant="subtitle2">Base Salary High</Typography>
                        <Typography>{usdFormatter.format(job.correlatedCustomFloat2)}</Typography>
                      </Box>
                    )}
                    {!!job.correlatedCustomInt1 && (
                      <Box mt={1} className={classes.row}>
                        <Typography variant="subtitle2">Target Bonus % Low</Typography>
                        <Typography>{`${job.correlatedCustomInt1}%`}</Typography>
                      </Box>
                    )}
                    {!!job.correlatedCustomInt2 && (
                      <Box mt={1} className={classes.row}>
                        <Typography variant="subtitle2">Target Bonus % High</Typography>
                        <Typography>{`${job.correlatedCustomInt2}%`}</Typography>
                      </Box>
                    )}
                    {!!job.correlatedCustomText9 && (
                      <Box mt={1} className={classes.row}>
                        <Typography variant="subtitle2">Equity Compensation</Typography>
                        <Typography>{job.correlatedCustomText9}</Typography>
                      </Box>
                    )}
                    {!!job.correlatedCustomText10 && (
                      <Box mt={1} className={classes.row}>
                        <Typography variant="subtitle2">Equity Types</Typography>
                        <Typography>{job.correlatedCustomText10}</Typography>
                      </Box>
                    )}
                    {!!job.correlatedCustomFloat1 && !!job.correlatedCustomInt1 && (
                      <Box className={classes.row}>
                        <Typography variant="subtitle2">All In Low End</Typography>
                        <Typography>
                          {usdFormatter.format(
                            job.correlatedCustomFloat1 +
                              job.correlatedCustomFloat1 *
                                (parseFloat(job.correlatedCustomInt1) / 100)
                          )}
                        </Typography>
                      </Box>
                    )}
                    {!!job.correlatedCustomFloat2 && !!job.correlatedCustomInt2 && (
                      <Box className={classes.row}>
                        <Typography variant="subtitle2">All In High End</Typography>
                        <Typography>
                          {usdFormatter.format(
                            job.correlatedCustomFloat2 +
                              job.correlatedCustomFloat2 *
                                (parseFloat(job.correlatedCustomInt2) / 100)
                          )}
                        </Typography>
                      </Box>
                    )}
                    {!!job && fileId && (
                      <Box className={classes.row}>
                        <Typography variant="subtitle2">Attached Files</Typography>
                        <Typography>
                          {attachedFile ? (
                            <a
                              href={`data:application/pdf;base64,${attachedFile}`}
                              download={fileName}
                            >
                              Download Files
                            </a>
                          ) : (
                            <CircularProgress />
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
