/* eslint-disable react/prop-types */
import React from "react";
import { colors } from "../../styles/config";
import { Box, Typography } from "@mui/material";

export default function ErrorMesage({ message, style = {} }) {
  return (
    <Box
      style={{
        padding: "0px 14px",
        position: "absolute",
        bottom: "10px",
        ...style
      }}
    >
      <Typography
        variant="body2"
        style={{
          color: colors.red,
          fontSize: "10px"
        }}
      >
        {message}
      </Typography>
    </Box>
  );
}
