/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import _ from "lodash";
import { colors } from "../../../styles/config";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2,
    fontSize: 12
    // backgroundColor: getStatusColor(submission.status),
    // color: colors.white,
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      zIndex: 6005
    }
  }
};

export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const classes = useStyles();
  // Calculate the options for filtering using the preFilteredRows
  const options = useMemo(() => {
    const optionsSet = new Set();

    const uniqueOptions = preFilteredRows
      .map((row) => row.values[id])
      .filter((value) => {
        if (!value || optionsSet.has(value)) {
          return false;
        }
        optionsSet.add(value);
        return true;
      });

    return _.sortBy(uniqueOptions);
  }, [id, preFilteredRows]);

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <FormControl className={classes.formControl} variant="standard">
      <Select
        variant="standard"
        labelId={`column-filter-${id}-label`}
        id={`column-filter-${id}`}
        multiple
        value={filterValue || []}
        onChange={handleChange}
        input={<Input id={`select-multiple-column-${id}`} />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip size="small" key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
        style={{ minWidth: 400, backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        {options.map((opt, index) => {
          const grayStyle = {
            fontWeight: "bold",
            backgroundColor: `${colors.gray43} !important`,
            fontSize: 12
          };

          return (
            <MenuItem
              color="primary"
              key={`${opt}-${index}`}
              value={opt}
              style={filterValue?.indexOf(opt) > -1 ? grayStyle : { fontSize: 12 }}
            >
              {opt}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
