/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { ResponsivePie } from "@nivo/pie";
import _ from "lodash";
import moment from "moment";
import { findStatusObject, findStatusObjectIndex } from "@/helpers/statuses";

const getStatusColor = (status) => {
  const statusMapObject = findStatusObject(status);
  return statusMapObject.color;
};

const randomColorsMap = [
  "#FF5442",
  "#BB8378",
  "#56423E",
  "#D82E26",
  "#690000",
  "#BEA6A1",
  "#996B6B",
  "#56423E",
  "#BEA6A1",
  "#9F0000",
  "#B2000B"
];

export default function SubmissionsStatusPie({
  submissions,
  showLabel = true,
  onSliceClick,
  currentDay,
  colors
}) {
  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0;
    dataWithArc.forEach((datum) => {
      total += datum.value;
    });

    return (
      <React.Fragment>
        <text
          onClick={() => onSliceClick(null)}
          x={centerX}
          y={centerY - 16}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "proxima-nova",
            cursor: "pointer",
            fill: "#909090"
          }}
        >
          {currentDay ? `${moment(currentDay).format("ddd MM/DD/YYYY")}` : "Total"}
        </text>
        <text
          onClick={() => onSliceClick(null)}
          x={centerX}
          y={centerY + 8}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "24px",
            fontWeight: "600",
            fontFamily: "proxima-nova",
            cursor: "pointer"
          }}
        >
          {`${total}`}
        </text>
      </React.Fragment>
    );
  };
  const submissionsByStatus = _.groupBy(submissions, "status");

  const sortedSubmissionsByStatus = useMemo(() => {
    let sortable = [];
    for (let status in submissionsByStatus) {
      sortable.push([status, submissionsByStatus[status]]);
    }

    return sortable.sort(function (a, b) {
      const aStatusMapObjectIndex = findStatusObjectIndex(a[0]);
      const bStatusMapObjectIndex = findStatusObjectIndex(b[0]);
      return aStatusMapObjectIndex - bStatusMapObjectIndex;
    });
  }, [submissionsByStatus]);

  const data = useMemo(() => {
    const dataArray =
      sortedSubmissionsByStatus &&
      sortedSubmissionsByStatus.map((statusSubmissions, index) => {
        const status = statusSubmissions[0];
        const submissions = statusSubmissions[1];
        return {
          id: status,
          label: status,
          value: submissions.length,
          color: colors === "random" ? randomColorsMap[index] : getStatusColor(status)
        };
      });

    return dataArray;
  }, [sortedSubmissionsByStatus, colors]);

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 16, right: 16, bottom: 16, left: 16 }}
      innerRadius={0.6}
      padAngle={0.7}
      cornerRadius={2}
      colors={{ datum: "data.color" }}
      borderWidth={0}
      // borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      enableArcLinkLabels={showLabel}
      arcLinkLabelsSkipAngle={0}
      arcLinkLabel={(d) => `${d.label}`}
      radialLabelsSkipAngle={0}
      arcLabelsSkipAngle={0}
      arcLabelsTextColor="white"
      arcLinkLabelsDiagonalLength={8}
      arcLinkLabelsStraightLength={10}
      onClick={onSliceClick && onSliceClick}
      layers={["arcLinkLabels", "arcs", "arcLabels", "legends", CenteredMetric]}
    />
  );
}
