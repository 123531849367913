/* eslint-disable react/prop-types */
// @ts-nocheck
import React from "react";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// Define a default UI for filtering
export function TextFilter({ column }) {
  const { Header, filterValue, preFilteredRows, setFilter } = column;
  const count = preFilteredRows.length;

  const selectedFilterValue = filterValue;

  return (
    <TextField
      variant="standard"
      id={`default-column-filter-${Header.replace(/[^A-Z0-9]/gi, "")}`}
      size="small"
      iconRight={SearchIcon}
      placeholder={`${Header} (search ${count} records)`}
      value={selectedFilterValue || ""}
      onChange={(event) => {
        setFilter(event.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}
