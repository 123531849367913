import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIError } from "@/errors";

const useAPIErrorEffect = ({ error = APIError, modelName, effectName, callback }) => {
  const dispatch = useDispatch();

  const { lastAPIError, lastAPIErrorEffect } = useSelector((state) => state[modelName]);

  useEffect(() => {
    if (!(lastAPIError instanceof error)) return;

    if (effectName && lastAPIErrorEffect !== effectName) return;

    callback();
  }, [lastAPIError, lastAPIErrorEffect, dispatch, error, effectName, callback]);
};

export default useAPIErrorEffect;
