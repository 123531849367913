import moment from "moment";
import _ from "lodash";
import { getStateAbbr } from "../../helpers/states";

export default function makeData(submissions) {
  return submissions.map((submission) => {
    const answers = [
      {
        question: "Why I am interested in this opportunity",
        answer: submission.customTextBlock1
      },
      {
        question: "My relevant work experience",
        answer: submission.customTextBlock3
      },
      {
        question: "Why I am interested in the location",
        answer: submission.customTextBlock2
      },
      {
        question: "Earliest Start Date",
        answer: submission.customDate2 && moment(submission.customDate2).format("MMM Do, YYYY")
      },
      {
        question: "Ideal Start Date",
        answer: submission.customDate1 && moment(submission.customDate1).format("MMM Do, YYYY")
      }
    ].filter((a) => a.answer);
    const answeredTotal = answers.length;

    const complete = answeredTotal === 5;
    const partial = answeredTotal > 0 && answeredTotal < 5;
    const desireScore = submission.varinerScore.desire;
    const abilityScore = submission.varinerScore.ability;
    const fitScore = submission.varinerScore.fit;

    const varinerScoreTotal =
      desireScore && abilityScore && fitScore ? desireScore + abilityScore + fitScore : null;

    return {
      id: _.get(submission, ["id"]),
      firstName: _.get(submission, ["candidate", "firstName"]),
      lastName: _.get(submission, ["candidate", "lastName"]),
      undergradYear: _.get(submission, ["candidate", "undergradYear"]),
      undergradSchool: _.get(submission, ["candidate", "undergradSchool"]),
      email: (_.get(submission, ["candidate", "email"]) || "").toLowerCase(),
      currentFirm: _.get(submission, ["candidate", "companyName"]),
      undergradGPA: _.get(submission, ["candidate", "gpa"]),
      SATMath: _.get(submission, ["candidate", "customFloat2"]),
      SATVerbal: _.get(submission, ["candidate", "customFloat3"]),
      ACT: _.get(submission, ["candidate", "customText6"]),
      GMAT: _.get(submission, ["candidate", "customInt3"]),
      occupation: _.get(submission, ["candidate", "occupation"]),
      city: _.get(submission, ["candidate", "address", "city"]),
      state: getStateAbbr(_.get(submission, ["candidate", "address", "state"])),
      status: _.get(submission, ["status"]),
      owner: _.get(submission, ["sendingUser", "firstName"]),
      source: _.get(submission, ["source"]),
      submissionOwnerGrade: _.get(submission, ["submissionOwnerGrade"]),
      jobOwnerGrade: _.get(submission, ["jobOwnerGrade"]),
      score: varinerScoreTotal,
      qa: complete ? 2 : partial ? 1 : 0
    };
  });
}
