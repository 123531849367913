/* eslint-disable react/prop-types */
import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";
import { ZERO_VALUE } from "@/helpers/constants";

export default function ReportingFiltersMenu({ filters, setHasOwnerFilter }) {
  const theme = useTheme();

  const renderFilters = () => {
    return filters.map(renderFilter);
  };

  const renderFilter = ({ label, icon, onClick = () => {}, onDelete = () => {} }) => {
    const Icon = icon;

    if (filters[0]?.kind === "owner") {
      setHasOwnerFilter(true);
    }

    const handleDelete = () => {
      setHasOwnerFilter(false);
      onDelete();
    };

    return (
      <Chip
        key={label}
        size="small"
        icon={icon ? <Icon /> : null}
        label={label}
        onClick={onClick}
        onDelete={handleDelete}
        variant="outlined"
        style={{ marginRight: theme.spacing(2), fontSize: 12 }}
      />
    );
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="subtitle1" style={{ marginRight: theme.spacing(2) }}>
        Filters
      </Typography>
      {filters?.length > ZERO_VALUE && renderFilters()}
    </Box>
  );
}
