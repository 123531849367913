/* eslint-disable react/prop-types */
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { colors } from "../styles/config";

export default function JobSlateHeaderInfo({
  descriptor,
  title,
  helperText,
  compact = false,
  includeDescriptor = true,
  component = null,
  style = {}
}) {
  const Component = component;
  const theme = useTheme();

  return (
    <Box
      mr={compact ? 0 : 5}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      style={{ width: compact ? "auto" : 140, ...style }}
    >
      <Box>
        <Box
          px={2}
          display="flex"
          style={{
            borderLeft: `2px solid ${colors.varinerGold}`,
            visibility: includeDescriptor ? "visible" : "hidden"
          }}
        >
          <Typography variant="body2" style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: 12 }}>
            {descriptor}
          </Typography>
        </Box>
        <Box pt={compact ? 1 : 2}>
          <Typography variant="h3" style={{ marginLeft: 16, color: "rgba(255, 255, 255, 1)" }}>
            {title}
          </Typography>
        </Box>
        {helperText && (
          <Box
            style={{
              paddingTop: compact ? theme.spacing(0.5) : theme.spacing(1),
              paddingBottom: compact ? 0 : theme.spacing(1)
            }}
          >
            <Typography
              variant="body2"
              style={{ marginLeft: 16, color: "rgba(255, 255, 255, 1.0)" }}
            >
              {helperText}
            </Typography>
          </Box>
        )}
      </Box>
      {component && (
        <Box px={2} pt={1}>
          <Component />
        </Box>
      )}
    </Box>
  );
}
