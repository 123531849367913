/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Container, IconButton } from "@mui/material";
import moment from "moment";
import _ from "lodash";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";

import ProgressBar from "./ProgressBar";
import JobSlateHeaderInfo from "./JobSlateHeaderInfo";
import { colors } from "../styles/config";

function MoveUpOnScroll({ children, style = {} }) {
  return (
    <Box
      style={{
        transition: "0.2s all ease",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        // left: 57,
        // width: "calc(100% - 57px)",
        // marginLeft: -57,
        zIndex: 10,
        ...style
      }}
    >
      {children}
    </Box>
  );
}

function UserContactButtons({ owner }) {
  const theme = useTheme();
  const { internalUsers } = useSelector((state) => state.user);
  const internalUser = internalUsers && internalUsers.find((u) => u.id === owner.id);

  if (!internalUser) return null;

  return (
    <Box>
      <IconButton
        size="small"
        color="inherit"
        style={{
          color: colors.white,
          backgroundColor: colors.varinerBlue,
          marginRight: theme.spacing(1)
        }}
        href={`mailto:${internalUser.email}`}
      >
        <MailOutlineIcon
          fontSize="small"
          style={{
            fontSize: 14,
            padding: theme.spacing(0.2)
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
        style={{
          color: colors.white,
          backgroundColor: colors.varinerBlue
        }}
        href={`tel:${internalUser.phone}`}
      >
        <PhoneInTalkOutlinedIcon
          fontSize="small"
          style={{
            fontSize: 14,
            padding: theme.spacing(0.2)
          }}
        />
      </IconButton>
    </Box>
  );
}

export default function JobSlateHeader({ groupedSubmissions, style = {} }) {
  const theme = useTheme();
  const { submissions, currentJob } = useSelector((state) => state.jobs);

  const getDiff = (date, now) => {
    const diff = moment(date).diff(now, "days");
    const pastDate = diff < 0;
    return pastDate ? `${Math.abs(diff)} days ago` : `In ${Math.abs(diff)} days`;
  };

  const assignedUsers = (_.get(currentJob, ["assignedUsers", "data"]) || [])
    .sort((a, b) => a.id - b.id)
    .filter((user) => user.id !== currentJob.owner.id);

  const recruitingTeam = _.uniqBy(assignedUsers, "id");

  return (
    <MoveUpOnScroll style={{ ...style }}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        boxShadow={2}
        pb={1}
        pt={4}
        style={{
          minHeight: 140,
          backgroundColor: colors.darkGray
          // ...style,
        }}
      >
        <Container style={{ maxWidth: 1600 }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              {currentJob && currentJob.owner && (
                <JobSlateHeaderInfo
                  descriptor="Account Manager"
                  title={`${currentJob.owner.firstName} ${currentJob.owner.lastName}`}
                  component={() => {
                    return <UserContactButtons owner={currentJob.owner} />;
                  }}
                />
              )}
              {recruitingTeam &&
                !!(recruitingTeam.length > 0) &&
                recruitingTeam.map((recruiter, index) => (
                  <JobSlateHeaderInfo
                    key={recruiter.id}
                    descriptor={recruitingTeam.length > 1 ? "Recruiting Team" : "Recruiter"}
                    title={`${recruiter.firstName} ${recruiter.lastName}`}
                    includeDescriptor={!!(index === 0)}
                    component={() => {
                      return <UserContactButtons owner={recruiter} />;
                    }}
                    style={
                      index !== recruitingTeam.length - 1 ? { marginRight: theme.spacing(2) } : {}
                    }
                  />
                ))}
              {currentJob && currentJob.engagementDate && (
                <JobSlateHeaderInfo
                  descriptor="Engagement Date"
                  title={moment(currentJob.engagementDate).format("M/D/yyyy")}
                />
              )}
              {currentJob && currentJob.launchDate && (
                <JobSlateHeaderInfo
                  descriptor="Search Launch Date"
                  title={moment(currentJob.launchDate).format("M/D/yyyy")}
                  helperText={getDiff(currentJob.launchDate, moment())}
                />
              )}
              {currentJob && currentJob.slateDeliveryDate && (
                <JobSlateHeaderInfo
                  descriptor="Target Slate Date"
                  title={moment(currentJob.slateDeliveryDate).format("M/D/yyyy")}
                  helperText={getDiff(currentJob.slateDeliveryDate, moment())}
                />
              )}
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              mr={0.5}
              style={{ width: 400 }}
            >
              {groupedSubmissions &&
                ["Variner Pursuing", "New Candidates", "In Process", "Reviewed"].map((key) => {
                  const total = groupedSubmissions[key].length;

                  return (
                    <ProgressBar
                      key={key}
                      backgroundColor={colors.variner}
                      style={{ marginBottom: theme.spacing(1) }}
                      labelBefore={key}
                      labelAfter={total}
                      labelStyle={{
                        color: colors.white,
                        fontSize: 12,
                        minWidth: 125
                      }}
                      value={(total / submissions.length) * 100}
                    />
                  );
                })}
              {!!submissions && groupedSubmissions && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  mt={1}
                  mb={2}
                  style={{
                    width: "100%"
                  }}
                >
                  <Typography
                    style={{
                      color: "rgba(255, 255, 255, 0.6)",
                      fontSize: 12,
                      marginRight: theme.spacing(1)
                    }}
                  >
                    Total Submissions
                  </Typography>

                  <Typography
                    style={{
                      color: colors.white,
                      fontWeight: "bold",
                      fontSize: 12,
                      marginRight: "-4px"
                    }}
                  >
                    {submissions.length}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </MoveUpOnScroll>
  );
}
