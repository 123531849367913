/* eslint-disable react/prop-types */
import React from "react";

import { InputBase, IconButton } from "@mui/material";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
// import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import { colors } from "../../styles/config";

const useStyles = makeStyles((theme) => ({
  search: {
    border: `1px solid ${colors.gray13}`,
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: 0,
    marginLeft: 0,
    width: "100%",
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    marginRight: theme.spacing(1),
    width: 22,
    height: "100%",
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 200,
    // pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 7, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
      marginRight: 30
    }
  }
}));

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  handleCloseSearch,
  style = {}
}) => {
  const classes = useStyles();
  const count = preGlobalFilteredRows.length;

  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.

  return (
    <div className={classes.search} style={style}>
      <div className={classes.searchIcon}>
        <IconButton size="small" onClick={handleCloseSearch}>
          <CloseIcon style={{ color: colors.gray43, height: 16, width: 16 }} />
        </IconButton>
      </div>
      <InputBase
        value={globalFilter || ""}
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records`}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
};

export default GlobalFilter;
