import { APIError } from "errors";
import api from "../api";
import commonReducers from "./utils/common-reducers";

const INITIAL_STATE = {
  loading: false,
  fetching: false,
  error: false,
  clientId: null,
  clients: null,
  currentClient: null,
  jobs: null
};

export const clients = {
  state: INITIAL_STATE,

  reducers: {
    ...commonReducers,

    setClients(state, payload) {
      return {
        ...state,
        clients: payload ? payload.data : null,
        loading: false,
        fetching: false
      };
    },

    setClient(state, payload) {
      return {
        ...state,
        clientId: payload,
        loading: false,
        fetching: false
      };
    },

    setCurrentClient(state, payload) {
      return {
        ...state,
        currentClient: payload,
        loading: false,
        fetching: false
      };
    },

    resetClientState() {
      return {
        loading: false,
        fetching: false,
        error: false,
        clientId: null,
        clients: null,
        currentClient: null
      };
    },

    setClientJobs(state, payload) {
      return {
        ...state,
        jobs: payload.data,
        loading: false,
        fetching: false
      };
    },

    setFetching(state, payload) {
      return {
        ...state,
        fetching: payload
      };
    },

    setLoading(state, payload) {
      return {
        ...state,
        loading: payload
      };
    },

    purge() {
      return {
        ...INITIAL_STATE
      };
    }
  },
  effects: (dispatch) => ({
    async fetch() {
      try {
        dispatch.clients.setFetching(true);
        const { data } = await api.get("/clients");

        if (!data || !data.success) {
          dispatch.clients.setClients(null);
          dispatch.clients.setError({
            error: !data.success ? "error in fetch clients request" : "no clients found"
          });
        } else {
          dispatch.clients.setClients(data);
        }
      } catch (error) {
        dispatch.clients.setClients(null);
        dispatch.clients.setError({ error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.clients.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        } else {
          dispatch.clients.setError(error);
        }

        throw error;
      } finally {
        dispatch.clients.setFetching(false);
      }
    },
    async fetchById(payload) {
      try {
        const { data } = await api.get(`/companyData?id=${payload}`);
        dispatch.clients.setCurrentClient(data);
      } catch (error) {
        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.clients.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        } else {
          dispatch.clients.setError(error);
        }

        throw error;
      }
    },
    async fetchClientJobs(payload) {
      try {
        const { data } = await api.get(`/jobs?company=${payload}`);

        if (!data) {
          dispatch.clients.setClientJobs([]);
          dispatch.clients.setError({
            error: "There was a problem fetching jobs."
          });
        } else {
          dispatch.clients.setClientJobs(data);
        }
      } catch (error) {
        dispatch.clients.setClientJobs([]);
        dispatch.clients.setError({ error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.clients.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        } else {
          dispatch.clients.setError(error);
        }

        throw error;
      }
    }
  })
};
