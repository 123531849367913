import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useAPIErrorEffect from "@/hooks/use-api-error-effect.hook";
import { APIUnauthorizedError } from "@/errors";
import SideNavDrawer from "@/components/SideNavDrawer";
import Splash from "@/components/Splash";

const useStyles = makeStyles(() => ({
  content: {
    flexGrow: 1,

    paddingRight: 0,
    paddingLeft: 0,
    backgroundColor: "#f8f8f8"
  }
}));

// eslint-disable-next-line react/prop-types
export default function BoostrapWrapper({ children }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [filteredActiveJobs, setFilteredActiveJobs] = useState(null);

  const { activeJobs } = useSelector((state) => state.jobs);
  const { apiTokenSet } = useSelector((state) => state.app);

  const { user, internalUsers, token } = useSelector((state) => state.user);

  useAPIErrorEffect({
    error: APIUnauthorizedError,
    modelName: "app",
    callback: async () => {
      // console.log("APIUnauthroizedError occured. Logging out user.");

      dispatch.user.logout();
    }
  });

  useEffect(() => {
    dispatch.user.setUserToken(token);
  }, [dispatch, token]);

  useEffect(() => {
    const fetchData = async () => {
      // eslint-disable-next-line no-useless-catch
      try {
        if (!activeJobs) {
          await dispatch.jobs.fetchActiveBullhornJobs();
        }

        if (!internalUsers) {
          await dispatch.user.fetchInternalUsers();
        }
      } catch (error) {
        throw error;
      }
    };

    if (user && token) {
      setTimeout(() => {
        fetchData();
      }, 500);
    }
  }, [dispatch, user, token, activeJobs, internalUsers]);

  useEffect(() => {
    if (!filteredActiveJobs && activeJobs) setFilteredActiveJobs(activeJobs);
  }, [activeJobs, filteredActiveJobs, setFilteredActiveJobs]);

  if (!apiTokenSet || !user) return <Splash />;

  return (
    <Box>
      <SideNavDrawer />
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
}
