import React, { useEffect, useState, useMemo, useCallback } from "react";

// 3rd party libraries
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import size from "lodash/size";
import filter from "lodash/filter";
import { toast } from "react-toastify";

// components
import DashboardModuleCard from "@/components/DashboardModuleCard";
import StatCardV2 from "@/components/reports/Cards/StatCardV2";
import ProgressBarForStatCard from "@/components/ProgressBarForStatCard";
import DateSelect from "@/components/tables/filters/DateSelect";
import { Box } from "@mui/material";

// helpers
import { convertNumToTime, getCallTime } from "@/helpers/callTime";
import { statuses } from "@/helpers/statuses";
import { QUOTAS_BY_DATE_RANGE, submissionsWithStatusInDateRange } from "@/helpers/quotas";
import { prettyName, superAccess, userQuotasMap } from "@/helpers/user";
import FilterDropdown from "../FilterDropdown";
import { rangeSelectionMap } from "@/helpers/dateRanges";
import { isAdminSelector, userSelector } from "models/selectors/user";

// style things

export default function QuotasSection() {
  const dispatch = useDispatch();

  const { user, internalUsers } = useSelector(userSelector);

  const isAdmin = useSelector(isAdminSelector);

  const { calls, loading: callTimeLoading } = useSelector((state) => state.calls);

  const { candidates, loading: candidatesLoading } = useSelector((state) => state.candidates);

  const { submissions, loading: submissionsLoading } = useSelector((state) => state.submissions);

  const { loading: placementsLoading } = useSelector((state) => state.placements);

  const { dateRange, rangeSelection, customDateRange } = useSelector((state) => state.app);

  const [quotasUserSelection, setQuotasUserSelection] = useState();

  const fetchQuotasData = useCallback(
    async ({ calls, candidates, submissions, placements, userId }) => {
      try {
        const dateRange = rangeSelectionMap[rangeSelection];

        const dateRangePayload = {
          startDate: `${moment(dateRange.startDate).startOf("day").format("YYYYMMDD")}000000`,
          endDate: moment(dateRange.endDate).endOf("day").format("YYYYMMDDkkmmss")
        };

        if (calls)
          await dispatch.calls.fetchCalls({
            dateRangePayload,
            userId
          });

        if (candidates)
          await dispatch.candidates.fetchCandidates({
            dateRangePayload,
            userId
          });

        if (submissions)
          await dispatch.submissions.fetchSubmissionsBySender({
            dateRangePayload,
            userId
          });

        if (placements)
          await dispatch.placements.fetchPlacementsBySender({
            dateRangePayload,
            userId
          });
      } catch (error) {
        toast.error("Something went wrong getting data for the quotas module.");
      }
    },
    [rangeSelection, dispatch]
  );

  const loading = callTimeLoading || candidatesLoading || submissionsLoading || placementsLoading;

  const quotasByDateRange = useMemo(() => {
    return QUOTAS_BY_DATE_RANGE({
      dateRange: rangeSelectionMap[rangeSelection],
      quotasByYear: {
        candidates: user[userQuotasMap.activeCandidateAdds.accessor],
        prescreenCalls: user[userQuotasMap.prescreenCalls.accessor],
        pendingSubmissions: user[userQuotasMap.pendingInterest.accessor],
        confirmedSubmissions: user[userQuotasMap.confirmedInterest.accessor],
        clientSubmissions: user[userQuotasMap.clientSubmission.accessor],
        interviews: user[userQuotasMap.interviews.accessor],
        calltime: user[userQuotasMap.callTime.accessor]
      }
    });
  }, [user, rangeSelection]);

  const fetchWithDateRange = useCallback(
    async (dateRange) => {
      try {
        const dateRangePayload = {
          startDate: `${moment(dateRange.startDate).startOf("day").format("YYYYMMDD")}000000`,
          endDate: moment(dateRange.endDate).endOf("day").format("YYYYMMDDkkmmss")
        };

        const quotasUser = internalUsers?.find(
          (internalUser) => prettyName(internalUser) === quotasUserSelection
        );

        await dispatch.calls.fetchCalls({
          dateRangePayload,
          userId: quotasUser?.id
        });

        await dispatch.candidates.fetchCandidates({
          dateRangePayload,
          userId: quotasUser?.id
        });

        await dispatch.submissions.fetchSubmissionsBySender({
          dateRangePayload,
          userId: quotasUser?.id
        });

        await dispatch.placements.fetchPlacementsBySender({
          dateRangePayload,
          userId: quotasUser?.id
        });
      } catch (error) {
        toast.error("Something went wrong getting data for this date range.");
      }
    },
    [dispatch, quotasUserSelection, internalUsers]
  );

  const handleDateRangeSelectionChange = useCallback(
    (newValue, customRange) => {
      if (newValue !== rangeSelection) dispatch.app.setRangeSelection(newValue);

      if (newValue === "custom") {
        dispatch.app.setDateRange(customRange);
        fetchWithDateRange(customRange);
      } else {
        const newDateRange = rangeSelectionMap[newValue];
        dispatch.app.setDateRange(newDateRange);
        fetchWithDateRange(newDateRange);
      }
    },
    [fetchWithDateRange, rangeSelection]
  );

  // empty array so this data is only called once
  useEffect(() => {
    fetchQuotasData({
      calls: true,
      candidates: true,
      submissions: true,
      placements: true,
      userId: user.id
    });
  }, []);

  useEffect(() => {
    if (user) setQuotasUserSelection(prettyName(user));
  }, [user]);

  const prescreenCalls = useMemo(() => {
    return calls?.filter((call) => call.action === "Prescreen");
  }, [calls]);

  // const interviews = useMemo(() => {
  //   return calls?.filter((call) => call.action === "Interview");
  // }, [calls]);

  const candidateCalls = useMemo(() => {
    return filter(calls, (call) => call.personReference.subtype === "Candidate");
  }, [calls]);

  const pendingInterestSubmissionsDuringDateRange = useMemo(() => {
    const submissionsWithStatusHistoryInDateRange = submissionsWithStatusInDateRange({
      submissions,
      dateRange,
      statusString: statuses.PENDING_INTEREST.bhLabel
    });

    return submissionsWithStatusHistoryInDateRange;
  }, [submissions, dateRange]);

  const confirmedInterestSubmissionsDuringDateRange = useMemo(() => {
    const submissionsWithStatusHistoryInDateRange = submissionsWithStatusInDateRange({
      submissions,
      dateRange,
      statusString: statuses.CONFIRMED_INTEREST.bhLabel
    });

    return submissionsWithStatusHistoryInDateRange;
  }, [submissions, dateRange]);

  const clientSubmissionsSubmissionsDuringDateRange = useMemo(() => {
    const submissionsWithStatusHistoryInDateRange = submissionsWithStatusInDateRange({
      submissions,
      dateRange,
      statusString: statuses.CLIENT_SUBMISSION.bhLabel
    });

    return submissionsWithStatusHistoryInDateRange;
  }, [submissions, dateRange]);

  return (
    <DashboardModuleCard
      title="My Quotas"
      refresh={true}
      refreshing={loading}
      onRefresh={() => {
        const quotasUser = internalUsers?.find(
          (internalUser) => prettyName(internalUser) === quotasUserSelection
        );

        fetchQuotasData({
          calls: true,
          candidates: true,
          submissions: true,
          placements: true,
          userId: quotasUser?.id
        });
      }}
      filter={
        <>
          {internalUsers && isAdmin && (
            <FilterDropdown
              style={{
                height: 36,
                width: 180,
                marginRight: 10,
                borderWidth: 0
              }}
              fontSize={14}
              initialSelected={prettyName(user)}
              options={
                superAccess(user)
                  ? internalUsers?.map((internalUser) => prettyName(internalUser))
                  : [prettyName(user)]
              }
              maxSelected={1}
              handleSetSelectedStatuses={(selected) => {
                setQuotasUserSelection(selected);

                const quotasUser = internalUsers?.find(
                  (internalUser) => prettyName(internalUser) === selected
                );

                fetchQuotasData({
                  calls: true,
                  candidates: true,
                  submissions: true,
                  placements: true,
                  userId: quotasUser?.id
                });
              }}
              subheader={false}
            />
          )}
          <DateSelect
            handleDateRangeSelectionChange={handleDateRangeSelectionChange}
            rangeSelectionMap={rangeSelectionMap}
            dateRange={dateRange}
            rangeSelection={rangeSelection}
            setRangeSelection={(payload) => dispatch.app.setRangeSelection(payload)}
            customDateRange={customDateRange}
            setCustomDateRange={(payload) => dispatch.app.setCustomDateRange(payload)}
            disabled={loading}
          />
        </>
      }
    >
      <Box sx={{ display: "flex", gap: 3 }}>
        <StatCardV2
          title={userQuotasMap.activeCandidateAdds.label}
          topStat={size(candidates) || 0}
          bottomStat={parseFloat(quotasByDateRange.candidates.toFixed(2))}
          bottomComponent={
            <ProgressBarForStatCard
              value={((size(candidates) / quotasByDateRange.candidates.toFixed(2)) * 100).toFixed(
                2
              )}
              type={
                size(candidates) > quotasByDateRange.candidates.toFixed(2) ? "increase" : "decrease"
              }
            />
          }
          statDivider={true}
          loading={loading}
        />
        <StatCardV2
          title={userQuotasMap.prescreenCalls.label}
          topStat={prescreenCalls?.length || 0}
          bottomStat={parseFloat(quotasByDateRange.prescreenCalls.toFixed(2))}
          bottomComponent={
            <ProgressBarForStatCard
              type={
                size(prescreenCalls) > quotasByDateRange.prescreenCalls.toFixed(2)
                  ? "increase"
                  : "decrease"
              }
              value={(
                (size(prescreenCalls) / quotasByDateRange.prescreenCalls.toFixed(2)) *
                100
              ).toFixed(2)}
            />
          }
          statDivider={true}
          loading={loading}
        />
        <StatCardV2
          title={userQuotasMap.pendingInterest.label}
          topStat={size(pendingInterestSubmissionsDuringDateRange) || 0}
          bottomStat={parseFloat(quotasByDateRange.pendingSubmissions.toFixed(2))}
          bottomComponent={
            <ProgressBarForStatCard
              type={
                size(pendingInterestSubmissionsDuringDateRange) >
                quotasByDateRange.pendingSubmissions.toFixed(2)
                  ? "increase"
                  : "decrease"
              }
              value={(
                ((size(pendingInterestSubmissionsDuringDateRange) || 0) /
                  quotasByDateRange.pendingSubmissions.toFixed(2)) *
                100
              ).toFixed(2)}
            />
          }
          statDivider={true}
          loading={loading}
        />
        <StatCardV2
          title={userQuotasMap.confirmedInterest.label}
          topStat={size(confirmedInterestSubmissionsDuringDateRange) || 0}
          bottomStat={parseFloat(quotasByDateRange.confirmedSubmissions.toFixed(2))}
          bottomComponent={
            <ProgressBarForStatCard
              type={
                size(confirmedInterestSubmissionsDuringDateRange) >
                quotasByDateRange.confirmedSubmissions.toFixed(2)
                  ? "increase"
                  : "decrease"
              }
              value={(
                ((size(confirmedInterestSubmissionsDuringDateRange) || 0) /
                  quotasByDateRange.confirmedSubmissions.toFixed(2)) *
                100
              ).toFixed(2)}
            />
          }
          statDivider={true}
          loading={loading}
        />
        <StatCardV2
          title={userQuotasMap.clientSubmission.label}
          topStat={size(clientSubmissionsSubmissionsDuringDateRange) || 0}
          bottomStat={parseFloat(quotasByDateRange.clientSubmissions.toFixed(2))}
          bottomComponent={
            <ProgressBarForStatCard
              type={
                size(clientSubmissionsSubmissionsDuringDateRange) >
                quotasByDateRange.clientSubmissions.toFixed(2)
                  ? "increase"
                  : "decrease"
              }
              value={(
                ((size(clientSubmissionsSubmissionsDuringDateRange) || 0) /
                  quotasByDateRange.clientSubmissions.toFixed(2)) *
                100
              ).toFixed(2)}
            />
          }
          statDivider={true}
          loading={loading}
        />
        <StatCardV2
          title={userQuotasMap.callTime.label}
          topStat={candidateCalls ? convertNumToTime(getCallTime(candidateCalls, "hrs")) : 0}
          bottomStat={`${convertNumToTime(parseFloat(quotasByDateRange.calltime))}`}
          bottomComponent={
            <ProgressBarForStatCard
              value={(
                (getCallTime(candidateCalls, "hrs") / quotasByDateRange.calltime).toFixed(2) * 100
              ).toFixed(2)}
              type={
                getCallTime(candidateCalls, "hrs").toFixed(2) >
                quotasByDateRange.calltime.toFixed(2)
                  ? "increase"
                  : "decrease"
              }
            />
          }
          statDivider={true}
          loading={loading}
        />
      </Box>
    </DashboardModuleCard>
  );
}
