import { updateById, removeAllIds, removeById } from "./index";

const commonReducers = {
  load(state, payload) {
    const allIds = payload.map((c) => c.id);
    const byId = payload.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});

    return {
      ...state,
      allIds,
      byId,
      loading: false
    };
  },
  setError(state, error) {
    // TODO: error reporting
    return {
      ...state,
      ...error,
      loading: false,
      fetching: false
    };
  },
  add(state, payload) {
    // TODO: error reporting
    return {
      ...state,
      byId: {
        ...state.byId,
        [payload.id]: payload
      },
      allIds: [...state.allIds, payload.id]
    };
  },
  update(state, payload) {
    return {
      ...state,
      byId: updateById(state.byId, payload)
    };
  },
  remove(state, payload) {
    return {
      ...state,
      byId: removeById(state.byId, payload),
      allIds: removeAllIds(state.allIds, payload)
    };
  },
  purge() {
    return {
      loading: true,
      error: false,
      byId: {},
      allIds: []
    };
  },
  purgeErrors(state) {
    return {
      ...state,
      error: false
    };
  }
};

export default commonReducers;
