/* eslint-disable react/prop-types */
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import _ from "lodash";

import { statuses, findStatusObject } from "@/helpers/statuses";
import { colors } from "@/styles/config";

export default function SubmissionStatusTooltip({ newSubmissionsByOwner, innerData }) {
  const theme = useTheme();

  const subsByStatus = _.groupBy(newSubmissionsByOwner[innerData.ownerId].submissions, "status");

  const subsByStatusArray = Object.keys(subsByStatus).map((status) => {
    return {
      status,
      data: subsByStatus[status]
    };
  });

  const subsBystatusArraySorted = subsByStatusArray.sort((a, b) => {
    let aIndex = 0;
    let bIndex = 0;

    Object.keys(statuses).find((statusKey, index) => {
      const statusObj = statuses[statusKey];
      aIndex = a.status === statusObj.bhLabel ? index : aIndex;
      return a.status === statusObj.bhLabel;
    });

    Object.keys(statuses).find((statusKey, index) => {
      const statusObj = statuses[statusKey];
      bIndex = b.status === statusObj.bhLabel ? index : bIndex;
      return b.status === statusObj.bhLabel;
    });

    return aIndex - bIndex;
  });

  return (
    <Box>
      <Typography
        style={{
          fontSize: 10,
          fontWeight: "bold",
          paddingBottom: theme.spacing(0.5)
        }}
      >
        New Submissions by Current Status
      </Typography>
      {subsBystatusArraySorted.map((statusObj, index) => {
        const status = statusObj.status;
        const count = statusObj.data.length;

        const statusAfter = subsBystatusArraySorted[index + 1];
        const lastActive =
          status.indexOf("Rejected") < 0 &&
          statusAfter &&
          statusAfter.status.indexOf("Rejected") > -1;

        const statusBefore = subsBystatusArraySorted[index - 1];
        const firstRejected =
          status.indexOf("Rejected") > -1 &&
          statusBefore &&
          statusBefore.status.indexOf("Rejected") < 0;

        const statusMeta = findStatusObject(status);

        const statusColor = {
          Placed: colors.varinerLight,
          Rejected: colors.red,
          "Holding Pattern": colors.varinerGold
        };

        const positionColor =
          statusMeta.position < 40 && statusMeta.position >= 10 ? colors.varinerLight : "inherit";

        return (
          <Box
            display="flex"
            alignItems="center"
            key={status}
            style={{
              borderTop: firstRejected ? "1px solid #dddddd" : "none",
              paddingTop: firstRejected ? theme.spacing(0.5) : 0,
              paddingBottom: lastActive ? theme.spacing(0.5) : 0
            }}
          >
            <Typography
              style={{
                width: 30,
                fontSize: 10,
                fontWeight: "bold"
              }}
            >{`[${count}]`}</Typography>
            <Typography
              style={{
                fontSize: 10,
                color: !statusMeta ? "inherit" : statusColor[statusMeta.group] || positionColor
              }}
            >
              {status}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
