import api from "../api";
import commonReducers from "./utils/common-reducers";
import { toast } from "react-toastify";
import _ from "lodash";
import { APIError } from "errors";

const INITIAL_STATE = {
  attachedFile: null,
  loading: false,
  submissionsLoading: false,
  generatingResume: false,
  error: false,
  jobs: null,
  jobId: null,
  bullhornJobId: null,
  redirectToJob: false,
  currentJob: null,
  content: null,
  activeJobs: null,
  submissions: null,
  resumeBook: null,
  currentSubmission: null,
  publicJobsList: null,
  updatingSubmission: false
};

export const jobs = {
  state: INITIAL_STATE,
  reducers: {
    ...commonReducers,

    update(state, updates) {
      return {
        ...state,
        ...updates
      };
    },

    setJobs(state, payload) {
      return {
        ...state,
        jobs: payload.data,
        loading: false
      };
    },

    setAttachedFile(state, payload) {
      return {
        ...state,
        attachedFile: payload,
        loading: false
      };
    },

    setActiveJobs(state, payload) {
      return {
        ...state,
        activeJobs: payload,
        loading: false
      };
    },

    setPublicJobsList(state, payload) {
      return {
        ...state,
        publicJobsList: payload,
        loading: false
      };
    },

    setJobId(state, payload) {
      return {
        ...state,
        jobId: null,
        bullhornJobId: payload,
        redirectToJob: true,
        loading: false
      };
    },

    setCurrentJob(state, payload) {
      return {
        ...state,
        currentJob: payload,
        loading: false
      };
    },

    setSubmissions(state, payload) {
      return {
        ...state,
        submissions: payload.data,
        // [`submissions-${payload.status
        //   .toLowerCase()
        //   .replace(" ", "-")}`]: payload.data,
        loading: false
      };
    },

    setUpdatingSubmission(state, payload) {
      return {
        ...state,
        updatingSubmission: payload
      };
    },

    setTeam(state, data) {
      return {
        ...state,
        content: {
          ...state.content,
          team: data
        }
      };
    },

    setResumeBook(state, data) {
      return {
        ...state,
        resumeBook: data
      };
    },

    resetJobStatePartial(state) {
      return {
        ...state,
        loading: true,
        submissionsLoading: false,
        generatingResume: false,
        error: false,
        jobs: null,
        jobId: null,
        bullhornJobId: null,
        redirectToJob: false,
        currentJob: null,
        content: null,
        submissions: null,
        resumeBook: null
      };
    },

    setError(state, payload) {
      return {
        ...state,
        loading: false,
        submissionsLoading: false,
        generatingResume: false,
        error: payload,
        jobs: null,
        jobId: null,
        bullhornJobId: null,
        redirectToJob: false,
        // currentJob: null,
        content: null
      };
    },

    setLoading(state, payload) {
      return {
        ...state,
        loading: payload
      };
    },

    setSubmissionsLoading(state, payload) {
      return {
        ...state,
        submissionsLoading: payload
      };
    },

    setCurrentSubmission(state, payload) {
      return {
        ...state,
        currentSubmission: payload
      };
    },

    purge() {
      return {
        ...INITIAL_STATE
      };
    }
  },
  effects: (dispatch) => ({
    async startupJobsList() {
      try {
        dispatch.jobs.setLoading(true);

        await dispatch.jobs.fetchPublicBullhornJobs();

        return new Promise((resolve) => {
          resolve(true);
        });
      } catch (error) {
        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.jobs.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        } else {
          dispatch.jobs.setError(error);
        }

        // throw error;
      } finally {
        dispatch.jobs.setLoading(false);
      }
    },
    async startupOptIn(payload) {
      return dispatch.jobs.bootstrapOptIn(payload);
    },
    async bootstrapOptIn(payload) {
      dispatch.app.setLoading(true);
      dispatch.jobs.setLoading(true);
      const { id, submissionId } = payload;
      dispatch.jobs.setJobId(id);

      // optin data needs job and submission
      const { data } = await api.get(`/optInData?job=${id}&submission=${submissionId}`);

      if (!data.success) {
        dispatch.app.setLoading(false);
        dispatch.jobs.setError({
          error: "Something went wrong getting job data."
        });
      } else if (data.submission && data.submission.error) {
        dispatch.app.setLoading(false);
        dispatch.jobs.setError({
          error: "Something went wrong getting candidate data."
        });
      }

      if (data.job && data.submission && !data.submission.error) {
        // set current job data
        dispatch.jobs.setCurrentJob(data.job);
        // set user submission data
        dispatch.user.setUserSubmission(data.submission);

        // set loading to false once completed
        dispatch.app.setLoading(false);
        dispatch.jobs.setLoading(false);
      } else {
        dispatch.app.setLoading(false);
        dispatch.jobs.setError({ error: `Job with ID ${id} not found.` });
      }

      dispatch.jobs.update({
        loading: false,
        bootstrapped: true
      });

      return true;
    },
    async startupPostClose(payload) {
      return dispatch.jobs.bootstrapPostClose(payload);
    },
    async bootstrapPostClose(payload) {
      // TODO: wrap in try / catch

      dispatch.app.setLoading(true);
      dispatch.jobs.setLoading(true);

      const { id, placementId } = payload;
      dispatch.jobs.setJobId(id);

      // post close data needs job and submission
      const { data } = await api.get(`/placementPostCloseData?job=${id}&placement=${placementId}`);

      if (!data.success) {
        dispatch.app.setLoading(false);
        dispatch.jobs.setError({
          error: "Something went wrong getting job data."
        });
      } else if (data.placement && data.placement.error) {
        dispatch.app.setLoading(false);
        dispatch.jobs.setError({
          error: "Something went wrong getting candidate data."
        });
      }

      if (data.job && data.placement && !data.placement.error) {
        // set current job data
        dispatch.jobs.setCurrentJob(data.job);

        // set user placement data
        dispatch.user.setUserPlacement(data.placement);

        // set loading to false once completed
        dispatch.app.setLoading(false);
        dispatch.jobs.setLoading(false);
      } else {
        dispatch.app.setLoading(false);
        dispatch.jobs.setError({ error: `Job with ID ${id} not found.` });
      }

      dispatch.jobs.update({
        loading: false,
        bootstrapped: true
      });

      return true;
    },
    async startupJob(payload) {
      return dispatch.jobs.bootstrapJob(payload);
    },
    async bootstrapJob(payload) {
      try {
        // TODO: do not set app level loading
        dispatch.app.setLoading(true);
        dispatch.jobs.setLoading(true);

        const { id, dashboard, status } = payload;

        dispatch.jobs.setJobId(id);

        const { data } = await api.get(`/bullhornJobWithoutContent?bullhorn_id=${id}`);

        if (dashboard) {
          // get submissions for job id with default status Client Submission
          await dispatch.jobs.fetchSubmissions({ id, status });
        }

        if (data.data && data.data.length) {
          const response = data.data[0];

          // set current job data
          dispatch.jobs.setCurrentJob(response);

          // set loading to false once completed
          dispatch.app.setLoading(false);
          dispatch.jobs.setLoading(false);
        } else {
          dispatch.app.setLoading(false);
          dispatch.jobs.setError({ error: `Job with ID ${id} not found.` });
        }

        dispatch.jobs.update({
          bootstrapped: true
        });

        return true;
      } catch (error) {
        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.jobs.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        } else {
          dispatch.jobs.setError(error);
        }

        throw error;
      } finally {
        dispatch.app.setLoading(false);
        dispatch.jobs.setLoading(false);
      }
    },
    async fetchById(payload) {
      try {
        const { data } = await api.get(`/jobFromBullhornId?bullhorn_id=${payload}`);
        if (data.data && data.data.length) {
          const response = data.data[0];

          dispatch.jobs.setCurrentJob(response);

          dispatch.clients.setClient(response.company.id);

          await dispatch.clients.fetchById(response.company.id);

          await dispatch.jobs.fetchJobContent({
            clientId: response.company.id,
            jobId: response.id,
            bullhornId: payload
          });
        } else {
          throw Error`Job with ID ${payload} not found.`;
        }
      } catch (error) {
        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.jobs.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        } else {
          dispatch.jobs.setError(error);
        }

        // throw error;
      }
    },
    async fetchJobContent(payload) {
      try {
        const { data } = await api.post("/content", payload);

        dispatch.jobs.setContent(data);
      } catch (error) {
        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.jobs.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        } else {
          dispatch.jobs.setError(error);
        }

        // throw error;
      }
    },

    async fetchAttachedFile(payload) {
      try {
        dispatch.jobs.setLoading(true);
        const response = await api.post("/attachedFile", payload);
        const { data } = response;
        dispatch.jobs.setAttachedFile(data.file.fileContent);
      } catch (error) {
        dispatch.jobs.setError({ error: error.message || error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.jobs.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        dispatch.jobs.setLoading(false);
      }
    },

    async fetchActiveBullhornJobs() {
      try {
        dispatch.jobs.setLoading(true);
        const response = await api.get("/activeJobsV2");
        const { data } = response;

        const formattedDataResponse =
          data &&
          data.map((job) => {
            job.category = _.get(job, ["categories", "data", "0", "name"]);
            return job;
          });

        dispatch.jobs.setActiveJobs(
          formattedDataResponse &&
            formattedDataResponse.sort(
              (a, b) => -b.title.charAt(0).localeCompare(a.title.charAt(0))
            )
        );

        dispatch.jobs.setLoading(false);
      } catch (error) {
        dispatch.jobs.setError({ error: error.message || error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.jobs.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        dispatch.jobs.setLoading(false);
      }
    },
    async fetchPublicBullhornJobs() {
      try {
        const response = await api.get("/publicJobs");
        const { data } = response;
        dispatch.jobs.setPublicJobsList(data);
      } catch (error) {
        dispatch.jobs.setError({ error: error.message || error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.jobs.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      }
    },
    async fetchSubmissions(payload) {
      try {
        dispatch.jobs.setSubmissionsLoading(true);
        const status = ["Client Submission"];

        const { data } = await api.post(`/submissions/${payload.id}`, {
          status: payload.status || status
        });

        if (data && typeof data !== "string") {
          dispatch.jobs.setSubmissions({
            data: data,
            status: payload.status || status
          });
          dispatch.jobs.setSubmissionsLoading(false);
        } else {
          dispatch.jobs.setError({
            error: `Submissions for ${payload.id} not found.`
          });
        }
      } catch (error) {
        dispatch.jobs.setError({ error: error.message || error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.jobs.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      }
    },
    async fetchSubmissionResume(payload, { jobs }) {
      try {
        const { candidateId, jobId } = payload;
        dispatch.jobs.update({ fetchingSubmissionData: true });

        const response = await api.get(`/resume/${candidateId}?jobId=${jobId}`);

        dispatch.jobs.setCurrentSubmission({
          ...jobs.currentSubmission,
          resume: response.data
        });

        dispatch.jobs.update({ fetchingSubmissionData: false });
      } catch (error) {
        dispatch.jobs.setError({ error: error.message || error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.jobs.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      }
    },
    async updateSubmissionData(payload) {
      try {
        dispatch.jobs.setUpdatingSubmission(true);
        const { id, data, submissions } = payload;
        const sendingData = {
          id,
          data,
          entity: "JobSubmission"
        };

        await api.post("/updateEntity", sendingData);
        const updatedSubmission = await api.get(`/submission/${id}`);

        dispatch.jobs.setCurrentSubmission(updatedSubmission.data);

        const updatedSubmissions = submissions.map((submission) => {
          const current = submission.id === id;

          if (current) {
            submission = updatedSubmission.data;
          }

          return submission;
        });

        dispatch.jobs.setSubmissions({
          data: updatedSubmissions
        });
      } catch (error) {
        dispatch.jobs.setError({ error: error.message || error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.jobs.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }
        // throw error;
      } finally {
        dispatch.jobs.setUpdatingSubmission(false);
      }
    },
    async updateSubmissionStatus(payload, { jobs }) {
      try {
        dispatch.jobs.setUpdatingSubmission(true);

        const {
          id,
          status,
          jobId,
          candidateId,
          userId,
          submissions,
          comments,
          submissionOwnerId,
          candidateOwnerId
        } = payload;

        const data = {
          id,
          status,
          jobId,
          candidateId,
          userId,
          comments,
          submissionOwnerId,
          candidateOwnerId
        };

        const response = await api.post("/updateSubmissionStatus", data);

        // let currentSubmission = submissions.find(
        //   (submission) => submission.id === id
        // );

        dispatch.jobs.setCurrentSubmission({
          ...jobs.currentSubmission,
          status
        });

        const updatedSubmissions = submissions.map((submission) => {
          const current = submission.id === id;
          if (current) {
            submission.status = status;
          }
          return submission;
        });

        dispatch.jobs.setSubmissions({
          data: updatedSubmissions
        });

        return response;
      } catch (error) {
        dispatch.jobs.setError({ error: error.message || error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.jobs.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }
        // throw error;
      } finally {
        dispatch.jobs.setUpdatingSubmission(false);
      }
    },
    async generateResumeBook(payload) {
      try {
        dispatch.jobs.update({ generatingResume: true });
        const response = await api.post("/generateResumeBook", payload);
        dispatch.jobs.setResumeBook(response.data.file);
        dispatch.jobs.update({ generatingResume: false });
      } catch (error) {
        toast.error(error.response.data.error);
        // @TODO [salva@morean@08-05-2024] close modal.

        dispatch.jobs.setError({ error: error.message || error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.jobs.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      }
    }
  })
};
