export const statuses = {
  NEW_LEAD: {
    bhLabel: "New Lead",
    label: "Web Response",
    position: 1,
    group: "Variner Pursuing",
    color: "#F0F0F0",
    active: false
  },
  WEB_RESPONSE_ACCEPTED: {
    bhLabel: "Web Response Accepted",
    label: "Web Response Accepted",
    position: 1,
    group: "Variner Pursuing",
    color: "#DDDDDD",
    active: false
  },
  INTERNALLY_SUBMITTED: {
    bhLabel: "Internally Submitted",
    label: "Internally Submitted",
    position: 1,
    group: "Variner Pursuing",
    color: "#DDDDDD",
    active: false
  },
  SELECT_RECRUIT: {
    bhLabel: "Select Recruit",
    label: "Select Recruit",
    position: 1,
    group: "Variner Pursuing",
    color: "#DDDDDD",
    active: true
  },
  VARINER_PURSUING: {
    bhLabel: "Variner Pursuing",
    label: "Variner Pursuing",
    position: 8,
    group: "Variner Pursuing",
    color: "#DDDDDD",
    active: true
  },
  PENDING_INTEREST: {
    bhLabel: "Pending Interest",
    label: "Pending Interest",
    position: 10,
    group: "Variner Pursuing",
    color: "#DDDDDD",
    active: true
  },
  CONFIRMED_INTEREST: {
    bhLabel: "Confirmed Interest",
    label: "Confirmed Interest",
    position: 20,
    group: "Variner Pursuing",
    color: "#3E9FAC",
    active: true
  },
  CLIENT_SUBMISSION: {
    bhLabel: "Client Submission",
    label: "Client Submission",
    position: 30,
    group: "In Process",
    color: "#1B444A",
    active: true
  },
  INITIAL_INTERVIEW: {
    bhLabel: "Initial Interview",
    label: "Initial Interview",
    position: 40,
    group: "In Process",
    color: "#1B444A",
    active: true
  },
  SECOND_INTERVIEW: {
    bhLabel: "Case Study",
    label: "Case Study",
    position: 40,
    group: "In Process",
    color: "#1B444A",
    active: true
  },
  FINAL_INTERVIEW: {
    bhLabel: "Final Interview",
    label: "Final Interview",
    position: 40,
    group: "In Process",
    color: "#1B444A",
    active: true
  },
  HOLDING_PATTERN: {
    bhLabel: "Holding Pattern",
    label: "Holding Pattern",
    position: 50,
    group: "In Process",
    color: "#FBA926",
    active: true
  },
  VARINER_REJECTED: {
    bhLabel: "Variner Rejected",
    label: "Variner Rejected",
    position: 86,
    group: "Rejected",
    color: "#FF5442",
    active: false
  },
  CLIENT_REJECTED: {
    bhLabel: "Client Rejected",
    label: "Client Rejected",
    position: 86,
    group: "Rejected",
    color: "#FF5442",
    active: false
  },
  CANDIDATE_REJECTED: {
    bhLabel: "Candidate Rejected",
    label: "Candidate Rejected",
    position: 86,
    group: "Rejected",
    color: "#FFA59C",
    active: false
  },
  CANDIDATE_REJECTED_NO_RESPONSE: {
    bhLabel: "Candidate Rejected-No Response",
    label: "Candidate Rejected-No Response",
    position: 86,
    group: "Rejected",
    color: "#FF8275",
    active: false
  },
  CANDIDATE_REJECTED_TIMING: {
    bhLabel: "Candidate Rejected-Timing",
    label: "Candidate Rejected-Timing",
    position: 86,
    group: "Rejected",
    color: "#FF260F",
    active: false
  },
  CANDIDATE_REJECTED_LOCATION: {
    bhLabel: "Candidate Rejected-Location",
    label: "Candidate Rejected-Location",
    position: 86,
    group: "Rejected",
    color: "#DB1500",
    active: false
  },
  CANDIDATE_REJECTED_COMP: {
    bhLabel: "Candidate Rejected-Comp",
    label: "Candidate Rejected-Comp",
    position: 86,
    group: "Rejected",
    color: "#BA1200",
    active: false
  },
  CANDIDATE_REJECTED_ROLE: {
    bhLabel: "Candidate Rejected-Role",
    label: "Candidate Rejected-Role",
    position: 86,
    group: "Rejected",
    color: "#A71000",
    active: false
  },
  CANDIDATE_REJECTED_INDUSTRY_SIZE: {
    bhLabel: "Candidate Rejected-Industry/Size",
    label: "Candidate Rejected-Industry/Size",
    position: 86,
    group: "Rejected",
    color: "#890D00",
    active: false
  },
  CANDIDATE_REJECTED_OTHER_OFFER: {
    bhLabel: "Candidate Rejected-Other Offer",
    label: "Candidate Rejected-Other Offer",
    position: 86,
    group: "Rejected",
    color: "#700B00",
    active: false
  },
  WEB_RESPONSE_REJECTED: {
    bhLabel: "Web Response Rejected",
    label: "Web Response Rejected",
    position: 86,
    group: "Rejected",
    color: "#FF5442",
    active: false
  },
  OFFER_DECLINED: {
    bhLabel: "Offer Declined",
    label: "Offer Declined",
    position: 90,
    group: "Rejected",
    color: "#4E0800",
    active: false
  },
  OFFER_REJECTED: {
    bhLabel: "Offer Rejected",
    label: "Offer Rejected",
    position: 90,
    group: "Rejected",
    color: "#4E0800",
    active: false
  },
  OFFER_EXTENDED: {
    bhLabel: "Offer Extended",
    label: "Offer Extended",
    position: 100,
    group: "Rejected",
    color: "#2699FB",
    active: false
  },
  OFFER_UPDATED: {
    bhLabel: "Offer Updated",
    label: "Offer Updated",
    position: 100,
    group: "Rejected",
    color: "#2699FB",
    active: false
  },
  PLACED: {
    bhLabel: "Placed",
    label: "Placed",
    position: 100,
    group: "Rejected",
    color: "#2699FB",
    active: false
  }
};

export const categories = {
  PURSUING: {
    position: 1,
    group: "Variner Pursuing",
    color: "#3e9fac"
  },
  IN_PROCESS: {
    position: 2,
    group: "In Process",
    color: "#b5ffdc"
  },
  REJECTED: {
    position: 3,
    group: "Rejected",
    color: "#35ab7c"
  }
};

export const findStatusObject = (statusString) => {
  if (!statusString) return;

  const foundKey = Object.keys(statuses).find((statusKey) => {
    const obj = statuses[statusKey];
    return obj.bhLabel === statusString;
  });

  return statuses[foundKey];
};

export const findStatusObjectIndex = (statusString) => {
  if (!statusString) return;

  const foundKey = Object.keys(statuses).find((statusKey) => {
    const obj = statuses[statusKey];
    return obj.bhLabel === statusString;
  });

  const index = Object.keys(statuses).indexOf(foundKey);

  return index;
};

export const getStatusStringArray = () => {
  return Object.keys(statuses).map((statusKey) => {
    return statuses[statusKey].bhLabel;
  });
};

export const sortSubmissionsByStatus = (submissions) => {
  const sorted = submissions.sort((a, b) => {
    const aStatusMapObjectIndex = findStatusObjectIndex(a.status);
    const bStatusMapObjectIndex = findStatusObjectIndex(b.status);
    return aStatusMapObjectIndex - bStatusMapObjectIndex;
  });

  return sorted;
};

export const sortSubmissionsByGroup = (submissions) => {
  var sortedSubmissions = {
    group: "Reviewed",
    "Variner Pursuing": 0,
    "Variner PursuingColor": "#3e9fac",
    "In Process": 0,
    "In ProcessColor": "#b5ffdc",
    Rejected: 0,
    RejectedColor: "#35ab7c"
  };

  // @todo: refactor this iterations.
  Object.keys(submissions).map((statusKey) => {
    const key = statusKey;

    Object.keys(statuses).find((statusKey) => {
      const obj = statuses[statusKey];

      if (obj.bhLabel === key) {
        Object.keys(categories).find((statusKey) => {
          const cat = categories[statusKey];

          if (obj.group === cat.group) {
            sortedSubmissions[obj.group] += submissions[obj.bhLabel];
          }

          return null;
        });
      }

      return null;
    });

    return null;
  });

  return sortedSubmissions;
};

export const submissionsByHighLevelGroup = (submissions) => {
  if (!submissions) return;

  return submissions.reduce((acc, curr) => {
    const statusObject = findStatusObject(curr.status);

    if (!statusObject) return { ...acc, Unknown: [...(acc.Unknown || []), curr] };

    if (acc[statusObject.group]) {
      const updatedSubmissionsArray = [...acc[statusObject.group], curr];

      return { ...acc, [statusObject.group]: updatedSubmissionsArray };
    } else {
      return { ...acc, [statusObject.group]: [curr] };
    }
  }, {});
};

export const statusGroupCategories = {
  "Variner Pursuing": {
    position: 1,
    color: "#3e9fac"
  },
  "In Process": {
    position: 2,
    color: "#b5ffdc"
  },
  Rejected: {
    position: 3,
    color: "#35ab7c"
  }
};

// module.exports = {
//   statuses,
//   categories,
//   findStatusObject,
//   findStatusObjectIndex,
//   getStatusStringArray,
//   sortSubmissionsByStatus,
//   sortSubmissionsByGroup,
//   submissionsByHighLevelGroup,
//   statusGroupCategories,
// };
