/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  CircularProgress,
  Typography,
  InputLabel,
  TextField,
  FormControl,
  useTheme
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";

import { colors } from "../../styles/config";
import ThankYouSplash from "../ThankYouSplash";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 0
  },
  formControl: {
    minWidth: "100%",
    borderRadius: 3,
    fontSize: 12
  }
}));

export default function SelectJobForm({ onSubmit, defaultValue, values = {} }) {
  const classes = useStyles();
  const theme = useTheme();
  const { activeJobs, loading, error } = useSelector((state) => state.jobs);

  const initialValues = {
    job: ""
  };

  const data = {
    ...initialValues,
    ...values
  };

  const validationSchema = yup.object().shape({
    job: yup.string()
  });

  if (error) {
    return (
      <ThankYouSplash
        header={"Something went wrong."}
        Component={() => {
          return <Typography>{error.error}</Typography>;
        }}
      />
    );
  }

  return (
    <Formik initialValues={data} validationSchema={validationSchema}>
      {({ values, setFieldValue }) => (
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <FormControl variant="outlined" className={classes.formControl}>
            {loading && (
              <InputLabel style={{ color: colors.variner }}>
                <CircularProgress size={20} />
              </InputLabel>
            )}
            {activeJobs && activeJobs.length && (
              <Autocomplete
                options={activeJobs}
                getOptionLabel={(option) =>
                  option.id ? `${option.id} | ${option.title}` : option.title || ""
                }
                value={values.job}
                defaultValue={defaultValue}
                onChange={(event, newValue) => {
                  // console.log("new value", newValue);
                  setFieldValue("job", newValue);
                  onSubmit({ job: newValue });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="All Searches"
                    variant="filled"
                    size="small"
                    style={{
                      fontSize: 12,
                      paddingTop: theme.spacing(0.5),
                      paddingBottom: theme.spacing(0.5)
                    }}
                  />
                )}
              />
            )}
          </FormControl>

          {/* <Button
            // variant="contained"
            color="primary"
            size="medium"
            disabled={!dirty}
            onClick={() => {
              onSubmit(values);
            }}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            Go
          </Button> */}
        </Box>
      )}
    </Formik>
  );
}
