/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ReportingFiltersMenu from "./ReportingFiltersMenu";
import { prettyName } from "../../helpers/user";
import { colors, constants } from "../../styles/config";
import StatCard from "./Cards/StatCard";

const prettifyOwnerName = (ownerObject) => {
  return `${ownerObject.firstName} ${ownerObject.lastName}`;
};

const Line = ({ series, lineGenerator, xScale, yScale }) => {
  return series.map(({ id, data }) => {
    return (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y)
          }))
        )}
        fill="none"
        stroke={colors.variner}
        style={{ strokeWidth: 3 }}
      />
    );
  });
};

export default function ReportingJobSubmissions({ submissionView, handleDataPointClick }) {
  // const dispatch = useDispatch();
  // const classes = useStyles();
  const theme = useTheme();
  const { submissions } = useSelector((state) => state.jobs);
  const [currentlyViewing, setCurrentlyViewing] = useState(null);
  const { currentJob } = useSelector((state) => state.jobs);
  const [filters, setFilters] = useState([]);
  const sendingUserOptions = useMemo(() => {
    const optionsSet = new Set();

    const uniqueOptions =
      submissions &&
      submissions.length &&
      submissions
        .map((sub) => sub.sendingUser)
        .filter((user) => {
          if (!user.id || optionsSet.has(user.id)) {
            return false;
          }
          optionsSet.add(user.id);
          return true;
        });

    return uniqueOptions;
  }, [submissions]);

  const events = currentJob && [
    {
      title: "Engagement",
      start: moment(currentJob.engagementDate).format("YYYY-MM-DD"),
      color: colors.gray43
    },
    {
      title: "Launch",
      start: moment(currentJob.launchDate).format("YYYY-MM-DD"),
      color: colors.variner
    },
    {
      title: "Slate Delivery",
      start: moment(currentJob.slateDeliveryDate).format("YYYY-MM-DD"),
      color: colors.darkerGray
    },
    {
      title: "Today",
      start: moment().format("YYYY-MM-DD"),
      color: colors.varinerGold
    }
  ];

  // console.log("sendingUserOptions", sendingUserOptions);

  const getUniqueDates = (submissions) => {
    const datesSet = new Set();
    const uniqueDates = submissions
      ?.map((sub) => ({ x: moment(sub.dateAdded).format("YYYY-MM-DD") }))
      .filter((sub) => {
        if (!sub.x || datesSet.has(sub.x)) {
          return false;
        }
        datesSet.add(sub.x);
        return true;
      });

    return uniqueDates;
  };

  const getSubmissionsForDate = (array, date) => {
    return array.filter((sub) => moment(sub.dateAdded).format("YYYY-MM-DD") === date);
  };

  const getOwnerSubmissionsForDate = (owner, array, date) => {
    return array
      .filter((sub) => `${sub.sendingUser.firstName} ${sub.sendingUser.lastName}` === owner)
      .filter((sub) => moment(sub.dateAdded).format("YYYY-MM-DD") === date);
  };

  const handleViewingChange = (userName) => {
    const foundSendingUser = sendingUserOptions.find((user) => {
      return `${user.firstName} ${user.lastName}` === userName;
    });

    setCurrentlyViewing(foundSendingUser);
  };

  const groupedByRecruiter = useMemo(() => {
    const groupObj =
      sendingUserOptions &&
      sendingUserOptions.reduce((acc, curr) => {
        const userSubmissions = submissions.filter(
          (submission) => submission.sendingUser.id === curr.id
        );

        return { ...acc, [curr.id]: { data: userSubmissions } };
      }, {});

    return groupObj;
  }, [sendingUserOptions, submissions]);

  // console.log("groupedByRecruiter", groupedByRecruiter);

  const dataAll = useMemo(() => {
    if (submissions) {
      const uniqueDates = getUniqueDates(submissions);

      const aggregatedByDate =
        uniqueDates &&
        uniqueDates.map((date) => {
          const submissionsAddedOnDate = submissions.filter(
            (sub) => moment(sub.dateAdded).format("YYYY-MM-DD") === date.x
          );

          return {
            ...date,
            y: submissionsAddedOnDate.length
          };
        });

      const sorted =
        aggregatedByDate &&
        aggregatedByDate.sort((a, b) => {
          return moment(a.x).valueOf() - moment(b.x).valueOf();
        });

      return sorted ? { id: "Team", color: "#1a444a", data: sorted } : null;
    }
  }, [submissions]);

  // console.log("data all:", dataAll);

  const dataByRecruiter = useMemo(() => {
    const formattedId = (value) => {
      const foundUser = sendingUserOptions.find((user) => user.id === parseInt(value));
      if (!foundUser) return value;

      return prettyName(foundUser);
    };

    const dataArray =
      groupedByRecruiter &&
      Object.keys(groupedByRecruiter).map((id) => {
        const submissionsArray = groupedByRecruiter[id].data;

        const datesSet = new Set();

        const uniqueDates =
          submissionsArray &&
          submissionsArray
            .map((sub) => ({
              x: moment(sub.dateAdded).format("YYYY-MM-DD")
            }))
            .filter((sub) => {
              if (!sub.x || datesSet.has(sub.x)) {
                return false;
              }
              datesSet.add(sub.x);
              return true;
            });

        const aggregatedByDate = uniqueDates.map((date) => {
          const submissionsAddedOnDate = submissionsArray.filter(
            (sub) => moment(sub.dateAdded).format("YYYY-MM-DD") === date.x
          );

          // console.log("submissionsAddedOnDate", submissionsAddedOnDate);

          return {
            ...date,
            y: submissionsAddedOnDate.length
          };
        });

        const sorted = aggregatedByDate.sort((a, b) => {
          return moment(a.x).valueOf() - moment(b.x).valueOf();
        });

        const formattedName = formattedId(id);

        return {
          id: formattedName,
          color: constants.colorsForData[formattedName],
          data: sorted
        };
      });

    return dataArray;
  }, [groupedByRecruiter, sendingUserOptions]);

  const filteredDataByRecruiter = useMemo(() => {
    if (currentlyViewing) {
      return dataByRecruiter.filter(
        (data) => data.id === `${currentlyViewing.firstName} ${currentlyViewing.lastName}`
      );
    } else {
      return dataByRecruiter;
    }
  }, [dataByRecruiter, currentlyViewing]);

  useEffect(() => {
    if (!currentlyViewing) {
      setFilters([]);
    } else {
      let newFilters = [];
      if (currentlyViewing) {
        newFilters = [
          ...newFilters,
          {
            icon: PersonOutlineIcon,
            label: `${currentlyViewing.firstName} ${currentlyViewing.lastName}`,
            onClick: () => {
              console.log("chip clicked");
            },
            onDelete: () => {
              setCurrentlyViewing(null);
            }
          }
        ];
      }
      setFilters(newFilters);
    }
  }, [currentlyViewing]);

  // console.log("filteredDataByRecruiter", filteredDataByRecruiter);

  const CustomSymbol = (data) => {
    var { size, borderWidth, borderColor } = data;
    // console.log(data);
    var strokeColor = colors.variner;

    if (submissionView !== "recruiter") {
      for (const e of events) {
        if (data.datum.xFormatted === e.start) {
          borderColor = e.color;
          strokeColor = "white";
        }
      }
    } else {
      strokeColor = "white";
    }

    return (
      <g>
        <circle
          r={size}
          strokeWidth={borderWidth}
          stroke={strokeColor}
          fill={borderColor}
          fillOpacity={1}
        />
      </g>
    );
  };

  if (!dataAll || !dataByRecruiter) return null;

  const findBy = (sub) =>
    prettifyOwnerName(sub.sendingUser) === prettifyOwnerName(currentlyViewing);

  const totalSubmissions = currentlyViewing
    ? submissions.filter(findBy).length
    : submissions.length;

  const defaultResponsiveLine = {
    anchor: "top-left",
    direction: "row",
    justify: false,
    translateX: -20,
    translateY: -50,
    itemsSpacing: 0,
    itemDirection: "left-to-right",
    itemWidth: 180,
    itemHeight: 20,
    itemOpacity: 0.75,
    symbolSize: 16,
    symbolShape: "square",
    // symbolBorderColor: "rgba(0, 0, 0, .5)",
    onClick: ({ id }) => {
      handleViewingChange(id);
    },
    effects: [
      {
        on: "hover",
        style: {
          itemBackground: "rgba(0, 0, 0, .03)",
          itemOpacity: 1
        }
      }
    ]
  };

  const ResponsiveLineLegend = currentlyViewing ? [] : [defaultResponsiveLine];

  return (
    <Box style={{ height: "100%", width: "100%" }}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        ml={4}
        mb={2}
        style={{ marginTop: "-44px" }}
        width={200}
      >
        <StatCard
          shadow={false}
          // backgroundColor={colors.varinerLight}
          stat={{
            title: "Submissions",
            total: totalSubmissions
          }}
        />
      </Box>
      {filters && filters.length > 0 && (
        <Box px={4}>
          <ReportingFiltersMenu filters={filters} />
        </Box>
      )}
      <Box height={300}>
        <ResponsiveLine
          animate={false}
          data={submissionView === "recruiter" ? filteredDataByRecruiter : [dataAll]}
          margin={{ top: 60, right: 40, bottom: 60, left: 60 }}
          colors={({ id }) => {
            const match = dataByRecruiter.find((obj) => obj.id === id);
            return (match && match.color) || "white";
          }}
          theme={{
            background: "#ffffff",
            grid: {
              line: {
                stroke: "rgba(239, 239, 239, 0.85)",
                strokeWidth: 1
              }
            }
          }}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day"
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: "linear",
            stacked: false
          }}
          axisLeft={{
            // tickSize: 8,
            // legend: "linear scale",
            // legendOffset: 12,
            tickSize: 0,
            tickPadding: 15
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 15,
            format: "%b %d",
            tickValues: "every 4 days"
            // legend: "time scale",
            // legendOffset: -12,
          }}
          axisTop={null}
          axisRight={null}
          onClick={(pointData) => {
            let daySubmissions;

            if (pointData.serieId === "Team") {
              daySubmissions = getSubmissionsForDate(submissions, pointData.data.xFormatted);
            } else {
              daySubmissions = getOwnerSubmissionsForDate(
                pointData.serieId,
                submissions,
                pointData.data.xFormatted
              );
            }
            handleDataPointClick({
              id: pointData.serieId,
              date: pointData.data.xFormatted,
              submissions: daySubmissions
            });
          }}
          tooltip={(d) => {
            const pointData = d.point;
            // console.log("pointData", pointData);
            if (!pointData) return null;

            return (
              <Box
                style={{
                  background: "white",
                  padding: "9px 12px",
                  border: "1px solid #ccc"
                }}
              >
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                  <Typography
                    style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      paddingBottom: theme.spacing(0.5),
                      width: 30
                    }}
                  >
                    {`[${pointData.data.yFormatted}]`}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      paddingBottom: theme.spacing(0.5)
                    }}
                  >
                    Submission(s) added on {pointData.data.xFormatted}
                  </Typography>
                </Box>

                {pointData.serieId === "Team" &&
                  filteredDataByRecruiter &&
                  filteredDataByRecruiter.map((owner, index) => {
                    const ownerName = owner.id;
                    const ownerData = owner.data;
                    const ownerDateData =
                      ownerData && ownerData.find((od) => od.x === pointData.data.xFormatted);
                    // console.log("ownerData", ownerData);

                    if (!ownerDateData) return null;

                    return (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Typography
                          style={{
                            fontSize: 10,
                            fontWeight: "bold",
                            paddingBottom: theme.spacing(0.5),
                            width: 30
                          }}
                        >
                          {`[${ownerDateData.y}]`}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 10,
                            paddingBottom: theme.spacing(0.5)
                          }}
                        >
                          {ownerName}
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
            );
          }}
          enablePointLabel={true}
          pointSymbol={CustomSymbol}
          pointBorderWidth={2}
          pointColor={{ theme: "background" }}
          pointBorderColor={{ from: "serieColor", modifiers: [] }}
          enableSlices={false}
          curve="natural"
          pointSize={8}
          pointLabelYOffset={-12}
          enableArea={false}
          useMesh={true}
          layers={[
            "grid",
            "markers",
            "axes",
            "areas",
            "crosshair",
            "lines",
            Line,
            "points",
            "slices",
            "mesh",
            "legends",
            "sliceTooltip",
            "tooltip"
          ]}
          legends={ResponsiveLineLegend}
        />
      </Box>
    </Box>
  );
}
