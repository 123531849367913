/* eslint-disable react/prop-types */
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { getCallTime, convertNumToTime } from "../../../helpers/callTime";

export default function PrescreensTooltip({ newPrescreensByOwner, innerData }) {
  const theme = useTheme();
  const prescreens = newPrescreensByOwner[innerData.ownerId].newPrescreens;

  const totalPrescreenCallTime = getCallTime(prescreens, "hrs");
  const totalPrescreenCallTimeMins = getCallTime(prescreens, "mins");
  const average = Math.ceil((totalPrescreenCallTimeMins / prescreens.length) * 10) / 10;

  return (
    <Box>
      <Typography
        style={{
          fontSize: 10,
          fontWeight: "bold",
          paddingBottom: theme.spacing(0.5)
        }}
      >
        New Prescreens - Call Time
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography
          style={{
            width: 35,
            fontSize: 10,
            fontWeight: "bold"
          }}
        >{`[${convertNumToTime(totalPrescreenCallTime)}]`}</Typography>
        <Typography style={{ fontSize: 10 }}>{"Total Time on Prescreens"}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography
          style={{
            width: 35,
            fontSize: 10,
            fontWeight: "bold"
          }}
        >{`[${convertNumToTime(average / 60)}]`}</Typography>
        <Typography style={{ fontSize: 10 }}>{"Average Time per Prescreen"}</Typography>
      </Box>
    </Box>
  );
}
