import moment from "moment";

export const generateSlateData = ({ submissions, currentJob, selectedSlateOptions }) => {
  const submissionData = submissions.map((submission) => {
    return {
      id: submission.id,
      status: submission.status,
      candidate: submission.candidate,
      varinerScore: submission.varinerScore,
      responses: [
        {
          question: "Why I am interested in this opportunity",
          answer: submission.customTextBlock1
        },
        {
          question: "My relevant work experience",
          answer: submission.customTextBlock3
        },
        {
          question: "Why I am interested in the location",
          answer: submission.customTextBlock2
        },
        {
          question: "Earliest Start Date",
          answer: submission.customDate2 && moment(submission.customDate2).format("MMM Do, YYYY")
        },
        {
          question: "Ideal Start Date",
          answer: submission.customDate1 && moment(submission.customDate1).format("MMM Do, YYYY")
        }
      ]
    };
  });

  const data = {
    submissions: submissionData,
    jobId: currentJob.id,
    jobTitle: currentJob.publicName,
    clientCorporation: currentJob.clientCorporation,
    options: {
      slateCover: selectedSlateOptions.indexOf("slateCover") > -1,
      summary: selectedSlateOptions.indexOf("summary") > -1,
      submissionQA: selectedSlateOptions.indexOf("submissionQA") > -1
    }
  };

  return data;
};
