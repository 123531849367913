/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Button,
  Box,
  TextField,
  FormControl,
  Typography,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  useTheme
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colors } from "../../styles/config";

import "react-datepicker/dist/react-datepicker.css";
import "../../styles/stylesheets/base.css";
import "../../styles/stylesheets/calendar.css";

import WysiwygEditor from "./Editor";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    minWidth: "100%"
  },
  label: {
    fontSize: "12px",
    textTransform: "capitalize",
    width: 70,
    textAlign: "center"
  },
  formSection: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  formSectionLabel: {
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: 10
  },
  specialInputLabel: {
    backgroundColor: colors.white,
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  italic: {
    fontStyle: "italic"
  },
  bold: {
    fontWeight: "500"
  },
  capitalize: {
    textTransform: "capitalize"
  }
}));

const initialValues = {
  roleInterest: "",
  locationInterest: "",
  earliestStartDate: "",
  idealStartDate: "",
  relevantExperience: "",
  base64Resume: "",
  banana: "empty"
};

const VALUE_OPTIONS = [
  {
    value: 1,
    label: "Strongly Disagree"
  },
  {
    value: 2,
    label: "Disagree"
  },
  {
    value: 3,
    label: "Neutral"
  },
  {
    value: 4,
    label: "Agree"
  },
  {
    value: 5,
    label: "Strongly Agree"
  }
];

const validationSchema = yup.object().shape({
  knowledgeScore: yup.string().required("Please make a selection for the knowledge section."),
  responsivenessScore: yup
    .string()
    .required("Please make a selection for the responsiveness section."),
  satisfactionScore: yup.string().required("Please make a selection for the satisfaction section."),
  referenceable: yup.string().required("Please make a selection for the referenceable section."),
  banana: yup
    .string()
    .matches(/(empty)/)
    .required("Required")
});

export default function PostCloseForm({ onSubmit, clientResponder, values = {} }) {
  const theme = useTheme();

  const { error, currentJob } = useSelector((state) => state.jobs);
  const { placement } = useSelector((state) => state.user);

  const notFoundError =
    error &&
    error.error &&
    error.error.toLowerCase().includes("job") &&
    error.error.includes("not found");

  const [editorFocused, setEditorFocused] = useState(null);
  const [resetting] = useState(null);

  const varinerEmployeeFirstName = useMemo(() => {
    if (!currentJob) return;

    return clientResponder
      ? `${currentJob?.owner?.firstName} ${currentJob?.owner?.lastName}`
      : `${placement?.jobSubmission?.sendingUser?.firstName} ${placement?.jobSubmission?.sendingUser?.lastName}`;
  }, [clientResponder, currentJob]);

  // const clientName = currentJob?.clientCorporation?.name;
  const jobName = currentJob.publicName;

  const data = {
    ...initialValues,
    ...values
  };

  const classes = useStyles();

  if (notFoundError) {
    return <Typography>{notFoundError}</Typography>;
  }

  const clientCorporationName = currentJob?.clientCorporation?.name || "the company";

  return (
    <Formik
      initialValues={data}
      onSubmit={async (values, { resetForm }) => {
        await onSubmit(values);
        resetForm();
      }}
      validationSchema={validationSchema}
    >
      {({ values, errors, setFieldTouched, setFieldValue, dirty, handleSubmit }) => {
        return (
          <Box
            style={{ width: "100%" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mx="auto"
          >
            <Grid
              container
              spacing={2}
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              style={{
                paddingTop: theme.spacing(2)
              }}
            >
              {/*
               * First Question.
               *
               * Knowledge
               */}
              <Grid item xs={12} sm={4}>
                <Typography variant="h3" className={`${classes.bold} ${classes.capitalize}`}>
                  1. Knowledge
                </Typography>

                <Box my={1} />

                <Typography
                  style={{
                    marginBottom: theme.spacing(1)
                  }}
                >
                  {varinerEmployeeFirstName} was knowledgeable of{" "}
                  {clientResponder
                    ? "the candidate slate."
                    : `${clientCorporationName} and the ${jobName} role you were recruited to.`}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={8}>
                <FormControl className={classes.formControl}>
                  <RadioGroup
                    row
                    style={{ justifyContent: "flex-end" }}
                    onChange={(event) => {
                      setFieldValue("knowledgeScore", parseInt(event.target.value));
                      setFieldTouched("knowledgeScore", true);
                    }}
                    name="knowledgeScore"
                    value={values.knowledgeScore}
                  >
                    {VALUE_OPTIONS.map((likert, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={likert.value}
                          classes={{
                            label: classes.label
                          }}
                          control={
                            <Radio
                              color="primary"
                              size="small"
                              checked={values["knowledgeScore"] === likert.value}
                            />
                          }
                          label={likert.label}
                          labelPlacement="top"
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Box my={2} />

            {/*
             * Second Question.
             *
             * Responsiveness
             */}
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
              <Grid item xs={12} sm={4}>
                <Typography variant="h3" className={`${classes.bold} ${classes.capitalize}`}>
                  2. Responsiveness
                </Typography>

                <Box my={1} />

                <Typography
                  style={{
                    marginBottom: theme.spacing(1)
                  }}
                >
                  {varinerEmployeeFirstName} was responsive throughout the entirety of the{" "}
                  {clientCorporationName} search process.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={8}>
                <FormControl className={classes.formControl}>
                  <RadioGroup
                    row
                    style={{ justifyContent: "flex-end" }}
                    onChange={(event) => {
                      setFieldValue("responsivenessScore", parseInt(event.target.value));
                      setFieldTouched("responsivenessScore", true);
                    }}
                    name="responsivenessScore"
                    value={values.responsivenessScore}
                  >
                    {VALUE_OPTIONS.map((likert, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={likert.value}
                          classes={{
                            label: classes.label
                          }}
                          control={
                            <Radio
                              color="primary"
                              size="small"
                              checked={values.responsivenessScore === likert.value}
                            />
                          }
                          label={likert.label}
                          labelPlacement="top"
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Box my={2} />

            {/*
             * Third Question.
             *
             * Satisfaction
             */}
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
              <Grid item xs={12} sm={4}>
                <Typography variant="h3" className={`${classes.bold} ${classes.capitalize}`}>
                  3. Satisfaction
                </Typography>

                <Box my={1} />

                <Typography
                  style={{
                    marginBottom: theme.spacing(1)
                  }}
                >
                  I was satisfied with the level of service provided by {varinerEmployeeFirstName}{" "}
                  throughout the entire search process.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={8}>
                <FormControl className={classes.formControl}>
                  <RadioGroup
                    row
                    style={{ justifyContent: "flex-end" }}
                    onChange={(event) => {
                      setFieldValue("satisfactionScore", parseInt(event.target.value));
                      setFieldTouched("satisfactionScore", true);
                    }}
                    name="satisfactionScore"
                    value={values.satisfactionScore}
                  >
                    {VALUE_OPTIONS.map((likert, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={likert.value}
                          classes={{
                            label: classes.label
                          }}
                          control={
                            <Radio
                              color="primary"
                              size="small"
                              checked={values.satisfactionScore === likert.value}
                            />
                          }
                          label={likert.label}
                          labelPlacement="top"
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Box my={2} />

            {/*
             * Fourth Question.
             *
             * Referenceable
             */}
            <Grid container spacing={2} direction="row" alignItems="flex-start">
              <Grid item xs={12} sm={4}>
                <Typography variant="h3" className={`${classes.bold} ${classes.capitalize}`}>
                  4. Referenceable
                </Typography>

                <Box my={1} />

                <Typography>
                  I would recommend Variner and their professional recruitment services to other
                  clients in need of recruitment services.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={8}>
                <FormControl className={classes.formControl}>
                  <RadioGroup
                    row
                    style={{ justifyContent: "flex-end" }}
                    onChange={(event) => {
                      setFieldValue("referenceable", parseInt(event.target.value));
                      setFieldTouched("referenceable", true);
                    }}
                    name="satisfactionScore"
                    value={values.referenceable}
                  >
                    {VALUE_OPTIONS.map((likert, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={likert.value}
                          classes={{
                            label: classes.label
                          }}
                          control={
                            <Radio
                              color="primary"
                              size="small"
                              checked={values.referenceable === likert.value}
                            />
                          }
                          label={likert.label}
                          labelPlacement="top"
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Box my={2} />

            {/*
             * Fifth Question
             *
             * Testimonial [Conditional]
             */}
            <FormControl variant="outlined" className={classes.formControl}>
              <Typography variant="h3" className={classes.bold}>
                5. Testimonial
              </Typography>

              <Box my={1} />

              <Typography>
                To the extent you would be referenceable in the future, we would appreciate a quick
                testimonial for our marketing collateral going forward (good, bad, or meh...). The
                testimonial will never be attached to your name and will remain anonymous. If you
                prefer not to provide a testimonial, please input &quot;Not Applicable.&quot;
              </Typography>

              {!clientResponder && (
                <>
                  <Typography>Some Examples from past candidates are below:</Typography>

                  <Box my={1} />

                  <Typography className={classes.italic}>
                    Working with Variner was a great experience. Variner was responsive,
                    straightforward and provided valuable preparation insights based on knowledge of
                    the interviewers and their styles. I also appreciated their willingness to jump
                    on the phone at any hour to discuss whatever was on my mind.
                  </Typography>

                  <Box my={1} />

                  <Typography className={classes.italic}>
                    Variner played an integral role in my search process. They spent time getting to
                    know me on a personal level and were great to work with in general. I highly
                    recommend Variner, especially to those looking to break into private equity.
                  </Typography>
                </>
              )}
            </FormControl>

            <Box my={1} />

            <FormControl
              className={classes.formControl}
              style={{
                marginTop: 0,
                border:
                  editorFocused === "responderTestimonial"
                    ? `2px solid ${colors.variner}`
                    : "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: 4
              }}
            >
              <TextField
                multiline
                rows={1}
                inputProps={{
                  maxLength: 80
                }}
                className={"hide"}
                variant="outlined"
                value={values.banana}
                onChange={(event) => setFieldValue("banana", event.target.value)}
                onBlur={() => {
                  setFieldTouched("banana", true);
                }}
                label="Do you like bananas?"
                placeholder="Enter few sentences about why you like bananas."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <WysiwygEditor
                reset={resetting}
                onBlur={() => setEditorFocused(null)}
                onFocus={() => setEditorFocused("responderTestimonial")}
                varinerCommentsHtml=""
                onChange={(string) => {
                  setFieldValue("responderTestimonial", string);
                  setFieldTouched("responderTestimonial", true);
                }}
                height={130}
              />
            </FormControl>

            <Box my={2} />

            {/*
             * Sixth Question.
             *
             * Constructive Feedback
             */}
            <FormControl variant="outlined" className={classes.formControl}>
              <Typography variant="h3" className={classes.bold}>
                6. Constructive Feedback
              </Typography>

              <Box my={1} />

              <Typography>
                From your experience working with Variner, what constructive feedback would you
                suggest we implement when working with future{" "}
                {clientResponder ? "clients" : "candidates"}?
              </Typography>
            </FormControl>

            <Box my={1} />

            <FormControl
              className={classes.formControl}
              style={{
                marginTop: 0,
                border:
                  editorFocused === "responderConstructiveFeedback"
                    ? `2px solid ${colors.variner}`
                    : "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: 4
              }}
            >
              <WysiwygEditor
                reset={resetting}
                onBlur={() => setEditorFocused(null)}
                onFocus={() => setEditorFocused("responderConstructiveFeedback")}
                varinerCommentsHtml=""
                onChange={(string) => {
                  setFieldValue("responderConstructiveFeedback", string);
                  setFieldTouched("responderConstructiveFeedback", true);
                }}
                height={130}
              />
            </FormControl>

            <Box my={2} />

            <Box width="100%" display="flex" alignItems="flex-end" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                style={{ width: "200px" }}
                size="medium"
                disabled={!dirty || (dirty && errors && Object.keys(errors).length)}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}
