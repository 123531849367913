/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Box, IconButton, Typography } from "@mui/material";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfViewer({ pdfBufferObj }) {
  const theme = useTheme();
  const [zoomed, setZoomed] = useState(false);
  const [showZoom, setShowZoom] = useState(false);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <Box>
      <Box
        boxShadow={2}
        style={{
          margin: "auto",
          marginTop: theme.spacing(1),
          width: zoomed ? "auto" : 640,
          position: "relative"
        }}
        onMouseEnter={() => {
          setShowZoom(true);
        }}
        onMouseLeave={() => {
          setShowZoom(false);
        }}
      >
        {showZoom && (
          <Box
            style={{
              position: "absolute",
              zIndex: 12000,
              top: 0,
              right: 0,
              margin: theme.spacing(1)
            }}
          >
            <IconButton onClick={() => setZoomed(!zoomed)} size="large">
              {!zoomed && <ZoomInIcon />}
              {zoomed && <ZoomOutIcon />}
            </IconButton>
          </Box>
        )}
        <Document
          onLoadSuccess={onDocumentLoadSuccess}
          noData={"No resume found."}
          loading={"..."}
          file={pdfBufferObj}
          options={{
            cMapUrl: "cmaps/",
            cMapPacked: true
          }}
        >
          {Array.from(new Array(numPages), (el, index) => {
            if (index + 1 === pageNumber) {
              return (
                <Page
                  scale={zoomed ? 1.24 : null}
                  height={zoomed ? null : 800}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              );
            } else return null;
          })}
        </Document>
      </Box>
      {numPages && numPages > 1 && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <IconButton
            style={{ margin: theme.spacing(1) }}
            disabled={pageNumber === 1}
            onClick={() => setPageNumber(pageNumber - 1)}
            size="large"
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant={"body2"}>
            Page {pageNumber} of {numPages}
          </Typography>
          <IconButton
            style={{ margin: theme.spacing(1) }}
            disabled={pageNumber === numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
            size="large"
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
