/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Box, Typography, LinearProgress, linearProgressClasses } from "@mui/material";
import { colors } from "../styles/config";

// might need to add a normalizer function to deal with values outside of 0-100?
function LinearProgressWithLabel({ value, type }) {
  const [barColor, setBarColor] = useState();

  useEffect(() => {
    if (type === "increase") {
      setBarColor("#30a171");
    } else if (type === "decrease") {
      setBarColor("#c92d17");
    }
  }, [setBarColor, type]);

  // console.log(Number(value));

  return (
    <Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body1" color={barColor}>{`${value}%`}</Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          value={Number(value) > 100 ? 100 : Number(value)}
          sx={{
            height: 6,

            borderRadius: 5,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: colors.gray1
            },
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: barColor
            }
          }}
        />
      </Box>
    </Box>
  );
}

export default function ProgressBarForStatCard({ value, type }) {
  return <LinearProgressWithLabel value={value} type={type} />;
}
