/* eslint-disable react/prop-types */
import React from "react";

// 3rd party libraries
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// style things
import { colors } from "../styles/config";

const useStyles = makeStyles(() => ({
  pill: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,
    borderRadius: 20,
    paddingTop: 4,
    paddingBottom: 2,
    paddingLeft: 6,
    paddingRight: 6,
    transition: "background-color 0.3s ease"
  },

  Content: {
    paddingRight: 10,
    paddingLeft: 10
  }
}));

export default function ContentPill({
  containerStyle = {},
  title,
  titleComponent,
  backgroundColor = colors.gray1,
  onHoverBGColor = colors.varinerLightBlue,
  isOutlined = false,
  outlineColor,
  textStyle = {},
  onClick,
  rightIcon
}) {
  const classes = useStyles();

  return (
    <Box
      onClick={onClick}
      sx={{
        ...containerStyle,
        backgroundColor: backgroundColor,
        "&:hover": {
          backgroundColor: onClick ? onHoverBGColor : backgroundColor,
          border: onClick ? 2 : 1,
          borderColor: onClick ? colors.varinerBlue : outlineColor
        },
        cursor: onClick ? "pointer" : null,
        border: !isOutlined ? `2px solid ${colors.gray1}` : `1px solid ${outlineColor}`
      }}
      className={classes.pill}
    >
      <Box className={classes.Content}>
        {/* if title component exists, render it. else do the other thing. */}
        {titleComponent ? (
          titleComponent
        ) : (
          <Typography sx={{ ...textStyle, pointerEvents: "none" }}>{title}</Typography>
        )}
      </Box>
      {rightIcon && <Box>{rightIcon}</Box>}
    </Box>
  );
}
