import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "@fontsource/roboto";

import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BarChartIcon from "@mui/icons-material/BarChart";
import WorkIcon from "@mui/icons-material/Work";
import {
  Box,
  Button,
  Popover,
  Typography,
  Avatar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@mui/material";
import { prettyName, superAccess } from "@/helpers/user";
import logo from "@/assets/logo.png";
import { colors, constants } from "@/styles/config";
import { useAuth } from "@/hooks/use-auth.hook";

const useStyles = makeStyles((theme) => ({
  logo: {},
  menuToggle: {
    display: "flex",
    alignItems: "center",
    gap: ".5vw",
    marginLeft: "5px",
    marginTop: "10px",
    marginBottom: "20px"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: colors.darkGray,
    transition: theme.transitions.create(["width", "margin"], {
      easing: "sharp",
      duration: "0.3s"
    })
  },
  hide: {
    display: "none"
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    backgroundColor: colors.darkGray,
    borderRadius: "2px",
    transition: theme.transitions.create("width", {
      easing: "sharp",
      duration: "0.3s"
    })
  },
  drawerClose: {
    backgroundColor: colors.darkGray,
    transition: theme.transitions.create("width", {
      easing: "sharp",
      duration: "0.3s"
    }),
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: 0
    },
    borderRight: "1px solid rgba(255, 255, 255, 0.2)"
  },
  menuTextRoot: {
    whiteSpace: "normal"
  },
  menuText: {
    fontSize: 12,
    color: "rgba(255, 255, 255, 0.8)",
    marginLeft: 10
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(5, 1.5)
    // necessary for content to be below app bar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(7.5),
    paddingRight: 0,
    paddingLeft: 0
  },
  formControl: {
    margin: 0,
    borderRadius: 3,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(8),
    backgroundColor: "rgba(238, 238, 238, 0.1)",
    fontSize: 12,
    color: colors.white
  },
  logOut: {
    display: "flex",
    alignItems: "center",
    gap: ".5vw",
    marginLeft: "10px"
  }
}));

export default function SideNavDrawer() {
  const { user } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(600);
  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    if (menuOpen === true) {
      setDrawerWidth(600);
    } else setDrawerWidth(200);
  }, [menuOpen]);

  const handleLoggedClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLoggedClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    auth.signout(() => history.push("/login"));
  };

  const handleDrawerClick = () => {
    if (!menuOpen) {
      setMenuOpen(true);
    } else setMenuOpen(false);
  };

  const handleDrawerClose = () => {
    setMenuOpen(false);
  };

  // const handleSearchJobs = (newValue) => {
  //   if (!newValue || newValue === "") {
  //     setFilteredActiveJobs(activeJobs);
  //   } else {
  //     const updatedFiltered = activeJobs.filter((job) => {
  //       const label = `${job.id} | ${job.title}`;
  //       return label.toLowerCase().indexOf(newValue.toLowerCase()) > -1;
  //     });
  //     setFilteredActiveJobs(updatedFiltered);
  //   }
  // };

  return (
    <Box>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        open={menuOpen}
        classes={{
          paper: classes.drawerOpen
        }}
        sx={{ minWidth: drawerWidth }}
      >
        <Box className={classes.menuToggle}>
          <IconButton
            onClick={handleDrawerClick}
            disableRipple={true}
            color="secondary"
            className={classes.menuButton}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          {menuOpen && (
            <img
              className={classes.logo}
              src={logo}
              style={{ maxHeight: 20, marginRight: "30px" }}
              alt="logo"
            />
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          style={{ height: "calc(100vh - 64px)" }}
        >
          <List>
            {[
              { label: "Home", link: "/variner/home", icon: HomeIcon },
              {
                label: "Reporting Dashboard",
                link: "/variner/reporting",
                icon: BarChartIcon,
                disabled: !superAccess(user)
                // user.occupation !== "Partner" &&
                // user.id !== 16 &&
                // user.username !== "15379.unassigned",
              },
              {
                label: "Active Job Searches",
                link: "/variner/active-searches",
                icon: WorkIcon
              }
            ].map((item) => {
              const Icon = item.icon;
              return (
                <ListItem
                  button
                  disabled={item.disabled}
                  key={item.label}
                  onClick={() => {
                    if (history.location.pathname !== item.link) {
                      history.push(item.link);
                      handleDrawerClose();
                    }
                  }}
                >
                  <ListItemIcon style={{ color: colors.white, minWidth: 20 }}>
                    <Icon />
                  </ListItemIcon>
                  {menuOpen && (
                    <ListItemText
                      classes={{
                        root: classes.menuTextRoot,
                        primary: classes.menuText
                      }}
                      primary={item.label}
                    />
                  )}
                </ListItem>
              );
            })}

            {/* {[
              {
                label: "Old Active Job Searches",
                link: "/variner/job",
                icon: GroupIcon,
              },
            ].map((item) => {
              const Icon = item.icon;

              return (
                <React.Fragment>
                  <ListItem
                    button
                    disabled={item.disabled}
                    key={item.label}
                    onClick={() => {
                      setSlatesListOpen(!slatesListOpen);
                      // if (history.location.pathname !== item.link) {
                      //   history.push(item.link);
                      //   handleDrawerClose();
                      // }
                    }}
                  >
                    <ListItemIcon style={{ color: colors.white, minWidth: 12 }}>
                      <Icon />
                    </ListItemIcon>

                    {menuOpen && (
                      <ListItemText
                        classes={{
                          root: classes.menuTextRoot,
                          primary: classes.menuText,
                        }}
                        primary={item.label}
                      />
                    )}
                    {menuOpen && (
                      <Box>
                        {slatesListOpen ? (
                          <ExpandLessIcon
                            style={{
                              width: theme.spacing(2.5),
                              height: theme.spacing(2.5),
                              color: "rgba(238, 238, 238, 0.5)",
                            }}
                          />
                        ) : (
                          <ExpandMoreIcon
                            style={{
                              width: theme.spacing(2.5),
                              height: theme.spacing(2.5),
                              color: "rgba(238, 238, 238, 0.5)",
                            }}
                          />
                        )}
                      </Box>
                    )}
                  </ListItem>
                  <Collapse in={slatesListOpen} timeout="auto" unmountOnExit>
                    <FormControl
                      className={[classes.formControl, "white-text"]
                        .join(" ")
                        .trim()}
                      variant="standard"
                    >
                      <TextField
                        className="white-text-input"
                        id="standard-search"
                        type="search"
                        placeholder="Search Jobs"
                        onChange={(event) => {
                          handleSearchJobs(event.target.value);
                        }}
                        variant="standard"
                      />
                    </FormControl>
                    {filteredActiveJobs && !!filteredActiveJobs.length && (
                      <List
                        component="div"
                        disablePadding
                        style={{
                          maxHeight: "60vh",
                          overflowY: "scroll",
                          overflowX: "hidden",
                          paddingLeft: theme.spacing(8),
                        }}
                      >
                        {filteredActiveJobs.map((job) => {
                          return (
                            <ListItem
                              style={{
                                paddingLeft: theme.spacing(1),
                                paddingRight: theme.spacing(3),
                                paddingTop: theme.spacing(0.5),
                                paddingBottom: theme.spacing(0.5),
                                borderTop: `1px solid rgba(238, 238, 238, 0.1)`,
                              }}
                            >
                              <ListItemText
                                classes={{
                                  root: classes.menuTextRoot,
                                  primary: classes.menuText,
                                }}
                                primary={`${job.id} | ${job.title}`}
                              />
                              <Tooltip title="Candidate Slate">
                                <IconButton
                                  style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                    marginRight: 4,
                                    marginLeft: 4,
                                  }}
                                  size="small"
                                  color="inherit"
                                  onClick={() => {
                                    const pathArray =
                                      history.location.pathname.split("/");
                                    const diffJob =
                                      pathArray.indexOf(`${job.id}`) < 0;
                                    const samePath =
                                      pathArray.indexOf("job") > -1;

                                    if (diffJob || !samePath) {
                                      history.push(`/variner/job/${job.id}`);
                                    }

                                    handleDrawerClose();
                                  }}
                                >
                                  <CandidateSlateIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Selling Points">
                                <IconButton
                                  style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                    marginRight: 4,
                                    marginLeft: 4,
                                  }}
                                  size="small"
                                  color="inherit"
                                  onClick={() => {
                                    const pathArray =
                                      history.location.pathname.split("/");
                                    const diffJob =
                                      pathArray.indexOf(`${job.id}`) < 0;
                                    const samePath =
                                      pathArray.indexOf("sellingpoints") > -1;

                                    if (diffJob || !samePath) {
                                      history.push(
                                        `/variner/sellingpoints/${job.id}`
                                      );
                                    }

                                    handleDrawerClose();
                                  }}
                                >
                                  <SellingPointsIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ListItem>
                          );
                        })}
                      </List>
                    )}
                  </Collapse>
                </React.Fragment>
              );
            })} */}
          </List>
          <List>
            <ListItem button disableRipple={true}>
              <ListItemIcon style={{ minWidth: 12 }}>
                <Avatar
                  onClick={handleLoggedClick}
                  style={{
                    width: 36,
                    height: 36,
                    marginLeft: "-4px",
                    cursor: "pointer",
                    backgroundColor: constants.colorsForData[prettyName(user)]
                  }}
                >
                  <Typography variant="h5">
                    {`${user.firstName.charAt(0).toUpperCase()}${user.lastName
                      .charAt(0)
                      .toUpperCase()}`}
                  </Typography>
                </Avatar>
              </ListItemIcon>

              {menuOpen && (
                <Box className={classes.logOut}>
                  <ListItemText
                    onClick={handleLoggedClick}
                    classes={{ primary: classes.menuText }}
                    primary={"Log out"}
                  />
                  <ExpandMoreIcon sx={{ color: "white" }} />
                </Box>
              )}
              <Popover
                style={{ marginTop: theme.spacing(1) }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleLoggedClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
              >
                <Box p={0.5}>
                  <Button
                    onClick={handleLogoutClick}
                    style={{ minWidth: 150 }}
                    startIcon={<ExitToAppIcon />}
                  >
                    Sign out
                  </Button>
                </Box>
              </Popover>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
