/* eslint-disable react/prop-types */
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";

import { colors } from "../styles/config";
import ProgressRow from "./ProgressRow";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1vw"
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1vw",
    boxShadow: "0px 10px 10px -10px  grey",
    padding: "10px 20px"
  },

  rows: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "1vw",
    padding: "10px 20px"
  },

  loadingLine: {
    height: 24,
    width: "100%",
    backgroundColor: colors.gray1,
    marginBottom: theme.spacing(0.25),
    borderRadius: 3
  }
}));

export default function ActiveSubmissionsBars({
  setToggleDrawer,
  groupedSubmissions = {},
  highestTotal,
  loading
}) {
  const classes = useStyles();

  return (
    <Box sx={{ borderTop: 2, borderColor: colors.gray1 }} className={classes.container}>
      <Box className={classes.header}>
        <Typography
          style={{
            fontWeight: "600",
            fontSize: 12,
            color: colors.darkGray
          }}
        >
          Status
        </Typography>

        <Typography
          style={{
            fontWeight: "600",
            fontSize: 12,
            color: colors.darkGray
          }}
        >
          Total
        </Typography>
      </Box>
      <Box className={classes.rows}>
        {groupedSubmissions?.map((submissionsByStatus, index) => {
          return loading ? (
            <Box className={classes.loadingLine} key={index}></Box>
          ) : (
            <ProgressRow
              key={submissionsByStatus.status}
              title={submissionsByStatus.status}
              percentComplete={(submissionsByStatus.submissions.length / highestTotal) * 100}
              total={submissionsByStatus.submissions.length}
              setToggleDrawer={setToggleDrawer}
              submissions={submissionsByStatus.submissions}
            />
          );
        })}
      </Box>
    </Box>
  );
}
