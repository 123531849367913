/* eslint-disable react/prop-types */
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { CircularProgress, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  loading: {
    display: "flex",
    flexDirection: "column",
    flexGrom: 1,
    height: "100vh",
    alignItems: "center",
    justifyContent: "center"
  },
  indicator: {
    textAlign: "center"
  }
}));

export default function Splash({ text, children }) {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <div className={classes.indicator}>
        {!text && !children && <Typography variant="h1">Loading</Typography>}
        {text && !children && <Typography variant="h1">{text}</Typography>}
        {children && children}
        <br></br>
        <CircularProgress />
      </div>
    </div>
  );
}
