/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";

export default function PlacementsTooltip({ newPlacementsByOwner, innerData }) {
  const placements = newPlacementsByOwner[innerData.ownerId].newPlacements;

  return (
    <Box>
      {placements.map((placement) => {
        return (
          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            justify="flex-start"
            mb={placements.length > 1 ? 1 : 0}
            key={placement.candidate.id}
          >
            <Typography
              style={{
                fontSize: 10,
                fontWeight: "bold"
              }}
            >{`${placement.jobOrder.title} (${placement.jobOrder.id})`}</Typography>
            <Typography
              style={{
                fontSize: 10
              }}
            >{`${placement.candidate.firstName} ${placement.candidate.lastName}`}</Typography>
          </Box>
        );
      })}
    </Box>
  );
}
