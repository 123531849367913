/* eslint-disable react/prop-types */
import React, { useMemo } from "react";

import { ResponsiveBar } from "@nivo/bar";
import size from "lodash/size";
import { Box, Typography, useTheme } from "@mui/material";

import {
  statusGroupCategories,
  // findStatusObject,
  submissionsByHighLevelGroup
} from "@/helpers/statuses";

// const getStatusColor = (status) => {
//   const statusMapObject = findStatusObject(status);
//   return statusMapObject.color;
// };

export default function SubmissionsStatusBar({
  filteredSubmissions,
  onBarClick,
  showLegend = true,
  height = 80,
  margin = { top: 32, right: 16, bottom: 16, left: 16 }
}) {
  const theme = useTheme();

  // const objectMap = (object, mapFn) => {
  //   return Object.keys(object).reduce(function (result, key) {
  //     result[key] = mapFn(object[key]);
  //     return result;
  //   }, {});
  // };

  const submissionsGrouped = useMemo(() => {
    if (size(filteredSubmissions)) return submissionsByHighLevelGroup(filteredSubmissions);
  }, [filteredSubmissions]);

  const barData = useMemo(() => {
    if (submissionsGrouped) {
      const dataObj = {
        group: "Reviewed",
        "Variner Pursuing": size(submissionsGrouped["Variner Pursuing"]),
        "Variner PursuingColor": statusGroupCategories["Variner Pursuing"].color,
        "In Process": size(submissionsGrouped["In Process"]),
        "In ProcessColor": statusGroupCategories["In Process"].color,
        Rejected: size(submissionsGrouped["Rejected"]),
        RejectedColor: statusGroupCategories["Rejected"].color
      };

      return [dataObj];
    }
  }, [submissionsGrouped]);

  if (!barData) return <></>;

  const legendStyles = {
    onClick: (key) => {
      onBarClick(key);
    },
    dataFrom: "keys",
    anchor: "top-left",
    direction: "row",
    justify: false,
    translateX: 0,
    translateY: -16,
    itemsSpacing: 2,
    itemWidth: 190,
    itemHeight: 20,
    itemDirection: "left-to-right",
    itemOpacity: 0.85,
    symbolSize: 10,
    effects: [
      {
        on: "hover",
        style: {
          itemOpacity: 1
        }
      }
    ]
  };

  return (
    <Box style={{ width: "100%", height }}>
      {(!!barData[0]["Variner Pursuing"] ||
        !!barData[0]["In Process"] ||
        !!barData[0]["Rejected"]) && (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              color: "black",
              fontSize: 14
            }}
            variant="body1"
            color="initial"
          >
            {barData[0]["Variner Pursuing"]}
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontSize: 14
            }}
            variant="body1"
            color="initial"
          >
            {barData[0]["In Process"]}
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontSize: 14
            }}
            variant="body1"
            color="initial"
          >
            {barData[0]["Rejected"]}
          </Typography>
        </Box>
      )}
      <ResponsiveBar
        innerPadding={0}
        theme={{
          grid: {
            line: {
              stroke: "transparent"
            }
          }
        }}
        data={barData}
        keys={["Variner Pursuing", "In Process", "Rejected"]}
        onClick={onBarClick}
        indexBy="group"
        tooltip={({ id, value }) => {
          return (
            <Box>
              <Typography
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  paddingBottom: theme.spacing(0.5)
                }}
              >
                {id}
              </Typography>

              <Box display="flex" alignItems="center">
                <Typography style={{ fontSize: 10 }}>{value}</Typography>
              </Box>
            </Box>
          );
        }}
        margin={margin}
        padding={0.3}
        layout="horizontal"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        // colors={{ scheme: "red_grey" }}
        colors={({ id, data }) => data[`${id}Color`]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        labelSkipWidth={7000} // skip at some crazy number to not show at all
        labelSkipHeight={1} // skip at some crazy number to not show at all
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        legends={showLegend ? [legendStyles] : []}
        animate={false}
        motionStiffness={90}
        motionDamping={15}
      />
    </Box>
  );
}
