/* eslint-disable react/prop-types */
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import _ from "lodash";

export default function CandidatesTooltip({ newCandidatesByOwner, innerData }) {
  const theme = useTheme();
  const candidatesBySource = _.groupBy(
    newCandidatesByOwner[innerData.ownerId].newCandidates,
    "source"
  );

  return (
    <Box>
      <Typography
        style={{
          fontSize: 10,
          fontWeight: "bold",
          paddingBottom: theme.spacing(0.5)
        }}
      >
        New Candidates by Source
      </Typography>
      {Object.keys(candidatesBySource).map((source) => {
        const count = candidatesBySource[source].length;
        return (
          <Box display="flex" alignItems="center" key={source}>
            <Typography
              style={{
                width: 25,
                fontSize: 10,
                fontWeight: "bold"
              }}
            >{`[${count}]`}</Typography>
            <Typography style={{ fontSize: 10 }}>{source}</Typography>
          </Box>
        );
      })}
    </Box>
  );
}
