import React, { useEffect } from "react";

// 3rd party libraries
import moment from "moment";
import sortBy from "lodash/sortBy";
import { useSelector, useDispatch } from "react-redux";
import { Box, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import "react-datepicker/dist/react-datepicker.css";

// components
import ActiveSearchesExpandedSection from "@/components/sections/ActiveSearchesExpandedSection";
import { constants as styleConstants } from "@/styles/config";
import { rangeSelectionMap } from "@/helpers/dateRanges";

const useStyles = makeStyles(() => ({
  container: {
    padding: styleConstants.desktopDrawerClosedWidth / 2,
    maxWidth: "100vw",
    minHeight: "100vh",
    // overflow: "hidden",
    backgroundColor: "white"
  },
  content: {
    paddingLeft: styleConstants.desktopDrawerClosedWidth
  },

  pageTitle: {
    marginLeft: styleConstants.desktopDrawerClosedWidth
  },
  titleText: { fontSize: 24, fontWeight: "bold" }
}));

export default function ActiveSearchesView() {
  // ↓ styling ↓
  // const theme = useTheme();
  const classes = useStyles();

  // ↓ redux ↓
  const dispatch = useDispatch();
  const { allSearches } = useSelector((state) => state.searches);

  const { dateRange, rangeSelection, customDateRange } = useSelector((state) => state.app);

  // ↓ state ↓
  // const [pickerDates, setPickerDates] = useState(dateRange);

  // ↓ effects ↓
  useEffect(() => {
    dispatch.jobs.setCurrentJob(null);
  }, [dispatch]);

  // ↓ computed ↓

  // ↓ callbacks ↓

  const handleDateRangeSelectionChange = (newValue, customRange) => {
    if (newValue !== rangeSelection) dispatch.app.setRangeSelection(newValue);

    if (newValue === "lifetime") {
      const searchesSortedByDate = allSearches && sortBy(allSearches, "dateAdded");

      const newDateRange = {
        ...rangeSelectionMap[rangeSelection],
        startDate: moment(searchesSortedByDate[0].dateAdded),
        endDate: moment()
      };

      dispatch.app.setDateRange(newDateRange);
      // setPickerDates(newDateRange);
    } else if (newValue === "custom") {
      dispatch.app.setDateRange(customRange);
      // setPickerDates(customRange);
    } else {
      const newDateRange = rangeSelectionMap[newValue];

      dispatch.app.setDateRange(newDateRange);
      // setPickerDates(newDateRange);
    }
  };

  // ↓ renders ↓

  return (
    <Container className={classes.container} maxWidth={false}>
      <Box className={classes.pageTitle}>
        <Typography className={classes.titleText}>Job Search Progress</Typography>
        <Typography variant="subtitle2">
          Showing submissions for {rangeSelectionMap[rangeSelection]?.title}
        </Typography>
      </Box>
      <Box className={classes.content}>
        <ActiveSearchesExpandedSection
          handleDateRangeSelectionChange={handleDateRangeSelectionChange}
          rangeSelectionMap={rangeSelectionMap}
          dateRange={dateRange}
          rangeSelection={rangeSelection}
          setRangeSelection={(payload) => dispatch.app.setRangeSelection(payload)}
          customDateRange={customDateRange}
          setCustomDateRange={(payload) => dispatch.app.setCustomDateRange(payload)}
        />
      </Box>
    </Container>
  );
}
