const prettyName = (user) => {
  return `${user?.firstName} ${user?.lastName}`;
};

const prettyNameTruncated = (user) => {
  return `${user?.firstName} ${user?.lastName.charAt(0)}.`;
};

const truncatedLastName = (nameString) => {
  const lastNameLetter = nameString.split(" ").pop().charAt(0);

  return `${nameString.split(" ")[0]} ${lastNameLetter}.`;
};

const prettyInitials = (user) => {
  return `${user?.firstName.charAt(0).toUpperCase()}${user?.lastName.charAt(0).toUpperCase()}`;
};

const nameToId = (name, users) => {
  return users.find((user) => {
    return prettyName(user) === name;
  });
};

const bhUserContactMap = {
  1: null,
  3: "nick@variner.com",
  2: "derek@variner.com",
  8068: "casey@variner.com",
  9491: "marymargaret@variner.com",
  9572: "katherine@variner.com",
  18210: "andrew@variner.com",
  19902: "gabby@variner.com",
  19374: "ryan@variner.com"
};

const superAccess = (user) => {
  return user.occupation === "Partner" || user.id === 16 || user.username === "15379.unassigned";
};

const userQuotasMap = {
  activeCandidateAdds: {
    accessor: "customText6",
    label: "Active Candidate Adds"
  },
  prescreenCalls: {
    accessor: "customText7",
    label: "Prescreen Calls"
  },
  pendingInterest: {
    accessor: "customText8",
    label: "Pending Interest"
  },
  confirmedInterest: {
    accessor: "customText9",
    label: "Confirmed Interest"
  },
  clientSubmission: {
    accessor: "customText10",
    label: "Client Submissions"
  },
  interviews: {
    accessor: "customText11",
    label: "Interviews"
  },
  callTime: {
    accessor: "customText12",
    label: "Candidate Call Time"
  }
};

export {
  prettyName,
  prettyNameTruncated,
  prettyInitials,
  nameToId,
  bhUserContactMap,
  truncatedLastName,
  superAccess,
  userQuotasMap
};
