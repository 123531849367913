import _ from "lodash";
import { getCallTime, convertNumToTime } from "@/helpers/callTime";
import { prettyName } from "@/helpers/user";
import { searchTypeMap } from "./search";

export default function makeCompactSearchesTableData({
  searches,
  searchesSubmissions,
  searchesCalls,
  currentOwner
}) {
  return searches
    ?.filter((s) => {
      return _.get(s, ["type"]);
    })
    .map((search) => {
      if (!search) return null;

      const { assignedUsers, id, owner, title, status, clientCorporation } = search;

      const submissions = searchesSubmissions[id] || [];

      const calls = (searchesCalls && searchesCalls[id]) || [];
      const callTime = getCallTime(calls, "hrs");
      const converted = convertNumToTime(callTime);

      return {
        id,
        search: `${title} (${id})`,
        clientCorporationName: clientCorporation?.name,
        status,
        priority: (_.get(search, ["type"]) && searchTypeMap[_.get(search, ["type"])]) || "Unknown",
        submissions,
        callTime: converted,
        // callTime: "TBD",
        accountManager: prettyName(owner),
        team: _.uniqBy([..._.sortBy(assignedUsers.data, "id")], "id").map((u) => {
          u.active = currentOwner ? currentOwner.id === u.id : true;

          return u;
        })
      };
    });
}
