import moment from "moment";

export const rangeSelectionMap = {
  ytd: {
    title: "Year to Date",
    startDate: moment().clone().startOf("year"),
    endDate: moment().clone().endOf("year")
  },
  lastMonth: {
    title: "Last Month",
    startDate: moment().subtract(1, "months").startOf("month"),
    endDate: moment().subtract(1, "months").endOf("month"),
    timespanTitle: "Month",
    timespan: "months"
  },
  thisMonth: {
    title: "This Month",
    startDate: moment().clone().startOf("month"),
    endDate: moment().clone().endOf("month"),
    timespanTitle: "Month",
    timespan: "months"
  },
  lastWeek: {
    title: "Last Week",
    startDate: moment().subtract(1, "weeks").startOf("isoWeek"),
    endDate: moment().subtract(1, "weeks").endOf("isoWeek"),
    timespanTitle: "Week",
    timespan: "weeks"
  },
  thisWeek: {
    title: "This Week",
    startDate: moment().clone().startOf("isoWeek"),
    endDate: moment().clone().endOf("isoWeek"),
    timespanTitle: "Week",
    timespan: "weeks"
  },
  today: {
    title: "Today",
    startDate: moment(),
    endDate: moment(),
    timespanTitle: "Day",
    timespan: "days"
  },
  Q1: {
    title: "1st Quarter",
    startDate: moment().month(0).startOf("month"),
    endDate: moment().month(2).endOf("month"),
    timespanTitle: "Quarter",
    timespan: "quarters"
  },
  Q2: {
    title: "2nd Quarter",
    startDate: moment().month(3).startOf("month"),
    endDate: moment().month(5).endOf("month"),
    timespanTitle: "Quarter",
    timespan: "quarters"
  },
  Q3: {
    title: "3rd Quarter",
    startDate: moment().month(6).startOf("month"),
    endDate: moment().month(8).endOf("month"),
    timespanTitle: "Quarter",
    timespan: "quarters"
  },
  Q4: {
    title: "4th Quarter",
    startDate: moment().month(9).startOf("month"),
    endDate: moment().month(11).endOf("month"),
    timespanTitle: "Quarter",
    timespan: "quarters"
  },
  lifetime: {
    title: "Since Launch"
  },
  custom: {
    title: "Custom"
  }
  // H1: {
  //     startDate: moment().month(0).startOf("month"),
  //     endDate: moment().month(5).endOf("month"),
  // },
  // H2: {
  //     startDate: moment().month(6).startOf("month"),
  //     endDate: moment().month(11).endOf("month"),
  // },
};
