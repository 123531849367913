import colors from "./colors";

const constants = {
  desktopDrawerClosedWidth: 64,
  desktopDrawerWidth: 336,
  defaultMobilePadding: 40,
  desktopTopBarHeight: 90,
  mobileTopBarHeight: 82,
  closedJobBarHeight: 64,
  colorsForData: {
    "Nick Medica": "#fba92d",
    "Derek Clemmensen": "#fdb5c2",
    "Katherine Crosby": "#35ab7c",
    "Casey Condon": "#3e6bad",
    "Mary Margaret Hambuchen": "#B2DDE4",
    "Webdeveloper API": "#F1F1E6",
    "Angelica Montano": colors.gray43,
    "Unassigned User": colors.gray2,
    "Andrew Wagner": "#35ab7c",
    "Gabby Smith": "#92DCE5",
    "Ryan Vierhile": "#8885BF"
  }
};

export default constants;
