/* eslint-disable react/prop-types */
import React, { useMemo } from "react";

import { ResponsiveBar } from "@nivo/bar";
import _ from "lodash";
import { Box, Typography, useTheme } from "@mui/material";

import { findStatusObject } from "../../helpers/statuses";

const getStatusColor = (status) => {
  const statusMapObject = findStatusObject(status);
  return statusMapObject.color;
};

export default function ReviewedSubmissionsStatusBar({
  filteredSubmissions,
  onBarClick,
  showLegend = true,
  height = 80,
  margin = { top: 32, right: 16, bottom: 16, left: 16 }
}) {
  const theme = useTheme();

  const objectMap = (object, mapFn) => {
    return Object.keys(object).reduce(function (result, key) {
      result[key] = mapFn(object[key]);
      return result;
    }, {});
  };

  const data = useMemo(() => {
    const submissionsByStatus = _.groupBy(filteredSubmissions, "status");
    let dataObj = {
      group: "Reviewed",
      ...objectMap(submissionsByStatus, function (value) {
        return value.length;
      })
    };
    Object.keys(dataObj).forEach((statusKey) => {
      if (statusKey !== "group") {
        dataObj[statusKey + "Color"] = getStatusColor(statusKey);
      }
    });

    return [dataObj];
  }, [filteredSubmissions]);

  const legendStyles = {
    onClick: (key) => {
      onBarClick(key);
    },
    dataFrom: "keys",
    anchor: "top-left",
    direction: "row",
    justify: false,
    translateX: 0,
    translateY: -16,
    itemsSpacing: 2,
    itemWidth: 190,
    itemHeight: 20,
    itemDirection: "left-to-right",
    itemOpacity: 0.85,
    symbolSize: 10,
    effects: [
      {
        on: "hover",
        style: {
          itemOpacity: 1
        }
      }
    ]
  };

  return (
    <Box style={{ width: "100%", height }}>
      <ResponsiveBar
        innerPadding={1}
        theme={{
          grid: {
            line: {
              stroke: "transparent"
            }
          }
        }}
        data={data}
        keys={Object.keys(_.groupBy(filteredSubmissions, "status"))}
        onClick={onBarClick}
        indexBy="group"
        tooltip={({ id, value }) => {
          return (
            <Box>
              <Typography
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  paddingBottom: theme.spacing(0.5)
                }}
              >
                {id}
              </Typography>

              <Box display="flex" alignItems="center">
                <Typography style={{ fontSize: 10 }}>{value}</Typography>
              </Box>
            </Box>
          );
        }}
        margin={margin}
        padding={0.3}
        layout="horizontal"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        // colors={{ scheme: "red_grey" }}
        colors={({ id, data }) => data[`${id}Color`]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        labelSkipWidth={5}
        labelSkipHeight={5}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        legends={showLegend ? [legendStyles] : []}
        animate={false}
        motionStiffness={90}
        motionDamping={15}
      />
    </Box>
  );
}
