/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import mountains from "../assets/mountains-c.jpg";
import logoNoBg from "../assets/logo-no-bg.png";
import { colors } from "../styles/config";

const useStyles = makeStyles((theme) => ({
  rightColumnContainer: {
    backgroundColor: "rgba(27, 68, 74, 0.75)",
    width: "40vw",
    height: "100vh",
    position: "relative"
  },
  leftContentContainer: {
    height: "100vh",
    overflowY: "scroll",
    boxSizing: "border-box"
  },
  logoContainer: {
    marginTop: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  viewHeightCenteredBox: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  logo: {
    marginBottom: 0
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: colors.darkGray
  },
  welcomeText: {
    alignSelf: "flex-start"
  },
  jobNameText: {
    color: colors.gray43,
    alignSelf: "flex-start"
    // fontWeight: "600",
    // fontSize: 12,
  },
  mainText: {
    marginTop: theme.spacing(3),
    alignSelf: "flex-start",
    lineHeight: 1.3
  },
  smallDisclaimerText: {
    color: colors.gray43,
    marginTop: theme.spacing(2),
    alignSelf: "flex-start",
    fontSize: 12
  },
  bgImg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: "-1",
    top: 0,
    left: 0
  }
}));

export default function FullPageForm({ children }) {
  const classes = useStyles();
  const { isMobile } = useSelector((state) => state.app);
  // function useQuery() {
  //   return new URLSearchParams(useLocation().search);
  // }
  // const query = useQuery();

  return (
    <Box
      display="flex"
      flexDirection="row"
      style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box
          style={{
            alignSelf: "flex-start",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 2,
            boxSizing: "border-box",
            paddingTop: isMobile ? 20 : 40,
            paddingBottom: isMobile ? 20 : 40,
            paddingLeft: isMobile ? 40 : 140,
            backgroundColor: colors.white,
            width: isMobile ? "100%" : "65vw",
            boxShadow: isMobile ? "-1px 1px 4px 0px rgba(0, 0, 0, 0.15)" : "none"
          }}
        >
          <img className={classes.logo} src={logoNoBg} style={{ maxHeight: 25 }} alt="logo" />
        </Box>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          className={classes.leftContentContainer}
          style={{
            paddingLeft: isMobile ? 40 : 200,
            paddingRight: isMobile ? 40 : 350,
            width: isMobile ? "100vw" : "65vw"
          }}
        >
          {children}
        </Box>
      </Box>
      {!isMobile && (
        <Box className={classes.rightColumnContainer}>
          <Box className={classes.bgImg}>
            <img
              src={mountains}
              alt="mountains"
              style={{
                height: "100%",
                width: "auto",
                minHeight: "100%"
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
