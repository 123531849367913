/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";

import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LaunchIcon from "@mui/icons-material/Launch";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, IconButton, Link, Popover, TablePagination, Typography } from "@mui/material";

import { useGlobalFilter, usePagination, useSortBy, useTable, useFilters } from "react-table";

import { colors } from "@/styles/config";

const useStyles = makeStyles(() => ({
  table: {
    backgroundColor: "white"
  },
  currentRow: {
    backgroundColor: "rgba(26, 68, 74, 0.1)",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px"
  },
  currentRowCell: {
    fontWeight: "bold"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  headerRow: {
    boxShadow: "0px 10px 15px -14px grey"
  },
  submissionTableLink: {
    cursor: "pointer",
    fontSize: 14,
    textDecoration: "none"
  }
}));

const DefaultCell = ({ value }) => {
  return <Typography variant="subtitle2">{value}</Typography>;
};

// Set our default cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: DefaultCell
};

const ActiveSubmissionsSlideOutTable = ({
  submissions,
  skipPageReset,
  data,
  onSubmissionNameClick,
  onSubmissionJobClick
}) => {
  const theme = useTheme();

  const sortTypes = useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId, desc) {
        if (!rowA.values[columnId] && !rowB.values[columnId]) {
          return 0;
        }

        if (!rowA.values[columnId]) {
          return desc ? -1 : 1;
        }

        if (!rowB.values[columnId]) {
          return desc ? 1 : -1;
        }

        return rowA.values[columnId].localeCompare(rowB.values[columnId]);
      }
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        width: "30%",
        Cell: ({ row }) => {
          const value = _.startCase(row.values?.name?.toLowerCase());

          return (
            <Link
              color={colors.varinerBlue}
              className={classes.submissionTableLink}
              onClick={(event) => {
                onSubmissionNameClick(row.original, event);
              }}
            >
              {value}
            </Link>
          );
        }
      },
      {
        Header: "Search",
        accessor: "search",
        width: "50%",
        Cell: ({ row }) => {
          const value = row.values?.search;

          return (
            <Link
              color={colors.varinerBlue}
              className={classes.submissionTableLink}
              onClick={() => {
                onSubmissionJobClick(row.original.jobId);
              }}
            >
              {value}
            </Link>
          );
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: "20%",
        Cell: ({ row }) => {
          const value = row.values.status;

          return <Typography sx={{ fontSize: 14 }}>{value}</Typography>;
        }
      }
    ],
    [submissions]
  );

  const useOverflowMenu = (hooks) => {
    hooks.visibleColumns.unshift((columns) => {
      return [
        ...columns,
        {
          id: "_overflowMenu",
          // Make this column a groupByBoundary. This ensures that groupBy
          // columns are placed after it
          groupByBoundary: true,
          Cell: ({ row }) => {
            const [open, setOpen] = useState(false);
            const popoverAnchorRef = useRef();

            return (
              <React.Fragment>
                <IconButton
                  ref={popoverAnchorRef}
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpen(true);
                  }}
                >
                  <MoreVertIcon
                    size="small"
                    style={{
                      width: 24,
                      height: 24,
                      padding: theme.spacing(0.5),
                      color: colors.darkGray
                    }}
                  />
                </IconButton>
                <Popover
                  open={open}
                  onClose={() => setOpen(false)}
                  anchorEl={popoverAnchorRef.current}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection="column"
                    p={1}
                  >
                    <Button
                      component="a"
                      color="primary"
                      endIcon={<LaunchIcon />}
                      disabled={!row.original.candidateId}
                      target="_blank"
                      href={`https://cls31.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=${row.original.candidateId}`}
                    >
                      Bullhorn
                    </Button>
                    <Button
                      color="primary"
                      endIcon={<LinkedInIcon />}
                      target="_blank"
                      disabled={!row.original.linkedinUrl}
                      href={row.original.linkedinUrl}
                    >
                      LinkedIn
                    </Button>
                  </Box>
                </Popover>
              </React.Fragment>
            );
          },
          width: 50,
          canSort: false
        }
      ];
    });
  };

  const hooks = [useFilters, useGlobalFilter, useSortBy, usePagination, useOverflowMenu];

  const instance = useTable(
    {
      columns,
      data,
      sortTypes,
      defaultColumn,
      autoResetPage: !skipPageReset,
      autoResetFilters: false,
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      initialState: {
        pageSize: 20
      }
    },
    ...hooks
  );

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    filteredFlatRows,
    visibleColumns,
    state: { pageIndex, pageSize }
  } = instance;

  const classes = useStyles();

  useEffect(() => {
    setPageSize(20);
  }, [setPageSize]);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  // Render the UI for your table
  return (
    <TableContainer>
      <MaUTable stickyHeader {...getTableProps()}>
        <TableHead className={classes.headerRow}>
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <TableRow key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, headerIndex) => (
                <TableCell
                  key={`${headerGroupIndex}-${headerIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  size="small"
                  active={column.isSorted ? "true" : "false"}
                  align="left"
                  style={{
                    width: column.width,
                    borderTop: `2px solid ${colors.gray1}`
                  }}
                  py={2}
                  px={3}
                >
                  {/* {column.render("Header")} */}
                  {column.id.charAt(0) !== "_" ? (
                    <TableSortLabel
                      IconComponent={KeyboardArrowDownOutlinedIcon}
                      size="small"
                      direction={column.isSortedDesc ? "desc" : "asc"}
                      active={column.isSorted}
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        <>
                          {column.isSortedDesc ? (
                            <KeyboardArrowDownOutlinedIcon size="small" />
                          ) : (
                            <KeyboardArrowUpOutlinedIcon size="small" />
                          )}
                        </>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    column.render("Header")
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow key={i} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <TableCell
                      key={`${i}-${index}`}
                      {...cell.getCellProps()}
                      size="small"
                      style={{
                        width: cell.column.width,
                        paddingTop: theme.spacing(2),
                        paddingBottom: theme.spacing(2),
                        verticalAlign: "top"
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            {data && data.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[20, 50, 100, { label: "All", value: data.length }]}
                style={{ borderBottom: "none" }}
                colSpan={visibleColumns ? visibleColumns.length : 0}
                count={filteredFlatRows ? filteredFlatRows.length : 0}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
              />
            )}
          </TableRow>
        </TableFooter>
      </MaUTable>
    </TableContainer>
  );
};

export default ActiveSubmissionsSlideOutTable;
