/* eslint-disable react/prop-types */
import React, { useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Drawer, Typography, IconButton, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";

import ActiveSubmissionsSlideOutTable from "@/components/tables/ActiveSubmissionsSlideOutTable";
import makeSubmissionsData from "@/components/tables/makeSubmissionsData";
import CandidateDrawer from "@/components/CandidateDrawer";
import { colors } from "@/styles/config";

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    backgroundColor: colors.darkGray,
    display: "flex",
    justifyContent: "space-between"
  },
  drawerTabContent: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    maxWidth: 900,
    position: "relative"
  }
}));

export default function SubmissionsTableDrawer({
  open,
  toggleDrawer,
  componentTitle,
  style = {},
  titleStyle = { fontWeight: 600, fontSize: 20 },
  id
}) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const classes = useStyles();

  const { activeSubmissions } = useSelector((state) => state.submissions);

  const [candidateDrawer, setCandidateDrawer] = useState({
    open: false
  });

  const submissionData = useMemo(() => {
    if (open.data) {
      return makeSubmissionsData(open.data);
    }
  }, [open.data]);

  const toggleSubmissionDrawer = useCallback(
    ({ open, submission, component }, event) => {
      if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
        return;
      }

      if (submission) {
        dispatch.jobs.setCurrentSubmission(submission);

        setCandidateDrawer({
          open,
          component: <CandidateDrawer />
        });
      } else {
        setCandidateDrawer({
          open,
          component
        });
      }
    },
    [id, dispatch.jobs]
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="right"
          open={open.open}
          onClose={() => toggleDrawer({ open: false, type: null })}
        >
          <Box sx={{ ...style, width: 775 }} role="presentation">
            <Box className={classes.drawerHeader}>
              <Box>
                <Typography variant="h5" color={colors.white}>
                  {componentTitle}
                </Typography>
                <Typography
                  sx={{ ...titleStyle, marginTop: theme.spacing(1) }}
                  variant="h2"
                  color={colors.white}
                >
                  {open.type}
                </Typography>
              </Box>

              <IconButton
                sx={{ color: colors.white }}
                aria-label="Close"
                onClick={() => toggleDrawer({ open: false, type: null })}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.drawerTabContent}>
              {submissionData && (
                <ActiveSubmissionsSlideOutTable
                  skipPageReset={false}
                  data={submissionData}
                  onSubmissionNameClick={(submissionTableData, event) => {
                    const submissionToUse = activeSubmissions?.find(
                      (singleSubmission) => singleSubmission.id === submissionTableData?.id
                    );

                    toggleSubmissionDrawer(
                      {
                        open: true,
                        submission: submissionToUse
                      },
                      event
                    );
                  }}
                  onSubmissionJobClick={(jobId) => window.open(`/variner/job/${jobId}`, "_blank")}
                  onOpenClick={(submissionTableData, event) => {
                    const submissionToUse = activeSubmissions?.find(
                      (singleSubmission) => singleSubmission.id === submissionTableData?.id
                    );

                    toggleSubmissionDrawer(
                      {
                        open: true,
                        submission: submissionToUse
                      },
                      event
                    );
                  }}
                />
              )}
            </Box>
          </Box>
        </Drawer>

        <Drawer
          anchor="right"
          open={candidateDrawer.open}
          onClose={(event) => toggleSubmissionDrawer({ open: false }, event)}
        >
          {candidateDrawer.component && candidateDrawer.component}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
