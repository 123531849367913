/* eslint-disable react/prop-types */
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import _ from "lodash";

import { convertNumToTime } from "../../../helpers/callTime";

export default function CallTimeTooltip({ newNotesByOwner, innerData }) {
  const theme = useTheme();
  const ignoreTypes = ["I/B", "O/B"];

  const newNotesByAction = _.groupBy(
    newNotesByOwner[innerData.ownerId].notes.filter(
      (note) => note.minutesSpent && ignoreTypes.indexOf(note.action) < 0
    ),
    "action"
  );

  return (
    <Box>
      <Typography
        style={{
          fontSize: 10,
          fontWeight: "bold",
          paddingBottom: theme.spacing(0.5)
        }}
      >
        Time Spent per Call Type
      </Typography>
      {Object.keys(newNotesByAction).map((action) => {
        const count = newNotesByAction[action].reduce((acc, curr) => {
          return acc + parseInt(curr.minutesSpent);
        }, 0);

        return (
          <Box display="flex" alignItems="center" key={action}>
            <Typography
              style={{
                width: 35,
                fontSize: 10,
                fontWeight: "bold"
              }}
            >{`[${convertNumToTime(Math.ceil((count / 60) * 10) / 10)}]`}</Typography>
            <Typography style={{ fontSize: 10 }}>{action}</Typography>
          </Box>
        );
      })}
    </Box>
  );
}
