import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";

// styles
import { colors } from "@/styles/config";

const useStyles = makeStyles(() => ({
  notFoundContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrom: 1,
    height: "100vh",
    alignItems: "center",
    justifyContent: "center"
  },
  notFoundContent: {
    textAlign: "center"
  }
}));

export default function NotFound() {
  const classes = useStyles();

  return (
    <div className={classes.notFoundContainer}>
      <div className={classes.notFoundContent}>
        <Typography variant="h1">404</Typography>
        <Typography variant="h1" style={{ marginTop: 24 }}>
          Sorry, we couldn`t find that page!
        </Typography>
        <Typography variant="body1" style={{ color: colors.gray43, marginTop: 8 }}>
          Please double check the URL entered. Thank you.
        </Typography>
        <br></br>
      </div>
    </div>
  );
}
