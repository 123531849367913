import React, { useEffect, useState, useMemo, useCallback } from "react";

// 3rd party libraroes
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import moment from "moment";

// components
import DashboardModuleCard from "@/components/DashboardModuleCard";
import AtAGlanceCard from "@/components/AtAGlanceCard";
import DateSelect from "@/components/tables/filters/DateSelect";

// helpers
import { getCallTime, convertNumToTime } from "@/helpers/callTime";

import {
  getCandidatesByOwner,
  getNotesByOwner,
  getSubmissionsByOwner,
  getPlacementsByOwner
} from "@/helpers/byRecruiter";

// style things
import { constants as styleConstants } from "@/styles/config";
import ByRecruiterStats from "@/components/reports/ByRecruiterStats";
import { rangeSelectionMap } from "@/helpers/dateRanges";
import { statuses } from "@/helpers/statuses";
import { submissionsWithStatusInDateRange } from "@/helpers/quotas";

const useStyles = makeStyles(() => ({
  container: {
    // margin: styleConstants.desktopDrawerClosedWidth / 2,
    // minWidth: "100%",
    // padding: theme.spacing(2),
    padding: styleConstants.desktopDrawerClosedWidth / 2,
    maxWidth: "100vw",
    minHeight: "100vh"
  },

  content: {
    marginTop: 20,
    maxWidth: "95vw",
    marginLeft: styleConstants.desktopDrawerClosedWidth
  },

  pageTitle: {
    marginLeft: styleConstants.desktopDrawerClosedWidth
  },
  titleText: { fontSize: 24, fontWeight: "bold" }
}));

export default function InternalReportingView() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const { loading: jobsLoading } = useSelector((state) => state.jobs);

  const {
    newCandidates,
    newSubmissions,
    newPlacements,
    newNotes,
    comparisonCandidates,
    comparisonSubmissions,
    comparisonPlacements,
    comparisonNotes,
    loading: reportingLoading
  } = useSelector((state) => state.reporting);

  const classes = useStyles();

  const [newNotesByOwnerId, setNewNotesByOwnerId] = useState([]);
  const [newCandidatesByOwnerId, setNewCandidatesByOwnerId] = useState([]);
  const [newPlacementsByOwnerId, setNewPlacementsByOwnerId] = useState([]);
  const [newSubmissionsByOwnerId, setNewSubmissionsByOwnerId] = useState([]);

  const [comparisonNotesByOwnerId, setComparisonNotesByOwnerId] = useState([]);
  const [comparisonCandidatesByOwnerId, setComparisonCandidatesByOwnerId] = useState([]);
  const [comparisonPlacementsByOwnerId, setComparisonPlacementsByOwnerId] = useState([]);
  const [comparisonSubmissionsByOwnerId, setComparisonSubmissionsByOwnerId] = useState([]);

  const { dateRange, rangeSelection, customDateRange } = useSelector((state) => state.app);

  // const [pendingInterestByOwnerId, setPendingInterestByOwnerId] = useState([]);
  // const [confirmedInterestByOwnerId, setConfirmedInterestByOwnerId] =
  //   useState(0);

  // TODO: zero states
  // components won't render if current user does not have any data to display

  const fetchReportingData = useCallback(() => {
    const comparisonStartDate = moment(dateRange.startDate).subtract(
      1,
      rangeSelectionMap[rangeSelection].timespan
    );

    const comparisonEndDate = moment(dateRange.endDate).subtract(
      1,
      rangeSelectionMap[rangeSelection].timespan
    );

    const comparisonDateRangePayload = {
      startDate: `${moment(comparisonStartDate).startOf("day").format("YYYYMMDD")}000000`,
      endDate: moment(comparisonEndDate).endOf("day").format("YYYYMMDDkkmmss")
    };

    const dateRangePayload = {
      startDate: `${moment(dateRange.startDate).startOf("day").format("YYYYMMDD")}000000`,
      endDate: moment(dateRange.endDate).endOf("day").format("YYYYMMDDkkmmss")
    };

    dispatch.reporting
      .fetchComparisonReportingData({
        ...comparisonDateRangePayload
        // queryStr: ` AND NOT commentingPerson:2021 AND isDeleted:false`,
      })
      .catch(() => {});

    dispatch.reporting
      .bootstrapReporting({
        ...dateRangePayload,
        notes: true,
        candidates: true,
        placements: true
        // queryStr: ` AND NOT commentingPerson:2021 AND isDeleted:false`,
      })
      .catch(() => {});
  }, [rangeSelection, dispatch]);

  // Fetch reporting data on every reporting page load AND
  // Fetch reporting data on every range selection change
  useEffect(() => {
    // console.log("FETCHING REPORTING DATA");
    if (dateRange && rangeSelection) fetchReportingData();
  }, [dateRange, rangeSelection]);

  const currentOwner = useMemo(() => {
    return {
      id: user.id,
      name: `${user.firstName} ${user.lastName}`
    };
  }, [user]);

  const loading = useMemo(() => {
    return jobsLoading || reportingLoading;
  }, [jobsLoading, reportingLoading]);

  const handleDateRangeSelectionChange = useCallback(
    (newValue, customRange) => {
      if (newValue !== rangeSelection) dispatch.app.setRangeSelection(newValue);

      if (newValue === "custom") {
        dispatch.app.setDateRange(customRange);
        // setPickerDates(customRange);
      } else {
        const newDateRange = rangeSelectionMap[newValue];

        dispatch.app.setDateRange(newDateRange);
        // setPickerDates(newDateRange);
      }
    },
    [rangeSelection]
  );

  // const userActiveJobs = useMemo(() => {
  //   return _.chain(activeJobs)
  //     .filter((job) => {
  //       const userIsOwner = job.owner?.id === user?.id;
  //       const userIsAssignedUser = job.assignedUsers?.data?.find(
  //         (assignedUser) => assignedUser.id === user.id
  //       )
  //         ? true
  //         : false;

  //       return userIsOwner || userIsAssignedUser;
  //     })
  //     .sortBy("id")
  //     .reverse()
  //     .value();
  // }, [user, activeJobs]);

  const timespanComparisonText = useMemo(() => {
    return rangeSelection === "custom" || rangeSelection === "ytd"
      ? null
      : `${rangeSelectionMap[rangeSelection].timespanTitle} over ${rangeSelectionMap[rangeSelection].timespanTitle}`;
  }, [rangeSelection]);

  useEffect(() => {
    if (currentOwner.id) {
      if (newPlacements) {
        const newPlacementsByOwner = getPlacementsByOwner(newPlacements);

        if (newPlacementsByOwner) {
          setNewPlacementsByOwnerId(newPlacementsByOwner[currentOwner.id]);
        }
      }

      if (comparisonPlacements) {
        const comparisonPlacementsByOwner = getPlacementsByOwner(comparisonPlacements);

        if (comparisonPlacementsByOwner) {
          setComparisonPlacementsByOwnerId(comparisonPlacementsByOwner[currentOwner.id]);
        }
      }
    }
  }, [newPlacements, currentOwner]);

  useEffect(() => {
    if (newNotes && currentOwner.id) {
      const newNotesByOwner = getNotesByOwner(newNotes);
      setNewNotesByOwnerId(newNotesByOwner[currentOwner.id]);
    }

    if (comparisonNotes && currentOwner.id) {
      const comparisonNotesByOwner = getNotesByOwner(comparisonNotes);
      setComparisonNotesByOwnerId(comparisonNotesByOwner[currentOwner.id]);
    }
  }, [newNotes, currentOwner]);

  useEffect(() => {
    if (currentOwner) {
      if (newCandidates) {
        const newCandidatesByOwner = getCandidatesByOwner(newCandidates);

        if (newCandidatesByOwner[currentOwner.id]) {
          setNewCandidatesByOwnerId(newCandidatesByOwner[currentOwner.id].newCandidates);
        }
      }

      if (comparisonCandidates) {
        const comparisonCandidatesByOwner = getCandidatesByOwner(comparisonCandidates);

        if (comparisonCandidatesByOwner[currentOwner.id]) {
          setComparisonCandidatesByOwnerId(
            comparisonCandidatesByOwner[currentOwner.id].newCandidates
          );
        }
      }
    }
  }, [newCandidates, currentOwner]);

  // useEffect(() => {
  //   if (newNotes && currentOwner) {
  //     const pendingInterest = getSpecificNotesByOwner(
  //       newNotes,
  //       "Pending Interest"
  //     );

  //     setPendingInterestByOwnerId(
  //       pendingInterest[currentOwner.id]
  //         ? pendingInterest[currentOwner.id].newPrescreens
  //         : 0
  //     );
  //   }
  // }, [newNotes, currentOwner]);

  // useEffect(() => {
  //   if (currentOwner) {
  //     if (newNotes) {
  //       const confirmedInterest = getSpecificNotesByOwner(
  //         newNotes,
  //         "Confirmed Interest"
  //       );

  //       if (confirmedInterest[currentOwner.id]) {
  //         setConfirmedInterestByOwnerId(
  //           confirmedInterest[currentOwner.id].newPrescreens
  //         );
  //       }
  //     }
  //   }
  // }, [newNotes, currentOwner]);

  useEffect(() => {
    if (currentOwner.id) {
      if (newSubmissions) {
        const newSubmissionsByOwner = getSubmissionsByOwner(newSubmissions);

        const submissionsWithStatusHistoryInDateRange = submissionsWithStatusInDateRange({
          submissions: newSubmissionsByOwner[currentOwner.id]?.submissions,
          dateRange,
          statusString: statuses.CLIENT_SUBMISSION.bhLabel
        });

        setNewSubmissionsByOwnerId(submissionsWithStatusHistoryInDateRange);
      }

      if (comparisonSubmissions) {
        const comparisonSubmissionsByOwner = getSubmissionsByOwner(comparisonSubmissions);

        const comparisonSubmissionsWithStatusHistoryInDateRange = submissionsWithStatusInDateRange({
          submissions: comparisonSubmissionsByOwner[currentOwner.id]?.submissions,
          dateRange,
          statusString: statuses.CLIENT_SUBMISSION.bhLabel
        });

        setComparisonSubmissionsByOwnerId(comparisonSubmissionsWithStatusHistoryInDateRange);
      }
    }
  }, [newSubmissions, currentOwner.id, dateRange]);

  const newNotesByContactType = useMemo(() => {
    if (newNotesByOwnerId?.notes) {
      const candidateNotes = newNotesByOwnerId.notes.filter((note) => {
        return note.personReference.subtype === "Candidate";
      });
      const clientNotes = newNotesByOwnerId.notes.filter((note) => {
        return note.personReference.subtype === "ClientContact";
      });

      return {
        Candidate: candidateNotes,
        ClientContact: clientNotes
      };
    }
  }, [newNotesByOwnerId]);

  const comparisonNotesByContactType = useMemo(() => {
    if (comparisonNotesByOwnerId?.notes) {
      const candidateNotes = comparisonNotesByOwnerId.notes.filter((note) => {
        return note.personReference.subtype === "Candidate";
      });

      const clientNotes = comparisonNotesByOwnerId.notes.filter((note) => {
        return note.personReference.subtype === "ClientContact";
      });

      return {
        Candidate: candidateNotes,
        ClientContact: clientNotes
      };
    }
  }, [comparisonNotesByOwnerId]);

  return (
    <Container className={classes.container} maxWidth={false}>
      <Box className={classes.pageTitle}>
        <Typography className={classes.titleText}>Reporting Dashboard</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
        className={classes.content}
      >
        <Box sx={{ display: "flex" }}>
          {/* <FilterDropdown
            dataLength={_.size(activeJobs)}
            label="Jobs"
            options={userActiveJobs.map((job) => `(${job.id}) ${job.title}`)}
            handleSetSelectedStatuses={(selected) =>
              console.log("handleSetSelectedJobs:", selected)
            }
          />
          <FilterDropdown
            dataLength="1"
            label="Sub Owner"
            options={[currentOwner.name]}
            handleSetSelectedStatuses={(selected) =>
              console.log("handleSetSelectedSubOwners:", selected)
            }
          /> */}
        </Box>
        <DateSelect
          handleDateRangeSelectionChange={handleDateRangeSelectionChange}
          rangeSelectionMap={rangeSelectionMap}
          dateRange={dateRange}
          rangeSelection={rangeSelection}
          setRangeSelection={(payload) => dispatch.app.setRangeSelection(payload)}
          customDateRange={customDateRange}
          setCustomDateRange={(payload) => dispatch.app.setCustomDateRange(payload)}
        />
      </Box>

      <Box className={classes.content}>
        <DashboardModuleCard
          title="At a Glance"
          refresh={true}
          refreshing={loading}
          onRefresh={() => fetchReportingData()}
        >
          <Box sx={{ display: "flex", gap: 3 }}>
            <AtAGlanceCard
              title="Candidate Call Time"
              loading={loading}
              formatter={(value) => convertNumToTime(value)}
              topStat={newNotesByContactType ? getCallTime(newNotesByContactType.Candidate) : 0}
              comparisonStat={
                comparisonNotesByContactType
                  ? getCallTime(comparisonNotesByContactType.Candidate)
                  : 0
              }
              dateSort={timespanComparisonText}
            />
            <AtAGlanceCard
              title="Contact Call Time"
              loading={loading}
              formatter={(value) => convertNumToTime(value)}
              topStat={newNotesByContactType ? getCallTime(newNotesByContactType.ClientContact) : 0}
              comparisonStat={
                comparisonNotesByContactType
                  ? getCallTime(comparisonNotesByContactType.ClientContact)
                  : 0
              }
              dateSort={timespanComparisonText}
            />
            <AtAGlanceCard
              title="Prescreens"
              loading={loading}
              topStat={_.size(
                newNotesByOwnerId?.notes?.filter((note) => note.action === "Prescreen")
              )}
              comparisonStat={_.size(
                comparisonNotesByOwnerId?.notes?.filter((note) => note.action === "Prescreen")
              )}
              dateSort={timespanComparisonText}
            />
            <AtAGlanceCard
              title="Candidate Adds"
              loading={loading}
              topStat={_.size(newCandidatesByOwnerId)}
              comparisonStat={_.size(comparisonCandidatesByOwnerId)}
              dateSort={timespanComparisonText}
            />
            <AtAGlanceCard
              title="Submissions"
              loading={loading}
              topStat={_.size(newSubmissionsByOwnerId?.submissions)}
              comparisonStat={_.size(comparisonSubmissionsByOwnerId?.submissions)}
              dateSort={timespanComparisonText}
            />
            <AtAGlanceCard
              title="Placed Searches"
              loading={loading}
              topStat={_.size(newPlacementsByOwnerId)}
              comparisonStat={_.size(comparisonPlacementsByOwnerId)}
              dateSort={timespanComparisonText}
            />
          </Box>
        </DashboardModuleCard>
        <Box sx={{ display: "flex", marginTop: 2, gap: 10 }}>
          <DashboardModuleCard
            title="Activity By Recruiter"
            refresh={true}
            refreshing={loading}
            onRefresh={() => fetchReportingData()}
            bottomContent={
              <ByRecruiterStats
                loading={loading}
                dateRange={dateRange}
                rangeSelection={rangeSelection}
              />
            }
          />
        </Box>
      </Box>
    </Container>
  );
}
