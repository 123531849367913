import _ from "lodash";
import { prettyName } from "@/helpers/user";
import { searchPublishedStatus, searchTypeMap } from "./search";

export default function makeExpandedSearchesTableData({
  searches,
  searchesSubmissions,
  currentOwner
}) {
  return searches
    ?.filter((s) => {
      return _.get(s, ["type"]);
    })
    .map((search) => {
      if (!search) return null;

      const { assignedUsers, id, owner, title, status, clientCorporation } = search;

      const submissions = searchesSubmissions[id] || [];

      // TODO: make work with id and not 463 once I get the right data
      // const calls = (searchesCalls && searchesCalls[id]) || [];

      // const callTime = getCallTime(calls, "hrs");
      // const converted = convertNumToTime(callTime);
      const published = searchPublishedStatus(search);

      return {
        id,
        publishedCategory: _.get(search, ["publishedCategory"]),
        published,
        search: `${title} (${id})`,
        clientCorporationName: clientCorporation?.name,
        status,
        submissions,
        // callTime: converted,
        callTime: "TBD",
        searchOwner: prettyName(owner),
        team: _.uniqBy([..._.sortBy(assignedUsers.data, "id")], "id").map((u) => {
          u.active = currentOwner ? currentOwner.id === u.id : true;

          return u;
        }),
        priority: (_.get(search, ["type"]) && searchTypeMap[_.get(search, ["type"])]) || "Unknown",
        function: _.reduce(
          search.categories?.data,
          (string, current) => string + (_.size(string) ? ", " : "") + current.name,
          ""
        ),
        city: _.get(search, ["address", "city"]),
        launchDate: _.get(search, ["launchDate"])
          ? new Date(_.get(search, ["launchDate"]))
          : new Date("01/01/3000"),
        slateDue: _.get(search, ["slateDeliveryDate"])
          ? new Date(_.get(search, ["slateDeliveryDate"]))
          : new Date("01/01/3000")
      };
    });
}
