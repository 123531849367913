/* eslint-disable react/prop-types */
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import { colors } from "../styles/config";
import makeStyles from "@mui/styles/makeStyles";
import { Cached as CachedIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  dashCard: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 6,
    backgroundColor: "white",
    // borderColor: colors.gray1,
    // borderWidth: 1,
    width: "100%",
    boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, .06)",
    border: `1px solid ${colors.gray1}`
  },
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  title: {
    fontSize: 25,
    fontWeight: "600",
    color: colors.darkGray
  },
  bottomContent: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

export default function DashboardModuleCard({
  title,
  filter,
  bottomContent,
  refresh = false,
  refreshing = false,
  onRefresh,
  children,
  badge
}) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box
      sx={{
        "&::-webkit-scrollbar": { display: "none" },
        "&::-moz-scrollbar": {
          display: "none"
        }
      }}
      className={classes.dashCard}
    >
      <Box className={classes.topRow}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography className={classes.title}>{title}</Typography>
          <Box mx={0.5}></Box>
          {badge && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                borderRadius: "100%",
                width: 34,
                height: 34,
                backgroundColor: colors.gray1
              }}
            >
              <Typography className={classes.title} style={{ fontSize: 18 }}>
                {badge}
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex" }}>
          {filter && filter}
          {refresh && (
            <Tooltip title="Reload">
              <span>
                <IconButton
                  disabled={refreshing}
                  color="inherit"
                  size="small"
                  onClick={onRefresh}
                  className="icon-button__backgrounded"
                  style={{
                    padding: theme.spacing(0.5),
                    borderRadius: 5,
                    marginLeft: theme.spacing(1),
                    height: 36,
                    width: 36
                  }}
                >
                  {refreshing && <CircularProgress size={16} />}
                  {!refreshing && <CachedIcon fontSize="small" />}
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box className={classes.bottomContent}>
        {bottomContent && bottomContent}
        {children && children}
      </Box>
    </Box>
  );
}
