/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { FormControl, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import { prettyName, truncatedLastName } from "../../../helpers/user";
import { FormControlLabel, FormGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as CheckboxIcon } from "../../../assets/icons/checkbox.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/checked-blue.svg";
import { ReactComponent as IndeterminateIcon } from "../../../assets/icons/indeterminate-blue.svg";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginLeft: theme.spacing(0.5)
  },
  flexbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  label: {
    fontSize: 12
  },
  chips: {
    display: "flex"
  },
  chip: {
    margin: 2,
    fontSize: 12
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

export function RecruiterFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const classes = useStyles();
  // Calculate the options for filtering using the preFilteredRows
  const options = useMemo(() => {
    const optionsSet = new Set();

    const mapped = preFilteredRows.map((row) => row.values[id]);
    const merged = [].concat.apply([], mapped);
    const ids = _.sortBy(merged, "id").map((user) => prettyName(user));

    const uniqueOptions = ids.filter((value) => {
      if (!value || optionsSet.has(value)) {
        return false;
      }
      optionsSet.add(value);
      return true;
    });

    return uniqueOptions;
  }, [id, preFilteredRows]);

  // const handleChange = (event) => {
  //   setFilter(event.target.value);
  // };

  const checkboxHandleChange = (event) => {
    if (!event.target.checked) {
      const newFilterArray = filterValue.filter((opt) => opt !== event.target.name);

      setFilter(newFilterArray);
    } else {
      const newFilterArray = filterValue
        ? [...filterValue, event.target.name]
        : [event.target.name];

      setFilter(newFilterArray);
    }
  };

  return (
    <Box>
      <FormControl required component="fieldset" className={classes.formControl} variant="standard">
        <FormGroup className={classes.flexbox}>
          {options &&
            options.map((opt, index) => {
              return (
                <FormControlLabel
                  key={`${opt}-${index}`}
                  classes={{
                    label: classes.label
                  }}
                  size="small"
                  control={
                    <Checkbox
                      style={{ fontSize: 12 }}
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedIcon />}
                      indeterminateIcon={<IndeterminateIcon />}
                      checked={filterValue ? filterValue.indexOf(opt) > -1 : false}
                      onChange={checkboxHandleChange}
                      name={opt}
                    />
                  }
                  label={truncatedLastName(opt)}
                />
              );
            })}
        </FormGroup>
      </FormControl>
    </Box>
  );
}
