import { APIError } from "errors";
import api from "../api";

const INITIAL_STATE = {
  candidates: undefined,
  candidate: undefined,
  error: undefined,
  loading: false
};

// ----------> MODEL <----------

export const candidates = {
  state: INITIAL_STATE,
  reducers: {
    setCandidates(state, candidates) {
      return { ...state, candidates };
    },

    setLoading(state, loading) {
      return { ...state, loading };
    },

    setError(state, error) {
      return { ...state, error };
    },

    purge() {
      return {
        ...INITIAL_STATE
      };
    }
  },
  effects: (dispatch) => ({
    fetchCandidates: async ({ dateRangePayload, userId }) => {
      try {
        dispatch.candidates.setLoading(true);

        const { data: candidates } = await api.post(
          `/candidates?owner=${userId}`,
          dateRangePayload
        );

        dispatch.candidates.setCandidates(candidates.data);
      } catch (error) {
        dispatch.candidates.setCandidates([]);

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.candidates.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        } else {
          dispatch.candidates.setError(error);
        }

        throw error;
      } finally {
        dispatch.candidates.setLoading(false);
      }
    }
  })
};
