// ↓ beloved react ↓
import React, { useRef, useState, useEffect, useCallback } from "react";

// ↓ 3rd party utils ↓
import _ from "lodash";
import moment from "moment";

// ↓ 3rd party components ↓
import { Box, Typography, Button, Popover, TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

// ↓ shared types ↓

// ↓ models & types ↓

// ↓ navigation ↓

// ↓ screens ↓

// ↓ modals ↓

// ↓ forms ↓

// ↓ components ↓

// ↓ fragments ↓

// ↓ hooks ↓

// ↓ utils ↓

import { colors } from "../../../styles/config";
import { useDispatch, useSelector } from "react-redux";

// ↓ constants ↓

// ↓ assets ↓

// ---

const useStyles = makeStyles((theme) => ({
  // container: { marginBottom: theme.spacing(3) },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3)
  },
  rightSideContainer: {
    display: "flex",
    gap: 20,
    alignItems: "center"
  },
  dateFilterButton: {
    display: "flex",
    justifyContent: "flex-start",
    padding: theme.spacing(0.4),
    paddingRight: 5,
    paddingLeft: 15,
    alignItems: "center",
    fontSize: 14,
    backgroundColor: colors.gray1,
    border: `2px solid ${colors.gray1}`,
    cursor: "pointer",
    gap: 15,
    "&:hover": {
      backgroundColor: colors.gray1,
      border: "2px solid #2699fb"
    }
  }
}));

export default function DateSelect({
  // eslint-disable-next-line react/prop-types
  handleDateRangeSelectionChange,
  // eslint-disable-next-line react/prop-types
  rangeSelectionMap,
  // eslint-disable-next-line react/prop-types
  disabled = false,
  // eslint-disable-next-line react/prop-types
  lifetime = false
}) {
  const classes = useStyles();
  const [openDateFilters, setOpenDateFilters] = useState(false);
  const datePopoverAnchorRef = useRef();
  const [customRangeFrom, setCustomRangeFrom] = useState(null);
  const [customRangeTo, setCustomRangeTo] = useState(null);
  const [customType, setCustomType] = useState("Between");
  const [customEnabled, setCustomEnabled] = useState(false);

  const dispatch = useDispatch();

  const { rangeSelection } = useSelector((state) => state.app);

  const applyRange = useCallback(() => {
    const startDate = customRangeFrom;

    const endDate = customType === "Between" ? customRangeTo : moment().add(1, "days");

    const customRange = {
      startDate,
      endDate
    };

    handleDateRangeSelectionChange("custom", customRange);

    setCustomEnabled(false);
    setOpenDateFilters(false);
  }, [
    handleDateRangeSelectionChange,
    setOpenDateFilters,
    setCustomEnabled,
    customRangeTo,
    customType,
    customRangeFrom
  ]);

  const handleRangeSelect = useCallback(
    (_event, newValue) => {
      if (newValue !== "custom") {
        handleDateRangeSelectionChange(newValue);
        setOpenDateFilters(false);
      } else {
        setCustomEnabled(true);
      }
    },
    [handleDateRangeSelectionChange]
  );

  const handleCustomRangeTypeChange = useCallback(
    (event) => {
      setCustomType(event.target.value);
    },
    [setCustomType]
  );

  useEffect(() => {
    if (customRangeFrom && customRangeTo && customType === "Between") {
      dispatch.app.setCustomDateRange({
        startDate: customRangeFrom,
        endDate: customRangeTo
      });
    } else if (customRangeFrom && customType === "Since") {
      dispatch.app.setCustomDateRange({
        startDate: customRangeFrom,
        endDate: moment().add(1, "days")
      });
    }
  }, [customRangeFrom, customRangeTo, customType]);

  return (
    <Box className={classes.container}>
      <Button
        disabled={disabled}
        className={classes.dateFilterButton}
        onClick={(event) => {
          event.stopPropagation();
          setOpenDateFilters(true);
        }}
        ref={datePopoverAnchorRef}
        sx={{ textTransform: "none" }}
      >
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography sx={{ color: "black" }}>Time:</Typography>
          <Typography sx={{ color: "black" }}>
            {/* eslint-disable-next-line react/prop-types */}
            {rangeSelection === "custom" ? "Custom" : rangeSelectionMap[rangeSelection]?.title}
          </Typography>
        </Box>
        <KeyboardArrowDownRoundedIcon
          sx={{
            borderRadius: "50px",
            "&:hover": { backgroundColor: "white" }
          }}
        />
      </Button>

      <Popover
        open={openDateFilters}
        onClose={() => setOpenDateFilters(false)}
        anchorEl={datePopoverAnchorRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        sx={{ width: "120vw" }}
      >
        <Box display="flex" alignItems="flex-start" justifyContent="flex-start" p={3}>
          <FormControl>
            <Box>
              <RadioGroup value={rangeSelection} onChange={handleRangeSelect}>
                {Object.keys(rangeSelectionMap).map((key) => {
                  if (key === "lifetime" || key === "custom") return null;

                  return (
                    <FormControlLabel
                      key={key}
                      value={key}
                      sx={{ minWidth: 200 }}
                      control={
                        <Radio
                          icon={<CheckBoxOutlineBlankIcon sx={{ color: colors.gray1 }} />}
                          checkedIcon={
                            <CheckBoxIcon
                              sx={{
                                color: "#2699fb"
                              }}
                            />
                          }
                        />
                      }
                      // @todo add prop types.
                      // eslint-disable-next-line react/prop-types
                      label={_.startCase(rangeSelectionMap[key]?.title)}
                    />
                  );
                })}
                {lifetime && (
                  <FormControlLabel
                    value="lifetime"
                    control={
                      <Radio
                        icon={<CheckBoxOutlineBlankIcon sx={{ color: colors.gray1 }} />}
                        checkedIcon={
                          <CheckBoxIcon
                            sx={{
                              color: "#2699fb"
                            }}
                          />
                        }
                      />
                    }
                    label="Since Launch"
                  />
                )}
                <FormControlLabel
                  value="custom"
                  control={
                    <Radio
                      icon={<CheckBoxOutlineBlankIcon sx={{ color: colors.gray1 }} />}
                      checked={rangeSelection === "custom" || customEnabled}
                      checkedIcon={
                        <CheckBoxIcon
                          sx={{
                            color: "#2699fb"
                          }}
                        />
                      }
                    />
                  }
                  label="Custom"
                />
              </RadioGroup>
            </Box>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              gap: ".6vw",
              flexDirection: "column"
            }}
          >
            <Typography variant="body1" color="initial">
              Custom Date Range
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={customType}
                label="Type"
                onChange={handleCustomRangeTypeChange}
                disabled={!customEnabled && rangeSelection !== "custom"}
              >
                <MenuItem value="Between">Between</MenuItem>
                <MenuItem value="Since">Since</MenuItem>
              </Select>
            </FormControl>
            {customType === "Between" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: ".2vw" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disabled={!customEnabled && rangeSelection !== "custom"}
                    label="From Date"
                    value={customRangeFrom}
                    onChange={(newValue) => {
                      setCustomRangeFrom(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <Typography variant="body1" color="initial">
                  -
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disabled={!customEnabled && rangeSelection !== "custom"}
                    label="To Date"
                    value={customRangeTo}
                    onChange={(newValue) => {
                      setCustomRangeTo(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
            )}
            {customType === "Since" && (
              <Box sx={{ width: "20vw" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Basic example"
                    value={customRangeFrom}
                    onChange={(newValue) => {
                      setCustomRangeFrom(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
            backgroundColor: "#2699fb",
            padding: 1
          }}
        >
          <Button
            sx={{ borderColor: "white", color: "white" }}
            variant="outlined"
            disableElevation
            disabled={customType && customRangeFrom && customRangeTo ? false : true}
            onClick={() => applyRange()}
          >
            Apply Range
          </Button>
        </Box>
      </Popover>
    </Box>
  );
}
