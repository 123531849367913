import { APIError } from "@/errors";
import api from "../api";

const INITIAL_STATE = {
  submissions: undefined,
  submission: undefined,
  error: undefined,
  loading: false
};

// ----------> MODEL <----------

export const placements = {
  state: INITIAL_STATE,

  reducers: {
    setPlacements(state, placements) {
      return { ...state, placements };
    },

    setLoading(state, loading) {
      return { ...state, loading };
    },

    setError(state, error) {
      return { ...state, error };
    },

    purge() {
      return {
        ...INITIAL_STATE
      };
    }
  },
  effects: (dispatch) => ({
    fetchPlacementsBySender: async ({ dateRangePayload, userId }) => {
      try {
        dispatch.placements.setLoading(true);

        const { data: placements } = await api.post(
          `/placementsBySendingUser?sender=${userId}`,
          dateRangePayload
        );

        dispatch.placements.setPlacements(placements.data);
      } catch (error) {
        dispatch.placements.setPlacements([]);

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.placements.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        } else {
          dispatch.placements.setError(error);
        }

        throw error;
      } finally {
        dispatch.placements.setLoading(false);
      }
    }
  })
};
