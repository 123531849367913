/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  pill: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    borderRadius: 5,
    maxWidth: "75px",
    minWidth: "75px"
  }
}));

export default function PercentPill({ changePercent, type }) {
  const classes = useStyles();
  const [backgroundColor, setBackgroundColor] = useState();
  const [foregroundColor, setForegroundColor] = useState();

  useEffect(() => {
    if (type === "increase") {
      setBackgroundColor("#eaf7f2");
      setForegroundColor("#35ab7c");
    } else if (type === "decrease") {
      setBackgroundColor("#faeae7");
      setForegroundColor("#d03318");
    }
  }, [setBackgroundColor, setForegroundColor, type]);

  return (
    <Box sx={{ backgroundColor }} className={classes.pill}>
      {type === "increase" && <ArrowUpwardIcon sx={{ color: foregroundColor }} />}
      {type === "decrease" && <ArrowDownwardIcon sx={{ color: foregroundColor }} />}
      <Typography sx={{ color: foregroundColor }}>{`${changePercent}%`}</Typography>
    </Box>
  );
}
