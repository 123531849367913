import React from "react";

// 3rd party libraries
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Typography, Grid, Box } from "@mui/material";

// components
import LoginForm from "@/components/forms/LoginForm";
import FullPageForm from "@/components/FullPageForm";
import Splash from "@/components/Splash";
import { useAuth } from "hooks/use-auth.hook";
import { toast } from "react-toastify";

export default function Login() {
  const auth = useAuth();

  const history = useHistory();

  // TODO: implement this
  // const { from } = location.state || { from: { pathname: "/variner/home" } };
  const { from } = { from: { pathname: "/variner/home" } };

  const { loggingIn } = useSelector((state) => state.user);

  const login = async (values) => {
    try {
      await auth.signin(values, () => {
        history.replace(from);
      });
    } catch (error) {
      toast.error("Something went wrong. Please refresh your browser and try again.");
    }
  };

  // const login = useCallback(
  //   async (values) => {
  //     // await dispatch.user.attemptLogin(values);

  //   },
  //   [dispatch]
  // );

  // if (authorized) return <Redirect to="/variner/home" />;

  if (loggingIn) {
    return <Splash text="Logging in" />;
  }

  return (
    <Grid container={true} direction="column" justifyContent="center" alignItems="center">
      <FullPageForm>
        <Box mb={4}>
          <Typography variant="h3">Please log in to access the Variner App.</Typography>
        </Box>
        <LoginForm onSubmit={login} />
      </FullPageForm>
    </Grid>
  );
}
