/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography, Avatar, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { prettyInitials, prettyName } from "@/helpers/user";
import { constants } from "@/styles/config";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 40,
    height: 40,
    marginLeft: theme.spacing(0.25),
    "&:hover": {
      opacity: "0.8 !important"
    },
    "&:not(:first-child)": {
      marginLeft: "-4px"
    }
  },
  useActiveAvatar: {
    "&:hover": {
      opacity: "0.8 !important"
    }
  }
}));

export default function TeamAvatarList({ users, style = {}, singleIcon = true, onAvatarClick }) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ justifyContent: singleIcon ? "center" : "flex-start", ...style }}
    >
      {users.map((user) => {
        return (
          <Tooltip title={prettyName(user)} key={prettyName(user)}>
            <Avatar
              className={classes.avatar}
              style={{
                backgroundColor: constants.colorsForData[prettyName(user)]
              }}
              onClick={() => onAvatarClick(user)}
            >
              <Typography sx={{ color: "black" }} variant="h6">
                {prettyInitials(user)}
              </Typography>
            </Avatar>
          </Tooltip>
        );
      })}
    </Box>
  );
}
