/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
// import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Typography,
  Tooltip,
  Link,
  Avatar,
  Chip,
  Divider
} from "@mui/material";

import BhIcon from "../assets/bullhorn-white.png";
import { findStatusObject } from "@/helpers/statuses";
import { prettyName } from "../helpers/user";
import { colors, constants } from "../styles/config";
import _ from "lodash";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ResponsiveBar } from "@nivo/bar";

const getStatusColor = (status) => {
  const statusMapObject = findStatusObject(status);
  return statusMapObject.color;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(1),
    position: "relative"
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0
  },
  actions: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  rightCorner: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2)
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "100%",
    marginRight: theme.spacing(0.5)
  },
  title: {
    fontSize: 16
  },
  subheader: {
    color: colors.gray43,
    fontSize: 12
  }
}));

export default function CandidateCard({ submission }) {
  const classes = useStyles();
  const theme = useTheme();
  const { internalUsers } = useSelector((state) => state.user);
  const { currentJob } = useSelector((state) => state.jobs);
  const [expanded, setExpanded] = React.useState(false);
  // console.log("Submission", submission);

  const getDiff = (date, secondDate) => {
    const diff = moment(date).diff(secondDate, "hours");
    return Math.round((Math.abs(diff) / 24) * 2) / 2;
  };

  const identifyingRecruiter = useMemo(() => {
    return (
      internalUsers &&
      submission.identifyingRecruiter &&
      internalUsers.find((user) => user.id === parseInt(submission.identifyingRecruiter))
    );
  }, [internalUsers, submission]);

  const handleExpandClick = () => {
    setExpanded(true);
  };

  const handleRetractClick = () => {
    setExpanded(false);
  };

  const fateHistoryItem = useMemo(() => {
    if (submission.history && !!submission.history.length) {
      const rejectedStatus = findStatusObject(submission.status).group === "Rejected";

      const fateStatusItems = submission.history.filter((historyItem) => {
        if (rejectedStatus) {
          return historyItem.status === submission.status;
        } else {
          return historyItem.status === "Client Submission";
        }
      });
      return fateStatusItems[0];
    } else {
      return null;
    }
  }, [submission]);

  const daysToFate = useMemo(() => {
    if (fateHistoryItem) {
      const submissionLifeStartDate = submission.history[submission.history.length - 1].dateAdded;

      return fateHistoryItem
        ? `${getDiff(submissionLifeStartDate, fateHistoryItem.dateAdded)} days to fate`
        : `${getDiff(submissionLifeStartDate, new Date())} days in process`;
    } else {
      return "Pending fate";
    }
  }, [submission, fateHistoryItem]);

  const statusHistoryData = useMemo(() => {
    if (submission.history && !!submission.history.length) {
      // go through submission history
      let tableData = {
        group: "history"
      };

      submission.history.forEach((h, index) => {
        const statusObject = findStatusObject(h.status);
        if (statusObject.position < 30) {
          const nextHistoryItem = submission.history[index - 1];
          const secondDate = nextHistoryItem ? nextHistoryItem.dateAdded : new Date();

          const currentDiff = getDiff(h.dateAdded, secondDate);
          // some way to show the statuses regardless
          if (currentDiff === 0) return 0.1;

          if (!tableData[h.status]) {
            tableData[h.status] = currentDiff;
            tableData[h.status + "Color"] = getStatusColor(h.status);
          } else {
            tableData[h.status] = tableData[h.status] + currentDiff;
          }
        }
      });
      return tableData;
    } else {
      return null;
    }
  }, [submission]);

  if (!submission || !submission.candidate) return null;

  const undergradSchool =
    _.get(submission, ["candidate", "undergradSchool"]) ||
    _.get(submission, ["candidate", "customText2"]);
  const undergradYear =
    _.get(submission, ["candidate", "undergradYear"]) ||
    _.get(submission, ["candidate", "customInt1"]);

  const jobTitle = _.get(submission, ["jobOrder", "title"]) || _.get(currentJob, ["publicName"]);

  const submissionOwnerGrade = submission?.submissionOwnerGrade
    ? `${submission.sendingUser?.firstName}'s Grade: ${submission.submissionOwnerGrade}`
    : "No grade found for submission";

  return (
    <Card className={classes.root}>
      <CardHeader
        style={{ paddingBottom: 0 }}
        classes={{
          title: classes.title,
          subheader: classes.subheader
        }}
        title={`${_.get(submission, ["candidate", "firstName"])} ${_.get(submission, [
          "candidate",
          "lastName"
        ])}`}
        subheader={`${undergradYear} | ${undergradSchool}`}
      />
      <CardContent classes={{ root: classes.content }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="flex-end"
          className={classes.rightCorner}
        >
          {submission.sendingUser && (
            <Tooltip title="Submission Owner">
              <Avatar
                style={{
                  width: 28,
                  height: 28,
                  backgroundColor: constants.colorsForData[prettyName(submission.sendingUser)]
                }}
              >
                <Typography variant="h5">
                  {`${submission.sendingUser.firstName
                    .charAt(0)
                    .toUpperCase()}${submission.sendingUser.lastName.charAt(0).toUpperCase()}`}
                </Typography>
              </Avatar>
            </Tooltip>
          )}
        </Box>
        <Box pb={2}>
          <Typography variant="body2" className={classes.subheader}>
            {`${_.get(submission, ["candidate", "companyName"])} |
              ${_.get(submission, ["candidate", "occupation"])}`}
          </Typography>
          <Typography className={classes.subheader}>{`${_.get(submission, [
            "candidate",
            "address",
            "city"
          ])}, ${_.get(submission, ["candidate", "address", "state"])}`}</Typography>
        </Box>

        <Chip
          label={`${submission.status}`}
          size="small"
          style={{
            fontSize: 12,
            backgroundColor: getStatusColor(submission.status),
            color: colors.white,
            marginLeft: -2
          }}
        />

        <Box>
          <Box py={2}>
            <Divider />
          </Box>
          {jobTitle && <Typography variant="subtitle1">{`${jobTitle}`}</Typography>}
          <Typography variant="body2" className={classes.subheader}>
            {_.get(submission, ["source"])}
          </Typography>
          <Typography variant="body2" className={classes.subheader}>
            {submissionOwnerGrade}
          </Typography>
        </Box>
        {submission.history && !!submission.history.length && (
          <Box>
            <Box py={2}>
              <Divider />
            </Box>
            <Typography variant="subtitle1">{daysToFate}</Typography>
            <Box
              style={{
                width: "100%",
                height: 32,
                marginBottom: theme.spacing(2)
              }}
            >
              <ResponsiveBar
                theme={{
                  grid: {
                    line: {
                      stroke: "transparent"
                    }
                  }
                }}
                data={[statusHistoryData]}
                reverse={true}
                keys={Object.keys(statusHistoryData).filter(
                  (k) => k !== "group" && k.indexOf("Color") < 0
                )}
                // onClick={onBarClick}
                innerPadding={1}
                indexBy="group"
                tooltip={({ id, value }) => {
                  return (
                    <Box>
                      <Typography
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          paddingBottom: theme.spacing(0.5)
                        }}
                      >
                        {id}
                      </Typography>

                      <Box display="flex" alignItems="center">
                        <Typography style={{ fontSize: 10 }}>{value}</Typography>
                      </Box>
                    </Box>
                  );
                }}
                margin={{ top: 8, right: 0, bottom: 0, left: 0 }}
                padding={0.3}
                layout="horizontal"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={({ id, data }) => data[`${id}Color`]}
                borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                labelSkipWidth={0}
                labelSkipHeight={0}
                labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
              />
              <Typography
                variant="body2"
                className={classes.subheader}
                style={{ textAlign: "center" }}
              >
                Submission Lifecycle to Fate (in days)
              </Typography>
            </Box>
          </Box>
        )}
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ paddingTop: 0 }}>
          <Box>
            <Box py={2}>
              <Divider />
            </Box>
            <Typography variant="subtitle1">Origination Source</Typography>
            <Typography variant="body2">
              {submission.candidate.source || "No origination source found."}
            </Typography>
          </Box>
          <Box>
            <Box py={2}>
              <Divider />
            </Box>
            <Typography variant="subtitle1">Identifying Recruiter</Typography>
            <Typography variant="body2">
              {identifyingRecruiter ? prettyName(identifyingRecruiter) : "Unknown"}
            </Typography>
          </Box>
          {fateHistoryItem && (
            <Box>
              <Box py={2}>
                <Divider />
              </Box>
              <Typography variant="subtitle1">{`${prettyName(
                fateHistoryItem.modifyingUser
              )}'s Comments`}</Typography>
              {fateHistoryItem.comments && (
                <Box
                  className="external-html-container MuiTypography-body2"
                  dangerouslySetInnerHTML={{
                    __html: fateHistoryItem.comments
                  }}
                ></Box>
              )}
              {!fateHistoryItem.comments && (
                <Typography variant="body2">{"No comments available."}</Typography>
              )}
            </Box>
          )}
        </CardContent>
      </Collapse>
      <CardActions disableSpacing classes={{ root: classes.actions }}>
        {(_.get(submission, ["candidate", "linkedinUrl"]) ||
          _.get(submission, ["candidate", "customText5"])) && (
          <Link
            style={{
              color: colors.gray43,
              marginLeft: -4,
              marginTop: 2
            }}
            size="small"
            target="_blank"
            href={`${
              _.get(submission, ["candidate", "linkedinUrl"]) ||
              _.get(submission, ["candidate", "customText5"])
            }`}
          >
            <LinkedInIcon size="small" style={{ height: 20 }} />
          </Link>
        )}
        <Link
          style={{
            marginLeft: 2
          }}
          color="secondary"
          size="small"
          target="_blank"
          href={`https://cls31.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=${submission.candidate.id}`}
        >
          <Box
            style={{
              backgroundColor: "rgba(144, 144, 144, 1)",
              borderRadius: 2,
              padding: 0,
              "&:hover": {
                backgroundColor: "rgba(144, 144, 144, 0.8)"
              },
              height: 15,
              width: 15,
              marginTop: -2
            }}
          >
            <img
              src={BhIcon}
              style={{ maxWidth: 14, maxHeight: 14, marginTop: -2 }}
              alt="Bullhorn app icon"
            />
          </Box>
        </Link>
        {!expanded && (
          <Button
            onClick={handleExpandClick}
            style={{ marginLeft: "auto", fontWeight: "normal", fontSize: 10 }}
          >
            Show More
          </Button>
        )}
        {expanded && (
          <Button
            onClick={handleRetractClick}
            style={{ marginLeft: "auto", fontWeight: "normal", fontSize: 10 }}
          >
            Show Less
          </Button>
        )}
        {/* <IconButton
          size="small"
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
        >
          <ExpandMoreIcon />
        </IconButton> */}
      </CardActions>
    </Card>
  );
}
