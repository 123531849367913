export const getCallTime = (notesArray = [], units = "hrs") => {
  const ignoreTypes = ["I/B", "O/B"];

  // // const ignoreTypes = [];
  // const cloudCallNotes = _.groupBy(
  //   notesArray
  //     .map((note) => {
  //       note.commentingPersonId = note.commentingPerson.id;
  //       return note;
  //     })
  //     .filter(
  //       (note) => ignoreTypes.indexOf(note.action) > -1 && note.minutesSpent > 1
  //     ),
  //   "commentingPersonId"
  // );

  // console.log("cloudCallNotes", cloudCallNotes);

  const notesWithCallTime = notesArray.filter(
    (note) => note.minutesSpent && ignoreTypes.indexOf(note.action) < 0
  );
  const minutes = notesWithCallTime.reduce((acc, curr) => {
    if (curr.minutesSpent) return acc + parseInt(curr.minutesSpent);
    return acc;
  }, 0);

  return units === "hrs" ? ((minutes / 60) * 10) / 10 : minutes;
};

export const convertNumToTime = (number) => {
  // Check sign of given number
  let sign = number >= 0 ? 1 : -1;

  // Set positive value of number of sign negative
  number = number * sign;

  // Separate the int from the decimal part
  let hour = Math.floor(number);
  let decpart = number - hour;

  let min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);

  let minute = Math.floor(decpart * 60) + "";

  // Add padding if need
  if (minute.length < 2) {
    minute = "0" + minute;
  }

  // Add Sign in final result
  sign = sign === 1 ? "" : "-";

  // Concate hours and minutes
  const time = sign + hour + ":" + minute;

  return time;
};
