/* eslint-disable react/prop-types */
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Drawer, Typography, IconButton, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";

import ActiveSubmissionsSlideOutTable from "@/components/tables/ActiveSubmissionsSlideOutTable";
import makeSubmissionsData from "@/components/tables/makeSubmissionsData";
import CandidateDrawer from "@/components/CandidateDrawer";
import { colors } from "@/styles/config";
import _ from "lodash";
import SubmissionsStatusPieV2 from "./reports/SubmissionStatusPieV2";
import { findStatusObject } from "@/helpers/statuses";

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    backgroundColor: colors.darkGray,
    display: "flex",
    justifyContent: "space-between"
  },
  drawerTabContent: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    maxWidth: 900,
    position: "relative"
  }
}));

export default function SubmissionsBarDrawer({
  open,
  toggleDrawer,
  componentTitle,
  style = {},
  titleStyle = { fontWeight: 600, fontSize: 20 },
  id
}) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const classes = useStyles();

  const { activeSubmissions } = useSelector((state) => state.submissions);

  const [candidateDrawer, setCandidateDrawer] = useState({
    open: false
  });

  const [currentSubmissionStatusGroup, setCurrentSubmissionStatusGroup] = useState(null);

  useEffect(() => {
    if (open.data) {
      setCurrentSubmissionStatusGroup(open.data.initialStatusGroup);
    }
  }, [open.data]);

  const statusObjectGroup = _.filter(open?.data?.submissions, (sub) => {
    const statusObject = findStatusObject(sub.status);
    return statusObject?.group === currentSubmissionStatusGroup;
  });

  const submissionData = useMemo(() => {
    if (open.data) {
      const submissionsByCurrentStatusGroup = currentSubmissionStatusGroup
        ? statusObjectGroup
        : open.data.submissions;

      return makeSubmissionsData(submissionsByCurrentStatusGroup);
    }
  }, [open.data, currentSubmissionStatusGroup]);

  const currentSubmissionsByStatus = useMemo(() => {
    if (!open.data?.submissions) return [];

    if (currentSubmissionStatusGroup) {
      return _.filter(open.data?.submissions, (sub) => {
        const statusObject = findStatusObject(sub.status);
        return currentSubmissionStatusGroup === statusObject?.group;
      });
    } else {
      return open.data?.submissions;
    }
  }, [currentSubmissionStatusGroup, open.data]);

  const submissionsBySubmissionOwnerGrade = useMemo(() => {
    return _.groupBy(currentSubmissionsByStatus, "submissionOwnerGrade");
  }, [currentSubmissionsByStatus]);

  const handlePieSliceClick = (data) => {
    setCurrentSubmissionStatusGroup(data?.id || null);
  };

  const toggleSubmissionDrawer = useCallback(
    ({ open, submission, component }, event) => {
      if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
        return;
      }

      if (submission) {
        dispatch.jobs.setCurrentSubmission(submission);

        setCandidateDrawer({
          open,
          component: <CandidateDrawer />
        });
      } else {
        setCandidateDrawer({
          open,
          component
        });
      }
    },
    [id]
  );

  if (!open.data) return <></>;

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="right"
          open={open.open}
          onClose={() => toggleDrawer({ open: false, type: null })}
        >
          <Box sx={{ ...style, width: 775 }} role="presentation">
            <Box className={classes.drawerHeader}>
              <Box>
                <Typography variant="h5" color={colors.white}>
                  {componentTitle}
                </Typography>
                <Typography
                  sx={{ ...titleStyle, marginTop: theme.spacing(1) }}
                  variant="h2"
                  color={colors.white}
                >
                  {open.type}
                </Typography>
              </Box>

              <IconButton
                sx={{ color: colors.white }}
                aria-label="Close"
                onClick={() => toggleDrawer({ open: false, type: null })}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.drawerTabContent}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box ml={2}>
                  <Typography variant="h5" mb={2.5} style={{ textTransform: "uppercase" }}>
                    Sub Owner Score breakdown
                  </Typography>
                  {Object.keys(submissionsBySubmissionOwnerGrade).map((gradeKey) => {
                    return (
                      <Box display="flex" key={gradeKey} mb={2}>
                        <Box width={100}>
                          <Typography style={{ fontSize: 14 }}>
                            {gradeKey === "null" ? "Unknown" : `${gradeKey}'s`}
                          </Typography>
                        </Box>
                        <Box width={100} display="flex">
                          <Typography style={{ fontSize: 14, minWidth: 20 }} pr={0.5}>
                            {`${_.size(submissionsBySubmissionOwnerGrade[gradeKey])}`}
                          </Typography>
                          <Typography style={{ fontSize: 14, minWidth: 30 }}>{`(${Math.round(
                            (_.size(submissionsBySubmissionOwnerGrade[gradeKey]) /
                              _.size(open.data?.submissions)) *
                              100
                          )}%)`}</Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                {currentSubmissionsByStatus && (
                  <Box width={300} height={250}>
                    <SubmissionsStatusPieV2
                      submissions={open.data?.submissions}
                      showLabel={false}
                      onSliceClick={handlePieSliceClick}
                      currentDay={null}
                      currentSubmissionStatusGroup={currentSubmissionStatusGroup}
                    />
                  </Box>
                )}
              </Box>
              {submissionData && (
                <ActiveSubmissionsSlideOutTable
                  skipPageReset={false}
                  data={submissionData}
                  onSubmissionNameClick={(submissionTableData, event) => {
                    const activeSubmissionToUse = activeSubmissions?.find(
                      (singleSubmission) => singleSubmission.id === submissionTableData?.id
                    );

                    const localSubmissionToUse = open.data?.submissions.find(
                      (singleSubmission) => singleSubmission.id === submissionTableData?.id
                    );

                    toggleSubmissionDrawer(
                      {
                        open: true,
                        submission: activeSubmissionToUse || localSubmissionToUse
                      },
                      event
                    );
                  }}
                  onSubmissionJobClick={(jobId) => window.open(`/variner/job/${jobId}`, "_blank")}
                  onOpenClick={(submissionTableData, event) => {
                    const submissionToUse = activeSubmissions?.find(
                      (singleSubmission) => singleSubmission.id === submissionTableData?.id
                    );

                    toggleSubmissionDrawer(
                      {
                        open: true,
                        submission: submissionToUse
                      },
                      event
                    );
                  }}
                />
              )}
            </Box>
          </Box>
        </Drawer>

        <Drawer
          anchor="right"
          open={candidateDrawer.open}
          onClose={(event) => toggleSubmissionDrawer({ open: false }, event)}
        >
          {candidateDrawer.component && candidateDrawer.component}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
