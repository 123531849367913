/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { ResponsivePie } from "@nivo/pie";
import _ from "lodash";
import moment from "moment";
import { statusGroupCategories, submissionsByHighLevelGroup } from "@/helpers/statuses";

export default function SubmissionsStatusPieV2({
  submissions,
  showLabel = true,
  onSliceClick,
  currentDay,
  currentSubmissionStatusGroup
}) {
  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0;

    dataWithArc.forEach((datum) => {
      total += datum.value;
    });

    return (
      <React.Fragment>
        <text
          onClick={() => onSliceClick(null)}
          x={centerX}
          y={centerY - 16}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "proxima-nova",
            cursor: "pointer",
            fill: "#909090"
          }}
        >
          {currentDay ? `${moment(currentDay).format("ddd MM/DD/YYYY")}` : "Total"}
        </text>
        <text
          onClick={() => onSliceClick(null)}
          x={centerX}
          y={centerY + 8}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "24px",
            fontWeight: "600",
            fontFamily: "proxima-nova",
            cursor: "pointer"
          }}
        >
          {`${total}`}
        </text>
      </React.Fragment>
    );
  };

  const submissionsGrouped = useMemo(() => {
    if (_.size(submissions)) return submissionsByHighLevelGroup(submissions);
  }, [submissions]);

  const pieData = useMemo(() => {
    if (submissionsGrouped)
      return Object.keys(submissionsGrouped).map((submissionsGroupKey) => {
        return {
          color: statusGroupCategories[submissionsGroupKey].color,
          id: submissionsGroupKey,
          label: submissionsGroupKey,
          value: _.size(submissionsGrouped[submissionsGroupKey])
        };
      });
  }, [submissionsGrouped]);

  if (!pieData) return <></>;

  return (
    <ResponsivePie
      data={pieData}
      margin={{ top: 16, right: 16, bottom: 16, left: 16 }}
      innerRadius={0.6}
      padAngle={0.7}
      cornerRadius={2}
      colors={({ id, data }) => {
        if (id === currentSubmissionStatusGroup) {
          return data.color;
        } else {
          return `${data.color}60`;
        }
      }}
      borderWidth={0}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      enableArcLinkLabels={showLabel}
      arcLinkLabelsSkipAngle={0}
      arcLinkLabel={(d) => `${d.label}`}
      radialLabelsSkipAngle={0}
      arcLabelsSkipAngle={0}
      arcLabelsTextColor="white"
      arcLinkLabelsDiagonalLength={8}
      arcLinkLabelsStraightLength={10}
      onClick={onSliceClick && onSliceClick}
      layers={["arcLinkLabels", "arcs", "arcLabels", "legends", CenteredMetric]}
      // activeOuterRadiusOffset={20}
    />
  );
}
