import _ from "lodash";
import moment from "moment";

const calculateBusinessDays = (startDate, endDate) => {
  const day = moment(startDate);

  let businessDays = 0;

  while (day.isSameOrBefore(endDate, "day")) {
    if (day.day() !== 0 && day.day() !== 6) {
      businessDays++;
    }

    day.add(1, "d");
  }

  return businessDays;
};

export const QUOTAS_BY_DAY = (quotasByYear) => {
  const weeksInYear = 52;
  const daysInWeek = 5;
  // TODO: count OUT holidays / vacation days
  const vacationDays = 0;
  const workDaysInYear = weeksInYear * daysInWeek - vacationDays;

  return {
    candidates: quotasByYear.candidates / workDaysInYear,
    prescreenCalls: quotasByYear.prescreenCalls / workDaysInYear,
    pendingSubmissions: quotasByYear.pendingSubmissions / workDaysInYear,
    confirmedSubmissions: quotasByYear.confirmedSubmissions / workDaysInYear,
    clientSubmissions: quotasByYear.clientSubmissions / workDaysInYear,
    interviews: quotasByYear.interviews / workDaysInYear,
    calltime: quotasByYear.calltime / workDaysInYear
  };
};

export const QUOTAS_BY_DATE_RANGE = ({ dateRange, quotasByYear }) => {
  const quotasByDay = QUOTAS_BY_DAY(quotasByYear);

  const businessDays = calculateBusinessDays(dateRange.startDate, dateRange.endDate);

  // TODO: count OUT holidays / vacation days

  const quotas = {
    candidates: quotasByDay.candidates * businessDays,
    prescreenCalls: quotasByDay.prescreenCalls * businessDays,
    pendingSubmissions: quotasByDay.pendingSubmissions * businessDays,
    confirmedSubmissions: quotasByDay.confirmedSubmissions * businessDays,
    clientSubmissions: quotasByDay.clientSubmissions * businessDays,
    interviews: quotasByDay.interviews * businessDays,
    calltime: quotasByDay.calltime * businessDays
    // conversionPercentage: quotasByDay.conversionPercentage * days,
    // placements: quotasByDay.placements * days,
  };

  return quotas;
};

export const PERCENTAGE_CHANGE = ({ comparisonStat, topStat }) => {
  if (topStat > comparisonStat) {
    return {
      positive: true,
      percentChange: Math.round(((topStat - comparisonStat) / (comparisonStat || 1)) * 100)
    };
  } else if (comparisonStat > topStat) {
    return {
      positive: false,
      percentChange: Math.round(((comparisonStat - topStat) / (topStat || 1)) * 100)
    };
  } else {
    return {
      positive: true,
      percentChange: 0
    };
  }
};

export const submissionsWithStatusInDateRange = ({ submissions, dateRange, statusString }) => {
  const passingSubmissions = _.chain(submissions)
    .filter((submission) => {
      const foundPendingInterestInHistory = _.find(submission.history?.reverse(), (historyItem) => {
        return historyItem.status === statusString;
      });

      return !!foundPendingInterestInHistory;
    })
    .filter((submission) => {
      const foundPendingInterestInHistory = _.find(submission.history, (historyItem) => {
        return historyItem.status === statusString;
      });

      const historyItemDateInDateRange = moment(foundPendingInterestInHistory.dateAdded).isBetween(
        moment(dateRange.startDate),
        moment(dateRange.endDate),
        null,
        "[]"
      );

      return !!historyItemDateInDateRange;
    })
    .value();

  return passingSubmissions;
};
