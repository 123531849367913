export const searchPublishedStatus = (search) => {
  if (!search) return false;

  return (
    search.sellingPoints?.correlatedCustomTextBlock1 &&
    search.quickSellingPoints &&
    search.hfJobDescription &&
    search.hfAdditionalInfo &&
    search.idealCandidate
  );
};

export const searchTypeMap = {
  1: "Retained",
  2: "Contingent",
  3: "Pending",
  5: "Qualifying"
};
//this is used in src/components/sections/ActiveSearchesCompactSection.js
export const ACTIVE_SEARCHES_TYPE_MAP = {
  RETAINED: 1,
  CONTINGENT: 2,
  PENDING: 3
};
export const ACTIVE_SEARCHES_LABELS_MAP = {
  RETAINED: "Retained",
  CONTINGENT: "Contingent",
  PENDING: "Pending"
};

export const getSearchSeverityStatus = () => {};
