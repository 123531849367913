import React, { useEffect, useState, useMemo } from "react";

// 3rd party libraries
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, CircularProgress, Button, Link } from "@mui/material";

// assets
import mountains from "@/assets/mountains-c.jpg";
import logoNoBg from "@/assets/logo-no-bg.png";

// style imports
import { colors } from "@/styles/config";

// components
import Splash from "@/components/Splash";
import ThankYouSplash from "@/components/ThankYouSplash";
import PostCloseForm from "@/components/forms/PostCloseForm";
import { bhUserContactMap } from "@/helpers/user";

// styles
const useStyles = makeStyles((theme) => ({
  rightColumnContainer: {
    backgroundColor: "rgba(27, 68, 74, 0.75)",
    width: "20vw",
    height: "100vh",
    position: "relative"
  },
  leftContentContainer: {
    height: "100vh",
    overflowY: "scroll",
    boxSizing: "border-box"
  },
  logoContainer: {
    marginTop: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  viewHeightCenteredBox: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  logo: {
    marginBottom: 0
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: colors.darkGray
  },
  welcomeText: {
    alignSelf: "flex-start"
  },
  jobNameText: {
    color: colors.gray43,
    alignSelf: "flex-start"
    // fontWeight: "600",
    // fontSize: 12,
  },
  mainText: {
    marginTop: theme.spacing(3),
    alignSelf: "flex-start",
    lineHeight: 1.3
  },
  smallDisclaimerText: {
    marginTop: theme.spacing(2),
    alignSelf: "flex-start",
    fontSize: 12
  },
  optButton: {
    width: 200,
    marginTop: theme.spacing(1)
  },
  optingFormJobTitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    color: colors.gray43,
    textAlign: "center"
  },
  bgImg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: "-1",
    top: 0,
    left: 0
  },
  mountainsImage: {
    height: "100%",
    width: "auto",
    minHeight: "100%"
  }
}));

export default function OptIn() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const { isMobile } = useSelector((state) => state.app);
  const { error, currentJob, loading } = useSelector((state) => state.jobs);

  const {
    placement,
    error: userError,
    loading: userLoading,
    submitting,
    submitted
  } = useSelector((state) => state.user);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const placementId = query.get("placement");
  const clientResponder = query.get("client") && query.get("client") === "true";

  const [jobInitialized, setJobInitialized] = useState(false);

  useEffect(() => {
    dispatch.user.setAnonymousToken();

    dispatch.jobs.startupPostClose({ id, placementId }).then(() => setJobInitialized(true));
  }, [dispatch, id, placementId]);

  const jobPlacementMismatch = useMemo(() => {
    if (!placement) return false;

    const matching = placement && parseInt(id) === placement.jobOrder.id;
    return !matching;
  }, [id, placement]);

  const pageTitle = useMemo(() => {
    if (!currentJob || !placement) return;

    if (clientResponder) {
      return "Post Close Review";
    } else {
      return "Search Process Review";
    }
  }, [currentJob, clientResponder, placement]);

  const welcomeMessage = useMemo(() => {
    if (!currentJob || !placement) return;

    if (clientResponder) {
      return `We hope all is well at ${currentJob.clientCorporation?.name}.`;
    } else {
      return `Hi ${placement.candidate.firstName}.`;
    }
  }, [currentJob, clientResponder, placement]);

  const getVarinerContact = () => {
    if (!placement) return null;

    // default to Unassigned User in BH for now
    const sendingUser = placement.jobSubmission?.sendingUser || {
      id: 1,
      firstName: "Unassigned",
      lastName: "User"
    };

    let email = bhUserContactMap[sendingUser.id];
    let name = `${sendingUser.firstName} ${sendingUser.lastName}`;

    // if default user, use nick's info for now
    if (parseInt(sendingUser.id) === 1) {
      email = "nick@variner.com";
      name = "Variner";
    }

    return {
      email,
      name
    };
  };

  const varinerContact = placement ? getVarinerContact() : null;

  if (!placementId) {
    return (
      <Box className={classes.viewHeightCenteredBox}>
        <Typography>
          No placement ID found in URL. Please contact your recruiter at Variner.
        </Typography>
        <Box className={classes.logoContainer}>
          <a href="https://www.variner.com">
            <img src={logoNoBg} style={{ maxHeight: 15 }} alt="variner logo" />
          </a>
        </Box>
      </Box>
    );
  }

  // show loading screen until all job content is captured
  if (!jobInitialized || userLoading) {
    return <Splash />;
  }

  // show loading screen until all job content is captured
  if (submitting) {
    return <Splash text={"Sending"} />;
  }

  // Do not show for dev placement ids
  const devPlacements = [161];

  if (placement && placement.customTextBlock1 && devPlacements.indexOf(placement.id) < 0) {
    return (
      <ThankYouSplash
        header={"Thank you!"}
        Component={() => {
          return (
            <Typography>
              It looks like we`ve already received your post search process responses for
              {currentJob.publicName}. If you have questions please reach out to
              <Link href={`mailto:${varinerContact.email}`}>{varinerContact.name}</Link>.
            </Typography>
          );
        }}
      />
    );
  }

  if (submitted) {
    if (userError) {
      return (
        <ThankYouSplash
          header="Something went wrong."
          Component={() => {
            return (
              <Typography>
                We are so sorry for the inconvenience, but we were unable to save some information
                on our end. Please reach out to{" "}
                <Link href={`mailto:${varinerContact.email}`}>{varinerContact.name}</Link> to see
                what is missing from your response. Thank you.
              </Typography>
            );
          }}
        />
      );
    } else {
      return <ThankYouSplash header="Thank you for your feedback!" />;
    }
  }

  if (jobPlacementMismatch) {
    return (
      <Box className={classes.viewHeightCenteredBox}>
        <Typography
          style={{
            maxWidth: "50vw",
            textAlign: "center",
            marginBottom: 16
          }}
        >
          Sorry, something seems to be wrong with this URL. If you believe this notice is in error,
          please reach out to your contact at Variner.
        </Typography>
        <Link href="https://www.variner.com">Visit the Variner Website</Link>
      </Box>
    );
  }

  if (error || userError || !placement) {
    return (
      <Box className={classes.viewHeightCenteredBox}>
        <Typography style={{ maxWidth: "50vw", textAlign: "center" }}>
          Sorry, something went wrong. Try reloading the page. If you reach this error multiple
          times, please reach out to your contact at Variner.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{ width: 200, marginTop: 16, marginBottom: 16 }}
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload Page
        </Button>
        <Link href="https://www.variner.com">Visit the Variner Website</Link>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent={"flex-start"}>
        <Box
          style={{
            alignSelf: "flex-start",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 2,
            boxSizing: "border-box",
            paddingTop: isMobile ? 20 : 16,
            paddingBottom: isMobile ? 20 : 16,
            paddingLeft: isMobile ? 40 : 140,
            backgroundColor: colors.white,
            width: isMobile ? "100%" : "65vw",
            boxShadow: isMobile ? "-1px 1px 4px 0px rgba(0, 0, 0, 0.15)" : "none"
          }}
        >
          <img className={classes.logo} src={logoNoBg} style={{ maxHeight: 20 }} alt="logo" />
        </Box>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent={"flex-start"}
          className={classes.leftContentContainer}
          style={{
            padding: isMobile ? 20 : 140,
            paddingTop: isMobile ? 20 : 40,
            marginTop: isMobile ? 70 : 110,
            width: isMobile ? "100vw" : "85vw"
          }}
        >
          {(loading || userLoading) && <CircularProgress />}
          <Box>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Typography variant="h1" className={classes.jobNameText}>
                {`${currentJob.clientCorporation && currentJob.clientCorporation.name} ${
                  currentJob.publicName
                } ${pageTitle}`}
              </Typography>

              <Typography variant="h3" className={classes.mainText}>
                {welcomeMessage}
              </Typography>

              <Typography variant="h3" className={classes.mainText}>
                As part of an effort to make our services more valuable to our clients and
                candidates, we would appreciate your feedback highlighting what you enjoyed about
                your Variner recruiting experience
                {clientResponder
                  ? ` and the placement of ${placement.candidate.firstName} ${placement.candidate.lastName} `
                  : ` to ${currentJob.clientCorporation.name}`}
                . Additionally, any constructive feedback you may have for us would be appreciated.
              </Typography>

              <Typography variant="h3" className={classes.mainText}>
                This information will be evaluated as part of our annual review process to make us
                better as individual recruiters; and as a team.
              </Typography>

              <Typography variant="h3" className={classes.mainText}>
                - Variner Team
              </Typography>

              {!clientResponder && (
                <Typography className={classes.smallDisclaimerText}>
                  Not {`${placement.candidate.firstName} ${placement.candidate.lastName}`}? Please
                  contact <Link href={`mailto:${varinerContact.email}`}>{varinerContact.name}</Link>{" "}
                  for more information.
                </Typography>
              )}

              <Typography variant="h3" className={classes.mainText}>
                Please rate your level of agreement with each of the four statements below:
              </Typography>
            </Box>

            <Box my={5}></Box>

            <PostCloseForm
              clientResponder={clientResponder}
              onCancel={() => {
                console.log("cancel pressed");
              }}
              onSubmit={(values) => {
                let data = {};
                if (clientResponder) {
                  data = {
                    customInt5: values.knowledgeScore,
                    customInt6: values.responsivenessScore,
                    customInt7: values.satisfactionScore,
                    customInt8: values.referenceable,
                    customTextBlock3: values.responderTestimonial,
                    customTextBlock4: values.responderConstructiveFeedback
                  };
                } else {
                  data = {
                    customInt1: values.knowledgeScore,
                    customInt2: values.responsivenessScore,
                    customInt3: values.satisfactionScore,
                    customInt4: values.referenceable,
                    customTextBlock1: values.responderTestimonial,
                    customTextBlock2: values.responderConstructiveFeedback
                  };
                }

                dispatch.user.postClose({
                  id: placementId,
                  jobId: id,
                  candidateId: placement.candidate.id,
                  data
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      {!isMobile && (
        <Box className={classes.rightColumnContainer}>
          <Box className={classes.bgImg}>
            <img src={mountains} alt="mountain" className={classes.mountainsImage} />
          </Box>
        </Box>
      )}
    </Box>
  );
}
