import api from "../api";
import { persistor } from "index";
import { APIError } from "@/errors";

const INITIAL_STATE = {
  authorized: false,
  authorizing: false,
  error: false,
  internalUsers: null,
  loading: false,
  loggingIn: false,
  loginError: null,
  optingIn: false,
  optingOut: false,
  resumeSuccess: false,
  submission: null,
  placement: null,
  submitted: false,
  submitting: false,
  token: null,
  username: null,
  user: null,
  role: null //TODO: Check if role is being used
};

export const user = {
  state: INITIAL_STATE,

  reducers: {
    setAuthorizing(state, authorizing) {
      return {
        ...state,
        authorizing
      };
    },

    setLoggingIn(state, loggingIn) {
      return {
        ...state,
        loggingIn
      };
    },

    setLoading(state, loading) {
      return {
        ...state,
        loading
      };
    },

    update(state, updates) {
      return {
        ...state,
        ...updates
      };
    },

    setInternalUsers(state, payload) {
      return {
        ...state,
        internalUsers: payload.data
      };
    },

    setUser(state, { username, token, authorized, user }) {
      return {
        ...state,
        username,
        token,
        authorized,
        user
      };
    },

    setUserSubmission(state, data) {
      return {
        ...state,
        submission: data,
        loading: false
      };
    },

    setUserPlacement(state, data) {
      return {
        ...state,
        placement: data,
        loading: false
      };
    },

    setError(state, error) {
      return {
        ...state,
        loading: false,
        ...error
      };
    },

    purgeErrors(state) {
      return {
        ...state,
        error: false,
        loginError: null
      };
    },

    setSubmitting(state, payload) {
      return {
        ...state,
        submitting: payload
      };
    },

    setOpting(state, payload) {
      return {
        ...state,
        ...payload
      };
    },

    purge() {
      return {
        ...INITIAL_STATE
      };
    }
  },
  effects: (dispatch) => ({
    async setAnonymousToken() {
      // TODO: figure out a better way to handle anonymouse API requests
      api.setToken("anonymous");
    },
    async setUserToken(payload) {
      api.setToken(payload);
      dispatch.app.setApiTokenSet(true);
    },
    async attemptLogout(_payload, { user }) {
      const { token } = user;

      if (token) {
        api.post("/logout");
      }

      // pause and purge persisted data on logout
      persistor.pause();
      setTimeout(async () => await persistor.purge(), 200);

      // reset api instance's token
      api.setToken(null);

      // purge redux models
      dispatch.app.purge();
      dispatch.calls.purge();
      dispatch.candidates.purge();
      dispatch.clients.purge();
      dispatch.jobs.purge();
      dispatch.notes.purge();
      dispatch.placements.purge();
      dispatch.reporting.purge();
      dispatch.searches.purge();
      dispatch.submissions.purge();
      dispatch.user.purge();
    },
    async logout() {
      // pause and purge persisted data on logout
      persistor.pause();
      setTimeout(async () => await persistor.purge(), 200);

      // reset api instance's token
      api.setToken(null);

      // purge redux models
      dispatch.app.purge();
      dispatch.calls.purge();
      dispatch.candidates.purge();
      dispatch.clients.purge();
      dispatch.jobs.purge();
      dispatch.notes.purge();
      dispatch.placements.purge();
      dispatch.reporting.purge();
      dispatch.searches.purge();
      dispatch.submissions.purge();
      dispatch.user.purge();
    },
    // TODO: implement refresh token again
    async refreshToken(token, rootState) {
      try {
        api.setToken(token);

        const { data } = await api.post("/refresh", {
          username: rootState.username
        });

        api.setToken(data.data.token);

        dispatch.user.setUser({
          ...rootState.user,
          token: data.data.token,
          authorized: true
        });
      } catch (error) {
        // console.log("error refreshing token, resetting auth.", error);
        await dispatch.user.logout();

        dispatch.user.setError({ error: error.message });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.notes.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        dispatch.user.setAuthorizing(false);
      }
    },
    async attemptLogin(payload) {
      try {
        dispatch.user.setLoggingIn(true);

        dispatch.user.setError({
          error: null,
          loginError: null
        });

        const { data } = await api.post("/login", payload);

        if (!data.success && data.error) {
          throw new Error(data.error);
        }

        dispatch.user.setUser({
          username: payload.username,
          token: data.data.token,
          authorized: true,
          user: data.data.user
        });

        api.setToken(data.data.token);
      } catch (loginError) {
        await dispatch.user.logout();

        dispatch.user.setError({ loginError: loginError.message });

        if (loginError instanceof APIError) {
          dispatch.app.setLastAPIError(loginError);

          // TODO: implement model specific lastapierror
          // dispatch.notes.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        dispatch.user.setLoggingIn(false);
      }
    },
    async sendResume(payload) {
      try {
        await api.post("/resume", payload);

        dispatch.user.update({
          resumeSuccess: true
        });
      } catch (error) {
        dispatch.user.update({
          resumeSuccess: false
        });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.notes.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      }
    },
    async fetchInternalUsers() {
      try {
        const response = await api.get("/ownerUsers");
        dispatch.user.setInternalUsers(response.data);
      } catch (error) {
        dispatch.user.setError({ error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.notes.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      }
    },
    async postClose(payload) {
      try {
        dispatch.user.setSubmitting(true);

        await api.post("/placementPostClose", payload);
      } catch (error) {
        dispatch.user.setError({ error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.notes.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        dispatch.user.update({
          submitted: true,
          submitting: false
        });
      }
    },
    async opt(payload) {
      try {
        dispatch.user.setSubmitting(true);
        await api.post("/opt", payload);

        // TODO: move this to component level state
        dispatch.user.update({
          resumeSuccess: true
        });
      } catch (error) {
        // console.log("Error submitting submission opt in: ", error);
        dispatch.user.setError({ error });

        // TODO: move this to component level state
        dispatch.user.update({
          resumeSuccess: false
        });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.notes.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        dispatch.user.update({
          submitted: true,
          submitting: false
        });
      }
    },
    async optIn(payload) {
      try {
        dispatch.user.setSubmitting(true);
        await api.post("/submission", payload);
      } catch (error) {
        dispatch.user.setError({ error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.notes.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        dispatch.user.update({
          submitted: true,
          submitting: false
        });
      }
    }
  })
};
