/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {
  Box,
  Typography,
  Switch,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl
} from "@mui/material";

import { ReactComponent as CheckboxIcon } from "../assets/icons/checkbox.svg";
import { ReactComponent as CheckedIcon } from "../assets/icons/checked-blue.svg";
import { ReactComponent as IndeterminateIcon } from "../assets/icons/indeterminate-blue.svg";

import _ from "lodash";
import { colors } from "../styles/config";

const AntSwitch = withStyles(() => ({
  root: {
    width: 45,
    height: 31
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
    // backgroundColor: colors.varinerBlue,
  }
}))(Switch);

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start"
    // margin: theme.spacing(3),
  },
  contentContainer: {
    width: 530
  },
  drawerHeader: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: colors.variner
  },
  drawerTabContent: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    maxWidth: 900
  },
  tab: {
    textAlign: "left",
    minHeight: 0,
    minWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: theme.spacing(1 / 2),
    marginRight: theme.spacing(3)
  },
  grayText: {
    color: colors.gray43
  },
  helperText: {
    color: colors.gray43,
    fontSize: 12
  }
}));

export default function SettingsDrawer({ instance }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { presentationMode } = useSelector((state) => state.app);
  const { allColumns, visibleColumns, toggleHideColumn } = instance;
  const hideableColumns = allColumns.filter((column) => column.id.charAt(0) !== "_");
  const [checked, setChecked] = useState(visibleColumns.map((c) => c.id));
  const [columnOptions] = useState(hideableColumns);
  const checkedCount = hideableColumns.reduce((acc, val) => acc + (val.isVisible ? 0 : 1), 0);
  const onlyOneOptionLeft = checkedCount + 1 >= hideableColumns.length;

  const handleColumnSelectChange = (event) => {
    if (event.target.checked) {
      setChecked([...checked, event.target.name]);
    } else {
      setChecked(checked.filter((c) => c !== event.target.name));
    }
  };

  const handlePresentationModeChange = () => {
    // TODO: Confirm which columns they wanted to hide in presentation mode
    // toggleHideColumn("submissionOwnerGrade", true);
    // toggleHideColumn("jobOwnerGrade", true);
    setChecked(checked.filter((c) => c !== "submissionOwnerGrade" && c !== "jobOwnerGrade"));
    dispatch.app.setPresentationMode(!presentationMode);
  };

  return (
    <Box className={classes.contentContainer}>
      <Box
        className={classes.drawerHeader}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Typography variant="h3" style={{ fontWeight: "600", color: colors.white }}>
            Table Settings
          </Typography>
        </Box>
      </Box>
      <Box className={classes.drawerTabContent}>
        {/* <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="none"
          orientation={"horizontal"}
          variant={"standard"}
        >
          {["Table"].map((tabName, index) => {
            return (
              <Tab
                key={tabName}
                label={tabName}
                className={classes.tab}
                style={{
                  fontWeight: tabValue === index ? "bold" : "normal",
                  borderBottom:
                    tabValue === index
                      ? `2px solid ${colors.varinerLight}`
                      : "none",
                }}
              />
            );
          })}
        </Tabs> */}
        {/* <TabPanel value={tabValue} index={0} dir={theme.direction}> */}
        <Box mb={5}>
          <Typography variant="body2">
            Select or deselect columns to show / hide them in the table view.
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end" mb={2}>
          <Typography variant="body2" style={{ color: colors.gray43, fontSize: 10 }}>
            Presentation Mode
          </Typography>
          <AntSwitch
            checked={presentationMode}
            onChange={handlePresentationModeChange}
            color="primary"
            name="presentationMode"
            inputProps={{ "aria-label": "presentationMode" }}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          py={1.5}
          style={{ borderBottom: `1px solid ${colors.gray10}` }}
        >
          <Typography style={{ fontSize: 12, color: colors.gray43 }}>Column Title</Typography>
          <Typography style={{ fontSize: 12, color: colors.gray43 }}>Visible</Typography>
        </Box>
        <Box>
          <FormControl
            // required
            component="fieldset"
            className={classes.formControl}
            variant="standard"
          >
            {/* <FormLabel component="legend">Pick two</FormLabel> */}
            <FormGroup style={{ width: "100%" }}>
              {columnOptions &&
                !!columnOptions.length &&
                columnOptions.map((column) => {
                  return (
                    <FormControlLabel
                      key={column.id}
                      id={column.id}
                      label={_.startCase(column.id)}
                      labelPlacement="start"
                      style={{
                        marginLeft: 0,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: `1px solid ${colors.gray10}`,
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(1)
                      }}
                      control={
                        <Checkbox
                          style={{ fontSize: 14 }}
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedIcon />}
                          indeterminateIcon={<IndeterminateIcon />}
                          checked={checked.indexOf(column.id) > -1}
                          onChange={(event) => {
                            handleColumnSelectChange(event);
                            toggleHideColumn(column.id, column.isVisible);
                          }}
                          name={column.id}
                          disabled={(column.isVisible && onlyOneOptionLeft) || !column.hideable}
                        />
                      }
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </Box>
        {/* </TabPanel> */}
      </Box>
    </Box>
  );
}
