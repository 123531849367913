import { app } from "./app";
import { user } from "./user";
import { clients } from "./clients";
import { jobs } from "./jobs";
import { reporting } from "./reporting";
import { notes } from "./notes";
import { calls } from "./calls";
import { candidates } from "./candidates";
import { submissions } from "./submissions";
import { placements } from "./placements";
import { searches } from "./searches";

const store = {
  app,
  user,
  clients,
  jobs,
  reporting,
  notes,
  calls,
  candidates,
  submissions,
  placements,
  searches
};

export default store;
