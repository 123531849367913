/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Typography,
  Link,
  List,
  ListItemText,
  IconButton,
  Tabs,
  Tab,
  Snackbar,
  Button
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LinkIcon from "@mui/icons-material/Link";
import MailIcon from "@mui/icons-material/Mail";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GetAppIcon from "@mui/icons-material/GetApp";
import BhIcon from "@/assets/bullhorn-white.png";

import { colors } from "../styles/config";
import ProgressBar from "./ProgressBar";
import SubmissionStatusSelect from "./SubmissionStatusSelect";
import ScoreSubmissionForm from "./forms/ScoreSubmissionForm";
import PdfViewer from "./PdfViewer";
import { findStatusObject } from "@/helpers/statuses";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: "100%",
    backgroundColor: colors.white,
    borderRadius: 3
  },
  contentContainer: {
    width: "55vw"
  },
  drawerHeader: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    backgroundColor: colors.variner
  },
  drawerTabContent: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    maxWidth: 900,
    position: "relative"
  },
  tab: {
    textAlign: "left",
    minHeight: 0,
    minWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: theme.spacing(1 / 2),
    marginRight: theme.spacing(3)
  },
  grayText: {
    color: colors.gray43
  },
  helperText: {
    color: colors.gray43,
    fontSize: 12
  }
}));

const scoreCategories = [
  { id: "desire", title: "Desire to do the job" },
  { id: "ability", title: "Ability to do the job" },
  { id: "fit", title: "Fit with the team / culture" },
  { id: "reticence", title: "Additional Comments" }
];

const likertScale = ["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"];

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box p={0}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

export default function CandidateDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { submissions } = useSelector((state) => state.jobs);
  const { presentationMode } = useSelector((state) => state.app);
  const [copied, setCopied] = useState(false);
  const [submissionUpdated, setSubmissionUpdated] = useState(false);
  const [varinerScoreComments, setVarinerScoreComments] = useState(0);
  const { fetchingSubmissionData, currentSubmission } = useSelector((state) => state.jobs);

  const currentSubmissionJobId = useMemo(() => {
    return id || currentSubmission?.jobOrder?.id;
  }, [id, currentSubmission]);

  const [submissionInitialized, setSubmissionInitialized] = useState(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const currentTab = parseInt(query.get("tab")) - 1;
  const [tabValue, setTabValue] = useState(currentTab || 0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const updateSubmissionScoring = (values) => {
    const varinerScoreComments =
      values.varinerScoreDesireComments +
      values.varinerScoreAbilityComments +
      values.varinerScoreFitComments;

    const customTextBlock4 = varinerScoreComments;
    const customInt5 = values.submissionOwnerGrade;
    const customInt4 = values.jobOwnerGrade;
    const customInt1 = values.varinerScoreDesire;
    const customInt2 = values.varinerScoreAbility;
    const customInt3 = values.varinerScoreFit;

    const data = {
      customTextBlock4,
      customInt4,
      customInt5,
      customInt1,
      customInt2,
      customInt3
    };

    // remove any keys that have null for value so we don't overwrite something accidentally
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (!value) {
        delete data[key];
      }
    });

    // console.log("Update submission with new scoring data:", data);

    dispatch.jobs.updateSubmissionData({
      id: currentSubmission.id,
      data,
      submissions
    });
  };

  useEffect(() => {
    const fetchResume = async () => {
      try {
        await dispatch.jobs.fetchSubmissionResume({
          candidateId: currentSubmission.candidate.id,
          jobId: id,
          submissionId: currentSubmission.id
        });

        setSubmissionInitialized(true);
      } catch (error) {
        toast.error("Unable to get submission resume.");
      }
    };

    if (currentSubmission && !submissionInitialized) {
      fetchResume();
    }
  }, [currentSubmission, submissionInitialized, dispatch, id]);

  useEffect(() => {
    const getScoreComments = () => {
      let varinerScoreCommentsHtml = {
        desire: null,
        ability: null,
        fit: null,
        reticence: null
      };

      if (!currentSubmission.varinerScoreComments) return varinerScoreCommentsHtml;

      const el = document.createElement("div");
      el.innerHTML = currentSubmission.varinerScoreComments.trim();

      Object.keys(varinerScoreCommentsHtml).forEach(function (key) {
        varinerScoreCommentsHtml[key] = document.createElement("div");
      });

      let currentSection = "";
      const sections = ["desire", "ability", "fit", "reticence"];
      const nodesArray = [...el.childNodes];

      for (let i = 0; i < nodesArray.length; i++) {
        const node = nodesArray[i];
        const text = node.innerText ? node.innerText.replace(/[^A-Z0-9]/gi, "").toLowerCase() : "";
        if (sections.indexOf(text) > -1) {
          currentSection = text;
          continue;
        }

        // if we haven't continued then we need to add the rest of the nodes to it's section parent
        if (varinerScoreCommentsHtml[currentSection]) {
          if (node.tagName !== "HR") {
            varinerScoreCommentsHtml[currentSection].appendChild(node);
          }
        }
      }

      Object.keys(varinerScoreCommentsHtml).forEach((key) => {
        if (varinerScoreCommentsHtml[key].innerText === "") {
          varinerScoreCommentsHtml[key] = "";
        } else {
          varinerScoreCommentsHtml[key] = varinerScoreCommentsHtml[key].innerHTML;
        }
      });

      return varinerScoreCommentsHtml;
    };

    const varinerScoreCommentsHtml = getScoreComments();

    setVarinerScoreComments(varinerScoreCommentsHtml);
  }, [currentSubmission, submissionInitialized]);

  const answers = [
    {
      question: "Why I am interested in this opportunity",
      answer: currentSubmission.customTextBlock1
    },
    {
      question: "My relevant work experience",
      answer: currentSubmission.customTextBlock3
    },
    {
      question: "Why I am interested in the location",
      answer: currentSubmission.customTextBlock2
    },
    {
      question: "Earliest Start Date",
      answer:
        currentSubmission.customDate2 &&
        moment(currentSubmission.customDate2).format("MMM Do, YYYY")
    },
    {
      question: "Ideal Start Date",
      answer:
        currentSubmission.customDate1 &&
        moment(currentSubmission.customDate1).format("MMM Do, YYYY")
    }
  ].filter((a) => a.answer);

  const answeredTotal = answers.length;
  const emptyOptInQA = !!(answeredTotal === 0);

  const isRejected =
    _.get(currentSubmission, ["history", "0"]) &&
    findStatusObject(_.get(currentSubmission, ["history", "0"])?.status) &&
    findStatusObject(_.get(currentSubmission, ["history", "0"])?.status)?.group === "Rejected";

  return (
    <Box className={classes.contentContainer}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={copied || submissionUpdated}
        autoHideDuration={2000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }

          setCopied(false);
          setSubmissionUpdated(false);
        }}
        message={copied ? "Copied to clipboard" : submissionUpdated ? "Submission Updated" : ""}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(event, reason) => {
                if (reason === "clickaway") {
                  return;
                }

                setCopied(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Box
        className={classes.drawerHeader}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
            <Typography variant="h3" style={{ fontWeight: "600", color: colors.white }}>{`${_.get(
              currentSubmission,
              ["candidate", "firstName"]
            )} ${_.get(currentSubmission, ["candidate", "lastName"])}`}</Typography>
            <Box display="flex" alignItems="center" ml={1}>
              {_.get(currentSubmission, ["candidate", "email"]) && (
                <Link
                  style={{
                    color: "rgba(255, 255, 255, 0.6)",
                    "&:hover": {
                      color: "rgba(255, 255, 255, 0.8)"
                    }
                  }}
                  size="small"
                  target="_blank"
                  href={`mailto:${_.get(currentSubmission, ["candidate", "email"])}`}
                  underline="hover"
                >
                  <MailIcon size="small" style={{ height: 20 }} />
                </Link>
              )}

              {_.get(currentSubmission, ["candidate", "linkedinUrl"]) && (
                <Link
                  style={{
                    color: "rgba(255, 255, 255, 0.6)",
                    "&:hover": {
                      color: "rgba(255, 255, 255, 0.8)"
                    }
                  }}
                  color="secondary"
                  size="small"
                  target="_blank"
                  href={`${_.get(currentSubmission, ["candidate", "linkedinUrl"])}`}
                  underline="hover"
                >
                  <LinkedInIcon size="small" style={{ height: 20 }} />
                </Link>
              )}
              {!presentationMode && (
                <Link
                  style={{
                    color: "rgba(255, 255, 255, 0.6)",
                    "&:hover": {
                      color: "rgba(255, 255, 255, 0.8)"
                    }
                  }}
                  color="secondary"
                  size="small"
                  target="_blank"
                  href={`https://cls31.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=${currentSubmission?.candidate?.id}`}
                  underline="hover"
                >
                  <img
                    src={BhIcon}
                    style={{ maxWidth: 20, opacity: 0.6 }}
                    alt="Bullhorn app icon"
                  />
                </Link>
              )}
              {!presentationMode && (
                <CopyToClipboard
                  text={`${
                    window.location.origin
                  }/variner/job/${currentSubmissionJobId}?submission=${currentSubmission?.id}&tab=${
                    tabValue + 1
                  }`}
                  onCopy={() => {
                    setCopied(true);
                  }}
                >
                  <LinkIcon
                    onClick={{}}
                    color="secondary"
                    size="small"
                    style={{
                      marginTop: "-3px",
                      height: 20,
                      cursor: "pointer",
                      transform: "rotate(45deg)",
                      color: "rgba(255, 255, 255, 0.6)",
                      "&:hover": {
                        color: "rgba(255, 255, 255, 0.8)"
                      }
                    }}
                  />
                </CopyToClipboard>
              )}
            </Box>
          </Box>
          <Typography variant="body2" style={{ color: "rgba(255, 255, 255, 0.6)" }}>
            {`${_.get(currentSubmission, ["candidate", "address", "city"])}, ${_.get(
              currentSubmission,
              ["candidate", "address", "state"]
            )}`}
          </Typography>
        </Box>
        <SubmissionStatusSelect
          currentSubmission={currentSubmission}
          currentSubmissionId={currentSubmission.id}
        />
      </Box>
      <Box className={classes.drawerTabContent}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="none"
            orientation={"horizontal"}
            variant={"standard"}
          >
            {[
              "Resume",
              "Opt-in Q&A",
              "Variner Comments",
              "Update Submission",
              ...(findStatusObject(_.get(currentSubmission, ["history", "0"])?.status)?.group ===
                "Rejected" && currentSubmission.history[0].comments
                ? ["Rejected Comments"]
                : [])
            ].map((tabName, index) => {
              return (
                <Tab
                  key={tabName}
                  label={tabName}
                  className={classes.tab}
                  style={{
                    color: colors.variner,
                    fontWeight: tabValue === index ? "bold" : "normal",
                    borderBottom: tabValue === index ? `2px solid ${colors.varinerLight}` : "none"
                  }}
                />
              );
            })}
          </Tabs>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            style={{ minHeight: 48 }}
          >
            <AssignmentIndIcon size="small" style={{ maxHeight: 18, color: colors.gray43 }} />
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "normal"
              }}
            >
              {`${currentSubmission?.sendingUser?.firstName} ${currentSubmission?.sendingUser?.lastName}`}
            </Typography>
          </Box>
        </Box>
        <TabPanel value={tabValue} index={1} dir={theme.direction}>
          {emptyOptInQA && <Typography>No Opt In Q&A found for this submission.</Typography>}
          {!emptyOptInQA && (
            <List>
              {answers &&
                answers.length &&
                answers.map((answer) => (
                  <ListItemText
                    style={{ paddingBottom: 24 }}
                    alignItems="flex-start"
                    key={answer.question.replace(" ", "").slice(0, 30)}
                    primary={
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        style={{
                          fontWeight: "bold",
                          marginBottom: 8
                        }}
                      >
                        {answer.question}
                      </Typography>
                    }
                    secondary={
                      <Typography component="span" variant="body2" color="textPrimary">
                        {answer.answer}
                      </Typography>
                    }
                  />
                ))}
            </List>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={0} dir={theme.direction}>
          {fetchingSubmissionData && !currentSubmission.resume && (
            <Typography>Loading Resume...</Typography>
          )}
          {!currentSubmission?.resume?.fileData && (
            <Typography>No resume found for this submission.</Typography>
          )}
          {currentSubmission?.resume?.base64 && currentSubmission?.resume.fileData && (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Link
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: colors.varinerLight,
                  position: "absolute",
                  right: theme.spacing(6),
                  top: theme.spacing(6)
                }}
                download={`${_.get(currentSubmission, ["candidate", "firstName"])}${_.get(
                  currentSubmission,
                  ["candidate", "lastName"]
                )}_Resume`}
                href={`data:application/pdf;base64,${currentSubmission.resume?.base64}`}
                target="_blank"
                underline="hover"
              >
                <GetAppIcon style={{ height: 20 }} />
                <Typography variant="body2">Download</Typography>
              </Link>

              <Typography
                style={{
                  fontSize: 12,
                  width: "100%",
                  textAlign: "right"
                }}
              >
                {`Resume added on ${moment(
                  _.get(currentSubmission, ["resume", "dateAdded"])
                ).format("MM/DD/YYYY")}`}
              </Typography>

              <PdfViewer pdfBufferObj={currentSubmission.resume.fileData} />
            </Box>
          )}
        </TabPanel>
        {/* Variner Comments Panel  */}
        <TabPanel value={tabValue} index={2} dir={theme.direction}>
          <Box>
            {!currentSubmission.varinerScoreComments && (
              <Typography variant="body2">No score comments found for this submission.</Typography>
            )}
            <List>
              {scoreCategories.map((category) => {
                if (
                  !currentSubmission.varinerScore?.[category.id] &&
                  !varinerScoreComments?.[category.id]
                )
                  return null;

                return (
                  <ListItemText
                    style={{ paddingBottom: 24 }}
                    alignItems="flex-start"
                    key={category}
                    primary={
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          style={{
                            fontWeight: "bold",
                            marginBottom: 8
                          }}
                        >
                          {category.title}
                        </Typography>

                        {currentSubmission.varinerScore[category.id] && (
                          <ProgressBar
                            height={8}
                            backgroundColor={"rgba(62, 159, 172, 0.3)"}
                            labelAfter={
                              likertScale[currentSubmission.varinerScore[category.id] - 1]
                            }
                            labelStyle={{
                              fontSize: 12
                            }}
                            labelAfterStyle={{ minWidth: 95 }}
                            style={{
                              marginTop: theme.spacing(1),
                              marginBottom: theme.spacing(2),
                              width: "50%"
                            }}
                            value={(currentSubmission.varinerScore[category.id] / 5) * 100}
                          />
                        )}
                      </Box>
                    }
                    secondary={
                      !varinerScoreComments[category.id] ? null : (
                        <Box
                          className="external-html-container"
                          style={{ color: "black" }}
                          dangerouslySetInnerHTML={{
                            __html: varinerScoreComments[category.id]
                          }}
                        />
                      )
                    }
                  />
                );
              })}
            </List>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={3} dir={theme.direction}>
          <Box>
            {currentSubmission?.status === "Pending Interest" && (
              <React.Fragment>
                <CopyToClipboard
                  text={`${window.location.origin}/opt/${currentSubmissionJobId}?submission=${currentSubmission.id}`}
                  onCopy={() => {
                    if (!currentSubmission.customText11) {
                      dispatch.jobs.updateSubmissionData({
                        id: currentSubmission.id,
                        data: {
                          customText11: `https://app.variner.com/opt/${currentSubmissionJobId}?submission=${currentSubmission.id}`
                        },
                        submissions
                      });
                    }
                    setCopied(true);
                  }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<FileCopyIcon size="small" />}
                    style={{
                      color: colors.variner,
                      marginBottom: theme.spacing(2)
                    }}
                  >
                    Copy pending interest link
                  </Button>
                </CopyToClipboard>
              </React.Fragment>
            )}
            <ScoreSubmissionForm
              varinerScoreComments={varinerScoreComments}
              onSubmit={updateSubmissionScoring}
            />
          </Box>
        </TabPanel>
        {/* Rejected Comments Panel */}
        <TabPanel value={tabValue} index={4} dir={theme.direction}>
          <Box>
            <List>
              {isRejected && (
                <ListItemText
                  style={{ paddingBottom: 24 }}
                  alignItems="flex-start"
                  primary={
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        style={{
                          fontWeight: "bold",
                          marginBottom: 8
                        }}
                      >
                        Rejected Comments
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Box
                      className="external-html-container"
                      style={{ color: "black" }}
                      dangerouslySetInnerHTML={{
                        __html: currentSubmission.history[0].comments
                      }}
                    ></Box>
                  }
                />
              )}
            </List>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
}
