import React, { useEffect } from "react";

// 3rd party libraries
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import _ from "lodash";

// components
import SelectJobForm from "@/components/forms/SelectJobForm";
import { colors } from "@/styles/config";
import Splash from "@/components/Splash";

export default function JobLanding() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { loading } = useSelector((state) => state.jobs);
  let history = useHistory();
  const { id } = useParams();

  const goToJobPage = (values) => {
    const jobId = _.get(values, ["job", "id"]);
    // console.log("Navigate to job #:", jobId);
    if (jobId) {
      history.push(`/variner/job/${jobId}`);
    } else {
      dispatch.jobs.setError({
        error: "Weird. Something went wrong. Try again?"
      });
    }
  };

  useEffect(() => {
    if (!id) {
      dispatch.jobs.setCurrentJob(null);
    }
  }, [id, dispatch]);

  if (loading) {
    return <Splash />;
  }

  return (
    <Box style={{ height: "100vh", width: "100vw" }}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box style={{ width: "40%" }} mb={24}>
          <Typography style={{ color: colors.gray43, marginBottom: theme.spacing(2) }}>
            Select a Job
          </Typography>
          <SelectJobForm onSubmit={goToJobPage} />
        </Box>
      </Box>
    </Box>
  );
}
