/* eslint-disable react/prop-types */
// ↓ beloved react ↓
import React, { useRef, useState } from "react";

// ↓ 3rd party utils ↓
import _ from "lodash";

// ↓ 3rd party components ↓
import { Box, Typography, Popover, IconButton, Button, useTheme } from "@mui/material";

import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

// ↓ shared types ↓

// ↓ models & types ↓

// ↓ navigation ↓

// ↓ screens ↓

// ↓ modals ↓

// ↓ forms ↓

// ↓ components ↓
import ContentPill from "../ContentPill";

// ↓ fragments ↓

// ↓ hooks ↓

// ↓ utils ↓
import {
  DefaultColumnFilter,
  SelectColumnFilter,
  RecruiterFilter,
  CheckboxColumnFilter,
  PriorityColumnFilter
} from "./filters";

import { colors } from "../../styles/config";
import DateSelect from "./filters/DateSelect";
import { useDispatch, useSelector } from "react-redux";

// ↓ constants ↓

// ↓ assets ↓

// ---

const useStyles = makeStyles((theme) => ({
  container: { marginBottom: theme.spacing(3) },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3)
  },
  rightSideContainer: {
    display: "flex",
    gap: 20,
    alignItems: "center"
  },
  dateFilterButton: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 6,
    paddingRight: 5,
    paddingLeft: 15,
    alignItems: "center",
    fontSize: 14,
    backgroundColor: colors.gray1,
    border: `2px solid ${colors.gray1}`,
    cursor: "pointer",
    gap: 15,
    "&:hover": {
      backgroundColor: colors.gray1,
      border: "2px solid #2699fb"
    }
  },
  pill: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    transition: "background-color 0.3s ease",
    backgroundColor: "#f8f8f8",
    border: `1px solid ${colors.gray1}`,
    flexGrow: 1
  }
}));

export default function ActiveSearchesFilterRow({
  columnHeaders,
  handleDateRangeSelectionChange,
  rangeSelectionMap,
  filters,
  filteredFlatRows,
  setAllFilters
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [openFilters, setOpenFilters] = useState(false);
  const popoverAnchorRef = useRef();
  const { loading } = useSelector((state) => state.searches);
  const dispatch = useDispatch();

  const { rangeSelection, customDateRange } = useSelector((state) => state.app);

  const filterColumnHeaders = _.chain(columnHeaders)
    .filter((columnHeader) => columnHeader.id.indexOf("_") < 0)
    .groupBy("id")
    .value();

  const handleRemoveFilter = ({ filter, value: valueToRemove }) => {
    const column = columnHeaders.find((columnHeader) => columnHeader.id === filter.id);

    const { setFilter } = column;

    const newFilterArray = _.without(filter.value, valueToRemove);

    setFilter(newFilterArray);
  };

  if (!columnHeaders) return null;

  return (
    <Box className={classes.container}>
      <Box className={classes.topContainer}>
        <PriorityColumnFilter
          filteredFlatRows={filteredFlatRows}
          column={filterColumnHeaders.priority[0]}
        />
        <Box className={classes.rightSideContainer}>
          {filterColumnHeaders.search && (
            <DefaultColumnFilter column={filterColumnHeaders.search[0]} />
          )}
          <DateSelect
            handleDateRangeSelectionChange={handleDateRangeSelectionChange}
            rangeSelectionMap={rangeSelectionMap}
            rangeSelection={rangeSelection}
            setRangeSelection={(payload) => dispatch.app.setRangeSelection(payload)}
            customDateRange={customDateRange}
            setCustomDateRange={(payload) => dispatch.app.setCustomDateRange(payload)}
            disabled={loading}
            lifetime={true}
          />
        </Box>
      </Box>
      <Box ref={popoverAnchorRef}>
        <Box display="flex" alignItems="center">
          <ContentPill
            containerStyle={{ maxWidth: 70, minHeight: 26 }}
            title="+ Filter"
            onClick={(event) => {
              event.stopPropagation();
              setOpenFilters(true);
            }}
          />
          {filters && (
            <Box ml={1} display="flex" flexWrap={true}>
              {filters?.map((filter, index) => {
                if (!filter.value || !_.size(filter.value)) return null;

                return (
                  <Box display="flex" flexWrap key={index}>
                    {_.size(filter?.value) &&
                      filter?.id !== "search" &&
                      filter?.value?.map((filterValue, index) => {
                        return (
                          <Box key={index} mr={1} className={classes.pill}>
                            <Box px={1.5} display="flex">
                              <Typography
                                sx={{
                                  pointerEvents: "none",
                                  opacity: 0.4
                                }}
                              >
                                {filter.id.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                              </Typography>
                              <Box px={0.5}></Box>
                              <Typography sx={{ pointerEvents: "none" }}>{filterValue}</Typography>
                            </Box>
                            <Box
                              onClick={() =>
                                handleRemoveFilter({
                                  filter,
                                  value: filterValue
                                })
                              }
                            >
                              <CloseIcon fontSize="tiny" />
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
        <Popover
          open={openFilters}
          onClose={() => setOpenFilters(false)}
          anchorEl={popoverAnchorRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          sx={{ width: 500 }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 40,
              padding: theme.spacing(1),
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              backgroundColor: "#2d2d2d",
              color: "white"
            }}
          >
            <Typography variant="h3">Filters</Typography>
            <IconButton
              sx={{ color: "white" }}
              aria-label="Close"
              onClick={() => setOpenFilters(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              padding: theme.spacing(3)
            }}
          >
            <Box mb={2.5}>
              <Typography variant="subtitle2" style={{ fontWeight: "600", color: colors.darkGray }}>
                Account Manager
              </Typography>
              <CheckboxColumnFilter column={filterColumnHeaders.searchOwner?.[0]} />
            </Box>
            <Box mb={2.5}>
              <Typography variant="subtitle2" style={{ fontWeight: "600", color: colors.darkGray }}>
                Team
              </Typography>
              <RecruiterFilter column={filterColumnHeaders.team?.[0]} />
            </Box>
            <Box mb={2.5}>
              <Typography variant="subtitle2" style={{ fontWeight: "600", color: colors.darkGray }}>
                Function
              </Typography>
              {filterColumnHeaders.function?.[0] && (
                <SelectColumnFilter column={filterColumnHeaders.function?.[0]} />
              )}
            </Box>
            {/* <Box>
              <Typography variant="subtitle2"
                style={{ fontWeight: "600", color: colors.darkGray }}>
                Region
              </Typography>
            </Box> */}
            <Box mb={2.5}>
              <Typography variant="subtitle2" style={{ fontWeight: "600", color: colors.darkGray }}>
                City
              </Typography>
              {filterColumnHeaders.city?.[0] && (
                <SelectColumnFilter column={filterColumnHeaders.city?.[0]} />
              )}
            </Box>
            <Box mb={2.5}>
              <Typography variant="subtitle2" style={{ fontWeight: "600", color: colors.darkGray }}>
                Status
              </Typography>
              {filterColumnHeaders.status?.[0] && (
                <CheckboxColumnFilter column={filterColumnHeaders.status?.[0]} />
              )}
            </Box>
            <Box mb={1}>
              <Typography variant="subtitle2" style={{ fontWeight: "600", color: colors.darkGray }}>
                Priority
              </Typography>
              {filterColumnHeaders.priority?.[0] && (
                <CheckboxColumnFilter column={filterColumnHeaders.priority?.[0]} />
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" mb={2} mx={1}>
            <Button variant="default" onClick={() => setAllFilters([])}>
              Clear All Filters
            </Button>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}
