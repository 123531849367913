/* eslint-disable react/prop-types */
// ↓ beloved react ↓
import React, { useMemo, useRef, useState, useEffect } from "react";

// ↓ 3rd party utils ↓

// ↓ 3rd party components ↓
import MaUTable from "@mui/material/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Button,
  IconButton,
  Popover
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { usePagination, useSortBy, useTable, useFilters } from "react-table";
import { Typography, Box, Grid } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LaunchIcon from "@mui/icons-material/Launch";
import Pagination from "@mui/material/Pagination";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

// ↓ shared types ↓

// ↓ models & types ↓

// ↓ navigation ↓

// ↓ screens ↓

// ↓ modals ↓

// ↓ forms ↓

// ↓ components ↓

// ↓ fragments ↓

// ↓ hooks ↓

// ↓ utils ↓

import { DefaultColumnFilter, CheckboxColumnFilter } from "@/components/tables/filters";

// ↓ constants ↓
import { colors } from "@/styles/config";
import SellingPointsDrawer from "../SellingPointsDrawer";
import SubmissionsTableCell from "./SubmissionsTableCell";

// ↓ assets ↓

// ---

// const SubmissionsTableCell = ({ jobId, value, handleSubmissionsBarClick }) => {
//   const { activeJobs } = useSelector((state) => state.jobs);
//   const job = activeJobs?.find((job) => job.id === jobId);

//   // const theme = useTheme();
//   if (!value || !job) return null;

//   return (
//     <Box
//       style={{ marginTop: -4, maxWidth: 300, minWidth: 240 }}
//       display="flex"
//       alignItems="center"
//       justifyContent="space-between"
//     >
//       <SubmissionsStatusBar
//         height={30}
//         margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
//         showLegend={false}
//         filteredSubmissions={sortSubmissionsByStatus(value)}
//         onBarClick={(barData) => {
//           handleSubmissionsBarClick({
//             data: barData,
//             job,
//             submissions: value,
//           });
//         }}
//       />
//     </Box>
//   );
// };

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: "white"
  },
  currentRow: {
    backgroundColor: "rgba(26, 68, 74, 0.1)",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px"
  },
  currentRowCell: {
    fontWeight: "bold"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  headerRow: {
    boxShadow: "0px 10px 15px -14px grey",
    "& .MuiTableCell-root": {
      borderTop: `2px solid ${colors.gray1}`,
      padding: "10px 10px"
    }
  },
  loadingLine: {
    height: 60,
    width: "100%",
    backgroundColor: colors.gray1,
    marginTop: theme.spacing(1),
    borderRadius: 3
  }
}));

const DefaultCell = ({ value }) => {
  return <Typography variant="subtitle2">{value}</Typography>;
};

// Set our default cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: DefaultCell,
  Filter: DefaultColumnFilter
};

const ActiveSearchesTableCompact = ({
  data,
  handleSubmissionsBarClick,
  skipPageReset,
  loading
}) => {
  const theme = useTheme();
  // const { loading } = useSelector((state) => state.reporting);
  const [toggleSellingPointsDrawer, setToggleSellingPointsDrawer] = useState({
    open: false,
    type: null
  });

  const sortTypes = useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId, desc) {
        if (!rowA.values[columnId] && !rowB.values[columnId]) {
          return 0;
        }

        if (!rowA.values[columnId]) {
          return desc ? -1 : 1;
        }

        if (!rowB.values[columnId]) {
          return desc ? 1 : -1;
        }

        return rowA.values[columnId].localeCompare(rowB.values[columnId]);
      }
    }),
    []
  );

  // TODO: move make columns to its own file
  const columns = useMemo(
    () => [
      {
        Header: "Search / Client",
        accessor: "search",
        width: "50%",
        hideable: true,
        Cell: ({ cell: { value }, row }) => {
          return (
            <Box
              style={{ cursor: "pointer" }}
              onClick={() => {
                setToggleSellingPointsDrawer({
                  open: true,
                  type: value,
                  id: row.original.id
                });
              }}
            >
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: theme.typography.fontWeightSemibold,
                  fontSize: 14,
                  color: colors.varinerBlue
                }}
              >{`${value}`}</Typography>
              <Typography
                variant="body2"
                style={{ fontSize: 14 }}
              >{`${row.original.clientCorporationName}`}</Typography>
            </Box>
          );
        }
      },

      {
        Header: "Status",
        accessor: "status",
        width: "12%",
        hideable: true,
        Filter: CheckboxColumnFilter,
        filter: "includesSome",
        Cell: ({ cell: { value } }) => {
          if (!value) return null;

          return (
            <Typography
              noWrap
              style={{
                fontSize: 14,
                maxWidth: 80
              }}
            >
              {value}
            </Typography>
          );
        }
      },

      {
        Header: "Priority",
        accessor: "priority",
        width: "8%",
        hideable: true,
        Filter: CheckboxColumnFilter,
        filter: "includesSome",
        Cell: ({ cell: { value } }) => {
          if (!value) return null;

          return (
            <Typography
              noWrap
              style={{
                fontSize: 14,
                maxWidth: 80
              }}
            >
              {value}
            </Typography>
          );
        }
      },

      {
        Header: "submissions",
        accessor: "submissions",
        width: "20%",
        hideable: true,
        disableFilters: true,
        sortType: (rowA, rowB) => {
          if (rowA.original.submissions?.length > rowB.original.submissions?.length) return -1;
          if (rowB.original.submissions?.length > rowA.original.submissions?.length) return 1;
          return 0;
        },
        Cell: ({ cell: { value }, row }) => {
          return (
            <SubmissionsTableCell
              handleSubmissionsBarClick={handleSubmissionsBarClick}
              jobId={row.original.id}
              value={value}
            />
          );
        }
      },

      {
        Header: "Call Time",
        accessor: "callTime",
        width: "15%",
        hideable: true,
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          if (!value) return null;

          return (
            <Typography
              style={{
                fontSize: 14
              }}
            >
              {value}
            </Typography>
          );
        }
      }
    ],
    [handleSubmissionsBarClick, theme]
  );

  // TODO: (Jacob) Lets move this into it's own file and put it in .../tables/hooks
  const useOverflowMenu = (hooks) => {
    hooks.visibleColumns.unshift((columns) => {
      return [
        ...columns,
        {
          id: "_overflowMenu",
          // Make this column a groupByBoundary. This ensures that
          // groupBy columns are placed after it
          groupByBoundary: true,
          Header: () => (
            <SettingsOutlinedIcon
              sx={{
                fontSize: 23,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 1
              }}
            />
          ),
          Cell: ({ row }) => {
            const [open, setOpen] = useState(false);
            const popoverAnchorRef = useRef();

            return (
              <React.Fragment>
                <IconButton
                  ref={popoverAnchorRef}
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpen(true);
                  }}
                >
                  <MoreVertIcon
                    size="small"
                    style={{
                      width: 24,
                      height: 24,
                      padding: theme.spacing(0.5),
                      color: "rgba(144, 144, 144, 0.8)"
                    }}
                  />
                </IconButton>
                <Popover
                  open={open}
                  onClose={() => setOpen(false)}
                  anchorEl={popoverAnchorRef.current}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection="column"
                    p={1}
                  >
                    <Button
                      color="primary"
                      endIcon={<LaunchIcon />}
                      onClick={() => window.open(`/variner/job/${row.original.id}`)}
                    >
                      Candidate Slate
                    </Button>
                    <Button
                      color="primary"
                      endIcon={<LaunchIcon />}
                      onClick={() => window.open(`/variner/reporting/${row.original.id}`)}
                    >
                      Service Delivery Dashboard
                    </Button>
                    <Button
                      component="a"
                      color="primary"
                      endIcon={<LaunchIcon />}
                      target="_blank"
                      href={`https://cls31.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Job&id=${row.original.id}`}
                    >
                      Bullhorn
                    </Button>
                  </Box>
                </Popover>
              </React.Fragment>
            );
          },
          width: 50,
          canSort: false
        }
      ];
    });
  };

  const hooks = [useFilters, useSortBy, usePagination, useOverflowMenu];

  const instance = useTable(
    {
      columns,
      data,
      sortTypes,
      defaultColumn,
      autoResetPage: !skipPageReset,
      autoResetFilters: false,
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      initialState: {
        pageSize: 6,
        pageIndex: 0,
        sortBy: [{ id: "launchDate", desc: false }],
        hiddenColumns: columns
          .map((column) => {
            if (column.show === false) {
              return column.accessor || column.id;
            } else {
              return null;
            }
          })
          .filter((c) => c)
      }
    },
    ...hooks
  );

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    // setAllFilters,
    filteredFlatRows,
    state: {
      pageIndex,
      pageSize
      // filters
    }
  } = instance;

  useEffect(() => {
    setPageSize(6);
  }, [setPageSize]);

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage - 1);
  };

  if (loading)
    return (
      <Box>
        <Box className={classes.loadingLine}></Box>
        <Box className={classes.loadingLine}></Box>
        <Box className={classes.loadingLine}></Box>
        <Box className={classes.loadingLine}></Box>
        <Box className={classes.loadingLine}></Box>
        <Box className={classes.loadingLine}></Box>
      </Box>
    );

  // Render the UI for your table
  return (
    <React.Fragment>
      <SellingPointsDrawer
        componentTitle="Selling Points"
        open={toggleSellingPointsDrawer}
        toggleDrawer={setToggleSellingPointsDrawer}
      />

      <Grid>
        <Grid>
          <TableContainer
            sx={{
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none"
              },
              scrollbarWidth: "none"
            }}
          >
            <MaUTable stickyHeader {...getTableProps()}>
              <TableHead
                sx={{
                  "& .MuiTableCell-root:nth-child(2)": {
                    zIndex: 0,
                    boxShadow: `0 0 14px 14px ${colors.gray10}`,
                    clipPath: "polygon(0% 0%, 120% 0%, 120% 100%, 0% 100%)"
                  }
                }}
                className={classes.headerRow}
              >
                {headerGroups.map((headerGroup, index) => (
                  <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <TableCell
                        key={index}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        size="small"
                        active={column.isSorted ? "true" : "false"}
                        align={column.id === "accountManager" ? "center" : "left"}
                        style={{
                          width: column.width,
                          fontSize: 12,
                          paddingTop: theme.spacing(2),
                          paddingBottom: theme.spacing(2),
                          paddingLeft: theme.spacing(2),
                          textTransform: "capitalize"
                        }}
                      >
                        {column.id.charAt(0) !== "_" ? (
                          <TableSortLabel
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            size="small"
                            direction={
                              column.isSortedDesc || column.id === "submissions" ? "desc" : "asc"
                            }
                            active={column.isSorted}
                          >
                            {column.render("Header")}
                            {column.isSorted ? (
                              <>
                                {column.isSortedDesc ? (
                                  <KeyboardArrowDownOutlinedIcon size="small" />
                                ) : (
                                  <KeyboardArrowUpOutlinedIcon size="small" />
                                )}
                              </>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          column.render("Header")
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody
                sx={{
                  "& .MuiTableRow-root > td:nth-child(2)": {
                    boxShadow: 0,
                    clipPath: 0
                  }
                }}
                className={classes.tableBody}
              >
                {page.map((row, i) => {
                  prepareRow(row);

                  return (
                    <TableRow key={i} {...row.getRowProps()}>
                      {row.cells.map((cell, index) => {
                        return (
                          <TableCell
                            key={index}
                            {...cell.getCellProps()}
                            size="small"
                            sx={{
                              width: cell.column.width,
                              paddingTop: theme.spacing(2),
                              paddingBottom: theme.spacing(2),
                              verticalAlign: "top",
                              fontSize: 14
                            }}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </MaUTable>
          </TableContainer>
        </Grid>

        {data && data.length > 0 && (
          <Box
            style={{
              marginTop: theme.spacing(2),
              display: "flex",
              justifyContent: "end"
            }}
          >
            <Pagination
              count={Math.ceil(filteredFlatRows.length / pageSize)}
              shape="rounded"
              onChange={handleChangePage}
              hideNextButton={true}
              hidePrevButton={true}
              page={pageIndex + 1}
            />
          </Box>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default ActiveSearchesTableCompact;
