import _ from "lodash";

export default function makeSubmissionsData(groupedNewSubmissions) {
  return groupedNewSubmissions.map((submission) => {
    const candidate = submission["candidate"];
    const jobOrder = submission["jobOrder"];

    return {
      id: _.get(submission, ["id"]),
      jobId: _.get(jobOrder, ["id"]),
      candidateId: _.get(submission, ["candidate", "id"]),
      linkedinUrl:
        _.get(submission, ["candidate", "customText5"]) ||
        _.get(submission, ["candidate", "linkedinUrl"]),
      name: `${_.get(candidate, ["firstName"])} ${_.get(candidate, ["lastName"])}`,
      search: `${_.get(jobOrder, ["title"])} (${_.get(jobOrder, ["id"])})`,
      status: `${_.get(submission, ["status"])}`
    };
  });
}
