import { FormControl, Switch } from "@mui/material";
import PropTypes from "prop-types";

const SellingPointsSwitch = ({ id, showFullSellingPoints, onChange }) => {
  return (
    <FormControl component="fieldset">
      <div style={{ display: "flex", alignItems: "center" }}>
        <p>Quick</p>

        <Switch
          id={id}
          aria-labelledby={`${id}-label`}
          defaultChecked={showFullSellingPoints}
          onChange={onChange}
        />

        <p>Full</p>
      </div>
    </FormControl>
  );
};

SellingPointsSwitch.propTypes = {
  id: PropTypes.string,
  showFullSellingPoints: PropTypes.bool,
  onChange: PropTypes.func
};

export { SellingPointsSwitch };
