/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../styles/config";
import makeStyles from "@mui/styles/makeStyles";
import ProgressBarForActiveSubmissions from "./ProgressBarForActiveSubmissions";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(2),
    "&:hover": {
      cursor: "pointer"
    }
  },
  title: {
    whiteSpace: "nowrap",
    fontSize: 14,
    color: colors.darkGray,
    minWidth: 140
  },
  total: {
    minWidth: 36,
    fontWeight: "bold",
    fontSize: 14,
    color: colors.darkGray
  }
}));

export default function ProgressRow({
  title,
  total,
  percentComplete,
  setToggleDrawer,
  submissions
}) {
  const classes = useStyles();

  return (
    <Box
      className={classes.row}
      onClick={() => setToggleDrawer({ open: true, type: title, data: submissions })}
    >
      <Typography className={classes.title} variant="body1">
        {title}
      </Typography>
      <ProgressBarForActiveSubmissions
        percentComplete={percentComplete}
      ></ProgressBarForActiveSubmissions>
      <Typography className={classes.total} variant="body2">
        {total}
      </Typography>
    </Box>
  );
}
