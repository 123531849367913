/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { Box } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";

import SubmissionsStatusPie from "./SubmissionsStatusPie";
import CandidateCard from "../CandidateCard";

import { sortSubmissionsByStatus } from "@/helpers/statuses";

export default function SubmissionsPanel({
  submissions,
  currentSubmissionStatus,
  setCurrentSubmissionStatus,
  currentDate
}) {
  const currentSubmissionsByStatus = useMemo(() => {
    if (currentSubmissionStatus) {
      return submissions.filter((submission) => submission.status === currentSubmissionStatus);
    } else {
      return submissions;
    }
  }, [currentSubmissionStatus, submissions]);

  const handlePieSliceClick = (data) => {
    const status = data ? data.id : null;
    setCurrentSubmissionStatus(status);
  };

  if (!submissions || !submissions.length) {
    return null;
  }

  return (
    <Box>
      {submissions && currentSubmissionsByStatus && (
        <Box width="100%" height={250}>
          <SubmissionsStatusPie
            submissions={submissions}
            showLabel={false}
            onSliceClick={handlePieSliceClick}
            currentDay={currentDate}
          />
        </Box>
      )}
      {submissions &&
        currentSubmissionsByStatus &&
        sortSubmissionsByStatus(currentSubmissionsByStatus).map((submission) => (
          <CandidateCard key={submission.id} submission={submission} />
        ))}
    </Box>
  );
}
