/* eslint-disable react/prop-types */
import React from "react";
import { Box, LinearProgress, linearProgressClasses } from "@mui/material";
import { colors } from "../styles/config";

function CustomLinearProgress({
  percentComplete,
  backgroundColor = colors.gray1,
  foregroundColor = colors.varinerLight
}) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress
        variant="determinate"
        value={Number(percentComplete)}
        sx={{
          height: 24,
          borderRadius: 1,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: backgroundColor
          },
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: foregroundColor
          }
        }}
      />
    </Box>
  );
}

export default function ProgressBarForActiveSubmissions({ percentComplete }) {
  return <CustomLinearProgress percentComplete={percentComplete} />;
}
