import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";

const LoadingOrContent = ({ isLoading, children }) => {
  return isLoading ? <CircularProgress /> : children;
};

LoadingOrContent.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired
};

LoadingOrContent.defaultProps = {
  isLoading: false
};

export { LoadingOrContent };
