import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import _ from "lodash";
import { getCallTime, convertNumToTime } from "../../helpers/callTime";
import { colors, constants } from "../../styles/config";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import BarCallTimeByActionAndOwner from "./BarCallTimeByActionAndOwner";
import ReportingFiltersMenu from "./ReportingFiltersMenu";
import StatCard from "./Cards/StatCard";

export default function ReportingJobSubmissions() {
  const { id } = useParams();
  const { newNotes } = useSelector((state) => state.reporting);
  const [barChartKeys, setBarChartKeys] = useState(null);
  const [filters, setFilters] = useState([]);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentBarChartKey, setCurrentBarChartKey] = useState(null);
  const [filteredBarData, setFilteredBarData] = useState(null);

  const jobNotes = useMemo(() => {
    return (
      newNotes &&
      newNotes.filter((note) => {
        const directJobOrder = note.jobOrder && note.jobOrder.id === parseInt(id);
        if (directJobOrder) {
          return true;
        } else {
          return (
            note.jobOrders &&
            note.jobOrders.data &&
            note.jobOrders.data.find((jobOrder) => jobOrder.id === parseInt(id))
          );
        }
      })
    );
  }, [newNotes, id]);

  const flattenedNotes = useMemo(() => {
    return (
      jobNotes &&
      jobNotes.map((note) => {
        return {
          ...note,
          commentingPersonId: note.commentingPerson.id,
          commentingPersonName: `${note.commentingPerson.firstName} ${note.commentingPerson.lastName}`,
          personReferenceId: note.personReference.id,
          personReferenceContactType: note.personReference.subtype
        };
      })
    );
  }, [jobNotes]);

  const totalCallTime = useMemo(() => {
    const filterComparator = (note) => {
      let currentActionPass = true;
      let currentBarChartKeyPass = true;

      if (currentAction) {
        currentActionPass = note.action === currentAction;
      }

      if (currentBarChartKey) {
        const commentingPersonName = `${note.commentingPerson.firstName} ${note.commentingPerson.lastName}`;
        currentBarChartKeyPass = commentingPersonName === currentBarChartKey;
      }

      return currentActionPass && currentBarChartKeyPass;
    };

    const filteredNotes =
      flattenedNotes &&
      flattenedNotes.filter((note) => {
        return filterComparator(note);
      });

    return filteredNotes && convertNumToTime(getCallTime(filteredNotes, "hrs"));
  }, [flattenedNotes, currentBarChartKey, currentAction]);

  const callTimeNotes = useMemo(() => {
    return flattenedNotes && flattenedNotes.filter((note) => note.minutesSpent);
  }, [flattenedNotes]);

  const notesByAction = useMemo(() => {
    return callTimeNotes && _.groupBy(callTimeNotes, "action");
  }, [callTimeNotes]);

  const notesByCommentingPerson = useMemo(() => {
    return flattenedNotes && _.groupBy(flattenedNotes, "commentingPersonName");
  }, [flattenedNotes]);

  useEffect(() => {
    if (notesByCommentingPerson && !barChartKeys) {
      setBarChartKeys(Object.keys(notesByCommentingPerson));
    }
  }, [notesByCommentingPerson, barChartKeys, setBarChartKeys]);

  useEffect(() => {
    if (!currentAction && !currentBarChartKey) {
      setFilters([]);
    } else {
      let newFilters = [];
      if (currentBarChartKey) {
        newFilters = [
          ...newFilters,
          {
            icon: PersonOutlineIcon,
            label: currentBarChartKey,
            onClick: () => {
              console.log("chip clicked");
            },
            onDelete: () => {
              setBarChartKeys(Object.keys(notesByCommentingPerson));
              setCurrentBarChartKey(null);
            }
          }
        ];
      }

      if (currentAction) {
        newFilters = [
          ...newFilters,
          {
            icon: null,
            label: currentAction,
            onClick: () => {
              console.log("chip clicked");
            },
            onDelete: () => {
              setCurrentAction(null);
              setFilteredBarData(null);
            }
          }
        ];
      }

      setFilters(newFilters);
    }
  }, [currentAction, currentBarChartKey, notesByCommentingPerson]);

  // TODO: implement this somehow
  // const notesByPersonReference =
  //   callTimeNotes && _.groupBy(callTimeNotes, "personReferenceId");
  // console.log("Timed Activity by Person Reference:", notesByPersonReference);

  const notesByContactType = useMemo(() => {
    if (callTimeNotes) {
      const filterComparator = (note) => {
        let currentActionPass = true;
        let currentBarChartKeyPass = true;

        if (currentAction) {
          currentActionPass = note.action === currentAction;
        }

        if (currentBarChartKey) {
          const commentingPersonName = `${note.commentingPerson.firstName} ${note.commentingPerson.lastName}`;
          currentBarChartKeyPass = commentingPersonName === currentBarChartKey;
        }

        return currentActionPass && currentBarChartKeyPass;
      };

      const candidateNotes = callTimeNotes.filter((note) => {
        const pass = filterComparator(note);
        const contactType = note.personReferenceContactType;
        return pass && contactType === "Candidate";
      });
      const clientNotes = callTimeNotes.filter((note) => {
        const pass = filterComparator(note);
        const contactType = note.personReferenceContactType;
        return pass && contactType === "ClientContact";
      });

      return {
        Candidate: candidateNotes,
        ClientContact: clientNotes
      };
    }
  }, [callTimeNotes, currentBarChartKey, currentAction]);

  const ownerColorMap = useMemo(() => {
    return (
      notesByCommentingPerson &&
      Object.keys(notesByCommentingPerson).reduce((acc, curr) => {
        return {
          ...acc,
          ...{
            [curr]: constants.colorsForData[curr]
          }
        };
      }, {})
    );
  }, [notesByCommentingPerson]);

  const byActionBarData = useMemo(() => {
    let sortable = [];
    for (let action in notesByAction) {
      sortable.push([action, notesByAction[action]]);
    }

    const barGraphData = sortable.map((actionData) => {
      const notes = actionData[1];
      const notesByOwner = _.groupBy(notes, "commentingPersonName");

      const notesByOwnerForGraph = Object.keys(notesByCommentingPerson).reduce((acc, curr) => {
        const currOwnerName = curr;
        const currOwnerNotes = notesByOwner[curr] || [];

        if (acc[currOwnerName]) return acc;

        return {
          ...acc,
          ...{
            [currOwnerName]: getCallTime(currOwnerNotes),
            [`${currOwnerName}Color`]: ownerColorMap[currOwnerName]
          }
        };
      }, {});

      return {
        action: actionData[0],
        ...notesByOwnerForGraph
      };
    });

    return barGraphData;
  }, [notesByAction, notesByCommentingPerson, ownerColorMap]);

  const statsConfig = useMemo(() => {
    return [
      {
        sort: 1,
        title: "Total Call Time",
        color: colors.varinerMedium,
        total: totalCallTime
      },
      {
        sort: 2,
        title: "Candidate Call Time",
        color: colors.varinerMedium,
        total: notesByContactType && convertNumToTime(getCallTime(notesByContactType.Candidate))
      },
      {
        sort: 3,
        title: "Client Contact Call Time",
        color: colors.varinerMedium,
        total: notesByContactType && convertNumToTime(getCallTime(notesByContactType.ClientContact))
      }
    ];
  }, [notesByContactType, totalCallTime]);

  return (
    <Box width="100%">
      <Box
        mb={3}
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {_.sortBy(statsConfig, "sort").map((stat) => (
          <StatCard stat={stat} shadow={false} key={stat.title} />
        ))}
      </Box>
      <Box ml={4}>
        {filters && filters.length > 0 && <ReportingFiltersMenu filters={filters} />}
      </Box>

      {byActionBarData && barChartKeys && barChartKeys.length && (
        <Box height={400}>
          <BarCallTimeByActionAndOwner
            data={filteredBarData || byActionBarData}
            keys={barChartKeys}
            currentBarChartKey={currentBarChartKey}
            setBarChartKeys={(id) => {
              setBarChartKeys([id]);
              setCurrentBarChartKey(id);
            }}
            handleActionClick={(event, id) => {
              setCurrentAction(id);
              setFilteredBarData(byActionBarData.filter((d) => d.action === id));
            }}
          />
        </Box>
      )}
    </Box>
  );
}
