/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { FormControlLabel, FormGroup } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as CheckboxIcon } from "../../../assets/icons/checkbox.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/checked-blue.svg";
import { ReactComponent as IndeterminateIcon } from "../../../assets/icons/indeterminate-blue.svg";
import _ from "lodash";
import { truncatedLastName } from "@/helpers/user";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginLeft: theme.spacing(0.5)
  },
  flexbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  label: {
    fontSize: 12
  }
}));

export function CheckboxColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const classes = useStyles();
  // Calculate the options for filtering using the preFilteredRows
  const options = useMemo(() => {
    const optionsSet = new Set();

    const uniqueOptions = preFilteredRows
      .map((row) => row.values[id])
      .filter((value) => {
        if (!value || optionsSet.has(value)) {
          return false;
        }
        optionsSet.add(value);
        return true;
      });

    return _.sortBy(uniqueOptions);
  }, [id, preFilteredRows]);

  const handleChange = (event) => {
    if (!event.target.checked) {
      let newFilterArray = filterValue.filter((opt) => opt !== event.target.name);

      setFilter(newFilterArray);
    } else {
      let newFilterArray = filterValue ? [...filterValue, event.target.name] : [event.target.name];

      setFilter(newFilterArray);
    }
  };

  return (
    <FormControl required component="fieldset" className={classes.formControl} variant="standard">
      <FormGroup className={classes.flexbox}>
        {options &&
          options.map((opt, index) => {
            return (
              <FormControlLabel
                key={`${opt}-${index}`}
                classes={{
                  label: classes.label
                }}
                size="small"
                control={
                  <Checkbox
                    style={{ fontSize: 12 }}
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckedIcon />}
                    indeterminateIcon={<IndeterminateIcon />}
                    checked={filterValue ? filterValue.indexOf(opt) > -1 : false}
                    onChange={handleChange}
                    name={opt}
                  />
                }
                label={id === "searchOwner" ? truncatedLastName(opt) : opt}
              />
            );
          })}
      </FormGroup>
    </FormControl>
  );
}
