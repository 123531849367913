/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from "react";

// 3rd party libraries
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormControl,
  Select,
  MenuItem,
  useTheme,
  CircularProgress,
  IconButton
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";

// style things
import { colors } from "@/styles/config";

// helpers
import { findStatusObject, statuses } from "@/helpers/statuses";
import { prettyName, superAccess } from "@/helpers/user";

// forms
import SubmissionStatusChangeForm from "@/components/forms/SubmissionStatusChangeForm";
import { toast } from "react-toastify";

// styles
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: "100%",
    borderRadius: 3,
    paddingRight: theme.spacing(0.5)
  },
  statusText: {
    fontSize: 14
  }
}));

const getStatusChangeOptions = (status) => {
  const statusOptionsMap = {
    "New Lead": [
      "New Lead",
      "Internally Submitted",
      "Web Response Accepted",
      "Web Response Rejected",
      "Variner Rejected"
    ],
    "Select Recruit": [
      "Internally Submitted",
      "Select Recruit",
      "Variner Pursuing",
      "Pending Interest",
      "Candidate Rejected",
      "Variner Rejected"
    ],
    "Variner Pursuing": [
      "Internally Submitted",
      "Select Recruit",
      "Variner Pursuing",
      "Pending Interest",
      "Candidate Rejected",
      "Variner Rejected"
    ],
    "Internally Submitted": [
      "Pending Interest",
      "Variner Pursuing",
      "Select Recruit",
      "Web Response Accepted",
      "Internally Submitted",
      "Candidate Rejected",
      "Client Rejected",
      "Variner Rejected"
    ],
    "Pending Interest": ["Pending Interest", "Candidate Rejected", "Variner Rejected"],
    "Confirmed Interest": ["Client Submission", "Confirmed Interest", "Variner Rejected"],
    "Holding Pattern": [
      "Final Interview",
      "Case Study",
      "Initial Interview",
      "Holding Pattern",
      "Candidate Rejected",
      "Client Rejected",
      "Variner Rejected"
    ],
    "Client Submission": [
      "Initial Interview",
      "Client Submission",
      "Holding Pattern",
      "Candidate Rejected",
      "Client Rejected",
      "Variner Rejected"
    ],
    "Initial Interview": [
      "Final Interview",
      "Case Study",
      "Initial Interview",
      "Holding Pattern",
      "Candidate Rejected",
      "Client Rejected",
      "Variner Rejected"
    ],
    "Case Study": [
      "Final Interview",
      "Case Study",
      "Initial Interview",
      "Holding Pattern",
      "Candidate Rejected",
      "Client Rejected",
      "Variner Rejected"
    ],
    "Final Interview": [
      "Final Interview",
      "Case Study",
      "Initial Interview",
      "Holding Pattern",
      "Candidate Rejected",
      "Client Rejected",
      "Variner Rejected"
    ]
  };

  return statusOptionsMap[status];
};

export default function SubmissionStatusSelect({
  currentSubmission: currentSubmissionProp,
  currentSubmissionId,
  backgroundColor = colors.white
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { user } = useSelector((state) => state.user);
  const { activeSubmissions } = useSelector((state) => state.submissions);

  const {
    submissions: jobSubmissions,
    currentJob,
    updatingSubmission
  } = useSelector((state) => state.jobs);

  const submissions = jobSubmissions || activeSubmissions;

  const [showDialog, setShowDialog] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(false);
  const lastStatusRef = useRef();

  const currentSubmission =
    currentSubmissionProp || submissions.find((s) => s.id === currentSubmissionId);

  const handleStatusChange = useCallback(
    (newValue) => {
      if (newValue !== lastStatusRef.current) {
        lastStatusRef.current = selectedStatus;
        setSelectedStatus(newValue);
      }
    },
    [lastStatusRef, selectedStatus]
  );

  useEffect(() => {
    if (currentSubmission) {
      handleStatusChange(currentSubmission?.status);
    }
  }, [currentSubmission, submissions, handleStatusChange]);

  const handleSelectionDialogOpen = (newValue) => {
    setShowDialog(true);
    setSelectedStatus(newValue);
  };

  const handleOpenAlert = () => {
    setAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const handleCloseDialog = (cancelled) => {
    if (cancelled) setSelectedStatus(lastStatusRef.current);
    setShowDialog(false);
  };

  const doUpdateSubmissionStatus = async (payload, callback) => {
    try {
      await dispatch.jobs.updateSubmissionStatus(payload);

      await dispatch.jobs.startupJob({
        id: payload.jobId,
        dashboard: true,
        status: Object.keys(statuses).map((key) => statuses[key].bhLabel)
      });

      toast.success("Successfully updated submission status.");

      callback();
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const handleUpdateSubmissionWithComments = async (values) => {
    let requestData = {
      id: currentSubmission?.id,
      status: values.status,
      submissions,
      jobId: currentJob.id,
      candidateId: currentSubmission?.candidate.id,
      userId: user.id,
      comments: values.comments
    };

    const currentOwner = _.get(currentSubmission, ["sendingUser", "id"]);

    const currentCandidateOwner = _.get(currentSubmission, ["candidate", "owner", "id"]);

    const rejectedStatus = findStatusObject(values.status).group === "Rejected";

    if (!rejectedStatus) {
      // if the current submission is moving out of new lead and the owner is unassigned user, set as current logged in user
      if (currentSubmission?.status === "New Lead" && currentOwner && currentOwner === 1) {
        requestData.submissionOwnerId = user.id;
      }

      // if the current submission's candidate owner is unassigned user, assign them to current logged in user
      if (!currentCandidateOwner || (currentCandidateOwner && currentCandidateOwner === 1)) {
        requestData.candidateOwnerId = user.id;
      }
    }

    await doUpdateSubmissionStatus(requestData, () => handleCloseDialog());
  };

  const handleSubmissionStatusChange = async (event) => {
    const changed = currentSubmission?.status !== event.target.value;

    const currentCandidateOwner = _.get(currentSubmission, ["candidate", "owner", "id"]);

    if (!changed) return;

    handleStatusChange(event.target.value);

    const rejectedStatus = findStatusObject(event.target.value).group === "Rejected";

    if (!rejectedStatus) {
      // if the current submission's candidate owner is not the logged in user and is assigned to another user (not unassigned user), alert them, otherwise finish the status update with submission and candidate owner updates
      if (
        currentSubmission?.status === "New Lead" &&
        currentCandidateOwner &&
        currentCandidateOwner !== user.id &&
        currentCandidateOwner !== 1
      ) {
        handleStatusChange(selectedStatus);
        handleOpenAlert();
      }
    }

    handleSelectionDialogOpen(event.target.value);
  };

  const statusChangeOptions = getStatusChangeOptions(currentSubmission?.status);

  const isNotRejected =
    (!statusChangeOptions || !!statusChangeOptions.length === 0) &&
    currentSubmission?.status.indexOf("Candidate Rejected") < 0;

  const isRejected =
    (!statusChangeOptions || !!statusChangeOptions.length === 0) &&
    currentSubmission?.status.indexOf("Candidate Rejected") > -1;

  return (
    <Box>
      <Dialog onClose={handleCloseDialog} open={showDialog} fullWidth={true} maxWidth={"sm"}>
        {updatingSubmission && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            py={4}
            style={{ width: "100%", height: "100%" }}
          >
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <Typography variant="h3">Updating Submission</Typography>
            </Box>
            <CircularProgress color="inherit" style={{ marginTop: theme.spacing(2) }} />
          </Box>
        )}
        {!updatingSubmission && (
          <React.Fragment>
            <IconButton
              size="small"
              color="inherit"
              onClick={() => handleCloseDialog(true)}
              style={{
                alignSelf: "flex-end",
                margin: 8,
                position: "absolute",
                color: colors.gray43
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <Box
              py={4}
              px={4}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Typography style={{ color: colors.gray43, fontSize: 12 }}>Move Candidate</Typography>
              <SubmissionStatusChangeForm
                onCancel={() => handleCloseDialog(true)}
                onSubmit={handleUpdateSubmissionWithComments}
                values={{ status: selectedStatus }}
                currentSubmission={currentSubmission}
              />
            </Box>
          </React.Fragment>
        )}
      </Dialog>

      <Dialog
        open={alertOpen}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{`${prettyName(
          currentSubmission?.candidate
        )} has an existing Variner contact`}</DialogTitle>
        <DialogContent>
          <Typography color="primary" variant="body2">
            {`${currentSubmission?.candidate.firstName} ${
              currentSubmission?.candidate.lastName
            } already has a candidate owner - ${prettyName(
              currentSubmission?.candidate.owner
            )}. Discuss this submission with the candidate owner or the Account Manager before moving forward.`}
          </Typography>
          {/* <Typography
            color="primary"
            variant="body2"
            style={{ marginTop: theme.spacing(1) }}
          >
            Please discuss this submission with the candidate owner before
            moving forward.
          </Typography> */}
          {/* <Typography
            color="primary"
            variant="body2"
            style={{ marginTop: theme.spacing(1) }}
          >
            Would you like to continue?
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} color="primary" autoFocus>
            Close
          </Button>
          {/* <Button onClick={handleCloseAlert} color="primary">
            Continue
          </Button> */}
        </DialogActions>
      </Dialog>
      {/* If submission is rejected status, show text only without ability to select new submission status */}
      {isNotRejected && (
        <Typography
          className={classes.statusText}
          style={{
            fontSize: 12,
            color:
              backgroundColor.toLowerCase() === "#ffffff"
                ? "rgba(255, 255, 255, 0.6)"
                : colors.black
          }}
        >
          {currentSubmission?.status}
        </Typography>
      )}
      {isRejected && (
        <Box style={{ minWidth: 125 }}>
          <Typography
            className={classes.statusText}
            style={{
              fontSize: 12,
              color:
                backgroundColor.toLowerCase() === "#ffffff"
                  ? "rgba(255, 255, 255, 0.6)"
                  : colors.black
            }}
          >
            {currentSubmission?.status.split("-")[0]}
          </Typography>
          <Typography
            style={{
              fontSize: 10,
              fontStyle: "italic",
              color:
                backgroundColor.toLowerCase() === "#ffffff"
                  ? "rgba(255, 255, 255, 0.6)"
                  : colors.black
            }}
          >
            {currentSubmission?.status.split("-")[1]}
          </Typography>
        </Box>
      )}

      {/* for all non rejected statuses, show select dropdown */}
      {statusChangeOptions && !!(statusChangeOptions.length > 0) && (
        <FormControl className={classes.formControl} style={{ backgroundColor }} variant="standard">
          <Select
            IconComponent={() => (
              <KeyboardArrowDownIcon
                style={{
                  height: 18,
                  width: 18
                }}
              />
            )}
            value={selectedStatus}
            onChange={handleSubmissionStatusChange}
            label="Status"
            style={{ fontSize: 12, paddingLeft: 8 }}
            disabled={!superAccess(user)}
            // user.occupation !== "Partner" &&
            // user.id !== 16 &&
            // user.username !== "15379.unassigned"
            variant="standard"
          >
            {statusChangeOptions &&
              !!statusChangeOptions.length > 0 &&
              statusChangeOptions.map((option) => {
                const getColor = () => {
                  if (option.indexOf("Reject") > -1) {
                    return colors.red;
                  }

                  if (option.indexOf("Holding") > -1) {
                    return colors.varinerGold;
                  }

                  return "inherit";
                };

                return (
                  <MenuItem
                    key={option}
                    style={{
                      fontSize: 12,
                      color: getColor()
                    }}
                    value={option}
                    disabled={option === selectedStatus}
                  >
                    {option}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}
