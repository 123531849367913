import { createTheme } from "@mui/material/styles";
import { colors } from "./config";

const base = createTheme({
  palette: {
    common: {
      black: "#000000",
      white: "#ffffff"
    },
    primary: {
      light: "#3E9FAC",
      main: "#1B444A",
      dark: "#1B3B40",
      contrastText: colors.white
    },
    secondary: {
      light: "#EEEEEE",
      main: "#FFFFFF",
      dark: "#FBA926"
    },
    primaryLight: {
      main: "#3E9FAC"
    },
    alternateTextColor: colors.darkGray,
    error: {
      xLight: colors.salmon,
      main: colors.red
    },
    warning: {
      main: colors.varinerGold,
      dark: "#ffb25e"
    },
    info: {
      main: colors.varinerGold
    },
    success: {
      main: colors.varinerLight
    },
    grey: {
      50: colors.gray3,
      100: colors.gray6,
      200: colors.gray10,
      300: colors.gray13,
      500: colors.gray43
    },
    text: {
      primary: colors.black,
      secondary: colors.white
    },
    background: {
      paper: colors.white,
      default: colors.gray3
    }
  },
  typography: {
    fontFamily: "'proxima-nova', 'Roboto', 'Helvetica', sans-serif",
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightSemibold: 600,
    fontWeightBold: 700
  },
  overrides: {
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "none"
      }
    },
    MuiButton: {
      outlinedSecondary: {
        borderWidth: 2,
        "&:hover": {
          border: "2px solid #ffffff !important"
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: "#333333"
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: 12,
        color: colors.gray43,
        textAlign: "right"
      }
    },
    MuiDropzoneArea: {
      root: {
        minHeight: 80,
        borderColor: "rgba(0, 0, 0, 0.23)",
        borderWidth: 2
      }
    },
    MuiSelect: {
      select: {
        paddingLeft: 8
      },
      icon: {
        fontSize: 22,
        color: "black"
      }
    },
    MuiCheckbox: {
      root: {
        color: colors.variner
      }
    },
    MuiRadio: {
      root: {
        color: colors.variner
      }
    },
    MuiTablePagination: {
      input: {
        fontSize: 14
      }
    },
    MuiTableRow: {
      root: {
        "&:hover": {
          backgroundColor: "rgba(238, 238, 238, 0.6)",
          cursor: "pointer"
        }
      },
      head: {
        "&:hover": {
          backgroundColor: "initial",
          cursor: "default"
        }
      }
    }
  }
});

const headingText = {
  // fontWeight: base.typography.fontWeightSemibold,
  fontWeight: base.typography.fontWeightRegular,
  fontFamily: base.typography.fontFamily,
  letterSpacing: "-0.04em"
};

const theme = {
  ...base,
  palette: {
    ...base.palette,
    background: {
      ...base.palette.background,
      default: base.palette.common.white,
      placeholder: base.palette.grey[200]
    }
  },
  typography: {
    ...base.typography,
    h1: {
      ...base.typography.h1,
      ...headingText,
      fontSize: 36
    },
    h2: {
      ...base.typography.h2,
      ...headingText,
      fontSize: 28
    },
    h3: {
      ...base.typography.h3,
      ...headingText,
      fontSize: 20
    },
    h4: {
      ...base.typography.h4,
      ...headingText,
      fontSize: 16
    },
    h5: {
      ...base.typography.h5,
      fontSize: 12,
      fontWeight: base.typography.fontWeightSemibold
    },
    h6: {
      ...base.typography.h6,
      ...headingText,
      fontSize: 10,
      fontWeight: base.typography.fontWeightRegular
    },
    body1: {
      ...base.typography.body2,
      fontWeight: base.typography.fontWeightRegular,
      fontSize: 16
    },
    body2: {
      ...base.typography.body1,
      fontSize: 14
    },
    subtitle1: {
      ...base.typography.subtitle1,
      textTransform: "uppercase",
      color: colors.gray43,
      fontWeight: base.typography.fontWeightSemibold,
      fontSize: 12
    },
    subtitle2: {
      ...base.typography.subtitle2,
      fontWeight: base.typography.fontWeightRegular,
      fontSize: 12
    },
    button: {
      ...base.typography.button,
      textTransform: "uppercase",
      letterSpacing: "-0.01em",
      fontSize: 12
    }
  }
};

export default theme;
