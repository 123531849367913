/* eslint-disable react/prop-types */
import React from "react";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";
import { colors } from "../styles/config";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%"
  },
  progressContainer: {
    borderRadius: 12,
    width: "100%",
    marginRight: theme.spacing(1)
  },
  progress: {
    borderRadius: 12
  },
  labelBefore: {
    // minWidth: 125,
  }
}));
//NOTE THIS IS CURRENTLY DEPRECATED IN V2
export default function ProgressBar({
  labelAfter,
  labelBefore,
  value,
  backgroundColor = colors.variner,
  progressColor = colors.varinerLight,
  height = 6,
  labelStyle = {},
  labelAfterStyle = {},
  style = {}
}) {
  const classes = useStyles();
  const theme = useTheme();

  const fullNumberWidth = Math.round(value);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      className={classes.container}
      style={style}
    >
      {labelBefore && (
        <Typography className={classes.labelBefore} style={labelStyle}>
          {labelBefore}
        </Typography>
      )}

      <Box
        className={classes.progressContainer}
        style={{
          height,
          backgroundColor,
          marginLeft: labelBefore ? theme.spacing(1) : 0
        }}
      >
        <Box
          className={classes.progress}
          style={{
            height: "100%",
            width: `${fullNumberWidth}%`,
            backgroundColor: progressColor
          }}
        ></Box>
      </Box>
      {(labelAfter || labelAfter === 0) && (
        <Typography style={{ ...labelStyle, minWidth: 0, ...labelAfterStyle }}>
          {labelAfter}
        </Typography>
      )}
    </Box>
  );
}
