/* eslint-disable react/prop-types */
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, IconButton, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import clsx from "clsx";
import SellingPoints from "@/components/SellingPoints";
import { colors } from "@/styles/config";

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    backgroundColor: colors.darkGray,
    display: "flex",
    justifyContent: "space-between"
  },
  drawerTabContent: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    maxWidth: 900,
    position: "relative"
  },
  chevronIcon: {
    marginRight: theme.spacing(3),
    color: colors.white,
    transition: "transform 400ms cubic-bezier(0, 0, 0.2, 1)"
  },
  rotateIcon: {
    transform: "rotate(180deg)"
  },
  drawer: {
    width: "775px",
    transition: "width 400ms cubic-bezier(0, 0, 0.2, 1)"
  },
  expandedDrawer: {
    width: "80vw"
  }
}));

export default function SellingPointsDrawer({
  open,
  toggleDrawer,
  componentTitle,
  style = {},
  titleStyle = { fontWeight: 600, fontSize: 20 }
}) {
  const theme = useTheme();
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleToggleDrawer = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open.open}
        onClose={() => toggleDrawer({ open: false, type: null })}
      >
        <Box
          className={clsx(style, classes.drawer, {
            [classes.expandedDrawer]: expanded
          })}
          role="presentation"
        >
          <Box className={classes.drawerHeader}>
            <IconButton
              className={clsx(classes.chevronIcon, {
                [classes.rotateIcon]: expanded
              })}
              aria-label="Expand"
              onClick={handleToggleDrawer}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h5" color={colors.white}>
                {componentTitle}
              </Typography>
              <Typography
                sx={{ ...titleStyle, marginTop: theme.spacing(1) }}
                variant="h2"
                color={colors.white}
              >
                {open.type}
              </Typography>
            </Box>
            <IconButton
              sx={{ color: colors.white }}
              aria-label="Close"
              onClick={() => {
                toggleDrawer({ open: false, type: null });
                setExpanded(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.drawerTabContent}>
            {componentTitle === "Selling Points" && <SellingPoints id={open.id} />}
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
