import React from "react";
import PropTypes from "prop-types";
import { TextField, FormControl, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ErrorMessage } from "formik";

const useStyles = makeStyles(() => ({
  maxedChars: {
    borderColor: "red"
  },
  formControl: {
    marginTop: 16,
    marginBottom: 8,
    minWidth: "100%"
  },
  counteredChars: {
    position: "absolute",
    bottom: 8,
    right: 0
  }
}));

export default function TextArea({
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  characterLimit,
  rows,
  fullWidth = true,
  margin = "normal",
  InputLabelProps = { shrink: true },
  helperText,
  errorMessage = null
}) {
  const classes = useStyles();

  const hasMaxCharactersUsed = value.length >= characterLimit;

  return (
    <FormControl className={classes.formControl}>
      <TextField
        multiline
        rows={rows}
        inputProps={{
          maxLength: characterLimit
        }}
        helperText={helperText}
        className={hasMaxCharactersUsed ? "maxed-chars" : ""}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        placeholder={placeholder}
        fullWidth={fullWidth}
        margin={margin}
        InputLabelProps={InputLabelProps}
      />
      <Typography
        variant="body2"
        className={classes.counteredChars}
        color={hasMaxCharactersUsed ? "#ff5442" : "GrayText"}
      >
        {`${value.length}/${characterLimit}`}
      </Typography>

      {errorMessage && <ErrorMessage message={errorMessage} />}
    </FormControl>
  );
}

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  characterLimit: PropTypes.number.isRequired,
  rows: PropTypes.number,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  InputLabelProps: PropTypes.object,
  helperText: PropTypes.node,
  errorMessage: PropTypes.string
};
