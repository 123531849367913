/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Box, FormControl, Select, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import WysiwygEditor from "./Editor";
import ErrorMessage from "./ErrorMessage";
import { statuses } from "@/helpers/statuses";
import { colors } from "../../styles/config";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 0,
    minWidth: "100%"
  },
  select: {
    fontSize: 16,
    fontWeight: "bold",
    color: colors.varinerLight,
    paddingTop: 5,
    paddingBottom: 5
  }
}));

const getStatusChangeOptions = (status) => {
  if (status === "Candidate Rejected") {
    const candidateRejectedOpts = Object.keys(statuses)
      .filter((statusKey) => statusKey.indexOf("CANDIDATE_REJECTED") > -1)
      .map((statusKey) => {
        return statuses[statusKey].bhLabel;
      });

    return candidateRejectedOpts;
  } else {
    return [status];
  }
};

export default function SubmissionStatusChangeForm({
  onSubmit,
  onCancel,
  currentSubmission,
  values = {}
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [editorFocused, setEditorFocused] = useState(null);

  const initialValues = {
    status: "",
    comments: ""
  };

  const data = {
    ...initialValues,
    ...values
  };

  const validationSchema = yup.object().shape({
    status: yup
      .string()
      .notOneOf(["Candidate Rejected"])
      .required("Please select a detailed rejection status"),
    comments: yup.string().required("Please enter comments")
  });

  return (
    <Formik initialValues={data} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({
        values,
        setFieldTouched,
        errors,
        touched,
        dirty,
        handleSubmit,
        setFieldValue,
        resetForm
      }) => {
        const statusChangeOptions = getStatusChangeOptions(values.status);

        return (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            style={{ width: "100%" }}
          >
            <Box display="flex" flexDirection="row" mt={1.5} mb={4}>
              <Typography
                style={{
                  marginRight: theme.spacing(0.5)
                }}
              >{`${currentSubmission.candidate.firstName} ${currentSubmission.candidate.lastName} to `}</Typography>
              <FormControl variant="standard">
                <Select
                  variant="standard"
                  value={values.status}
                  onChange={(event) => setFieldValue("status", event.target.value)}
                  label="Status"
                  style={{ fontSize: 12 }}
                  classes={{
                    select: classes.select
                  }}
                >
                  {statusChangeOptions &&
                    !!statusChangeOptions.length > 0 &&
                    statusChangeOptions.map((option) => {
                      const getOptionColor = () => {
                        if (option.indexOf("Reject") > -1) {
                          colors.red;
                        }

                        if (option.indexOf("Holding") > -1) {
                          colors.varinerGold;
                        }

                        return "inherit";
                      };

                      return (
                        <MenuItem
                          key={option}
                          style={{
                            fontSize: 12,
                            color: getOptionColor
                          }}
                          value={option}
                          disabled={
                            ["Candidate Rejected", "Client Rejected", "Variner Rejected"].indexOf(
                              option
                            ) > -1
                          }
                        >
                          {option}
                        </MenuItem>
                      );
                    })}
                </Select>
                {errors.status && touched.comments ? (
                  <ErrorMessage
                    style={{
                      bottom: -20,
                      width: 200,
                      left: -15
                    }}
                    message={"Please select a detailed rejection status."}
                  />
                ) : null}
              </FormControl>
            </Box>
            <Box display="flex" flexDirection="column" mb={4} width={"100%"}>
              <FormControl
                className={classes.formControl}
                style={{
                  marginTop: 0,
                  border: editorFocused
                    ? `2px solid ${colors.variner}`
                    : "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: 4
                }}
                variant="standard"
              >
                <WysiwygEditor
                  // reset={resetting}
                  onBlur={() => setEditorFocused(null)}
                  onFocus={() => setEditorFocused(true)}
                  varinerCommentsHtml={null}
                  onChange={(string) => {
                    setFieldValue("comments", string);

                    setFieldTouched("comments", true);
                  }}
                />
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="flex-end" style={{ width: "100%" }}>
              <Button
                size="medium"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disabled={!dirty || (dirty && errors && Object.keys(errors).length) ? true : false}
                onClick={handleSubmit}
                style={{
                  marginLeft: theme.spacing(1)
                }}
              >
                Save & Move Candidate
              </Button>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}
