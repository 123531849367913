/* eslint-disable react/prop-types */
import React, { useMemo } from "react";

import StatCardV2 from "./reports/Cards/StatCardV2";
import ReportingCardRow from "./ReportingCardRow";
import { PERCENTAGE_CHANGE } from "@/helpers/quotas";

export default function AtAGlanceCard({
  title,
  topStat,
  comparisonStat,
  bottomStat,
  dateSort,
  formatter,
  statDivider,
  loading = false
}) {
  const comparisonNumbers = useMemo(() => {
    return PERCENTAGE_CHANGE({ topStat, comparisonStat });
  }, [topStat, comparisonStat]);

  return (
    <StatCardV2
      title={title}
      topStat={formatter ? formatter(topStat) : topStat}
      bottomStat={bottomStat}
      loading={loading}
      bottomComponent={
        dateSort ? (
          <ReportingCardRow
            dateSort={dateSort}
            changePercent={comparisonNumbers.percentChange}
            type={comparisonNumbers.positive ? "increase" : "decrease"}
          />
        ) : (
          <></>
        )
      }
      statDivider={statDivider}
    />
  );
}
