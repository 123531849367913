import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NOT_AVAILABLE_INFORMATION } from "@/helpers/constants";
import { LoadingOrContent } from "@/components";
import moment from "moment";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Divider, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import makeStyles from "@mui/styles/makeStyles";

import { colors } from "../../src/styles/config";
import { SellingPointsSwitch } from "./SellingPointsSwitch";

const useStyles = makeStyles((theme) => ({
  sellingPointsWrapper: {
    paddingTop: 0
  },
  cardInnerContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "calc(100% - 32px)"
  },
  cardTitle: {
    color: colors.black
  },
  divider: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100%"
  },
  container: {
    maxWidth: 1600,
    marginTop: 20
  },
  backIcon: {
    width: 16
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  fullWidth: {
    width: "100%"
  }
}));

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export default function SellingPoints(id) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [jobId] = useState(id.id);
  const { activeJobs, attachedFile, loading } = useSelector((state) => state.jobs);
  const [fileId, setFileId] = useState();
  const [fileName, setFileName] = useState();
  const [value, setValue] = useState("1");

  const [showFullSellingPoints, setShowFullSellingPoints] = useState(false);

  const job = useMemo(() => {
    // console.log(jobId);
    return activeJobs && activeJobs.length && activeJobs.find((j) => j.id === jobId);
  }, [activeJobs, jobId]);

  useEffect(() => {
    if (job) {
      job.fileAttachments &&
        job.fileAttachments.data &&
        job.fileAttachments.data.forEach((e) => {
          if (e.type === "Additional Info. Deck") {
            setFileId(e.id);
            setFileName(e.name);
          }
        });
    }
  }, [job]);

  useEffect(() => {
    if (fileId) {
      dispatch.jobs.fetchAttachedFile({ id: jobId, fileId: fileId });
    }
  }, [fileId, jobId, dispatch.jobs]);

  useEffect(() => {
    if (job) {
      dispatch.jobs.setAttachedFile(null);
      dispatch.jobs.setCurrentJob(job);
    }
    return function cleanup() {
      setFileId(null);
      dispatch.app.setCurrentPageTitle(null);
    };
  }, [job, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sellingPointsTabContent = showFullSellingPoints
    ? job?.correlatedCustomTextBlock1 ?? NOT_AVAILABLE_INFORMATION
    : job?.customTextBlock1 ?? NOT_AVAILABLE_INFORMATION;

  const handleToggleSellingPointsType = () => {
    setShowFullSellingPoints((showFullSellingPoints) => !showFullSellingPoints);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab style={{ color: colors.variner }} label="Overview" value="1" />
            <Tab style={{ color: colors.variner }} label="Selling Points" value="2" />
            <Tab style={{ color: colors.variner }} label="Attachments" value="3" />
            <Tab style={{ color: colors.variner }} label="Ideal Candidate" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.cardInnerContainer}
          >
            <Box className={classes.row}>
              <Typography variant="subtitle1" className={classes.cardTitle}>
                Reason for Hire
              </Typography>
              {job && (
                <Box>
                  <Typography>{job.correlatedCustomText2}</Typography>
                </Box>
              )}
            </Box>
            {/* ------------ */}
            <Divider dark orientation="horizontal" className={classes.divider} />
            <Box className={classes.row}>
              <Typography variant="subtitle1" className={classes.cardTitle}>
                Remote Allowed
              </Typography>
              {job && (
                <Box>
                  <Typography>{job.customText5 || "Unknown"}</Typography>
                </Box>
              )}
            </Box>
            {/* ------------ */}
            <Divider className={classes.divider} />
            <Box className={classes.row}>
              <Typography variant="subtitle1" className={classes.cardTitle}>
                Location & Travel
              </Typography>
            </Box>
            {job && (
              <Box className={classes.fullWidth}>
                <Box className={classes.row}>
                  <Typography variant="subtitle2">Preferred Location</Typography>
                  <Typography>
                    `${job?.address?.city}, ${job?.address?.state}`
                  </Typography>
                </Box>
                {job.customText6 && (
                  <Box mt={1} className={classes.row}>
                    <Typography variant="subtitle2">Secondary Location</Typography>
                    <Typography>{job.customText6}</Typography>
                  </Box>
                )}
                {job.travelRequirements && (
                  <Box mt={1} className={classes.row}>
                    <Typography variant="subtitle2">Travel Requirements</Typography>
                    <Typography>{job.travelRequirements}</Typography>
                  </Box>
                )}
              </Box>
            )}
            {/* ------------ */}
            <Divider className={classes.divider} />
            <Box className={classes.row}>
              <Typography variant="subtitle1" className={classes.cardTitle}>
                Target Start Date
              </Typography>
              {job && (
                <Box>
                  <Typography>{moment(job.startDate).format("MMM DD, YYYY")}</Typography>
                </Box>
              )}
            </Box>
            {/* ------------ */}
            <Divider className={classes.divider} />
            <Box className={classes.row}>
              <Typography variant="subtitle1" className={classes.cardTitle}>
                Compensation Info
              </Typography>
            </Box>
            {job && (
              <Box className={classes.fullWidth}>
                {!!job.correlatedCustomFloat1 && (
                  <Box className={classes.row}>
                    <Typography variant="subtitle2">Base Salary Low</Typography>
                    <Typography>{usdFormatter.format(job.correlatedCustomFloat1)}</Typography>
                  </Box>
                )}
                {!!job.correlatedCustomFloat2 && (
                  <Box mt={1} className={classes.row}>
                    <Typography variant="subtitle2">Base Salary High</Typography>
                    <Typography>{usdFormatter.format(job.correlatedCustomFloat2)}</Typography>
                  </Box>
                )}
                {!!job.correlatedCustomInt1 && (
                  <Box mt={1} className={classes.row}>
                    <Typography variant="subtitle2">Target Bonus % Low</Typography>
                    <Typography>{`${job.correlatedCustomInt1}%`}</Typography>
                  </Box>
                )}
                {!!job.correlatedCustomInt2 && (
                  <Box mt={1} className={classes.row}>
                    <Typography variant="subtitle2">Target Bonus % High</Typography>
                    <Typography>{`${job.correlatedCustomInt2}%`}</Typography>
                  </Box>
                )}
                {!!job.correlatedCustomText9 && (
                  <Box mt={1} className={classes.row}>
                    <Typography variant="subtitle2">Equity Compensation</Typography>
                    <Typography>{job.correlatedCustomText9}</Typography>
                  </Box>
                )}
                {!!job.correlatedCustomText10 && (
                  <Box mt={1} className={classes.row}>
                    <Typography variant="subtitle2">Equity Types</Typography>
                    <Typography>{job.correlatedCustomText10}</Typography>
                  </Box>
                )}
                {!!job.correlatedCustomFloat1 && !!job.correlatedCustomInt1 && (
                  <Box className={classes.row}>
                    <Typography variant="subtitle2">All In Low End</Typography>
                    <Typography>
                      {usdFormatter.format(
                        job.correlatedCustomFloat1 +
                          job.correlatedCustomFloat1 * (parseFloat(job.correlatedCustomInt1) / 100)
                      )}
                    </Typography>
                  </Box>
                )}
                {!!job.correlatedCustomFloat2 && !!job.correlatedCustomInt2 && (
                  <Box className={classes.row}>
                    <Typography variant="subtitle2">All In High End</Typography>
                    <Typography>
                      {usdFormatter.format(
                        job.correlatedCustomFloat2 +
                          job.correlatedCustomFloat2 * (parseFloat(job.correlatedCustomInt2) / 100)
                      )}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value="2" className={classes.sellingPointsWrapper}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.cardInnerContainer}
          >
            <SellingPointsSwitch
              id="selling-points-type"
              showFullSellingPoints={showFullSellingPoints}
              onChange={handleToggleSellingPointsType}
            />
            {job && (
              <Box
                className="external-html-container MuiTypography-body2"
                dangerouslySetInnerHTML={{
                  __html: sellingPointsTabContent
                }}
              ></Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value="3">
          <LoadingOrContent isLoading={loading}>
            {attachedFile ? (
              <Button
                href={`data:application/pdf;base64,${attachedFile}`}
                download={fileName}
                variant="contained"
              >
                Download Attachments
              </Button>
            ) : (
              <Typography variant="body1">No attachments available.</Typography>
            )}
          </LoadingOrContent>
        </TabPanel>
        <TabPanel value="4">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.cardInnerContainer}
          >
            {job && (
              <Box
                className="external-html-container MuiTypography-body2"
                dangerouslySetInnerHTML={{
                  __html: job.idealCandidate ?? NOT_AVAILABLE_INFORMATION
                }}
              ></Box>
            )}
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
}
