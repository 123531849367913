export const getCandidatesByOwner = (newCandidates) => {
  if (!newCandidates) return;

  return newCandidates?.reduce((acc, curr) => {
    const ownerId = curr.owner.id;

    if (acc[ownerId]) {
      acc[ownerId] = {
        ...acc[ownerId],
        newCandidates: [...acc[ownerId].newCandidates, curr]
      };
    } else {
      acc[ownerId] = {
        owner: `${curr.owner.firstName} ${curr.owner.lastName}`,
        newCandidates: [curr]
      };
    }

    return acc;
  }, {});
};

export const getNotesByOwner = (newNotes) => {
  if (!newNotes) return;

  return newNotes.reduce((acc, curr) => {
    const ownerId = curr.commentingPerson.id;

    if (acc[ownerId]) {
      acc[ownerId] = {
        ...acc[ownerId],
        notes: [...acc[ownerId].notes, curr]
      };
    } else {
      acc[ownerId] = {
        owner: `${curr.commentingPerson.firstName} ${curr.commentingPerson.lastName}`,
        notes: [curr],
        ownerId: curr.commentingPerson.id
      };
    }

    return acc;
  }, {});
};

export const getSubmissionsByOwner = (newSubmissions) => {
  if (!newSubmissions) return;

  return newSubmissions.reduce((acc, curr) => {
    const ownerId = curr.sendingUser.id;

    if (acc[ownerId]) {
      acc[ownerId] = {
        ...acc[ownerId],
        submissions: [...acc[ownerId].submissions, curr]
      };
    } else {
      acc[ownerId] = {
        owner: `${curr.sendingUser.firstName} ${curr.sendingUser.lastName}`,
        submissions: [curr]
      };
    }

    return acc;
  }, {});
};

export const getPlacementsByOwner = (newPlacements) => {
  if (!newPlacements) return;

  return newPlacements.reduce((acc, curr) => {
    const ownerId = curr.jobSubmission.sendingUser.id;

    if (acc[ownerId]) {
      acc[ownerId] = {
        ...acc[ownerId],
        newPlacements: [...acc[ownerId].newPlacements, curr]
      };
    } else {
      acc[ownerId] = {
        owner: `${curr.jobSubmission.sendingUser.firstName} ${curr.jobSubmission.sendingUser.lastName}`,
        newPlacements: [curr]
      };
    }

    return acc;
  }, {});
};

export const getSpecificNotesByOwner = (newNotes, action) => {
  // console.log(
  //   "first call",
  //   action,
  //   newNotes.reduce((acc, curr) => {
  //     const ownerId = curr.commentingPerson.id;
  //     const prescreen = curr.action === action;
  //     if (prescreen) {
  //       if (acc[ownerId]) {
  //         acc[ownerId] = {
  //           ...acc[ownerId],
  //           newPrescreens: [...acc[ownerId].newPrescreens, curr],
  //         };
  //       } else {
  //         acc[ownerId] = {
  //           owner: `${curr.commentingPerson.firstName} ${curr.commentingPerson.lastName}`,
  //           newPrescreens: [curr],
  //         };
  //       }
  //     }

  //     return acc;
  //   }, {})
  // );
  if (!newNotes) return;

  const notes = newNotes.reduce((acc, curr) => {
    const ownerId = curr.commentingPerson.id;
    const prescreen = curr.action === action;

    if (prescreen) {
      if (acc[ownerId]) {
        acc[ownerId] = {
          ...acc[ownerId],
          newPrescreens: [...acc[ownerId].newPrescreens, curr]
        };
      } else {
        acc[ownerId] = {
          owner: `${curr.commentingPerson.firstName} ${curr.commentingPerson.lastName}`,
          newPrescreens: [curr]
        };
      }
    }

    return acc;
  }, {});
  return notes;
};
